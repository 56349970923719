import { DirectiveAboutYouFormFields } from 'components/directive/directiveCommonForm/DirectiveAboutYouForm'
import { ApiType } from 'utils/types'

const aboutYouFields: Record<keyof DirectiveAboutYouFormFields, boolean> = {
  email: true,
  first_name: true,
  middle_name: true,
  last_name: true,
}

export function isValidationErrorRelatedToAboutYou(
  error: ApiType['UserDirectiveConstraintResponse']
) {
  const match = error.field?.match(/^user_detail\.(.*)$/)
  return !!(match && match[1] && match[1] in aboutYouFields)
}
