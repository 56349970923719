import { AnalyticsEvent } from 'utils/analytics/event'
import { EventArgs } from 'utils/analytics/types'

export const user_registered = new AnalyticsEvent<Partial<EventArgs['user_registered']> | void>({
  async onFire(ctx) {
    ctx.mutate({ is_signing_up: true, ...ctx.args })

    ctx.fireEvent('user_registered', {
      button: ctx.state.button,
      page: ctx.state.page,
      partner: ctx.state.partner,
      type: ctx.state.type,
    })
  },
})
