import { AnalyticsEvent } from 'utils/analytics/event'
import { pick } from 'utils/general'

/**
 * When user successfully passes the checkout and insurance is activated -
 * either with successfull payment or with a free promo code.
 * OR when user upgrades, downgrades or even cancels the plan =
 * this event is not limited only to purchases/activations as its name could suggest
 */
export const gl_plan_activated = new AnalyticsEvent<{ paid_price: number }>({
  async onFire(ctx) {
    const plan_selected = ctx.history.gl_plan_selected
    if (!plan_selected) return

    ctx.fireEvent('gl_plan_activated', {
      ...pick(plan_selected, 'flow', 'new_plan', 'previous_plan', 'type'),
      base_price: plan_selected.price,
      paid_price: ctx.args.paid_price / 100,
    })
  },
})
