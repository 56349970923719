import { useModal } from 'components/modal/Modal'
import { SButton, SInfoModal, STitle } from './InfoModal.styled'
import { useOuterClick } from 'utils/hooks'
import { Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'

const InfoModal = (props: { title: string; description: string; onClose: () => void }) => {
  const { t } = useTranslation()
  const ref = useOuterClick(props.onClose)
  return (
    <SInfoModal ref={ref}>
      <STitle>{props.title}</STitle>
      <Text css={{ marginBottom: '1rem' }}>{props.description}</Text>
      <SButton type="button" size="large" onClick={props.onClose}>
        {t('common.close')}
      </SButton>
    </SInfoModal>
  )
}

export const useInfoModal = (props: { infoTitle: string; infoDesc: string }) => {
  const [modal, openModal, closeModal] = useModal(({ close, isOpen }) =>
    isOpen ? (
      <InfoModal title={props.infoTitle} description={props.infoDesc} onClose={close} />
    ) : null
  )
  return [modal, openModal, closeModal] as const
}
