import { AnalyticsEvent } from 'utils/analytics/event'
import { determineGroupLifeFlow } from 'utils/analytics/history/determineGroupLifeFlow'
import { getPlanCart } from 'utils/cart'

/**
 * This event is fired when the user gets to the plans selection during the group life insurance flow.
 * This shall include both when it is a user who is new to the product or a user
 * who already is on one of the group life plans
 */
export const gl_plans_open = new AnalyticsEvent<void>({
  async onFire(ctx) {
    const planCart = getPlanCart()

    ctx.fireEvent('gl_plans_open', {
      flow: determineGroupLifeFlow(ctx),
      type: planCart?.isSigningUp ? 'new_user' : 'current_user',
    })
  },
})
