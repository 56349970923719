import { BasicLink } from 'components/Link'
import { Text } from 'components/Typography'
import { AnimatePresence } from 'framer-motion'
import cookie from 'js-cookie'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { colors } from 'utils/colors'
import { SCookieBanner, SCookieButton, SIcon } from './CookieBanner.styled'

export function CookieBanner() {
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    setVisible(!cookie.get('consent'))
  }, [])

  const onClose = () => {
    cookie.set('consent', 'v1')
    setVisible(false)
  }

  return (
    <AnimatePresence>
      {visible && (
        <SCookieBanner
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0, translateZ: 3 }}
          exit={{ opacity: 0, y: 200 }}
        >
          <SIcon name="Cookie" color={colors.gray[500]} className="desktop-only" />

          <Text variant="bodySmall">
            We and <b>our partners</b>
            {` use cookies to personalize your experience, to show offers based on your profile, and for measurement and analytical purposes. When you use GoodTrust and our services, you agree to use our cookies as described in our `}
            <Link href="/cookie-policy" passHref legacyBehavior>
              <BasicLink target="_blank" css={{ whiteSpace: 'nowrap' }}>
                Cookie policy.
              </BasicLink>
            </Link>
          </Text>

          <SCookieButton type="button" block={[true, false]} onClick={onClose}>
            Accept
          </SCookieButton>
        </SCookieBanner>
      )}
    </AnimatePresence>
  )
}
