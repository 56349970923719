import { PlanGroup } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { ApiType } from 'utils/types'

export function groupActiveSubscriptions(
  userMe: ApiType['UserResponse']
): Record<PlanGroup, ApiType['SubscriptionResponse'] | undefined> {
  return {
    ANIMATION: undefined,
    GROUP_LIFE: undefined,
    MAIN: undefined,
    ESTATE: undefined,
    ...Object.fromEntries(
      userMe.active_subscriptions?.map((sub) => {
        if (!sub.type) return []
        const planSpec = planSpecs[sub.type]
        return [planSpec.planGroup, sub]
      }) ?? []
    ),
  }
}
