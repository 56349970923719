import { DropdownHandle } from 'components/dropdown/Dropdown'
import { useEffect, useRef, useState } from 'react'
import { safeWindow } from 'utils/general'
import { useEvent } from 'utils/hooks'

export type SuggestionItem = {
  uuid?: string
  select: () => void
}

export function useKeyboardSelectionLogic(
  suggestionItems: SuggestionItem[],
  onNoSelectedEnterOrTab: () => void
) {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const dropdownHandleRef = useRef<DropdownHandle | null>(null)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [keyboardSelectedId, setKeyboardSelectedId] = useState<string | null | undefined>()

  useEffect(() => {
    if (!keyboardSelectedId) return

    const dropdownElement = dropdownRef.current
    const dropdownItemElement = dropdownElement?.querySelector(
      `[data-item-id="${keyboardSelectedId}"]`
    )
    const dropdownItem =
      dropdownItemElement instanceof HTMLElement ? dropdownItemElement : undefined
    const personList = dropdownItem?.closest('ul')
    if (personList && dropdownItem) {
      personList.scrollTo({
        behavior: 'smooth',
        top: Math.max(0, dropdownItem.offsetTop - dropdownItem.offsetHeight),
      })
    }
  }, [keyboardSelectedId])

  useEffect(() => {
    setKeyboardSelectedId(undefined)
  }, [isDropdownOpen])

  useEvent(safeWindow, 'keydown', (e) => {
    if (!(e instanceof KeyboardEvent)) return
    if (!isDropdownOpen) return

    const isArrowUp = e.key === 'ArrowUp'
    const isArrowDown = e.key === 'ArrowDown'
    const isEnter = e.key === 'Enter' || e.keyCode === 13
    const isTab = e.key === 'Tab' || e.keyCode === 9

    const shouldHandle = isArrowUp || isArrowDown || isEnter || isTab
    if (!shouldHandle) return

    if (isTab || isEnter) {
      e.stopPropagation()
      e.preventDefault()
      if (keyboardSelectedId) {
        const item = suggestionItems.find((item) => item.uuid === keyboardSelectedId)
        item?.select()
        dropdownHandleRef.current?.close()
      } else {
        onNoSelectedEnterOrTab()
      }
      return
    }

    e.preventDefault()

    const plannedVerticalMove = isArrowUp ? -1 : isArrowDown ? 1 : undefined
    if (!plannedVerticalMove) return

    setKeyboardSelectedId((id) => {
      const suggestionIndex = suggestionItems.findIndex((it) => it.uuid === id)
      const nextSuggestionIndex = suggestionIndex + plannedVerticalMove
      const nextItem = suggestionItems[nextSuggestionIndex]
      if (!nextItem) return id
      return nextItem.uuid
    })
  })
  return {
    keyboardSelectedId,
    deselect: () => {
      setKeyboardSelectedId(undefined)
    },
    dropdownProps: {
      ref: dropdownRef,
      handleRef: dropdownHandleRef,
      onOpen: () => setIsDropdownOpen(true),
      onClose: () => setIsDropdownOpen(false),
    },
  }
}
