import { AnalyticsEvent } from 'utils/analytics/event'
import { EventArgs } from 'utils/analytics/types'

/**
 * This event is fired when a user encounters a directive paywall.
 */
export const directive_paywall_hit = new AnalyticsEvent<EventArgs['directive_paywall_hit']>({
  async onFire({ args, ...ctx }) {
    ctx.mutate({
      flow: args.dir_type,
    })
    ctx.fireEvent('directive_paywall_hit', args)
  },
})
