import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { Spacer } from 'components/spacer/Spacer'
import {
  Children,
  cloneElement,
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
  isValidElement,
  ReactNode,
} from 'react'
import { getResponsiveStyles, ResponsiveValue, StyledLinkProps } from 'utils/styled'

type StackOrientation = 'horizontal' | 'vertical'

export const StackDiv = styled('div', {
  shouldForwardProp: (prop) => isPropValid(String(prop)) && prop !== 'orientation',
})<
  {
    orientation?: ResponsiveValue<StackOrientation>
    space?: ResponsiveValue<number | string>
    gap?: ResponsiveValue<number | string>
  } & StyledLinkProps
>`
  display: flex;
  break-inside: avoid;
  ${({ orientation = 'vertical' }) =>
    getResponsiveStyles(orientation, (orientation) => ({
      flexDirection: orientation === 'horizontal' ? 'row' : 'column',
    }))};

  ${({ gap }) => (!gap ? {} : getResponsiveStyles(gap, (gap) => ({ gap })))}

  & > *:not(:last-child) {
    ${({ orientation = 'vertical', space = 0, gap }) =>
      gap
        ? {}
        : getResponsiveStyles(orientation, (orientation) =>
            getResponsiveStyles(space, (space) =>
              orientation === 'horizontal'
                ? {
                    marginRight: space ?? 0,
                  }
                : orientation === 'vertical'
                ? {
                    marginBottom: space ?? 0,
                  }
                : {}
            )
          )}
  }
`
export type StackProps = ComponentProps<typeof StackDiv> & {
  accurateSpacers?: boolean
}

export const _Stack: ForwardRefRenderFunction<HTMLDivElement, StackProps> = (
  { accurateSpacers, ...props },
  ref
) => {
  return (
    <StackDiv {...props} ref={ref}>
      {!accurateSpacers
        ? props.children
        : Children.map(props.children, (child) => {
            if (
              !child ||
              !isValidElement<{ parentStackGap: ResponsiveValue<string | number> | undefined }>(
                child
              )
            )
              return child

            if (child.type === Spacer) {
              return cloneElement(child, {
                parentStackGap: props.gap,
              })
            }
            return child
          })}
    </StackDiv>
  )
}
export const Stack = forwardRef(_Stack)

export function HStack(props: {
  children?: ReactNode
  className?: string
  space?: ResponsiveValue<number | string>
  gap?: ResponsiveValue<number | string>
  onClick?: React.ComponentProps<typeof Stack>['onClick']
}) {
  return (
    <Stack
      gap={props.gap}
      space={props.space || '0.75rem'}
      orientation="horizontal"
      css={{ alignItems: 'center' }}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </Stack>
  )
}
