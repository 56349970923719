import { fetcher } from 'api/goodtrust/api'
import { cache } from 'utils/auth/cache'
import { clearPackagesCart, clearPlanCart, clearStoreCart } from 'utils/cart'
import { cobrandingLogic } from 'utils/cobranding/logic'
import { logError } from 'utils/general'
import { tryRemoveImpactClickId } from 'utils/impactCdn'
import { SWRCache } from 'utils/swr'

export const logout = async () => {
  const response = await fetcher('/api/token', {
    method: 'DELETE',
    headers: {
      'X-Request-Origin': 'v1',
    },
  })

  if (!response.ok) logError('Failed to logout')
  if (!SWRCache) throw Error('Missing cache object in logout')
  SWRCache.clear()

  cache.clear()
  clearPlanCart()
  clearStoreCart()
  clearPackagesCart()
  cobrandingLogic.onLoggedOut()
  tryRemoveImpactClickId()
}
