import { getUserMe } from 'api/goodtrust/user'
import sha1 from 'sha1'

export async function prepareUserDataForImpact() {
  try {
    const user = await getUserMe()
    const customerId = user.json?.uuid ?? "''"
    const email = user.json?.email
    const hashedEmail = email ? sha1(email) : "''"
    return { customerId, hashedEmail }
  } catch (e) {
    return { customerId: "''", hashedEmail: "''" }
  }
}
export function prepareDataLayerForCustomerIdAndEmailForImpact() {
  prepareUserDataForImpact().then(({ customerId, hashedEmail }) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      customerId,
      customerEmail: hashedEmail,
    })
  })
}
