export function parseEnteredName(enteredName: string | undefined | null) {
  if (enteredName == null) return undefined

  const words = enteredName.split(' ')
  const first_name = words[0]?.trim()
  const last_name = words.slice(1).join(' ').trim()

  if (!first_name || !last_name) return undefined

  return { first_name, last_name }
}
