import { DirectiveType } from 'components/directive/utils/types'
import { DirectiveTypeSpec } from 'logic/directive/types'
import { fireEvent, mutateAnalyticsData } from 'utils/analytics'
import { AnalyticsDirectiveType } from 'utils/analytics/types'
import { Subset } from 'utils/types'

export type DirectiveAnalyticSpec = {
  analyticsDirectiveType: AnalyticsDirectiveType
  mutateAnalytics: {
    userCreatedFromInviteEmail: () => void
    userCreatedFromAboutYou: () => void
  }
  fireAnalyticsEvent: {
    simpleFormBasicsFilled?: (
      section: Subset<
        NonNullable<DirectiveTypeSpec[DirectiveType]['patch']['last_section']>,
        'BASICS_ABOUT_YOU' | 'BASICS_FAMILY'
      >
    ) => void
    inviteAccepted: (args: { user_created: 'yes' | 'no' }) => void
    aboutYouFilled: (args: { new_user: 'yes' | 'no' }) => void
    tutorialViewed: () => void
    editDocument: () => void
    downloadPdf: () => void
    downloadStarted: (args: { is_owner: 'yes' | 'no' }) => void
    singleShare: (args: { was_suggested: 'yes' | 'no' }) => void
    shareOpen: () => void
  }
}

export const directiveAnalyticSpecs: Record<DirectiveType, DirectiveAnalyticSpec> = {
  FUNERAL: {
    analyticsDirectiveType: 'funeral',
    mutateAnalytics: {
      userCreatedFromInviteEmail: () => mutateAnalyticsData({ type: 'funeral_email' }),
      userCreatedFromAboutYou: () => mutateAnalyticsData({ type: 'funeral' }),
    },
    fireAnalyticsEvent: {
      inviteAccepted: (args) => fireEvent('fd_invite_accepted', args),
      aboutYouFilled: (args) => fireEvent('fd_aboutyou_filled', args),
      tutorialViewed: () => fireEvent('fd_tutorial_viewed'),
      editDocument: () => fireEvent('fd_edit_document'),
      downloadPdf: () => fireEvent('fd_download_pdf'),
      downloadStarted: (args) => fireEvent('fd_download_started', args),
      singleShare: (args) => fireEvent('fd_single_share', args),
      shareOpen: () => fireEvent('fd_share_open'),
    },
  },
  WILL: {
    analyticsDirectiveType: 'will',
    mutateAnalytics: {
      userCreatedFromInviteEmail: () => mutateAnalyticsData({ type: 'will_email' }),
      userCreatedFromAboutYou: () => mutateAnalyticsData({ type: 'will' }),
    },
    fireAnalyticsEvent: {
      simpleFormBasicsFilled: (section) => {
        if (section === 'BASICS_ABOUT_YOU') {
          fireEvent('est_basics_filled', { product: 'will' })
        } else if (section === 'BASICS_FAMILY') {
          fireEvent('est_family_filled', { product: 'will' })
        }
      },
      inviteAccepted: (args) => fireEvent('invite_accepted', args),
      aboutYouFilled: () => fireEvent('aboutyou_filled'),
      tutorialViewed: () => fireEvent('tutorial_viewed'),
      editDocument: () => fireEvent('edit_document'),
      downloadPdf: () => fireEvent('download_pdf'),
      downloadStarted: (args) => fireEvent('download_started', args),
      singleShare: (args) => fireEvent('single_share', args),
      shareOpen: () => fireEvent('share_open'),
    },
  },
  TRUST: {
    analyticsDirectiveType: 'trust',
    mutateAnalytics: {
      userCreatedFromInviteEmail: () => mutateAnalyticsData({ type: 'trust_email' }),
      userCreatedFromAboutYou: () => mutateAnalyticsData({ type: 'trust' }),
    },
    fireAnalyticsEvent: {
      simpleFormBasicsFilled: (section) => {
        if (section === 'BASICS_ABOUT_YOU') {
          fireEvent('est_basics_filled', { product: 'trust' })
        } else if (section === 'BASICS_FAMILY') {
          fireEvent('est_family_filled', { product: 'trust' })
        }
      },
      inviteAccepted: (args) => fireEvent('trust_invite_accepted', args),
      aboutYouFilled: (args) => fireEvent('trust_aboutyou_filled', args),
      tutorialViewed: () => fireEvent('trust_tutorial_viewed'),
      editDocument: () => fireEvent('trust_edit_document'),
      downloadPdf: () => fireEvent('trust_download_pdf'),
      downloadStarted: (args) => fireEvent('trust_download_started', args),
      singleShare: (args) => fireEvent('trust_single_share', args),
      shareOpen: () => fireEvent('trust_share_open'),
    },
  },

  POWER_OF_ATTORNEY: {
    analyticsDirectiveType: 'poa',
    mutateAnalytics: {
      userCreatedFromInviteEmail: () => mutateAnalyticsData({ type: 'poa_email' }),
      userCreatedFromAboutYou: () => mutateAnalyticsData({ type: 'poa' }),
    },
    fireAnalyticsEvent: {
      inviteAccepted: (args) => fireEvent('poa_invite_accepted', args),
      aboutYouFilled: (args) => fireEvent('poa_aboutyou_filled', args),
      tutorialViewed: () => fireEvent('poa_tutorial_viewed'),
      editDocument: () => fireEvent('poa_edit_document'),
      downloadPdf: () => fireEvent('poa_download_pdf'),
      downloadStarted: (args) => fireEvent('poa_download_started', args),
      singleShare: (args) => fireEvent('poa_single_share', args),
      shareOpen: () => fireEvent('poa_share_open'),
    },
  },
  PET: {
    analyticsDirectiveType: 'pet',
    mutateAnalytics: {
      userCreatedFromInviteEmail: () => mutateAnalyticsData({ type: 'pet_email' }),
      userCreatedFromAboutYou: () => mutateAnalyticsData({ type: 'pet' }),
    },
    fireAnalyticsEvent: {
      inviteAccepted: (args) => fireEvent('pet_invite_accepted', args),
      aboutYouFilled: (args) => fireEvent('pet_aboutyou_filled', args),
      tutorialViewed: () => fireEvent('pet_tutorial_viewed'),
      editDocument: () => fireEvent('pet_edit_document'),
      downloadPdf: () => fireEvent('pet_download_pdf'),
      downloadStarted: (args) => fireEvent('pet_download_started', args),
      singleShare: (args) => fireEvent('pet_single_share', args),
      shareOpen: () => fireEvent('pet_share_open'),
    },
  },
  MEDICAL: {
    analyticsDirectiveType: 'medical',
    mutateAnalytics: {
      userCreatedFromInviteEmail: () => mutateAnalyticsData({ type: 'medical_email' }),
      userCreatedFromAboutYou: () => mutateAnalyticsData({ type: 'medical' }),
    },
    fireAnalyticsEvent: {
      inviteAccepted: (args) => fireEvent('hd_invite_accepted', args),
      aboutYouFilled: (args) => fireEvent('hd_aboutyou_filled', args),
      tutorialViewed: () => fireEvent('hd_tutorial_viewed'),
      editDocument: () => fireEvent('hd_edit_document'),
      downloadPdf: () => fireEvent('hd_download_pdf'),
      downloadStarted: (args) => fireEvent('hd_download_started', args),
      singleShare: (args) => fireEvent('hd_single_share', args),
      shareOpen: () => fireEvent('hd_share_open'),
    },
  },
}
