import { useEffect } from 'react'
import useSWR from 'swr'
import { mutateAnalyticsData } from 'utils/analytics'
import { authFetcher } from 'utils/auth/authFetcher'
import { AUTH_KEY } from 'utils/auth/cache'

export const useAuth = () => {
  const { data: auth, mutate: mutateAuth } = useSWR(AUTH_KEY, authFetcher)

  const isLogged = auth?.isLogged
  useEffect(() => {
    mutateAnalyticsData({ loginState: isLogged ? 'logged_in' : 'not_logged_in' })
  }, [isLogged])

  return { isLogged, mutateAuth }
}
