// DO NOT EDIT THIS FILE DIRECTLY. Autogenerated by "yarn svgr:generate"
import { forwardRef, Ref } from 'react'
import dynamic from 'next/dynamic'

const ActionAccess = dynamic(() => import('./ActionAccess'))
const ActionContract = dynamic(() => import('./ActionContract'))
const Apple = dynamic(() => import('./Apple'))
const ArrowEnter = dynamic(() => import('./ArrowEnter'))
const ArrowEnterBold = dynamic(() => import('./ArrowEnterBold'))
const ArrowLeft = dynamic(() => import('./ArrowLeft'))
const ArrowRight = dynamic(() => import('./ArrowRight'))
const Bin = dynamic(() => import('./Bin'))
const Bird = dynamic(() => import('./Bird'))
const Bug = dynamic(() => import('./Bug'))
const BulletList = dynamic(() => import('./BulletList'))
const Cake = dynamic(() => import('./Cake'))
const Calendar = dynamic(() => import('./Calendar'))
const CalendarRefresh = dynamic(() => import('./CalendarRefresh'))
const Cam = dynamic(() => import('./Cam'))
const Camera = dynamic(() => import('./Camera'))
const Cancel = dynamic(() => import('./Cancel'))
const CancelCircle = dynamic(() => import('./CancelCircle'))
const CaretDown = dynamic(() => import('./CaretDown'))
const CaretRight = dynamic(() => import('./CaretRight'))
const Cat = dynamic(() => import('./Cat'))
const Check = dynamic(() => import('./Check'))
const CheckBold = dynamic(() => import('./CheckBold'))
const CheckCircle = dynamic(() => import('./CheckCircle'))
const CheckSimple = dynamic(() => import('./CheckSimple'))
const CheckTransparent = dynamic(() => import('./CheckTransparent'))
const Checklist = dynamic(() => import('./Checklist'))
const ChevronDown = dynamic(() => import('./ChevronDown'))
const ChevronLeft = dynamic(() => import('./ChevronLeft'))
const ChevronRight = dynamic(() => import('./ChevronRight'))
const Close = dynamic(() => import('./Close'))
const Coffin = dynamic(() => import('./Coffin'))
const ColorAol = dynamic(() => import('./ColorAol'))
const ColorAmericanExpress = dynamic(() => import('./ColorAmericanExpress'))
const ColorCyberscout = dynamic(() => import('./ColorCyberscout'))
const ColorDashlane = dynamic(() => import('./ColorDashlane'))
const ColorFacebook = dynamic(() => import('./ColorFacebook'))
const ColorGoodTrust = dynamic(() => import('./ColorGoodTrust'))
const ColorGoogle = dynamic(() => import('./ColorGoogle'))
const ColorInstagram = dynamic(() => import('./ColorInstagram'))
const ColorJcb = dynamic(() => import('./ColorJcb'))
const ColorLinkedIn = dynamic(() => import('./ColorLinkedIn'))
const ColorMasterCard = dynamic(() => import('./ColorMasterCard'))
const ColorMicrosoft = dynamic(() => import('./ColorMicrosoft'))
const ColorNetflix = dynamic(() => import('./ColorNetflix'))
const ColorPayPal = dynamic(() => import('./ColorPayPal'))
const ColorSnapchat = dynamic(() => import('./ColorSnapchat'))
const ColorSpotify = dynamic(() => import('./ColorSpotify'))
const ColorTikTok = dynamic(() => import('./ColorTikTok'))
const ColorTinder = dynamic(() => import('./ColorTinder'))
const ColorTwitter = dynamic(() => import('./ColorTwitter'))
const ColorVenmo = dynamic(() => import('./ColorVenmo'))
const ColorVisa = dynamic(() => import('./ColorVisa'))
const ColorWhatsapp = dynamic(() => import('./ColorWhatsapp'))
const ColorYahoo = dynamic(() => import('./ColorYahoo'))
const Cookie = dynamic(() => import('./Cookie'))
const CreditCard = dynamic(() => import('./CreditCard'))
const CreditCardCheck = dynamic(() => import('./CreditCardCheck'))
const CreditCardLocked = dynamic(() => import('./CreditCardLocked'))
const Cyberscout = dynamic(() => import('./Cyberscout'))
const Dashlane = dynamic(() => import('./Dashlane'))
const DigitalVault = dynamic(() => import('./DigitalVault'))
const DocumentEdit = dynamic(() => import('./DocumentEdit'))
const DocumentReady = dynamic(() => import('./DocumentReady'))
const Dog = dynamic(() => import('./Dog'))
const EditImage = dynamic(() => import('./EditImage'))
const EstatePlan = dynamic(() => import('./EstatePlan'))
const Executor = dynamic(() => import('./Executor'))
const EyeClosed = dynamic(() => import('./EyeClosed'))
const EyeOpened = dynamic(() => import('./EyeOpened'))
const Facebook = dynamic(() => import('./Facebook'))
const Family = dynamic(() => import('./Family'))
const Ferret = dynamic(() => import('./Ferret'))
const File = dynamic(() => import('./File'))
const FileDash = dynamic(() => import('./FileDash'))
const FileDownload = dynamic(() => import('./FileDownload'))
const FileDownloadFrame = dynamic(() => import('./FileDownloadFrame'))
const FileUpload = dynamic(() => import('./FileUpload'))
const FileUploadCloud = dynamic(() => import('./FileUploadCloud'))
const FileUploadFrame = dynamic(() => import('./FileUploadFrame'))
const Fish = dynamic(() => import('./Fish'))
const Flash = dynamic(() => import('./Flash'))
const Folder = dynamic(() => import('./Folder'))
const FuneralDirective = dynamic(() => import('./FuneralDirective'))
const Globe = dynamic(() => import('./Globe'))
const Goodtrust = dynamic(() => import('./Goodtrust'))
const Guide = dynamic(() => import('./Guide'))
const Hamster = dynamic(() => import('./Hamster'))
const Handshake = dynamic(() => import('./Handshake'))
const HealthCareDirective = dynamic(() => import('./HealthCareDirective'))
const Home = dynamic(() => import('./Home'))
const Hourglass = dynamic(() => import('./Hourglass'))
const IdentityBadge = dynamic(() => import('./IdentityBadge'))
const ImageAdd = dynamic(() => import('./ImageAdd'))
const ImageFolder = dynamic(() => import('./ImageFolder'))
const Info = dynamic(() => import('./Info'))
const InfoBubble = dynamic(() => import('./InfoBubble'))
const InfoCircle = dynamic(() => import('./InfoCircle'))
const Instagram = dynamic(() => import('./Instagram'))
const Key = dynamic(() => import('./Key'))
const Laptop = dynamic(() => import('./Laptop'))
const Layers = dynamic(() => import('./Layers'))
const Letter = dynamic(() => import('./Letter'))
const LifeInsurance = dynamic(() => import('./LifeInsurance'))
const LifeStories = dynamic(() => import('./LifeStories'))
const LightBulb = dynamic(() => import('./LightBulb'))
const Link = dynamic(() => import('./Link'))
const LinkedIn = dynamic(() => import('./LinkedIn'))
const LivePortrait = dynamic(() => import('./LivePortrait'))
const Loader = dynamic(() => import('./Loader'))
const LockClosed = dynamic(() => import('./LockClosed'))
const LogOut = dynamic(() => import('./LogOut'))
const LogoDid = dynamic(() => import('./LogoDid'))
const Mail = dynamic(() => import('./Mail'))
const MapPin = dynamic(() => import('./MapPin'))
const MenuOpen = dynamic(() => import('./MenuOpen'))
const Mic = dynamic(() => import('./Mic'))
const Microphone = dynamic(() => import('./Microphone'))
const ModalClose = dynamic(() => import('./ModalClose'))
const MoneyBag = dynamic(() => import('./MoneyBag'))
const MoreInfo = dynamic(() => import('./MoreInfo'))
const Music = dynamic(() => import('./Music'))
const Note = dynamic(() => import('./Note'))
const OpenLink = dynamic(() => import('./OpenLink'))
const PaintBrush = dynamic(() => import('./PaintBrush'))
const PassCode = dynamic(() => import('./PassCode'))
const Pause = dynamic(() => import('./Pause'))
const People = dynamic(() => import('./People'))
const PersonAdd = dynamic(() => import('./PersonAdd'))
const PersonSimple = dynamic(() => import('./PersonSimple'))
const PetDirective = dynamic(() => import('./PetDirective'))
const Phone = dynamic(() => import('./Phone'))
const PhoneBroken = dynamic(() => import('./PhoneBroken'))
const PhoneLock = dynamic(() => import('./PhoneLock'))
const Play = dynamic(() => import('./Play'))
const PlayCircle = dynamic(() => import('./PlayCircle'))
const Plus = dynamic(() => import('./Plus'))
const Poultry = dynamic(() => import('./Poultry'))
const PowerOfAttorney = dynamic(() => import('./PowerOfAttorney'))
const PremiumPlan = dynamic(() => import('./PremiumPlan'))
const Print = dynamic(() => import('./Print'))
const ProtectPlan = dynamic(() => import('./ProtectPlan'))
const ProtectPlanFilled = dynamic(() => import('./ProtectPlanFilled'))
const Question = dynamic(() => import('./Question'))
const QuestionBubble = dynamic(() => import('./QuestionBubble'))
const QuestionMini = dynamic(() => import('./QuestionMini'))
const Rabbit = dynamic(() => import('./Rabbit'))
const Redo = dynamic(() => import('./Redo'))
const Retake = dynamic(() => import('./Retake'))
const RibbonShield = dynamic(() => import('./RibbonShield'))
const Search = dynamic(() => import('./Search'))
const SelectSong = dynamic(() => import('./SelectSong'))
const Send = dynamic(() => import('./Send'))
const Share = dynamic(() => import('./Share'))
const Shield = dynamic(() => import('./Shield'))
const ShieldCheck = dynamic(() => import('./ShieldCheck'))
const ShieldCross = dynamic(() => import('./ShieldCross'))
const ShoppingBag = dynamic(() => import('./ShoppingBag'))
const SingingPortrait = dynamic(() => import('./SingingPortrait'))
const Skull = dynamic(() => import('./Skull'))
const SliderHandle = dynamic(() => import('./SliderHandle'))
const Snake = dynamic(() => import('./Snake'))
const SoundOff = dynamic(() => import('./SoundOff'))
const SoundOn = dynamic(() => import('./SoundOn'))
const StarWand = dynamic(() => import('./StarWand'))
const Stop = dynamic(() => import('./Stop'))
const Storage = dynamic(() => import('./Storage'))
const StoryPortraits = dynamic(() => import('./StoryPortraits'))
const Switch = dynamic(() => import('./Switch'))
const Tag = dynamic(() => import('./Tag'))
const TapGesture = dynamic(() => import('./TapGesture'))
const TextDocument = dynamic(() => import('./TextDocument'))
const TimeClock = dynamic(() => import('./TimeClock'))
const TimeClockFilled = dynamic(() => import('./TimeClockFilled'))
const TimelineClock = dynamic(() => import('./TimelineClock'))
const TrustedContact = dynamic(() => import('./TrustedContact'))
const Twitter = dynamic(() => import('./Twitter'))
const TypeText = dynamic(() => import('./TypeText'))
const UnShow = dynamic(() => import('./UnShow'))
const UserProfileTap = dynamic(() => import('./UserProfileTap'))
const Video = dynamic(() => import('./Video'))
const Voice = dynamic(() => import('./Voice'))
const Wand = dynamic(() => import('./Wand'))
const Warning = dynamic(() => import('./Warning'))
const WarningCircle = dynamic(() => import('./WarningCircle'))
const Will = dynamic(() => import('./Will'))
const World = dynamic(() => import('./World'))
const ZoomIn = dynamic(() => import('./ZoomIn'))

export type IconName =
  | 'ActionAccess'
  | 'ActionContract'
  | 'Apple'
  | 'ArrowEnter'
  | 'ArrowEnterBold'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Bin'
  | 'Bird'
  | 'Bug'
  | 'BulletList'
  | 'Cake'
  | 'Calendar'
  | 'CalendarRefresh'
  | 'Cam'
  | 'Camera'
  | 'Cancel'
  | 'CancelCircle'
  | 'CaretDown'
  | 'CaretRight'
  | 'Cat'
  | 'Check'
  | 'CheckBold'
  | 'CheckCircle'
  | 'CheckSimple'
  | 'CheckTransparent'
  | 'Checklist'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'Close'
  | 'Coffin'
  | 'ColorAol'
  | 'ColorAmericanExpress'
  | 'ColorCyberscout'
  | 'ColorDashlane'
  | 'ColorFacebook'
  | 'ColorGoodTrust'
  | 'ColorGoogle'
  | 'ColorInstagram'
  | 'ColorJcb'
  | 'ColorLinkedIn'
  | 'ColorMasterCard'
  | 'ColorMicrosoft'
  | 'ColorNetflix'
  | 'ColorPayPal'
  | 'ColorSnapchat'
  | 'ColorSpotify'
  | 'ColorTikTok'
  | 'ColorTinder'
  | 'ColorTwitter'
  | 'ColorVenmo'
  | 'ColorVisa'
  | 'ColorWhatsapp'
  | 'ColorYahoo'
  | 'Cookie'
  | 'CreditCard'
  | 'CreditCardCheck'
  | 'CreditCardLocked'
  | 'Cyberscout'
  | 'Dashlane'
  | 'DigitalVault'
  | 'DocumentEdit'
  | 'DocumentReady'
  | 'Dog'
  | 'EditImage'
  | 'EstatePlan'
  | 'Executor'
  | 'EyeClosed'
  | 'EyeOpened'
  | 'Facebook'
  | 'Family'
  | 'Ferret'
  | 'File'
  | 'FileDash'
  | 'FileDownload'
  | 'FileDownloadFrame'
  | 'FileUpload'
  | 'FileUploadCloud'
  | 'FileUploadFrame'
  | 'Fish'
  | 'Flash'
  | 'Folder'
  | 'FuneralDirective'
  | 'Globe'
  | 'Goodtrust'
  | 'Guide'
  | 'Hamster'
  | 'Handshake'
  | 'HealthCareDirective'
  | 'Home'
  | 'Hourglass'
  | 'IdentityBadge'
  | 'ImageAdd'
  | 'ImageFolder'
  | 'Info'
  | 'InfoBubble'
  | 'InfoCircle'
  | 'Instagram'
  | 'Key'
  | 'Laptop'
  | 'Layers'
  | 'Letter'
  | 'LifeInsurance'
  | 'LifeStories'
  | 'LightBulb'
  | 'Link'
  | 'LinkedIn'
  | 'LivePortrait'
  | 'Loader'
  | 'LockClosed'
  | 'LogOut'
  | 'LogoDid'
  | 'Mail'
  | 'MapPin'
  | 'MenuOpen'
  | 'Mic'
  | 'Microphone'
  | 'ModalClose'
  | 'MoneyBag'
  | 'MoreInfo'
  | 'Music'
  | 'Note'
  | 'OpenLink'
  | 'PaintBrush'
  | 'PassCode'
  | 'Pause'
  | 'People'
  | 'PersonAdd'
  | 'PersonSimple'
  | 'PetDirective'
  | 'Phone'
  | 'PhoneBroken'
  | 'PhoneLock'
  | 'Play'
  | 'PlayCircle'
  | 'Plus'
  | 'Poultry'
  | 'PowerOfAttorney'
  | 'PremiumPlan'
  | 'Print'
  | 'ProtectPlan'
  | 'ProtectPlanFilled'
  | 'Question'
  | 'QuestionBubble'
  | 'QuestionMini'
  | 'Rabbit'
  | 'Redo'
  | 'Retake'
  | 'RibbonShield'
  | 'Search'
  | 'SelectSong'
  | 'Send'
  | 'Share'
  | 'Shield'
  | 'ShieldCheck'
  | 'ShieldCross'
  | 'ShoppingBag'
  | 'SingingPortrait'
  | 'Skull'
  | 'SliderHandle'
  | 'Snake'
  | 'SoundOff'
  | 'SoundOn'
  | 'StarWand'
  | 'Stop'
  | 'Storage'
  | 'StoryPortraits'
  | 'Switch'
  | 'Tag'
  | 'TapGesture'
  | 'TextDocument'
  | 'TimeClock'
  | 'TimeClockFilled'
  | 'TimelineClock'
  | 'TrustedContact'
  | 'Twitter'
  | 'TypeText'
  | 'UnShow'
  | 'UserProfileTap'
  | 'Video'
  | 'Voice'
  | 'Wand'
  | 'Warning'
  | 'WarningCircle'
  | 'Will'
  | 'World'
  | 'ZoomIn'

const icons = {
  ActionAccess,
  ActionContract,
  Apple,
  ArrowEnter,
  ArrowEnterBold,
  ArrowLeft,
  ArrowRight,
  Bin,
  Bird,
  Bug,
  BulletList,
  Cake,
  Calendar,
  CalendarRefresh,
  Cam,
  Camera,
  Cancel,
  CancelCircle,
  CaretDown,
  CaretRight,
  Cat,
  Check,
  CheckBold,
  CheckCircle,
  CheckSimple,
  CheckTransparent,
  Checklist,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Close,
  Coffin,
  ColorAol,
  ColorAmericanExpress,
  ColorCyberscout,
  ColorDashlane,
  ColorFacebook,
  ColorGoodTrust,
  ColorGoogle,
  ColorInstagram,
  ColorJcb,
  ColorLinkedIn,
  ColorMasterCard,
  ColorMicrosoft,
  ColorNetflix,
  ColorPayPal,
  ColorSnapchat,
  ColorSpotify,
  ColorTikTok,
  ColorTinder,
  ColorTwitter,
  ColorVenmo,
  ColorVisa,
  ColorWhatsapp,
  ColorYahoo,
  Cookie,
  CreditCard,
  CreditCardCheck,
  CreditCardLocked,
  Cyberscout,
  Dashlane,
  DigitalVault,
  DocumentEdit,
  DocumentReady,
  Dog,
  EditImage,
  EstatePlan,
  Executor,
  EyeClosed,
  EyeOpened,
  Facebook,
  Family,
  Ferret,
  File,
  FileDash,
  FileDownload,
  FileDownloadFrame,
  FileUpload,
  FileUploadCloud,
  FileUploadFrame,
  Fish,
  Flash,
  Folder,
  FuneralDirective,
  Globe,
  Goodtrust,
  Guide,
  Hamster,
  Handshake,
  HealthCareDirective,
  Home,
  Hourglass,
  IdentityBadge,
  ImageAdd,
  ImageFolder,
  Info,
  InfoBubble,
  InfoCircle,
  Instagram,
  Key,
  Laptop,
  Layers,
  Letter,
  LifeInsurance,
  LifeStories,
  LightBulb,
  Link,
  LinkedIn,
  LivePortrait,
  Loader,
  LockClosed,
  LogOut,
  LogoDid,
  Mail,
  MapPin,
  MenuOpen,
  Mic,
  Microphone,
  ModalClose,
  MoneyBag,
  MoreInfo,
  Music,
  Note,
  OpenLink,
  PaintBrush,
  PassCode,
  Pause,
  People,
  PersonAdd,
  PersonSimple,
  PetDirective,
  Phone,
  PhoneBroken,
  PhoneLock,
  Play,
  PlayCircle,
  Plus,
  Poultry,
  PowerOfAttorney,
  PremiumPlan,
  Print,
  ProtectPlan,
  ProtectPlanFilled,
  Question,
  QuestionBubble,
  QuestionMini,
  Rabbit,
  Redo,
  Retake,
  RibbonShield,
  Search,
  SelectSong,
  Send,
  Share,
  Shield,
  ShieldCheck,
  ShieldCross,
  ShoppingBag,
  SingingPortrait,
  Skull,
  SliderHandle,
  Snake,
  SoundOff,
  SoundOn,
  StarWand,
  Stop,
  Storage,
  StoryPortraits,
  Switch,
  Tag,
  TapGesture,
  TextDocument,
  TimeClock,
  TimeClockFilled,
  TimelineClock,
  TrustedContact,
  Twitter,
  TypeText,
  UnShow,
  UserProfileTap,
  Video,
  Voice,
  Wand,
  Warning,
  WarningCircle,
  Will,
  World,
  ZoomIn,
}

export interface IconProps extends Omit<React.SVGProps<SVGSVGElement>, 'ref'> {
  name?: IconName
  size?: number | string
}

const _Icon = (props: IconProps, ref: Ref<SVGSVGElement>) => {
  const { name, size = '1.5rem', width, height, ...rest } = props

  const IconComponent = icons?.[name || 'Warning'] || icons.Warning

  return (
    <IconComponent
      aria-label={name}
      width={width ?? size}
      height={height ?? size}
      {...rest}
      ref={ref}
    />
  )
}

export const Icon = forwardRef(_Icon)
