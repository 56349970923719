import { DirectiveLPSection } from 'components/directive/directiveLPSection/DirectiveLPSection'
import { TranslatableLink } from 'components/TranslatableLink'
import { Trans, useTranslation } from 'next-i18next'
import { numberRange } from 'utils/general'

export const PetLPSections = () => {
  const { t } = useTranslation('pet_lp')

  return (
    <>
      <DirectiveLPSection
        type="PET"
        title={t('pet_lp.section_what_is.title')}
        description={
          <>
            {t('pet_lp.section_what_is.description')}
            <ul>
              {numberRange(7).map((i) => (
                <li key={i}>{t(`pet_lp.section_what_is.item_${i}`)}</li>
              ))}
            </ul>
          </>
        }
        imageSlug="pet_lp_section_what_is"
      />

      <DirectiveLPSection
        type="PET"
        imagePlacement="right"
        title={t('pet_lp.section_medical_issue.title')}
        description={t('pet_lp.section_medical_issue.description')}
        imageSlug="pet_lp_section_medical_issue"
      />

      <DirectiveLPSection
        type="PET"
        title={t('pet_lp.section_dont_leave_pets.title')}
        description={t('pet_lp.section_dont_leave_pets.description')}
        imageSlug="pet_lp_section_dont_leave_pets"
      />

      <DirectiveLPSection
        type="PET"
        imagePlacement="right"
        title={t('pet_lp.section_do_i_need.title')}
        description={
          <Trans
            t={t}
            i18nKey="pet_lp.section_do_i_need.description"
            components={{
              euthanized: (
                <TranslatableLink href="https://www.aspca.org/helping-people-pets/shelter-intake-and-surrender" />
              ),
            }}
          />
        }
        imageSlug="pet_lp_section_do_i_need"
        hideButton
      />

      <DirectiveLPSection
        type="PET"
        title={t('pet_lp.section_tips.title')}
        description={
          <>
            {t('pet_lp.section_tips.description')}
            <ul>
              {numberRange(5).map((i) => (
                <li key={i}>{t(`pet_lp.section_tips.item_${i}`)}</li>
              ))}
            </ul>
            {t('pet_lp.section_tips.last_paragraph')}
          </>
        }
        imageSlug="pet_lp_section_tips"
        hideButton
      />
    </>
  )
}
