import styled from '@emotion/styled'

export const AspectRatio = styled.div`
  position: relative;
  display: flex;

  &:before {
    content: '';
    height: 0;
    width: 0;
    padding-top: calc(${({ ratio }: { ratio: number }) => 1 / ratio} * 100%);
  }
`
