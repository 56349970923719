import { handleError } from 'components/Toast'
import { mutateAnalyticsData } from 'utils/analytics/mutate'
import { ANALYTICS } from 'utils/analytics/consts'
import { fireEvent } from 'utils/analytics/fire'
import { eventHistory } from 'utils/analytics/history'

export type EventTriggerContext<TArgs> = {
  history: typeof eventHistory
  state: typeof ANALYTICS
  fireEvent: typeof fireEvent
  mutate: typeof mutateAnalyticsData
  args: TArgs
}

export class AnalyticsEvent<TArgs extends Record<string, unknown> | void> {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly spec: {
      onFire?: (ctx: EventTriggerContext<TArgs>) => Promise<void> | void
    }
  ) {}

  async fire(args: TArgs): Promise<'success' | 'error'> {
    try {
      await this.spec.onFire?.({
        history: eventHistory,
        state: ANALYTICS,
        fireEvent,
        args,
        mutate: mutateAnalyticsData,
      })
      return 'success'
    } catch (err) {
      handleError(err)
      return 'error'
    }
  }
}
