import { putUserConfirm } from 'api/goodtrust/user'
import { handleAndToastError, toastSuccess } from 'components/Toast'
import omit from 'lodash.omit'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { sleep } from 'utils/general'
import { useRunOnceReady } from 'utils/hooks'

function isRedirecting() {
  return NProgress.isStarted()
}

export function useActivationToken() {
  const router = useRouter()

  const token = router.query.activationToken as string
  // activate logged-in user
  useRunOnceReady(!!token, async () => {
    await handleVerify(token)

    while (isRedirecting()) {
      // we need to wait until the redirect has stopped so that we don't cancel it by the call to router.replace
      // for example, useRedirectToSimpleFlowCheckout might have started the redirect
      await sleep(500)
    }

    const queryWithoutToken = omit(router.query, ['activationToken'])
    await router.replace({ query: queryWithoutToken })
  })
}

const handleVerify = async (token: string) => {
  const res = await putUserConfirm(token)

  if (res.ok) {
    toastSuccess('email_verified')
  } else if (!res.ok) {
    handleAndToastError(res.error ?? '', 'failed_to_verify_email')
  }
}
