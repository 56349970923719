import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite'
import useSWR from 'swr'
import { FetchResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { apiAuthFetcher } from 'api/goodtrust/api'
import { useMemo } from 'react'
import { encodePageQuery } from 'utils/pagination'
import { emptyObject } from 'utils/constants'
import { useDebounce } from 'utils/hooks'

export function useUserPersonList(
  size?: number,
  body?: ApiType['PersonSpecification'],
  config?: SWRInfiniteConfiguration<
    FetchResponse<ApiType['PaginatedResponsePersonResponse']>,
    Error
  >
) {
  const debouncedBody = useDebounce(body, 350)
  const memoizedBody = useMemo(() => {
    return {
      method: 'POST',
      body: debouncedBody || emptyObject,
    }
  }, [debouncedBody])

  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponsePersonResponse']>>(
    (_, prev) => [encodePageQuery('/api/v1/person/search', prev, { size }), memoizedBody],
    apiAuthFetcher,
    { revalidateOnFocus: false, revalidateOnReconnect: false, ...config }
  )
}

export function useUserPerson(uuid?: string | null) {
  return useSWR<FetchResponse<ApiType['PersonResponse']>>(
    uuid ? `/api/v1/person/${uuid}` : null,
    apiAuthFetcher
  )
}

export function postUserPerson(body: ApiType['CreatePersonCommand']) {
  return apiAuthFetcher<ApiType['PersonResponse']>(`/api/v1/person`, {
    method: 'POST',
    body,
  })
}

export function patchUserPerson(uuid: string, body: ApiType['PatchPersonCommand']) {
  return apiAuthFetcher<ApiType['PersonResponse']>(`/api/v1/person/${uuid}`, {
    method: 'PATCH',
    body,
  })
}
