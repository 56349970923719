import { AnalyticsEvent } from 'utils/analytics/event'
import { determineGroupLifeFlow } from 'utils/analytics/history/determineGroupLifeFlow'
import { EventArgs } from 'utils/analytics/types'
import { matchMap } from 'utils/general'

type Args = { action: 'confirm' | 'decline' | 'pop_up' }
/**
 * This event is fired when the when either the confirmaiton modal pops up or when user either confirms it or declines it.
 */
export const gl_confirmation_modal = new AnalyticsEvent<Args>({
  async onFire(ctx) {
    ctx.fireEvent('gl_confirmation_modal', {
      ...matchMap(ctx.args.action, map),
      flow: determineGroupLifeFlow(ctx),
    })
  },
})

const map: Record<Args['action'], Omit<EventArgs['gl_confirmation_modal'], 'flow'>> = {
  confirm: {
    action: 'confirmed',
    step: 'user_action',
  },
  decline: {
    action: 'declined',
    step: 'user_action',
  },
  pop_up: {
    action: 'null',
    step: 'pop_up',
  },
}
