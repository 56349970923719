import { fetcher } from 'api/goodtrust/api'
import { CookieBanner } from 'components/content/banner/CookieBanner'
import { MockApiText } from 'components/mockApiText/MockApiText'
import { PageMeta } from 'components/PageMeta'
import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ANALYTICS, mutateAnalyticsData, useAnalyticsUserId } from 'utils/analytics'
import { restoreEventHistory } from 'utils/analytics/history'
import { PrivateRoute } from 'utils/auth/privateRoute/PrivateRoute'
import { useActivationToken } from 'utils/auth/useActivationToken'
import { ContentfulImagesContextProvider } from 'utils/contentful/ContentfulImagesContext'
import { ContentfulVideosContextProvider } from 'utils/contentful/ContentfulVideosContext'
import { ContentfulPageProps, ContentfulVideoProps } from 'utils/contentful/types'
import { useDevtools } from 'utils/devtools/useDevtools'
import { useMockEnabled } from 'utils/devtools/useMock'
import 'utils/disableImmerAutofreeze'
import { logMsg } from 'utils/general'
import { I18nMock } from 'utils/i18n/mocks'
import { prepareDataLayerForCustomerIdAndEmailForImpact } from 'utils/impact/prepareDataLayerForCustomerIdAndEmailForImpact'
import { useSaveImpactClickId } from 'utils/impactCdn'
import { usePreviewLogic } from 'utils/preview'
import { useSavePromoCodeFromURL } from 'utils/promo'
import { globalStyles } from 'utils/styled'
import { useAssignSwrCache } from 'utils/swr'
import nextI18NextConfig from '../../next-i18next.config'

const ClosePreviewButton = dynamic(() => import('components/closePreviewButton/ClosePreviewButton'))

declare global {
  type IKeyValue =
    | {
        [key: string]: IKeyValue | string
      }
    | string

  interface Window {
    wpcc: {
      init: (params: { [key: string]: IKeyValue }) => void
    }
    dataLayer: Record<string, unknown>[]
  }
}

function useMaintenanceRedirect() {
  const router = useRouter()
  useEffect(() => {
    if (router.pathname.match(/\/maintenance/)) return
    fetcher<{ isActive: boolean }>('/api/maintenance').then((res) => {
      if (res.json?.isActive) {
        router.push('/maintenance')
      }
    })
  }, [router])
}

const ThirdPartyScripts = (): JSX.Element => (
  <>
    <Script
      id="freshdesk-func"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        window.fwSettings={
        'widget_id':66000002789
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
      `,
      }}
    ></Script>
    <Script
      id="freshdesk"
      strategy="afterInteractive"
      type="text/javascript"
      src="https://widget.freshworks.com/widgets/66000002789.js"
    ></Script>
  </>
)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore // Workaround for https://github.com/vercel/next.js/issues/8592
function App({ Component, pageProps, err }: AppProps) {
  const router = useRouter()
  useAssignSwrCache()
  useMaintenanceRedirect()
  useSavePromoCodeFromURL()
  useSaveImpactClickId()
  useActivationToken()
  const isMockingApi = useMockEnabled('api')
  const devtools = useDevtools()
  const isPreviewing = usePreviewLogic()
  useEffect(() => {
    restoreEventHistory()
  }, [])
  useEffect(() => {
    mutateAnalyticsData({
      router_pathname: router.pathname,
    })
    prepareDataLayerForCustomerIdAndEmailForImpact()
  }, [router.pathname])
  useEffect(() => {
    const handleStart = (url: string) => {
      NProgress.start()
      logMsg('Url change to: ', url, ' from: ', router?.pathname)
      mutateAnalyticsData({ last_page: router.pathname })
    }
    const handleComplete = (url: string) => {
      NProgress.done()
      // Detect that the user left signup flow
      if (
        ANALYTICS.is_signing_up &&
        !url.includes('/signup') &&
        !url.includes('/memories') &&
        !url.includes('/singing-portraits')
      ) {
        mutateAnalyticsData({ is_signing_up: false })
      }
      // Detect that the user left animation package upgrade flow
      if (
        ANALYTICS.upgrade_type &&
        !url.includes('subscription/animations') &&
        !url.includes('/signup/checkout')
      ) {
        mutateAnalyticsData({ upgrade_type: undefined })
      }
    }
    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  const base = process.env.NEXT_PUBLIC_WEBSITE_URL as string

  const { asPath } = useRouter()
  const _pathSliceLength = Math.min.apply(Math, [
    asPath.indexOf('?') > 0 ? asPath.indexOf('?') : asPath.length,
    asPath.indexOf('#') > 0 ? asPath.indexOf('#') : asPath.length,
  ])
  const canonicalURL = `${base}${asPath.substring(0, _pathSliceLength)}`

  useAnalyticsUserId()

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalURL} />
        <link rel="apple-touch-icon-precomposed" sizes="57x57" href="apple-touch-icon-57x57.png" />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="apple-touch-icon-114x114.png"
        />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="apple-touch-icon-72x72.png" />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="apple-touch-icon-144x144.png"
        />
        <link rel="apple-touch-icon-precomposed" sizes="60x60" href="apple-touch-icon-60x60.png" />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="apple-touch-icon-120x120.png"
        />
        <link rel="apple-touch-icon-precomposed" sizes="76x76" href="apple-touch-icon-76x76.png" />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="apple-touch-icon-152x152.png"
        />
        <link rel="icon" type="image/png" href="/favicon-196x196.png" sizes="196x196" />
        <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="/favicon-128.png" sizes="128x128" />
        <meta name="application-name" content="&nbsp;" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="mstile-144x144.png" />
        <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
        <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
        <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
        <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          key="fb:app_id"
          property="fb:app_id"
          content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        />
        <meta name="facebook-domain-verification" content="r27ze78upfkbzl2bgt8vhg5vwzbftt" />
        <script
          id="Website-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              name: 'MyGoodTrust',
              alternateName: 'GoodTrust',
              url: canonicalURL,
            }),
          }}
        />
      </Head>

      <PageMeta
        description="Memorialize Facebook and other social media accounts | Secure photos and videos | Stop subscription charges | Works with more than 100 of the most popular sites and apps."
        image="/images/preview/goodtrust.png"
      />
      <style jsx global>
        {globalStyles}
      </style>
      {devtools.jsx}
      {isPreviewing && <ClosePreviewButton />}
      {isMockingApi && <MockApiText />}
      <ThirdPartyScripts />
      <I18nMock>
        <SkeletonTheme baseColor="#f1f8fb" highlightColor="#F8FCFE">
          <ContentfulImagesContextProvider pageProps={pageProps as ContentfulPageProps}>
            <ContentfulVideosContextProvider pageProps={pageProps as ContentfulVideoProps}>
              <Toaster containerStyle={{ transform: 'translateZ(10px)' }} />
              <PrivateRoute>
                <Component {...pageProps} err={err} />
              </PrivateRoute>
            </ContentfulVideosContextProvider>
          </ContentfulImagesContextProvider>
        </SkeletonTheme>
      </I18nMock>
      <CookieBanner />
    </>
  )
}

export default appWithTranslation(App, nextI18NextConfig)
