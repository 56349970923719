// @ts-ignore
import { ReactNode } from 'react'

export interface ConditionalWrapperProps {
  children: ReactNode
  condition: boolean
  wrapper: (children: ReactNode) => JSX.Element
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) => {
  return condition ? wrapper(children) : <>{children}</>
}
