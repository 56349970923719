import { CompleteOfferModalResult } from 'components/modal/offerModal/CompleteOfferModalResult'
import { createDirectiveAndRedirect } from 'logic/directive/create/create'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { describePromoCodes } from 'logic/promo/describePromoCodes'
import { planSpecs } from 'logic/subscription/plan/spec'
import Router from 'next/router'
import { fireEvent } from 'utils/analytics'
import { getPlanCart } from 'utils/cart'
import { CobrandedOfferLogic } from 'utils/cobranding/useCobrandedOffer'
import { ShouldNeverHappenError } from 'utils/error'
import { encodeQuery } from 'utils/fetcher'
import { pick, toLowerCase } from 'utils/general'
import { prepareAndSendSignupDataForImpact } from 'utils/impact/prepareAndSendSignupDataForImpact'
import { offerSpecs } from 'utils/promo/spec'
import { SESSION_CONST_TRUE, SESSION_NEW_USER_CREATED, setSessionValue } from 'utils/sessionStorage'

export const sessionStorageEmailKey = '@goodtrust/email'

export const handleSignupSuccess = async (args: {
  cobranding: CobrandedOfferLogic['signup']
  offerResult: CompleteOfferModalResult | undefined
  next?: string
  flowDirective?: SimpleFlowDirectiveType
  email: string
  verifyEmail: boolean
}) => {
  setSessionValue(SESSION_NEW_USER_CREATED, SESSION_CONST_TRUE)

  fireEvent('est_signed_up', {
    product: args.flowDirective ? toLowerCase(args.flowDirective) : 'not_selected',
  })

  await prepareAndSendSignupDataForImpact()

  if (args.offerResult?.type === 'continue') {
    const { nowRedeemedSubscriptionOffers } = await describePromoCodes(
      args.offerResult.promo.code
    ).handleClaimPromoCode(args.offerResult.info, args.email)

    const hasRedeemedMainSubscription = nowRedeemedSubscriptionOffers.some((offer) => {
      if (!offer.type) throw new ShouldNeverHappenError()
      const offerSpec = offerSpecs[offer.type]
      if (!offerSpec.freeSubscription) throw new ShouldNeverHappenError()

      return planSpecs[offerSpec.freeSubscription].planGroup === 'MAIN'
    })
    if (hasRedeemedMainSubscription) {
      if (args.verifyEmail) {
        setSessionValue(sessionStorageEmailKey, args.email)
        const url = { pathname: '/verify' }
        await Router.push(url)
      } else {
        await Router.push('/signup/profile')
      }
      return
    }
    // IMPROVE [Test] once BE is ready: that this redirection works and redeems the plan
  }

  await args.cobranding.onMaySetPlanCart()
  if (args.verifyEmail) {
    setSessionValue(sessionStorageEmailKey, args.email)
    const url = { pathname: '/verify' }
    await Router.push(url)
    return
  }
  if (args.flowDirective) {
    await createDirectiveAndRedirect(args.flowDirective, {
      isLogged: true,
      routerMechanism: 'replace',
    })
  } else {
    const hasSelectedPlanPreviously = !!getPlanCart()
    const targetPath =
      args.cobranding.afterSignup.shouldRedirectToCheckout || hasSelectedPlanPreviously
        ? '/signup/checkout'
        : '/pricing'

    await Router.push(encodeQuery(targetPath, pick(args, 'next')))
  }
}
