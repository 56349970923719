import { createContext } from 'react'

export type MandatoryContactInfo = {
  first_name: boolean
  last_name: boolean
  email: boolean
  phone_number: boolean
}
export const MandatoryContactInfoContext = createContext<MandatoryContactInfo>({
  first_name: true,
  last_name: true,
  phone_number: false,
  email: false,
})
