import { PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { encodeQuery } from 'utils/fetcher'
import { matchMap } from 'utils/general'

export function getDowngradePageUrl(targetPlanType: PlanType) {
  const { planGroup } = planSpecs[targetPlanType]
  return matchMap(planGroup, {
    MAIN: encodeQuery('/signup/downgrade', { to: targetPlanType }),
    ANIMATION: encodeQuery('/subscription/animations/downgrade'),
    GROUP_LIFE: encodeQuery('/goodtrust-life/downgrade', { to: targetPlanType }),
    ESTATE: encodeQuery('/me/estate-planning/downgrade'),
  })
}
