import { PetStepKey } from 'components/directive/pet/types'
import { isValidationErrorRelatedToAboutYou } from 'components/directive/utils/isValidationErrorRelatedToAboutYou'
import { DirectiveSectionSpecification } from 'components/directive/utils/types'

export const PET_DIRECTIVE_PROGRESS: DirectiveSectionSpecification<PetStepKey>[] = [
  {
    name: 'directive.pet.about_you.nav_title',
    mobile: 'directive.pet.about_you.nav_title_short',
    value: 'ABOUT_YOU',
    url: 'about-you',
    subsections: [
      {
        sectionKey: 'ABOUT_YOU',
        isValidationErrorRelated: isValidationErrorRelatedToAboutYou,
      },
      {
        sectionKey: 'ABOUT_MORE',
      },
    ],
  },
  {
    name: 'directive.pet.form.about_your_pet.nav_title',
    mobile: 'directive.pet.form.about_your_pet.nav_title_short',
    value: 'ABOUT_YOUR_PET',
    url: 'about-your-pet',
  },
  {
    name: 'directive.pet.form.caregivers.nav_title',
    mobile: 'directive.pet.form.caregivers.nav_title_short',
    value: 'CAREGIVERS',
    url: 'caregivers',
  },
  {
    name: 'directive.pet.form.general_info.nav_title',
    mobile: 'directive.pet.form.general_info.nav_title_short',
    value: 'GENERAL_INFO',
    url: 'general-info',
  },
  {
    name: 'directive.pet.form.health_and_contacts.nav_title',
    mobile: 'directive.pet.form.health_and_contacts.nav_title_short',
    value: 'HEALTH_AND_CONTACTS',
    url: 'health-and-contacts',
  },
  {
    name: 'directive.pet.form.daily_routine.nav_title',
    mobile: 'directive.pet.form.daily_routine.nav_title_short',
    value: 'DAILY_ROUTINE',
    url: 'daily-routine',
  },
  {
    name: 'directive.pet.form.care_after_death.nav_title',
    mobile: 'directive.pet.form.care_after_death.nav_title_short',
    value: 'CARE_AFTER_DEATH',
    url: 'care-after-death',
  },
  {
    name: 'directive.pet.form.eol_care.nav_title',
    mobile: 'directive.pet.form.eol_care.nav_title_short',
    value: 'EOL_CARE',
    url: 'eol-care',
  },
  {
    name: 'directive.pet.review_nav_title',
    value: 'REVIEW',
    url: 'review',
  },
]
