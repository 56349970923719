import { GoodtrustApiMock } from 'utils/mocks/api.mock'
import { rest } from 'msw'
import { ApiType } from 'utils/types'

export class GoodtrustApiMockPromocodeSlice {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: GoodtrustApiMock) {}
  handlers() {
    return [
      rest.post(this.api.prefixed('/api/v1/promocode/validate'), (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json<ApiType['PromoCodeValidationResponse']>({
            offers: [
              {
                amount: 0.5,
                type: 'DISCOUNT',
                option: 'PERCENTAGE',
                discount_types: ['ESTATE_PLAN'],
              },
            ],
            promo_code_info: {
              uuid: 'uuid-promo-code',
              code: 'aol5',
              partner: this.api.mockCobrandedPartner('AOL'),
            },
          })
        )
      }),
    ]
  }
}
