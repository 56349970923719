export function createClientPromiseManager() {
  const localPromiseCache = new Map()
  const localStateCache = new Map()

  /**
   * A wrapped around an async call that returns existing promise for given key, if its
   * currently pending.
   */
  function resolveDeduped<T>(resolve: () => Promise<T>, options: { key: string }): Promise<T> {
    const { key } = options

    // step: If a promise for given key already exists, return the promise (thus "promise manager").
    // This handles concurrent resource calls for same key to be handled by only one promise.
    const existing = localPromiseCache.get(key)

    if (existing && localStateCache.get(key) === 'pending') {
      return existing
    }

    // step: If promise doesn't exist yet, create it and store it in context to work with it in later calls.
    const promise = resolve()
    localPromiseCache.set(key, promise)
    localStateCache.set(key, 'pending')

    promise.finally(() => {
      localStateCache.set(key, 'idle')
      localPromiseCache.delete(key)
    })

    // step: return the promise (do not await it) AS-IS
    return promise
  }

  return { resolveDeduped }
}

export function createServerPromiseManager() {
  return {
    // eslint-disable-next-line unused-imports/no-unused-vars
    resolveDeduped: <T>(resolve: () => Promise<T>, options: { key: string }): Promise<T> => {
      // note: on server we never cache one resource (could be more users, ...)
      return resolve()
    },
  }
}
