import { PlanGroup, PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { ApiType } from 'utils/types'
import { describeMyPlansForPlanGroup } from 'logic/subscription/plan/my/group/describe'
import { isGroupLifeAvailable } from 'logic/subscription/plan/groupLife/isGroupLifeAvailable'
import { numberComparator } from 'utils/general'
import { bound } from 'utils/class'

export function describeMyPlans(userMe: ApiType['UserResponse']) {
  return bound({
    forPlanGroup: (planGroup: PlanGroup) => describeMyPlansForPlanGroup(userMe, planGroup),
    forPlanTypeGroup(planType: PlanType) {
      return this.forPlanGroup(planSpecs[planType].planGroup)
    },
    hasFullDirectiveAccess: () => {
      const main = describeMyPlansForPlanGroup(userMe, 'MAIN')
      const estate = describeMyPlansForPlanGroup(userMe, 'ESTATE')

      return (
        main.getActivePlanSpec().givesFullDirectiveAccess ||
        estate.getActivePlanSpec().givesFullDirectiveAccess ||
        false
      )
    },
    //
    //
    canUpgradeTo(planType: PlanType): boolean {
      const { isGroupLife, isPeriodLongerThan, hasLifetimePlan, getActivePlanSpec } =
        this.forPlanTypeGroup(planType)

      if (isGroupLife() && !isGroupLifeAvailable()) return false

      if (this.isTrialing() && isPeriodLongerThan({ years: 1 })) {
        // trialing user AND period > 1 year isn't supported by BE due to implementation complexity
        return false
      }

      if (hasLifetimePlan())
        // lifetime users cannot change their plan without contacting support
        return false

      if (this.hasActivePlan(planType) || this.isPlanSuccessorToMyPlan(planType)) return false
      if (
        planType === 'ESTATE_PLANNING' &&
        this.forPlanTypeGroup('FREE_PLAN_MAIN').getActivePlanSpec().givesFullDirectiveAccess
      )
        return false

      return getActivePlanSpec().upgradeOptions?.includes(planType) ?? false
    },

    ///
    //
    listPossibleUpgrades(): PlanType[] {
      const planTypes = Object.keys(planSpecs) as PlanType[]
      return planTypes.filter((type) => this.canUpgradeTo(type))
    },
    //
    //
    canDowngradeTo(planType: PlanType) {
      const { hasLifetimePlan, getActivePlanSpec } = this.forPlanTypeGroup(planType)
      return (
        (getActivePlanSpec().downgradeOptions?.includes(planType) && !hasLifetimePlan()) ?? false
      )
    },
    //
    //
    hasActivePlan(planType: PlanType) {
      return planType === this.forPlanTypeGroup(planType).getActivePlan()
    },

    isTrialing() {
      return this.forPlanGroup('MAIN').getSubscription()?.state === 'TRIALING'
    },

    isFreePremium() {
      return this.forPlanGroup('MAIN').getSubscription()?.free_premium
    },

    canRenewPlan(planType: PlanType): boolean | undefined {
      const planSpec = planSpecs[planType]
      const { hasLifetimePlan, isSubscriptionBeingCancelled } = this.forPlanGroup(
        planSpec.planGroup
      )
      if (hasLifetimePlan()) return false
      if (!this.hasActivePlan(planType) && !this.isPlanSuccessorToMyPlan(planType)) return false
      return isSubscriptionBeingCancelled()
    },

    /**
     * @returns true if planType is a successor to the user
     */
    isPlanSuccessorToMyPlan(planType: PlanType) {
      const spec = planSpecs[planType]
      return spec.showAsCurrentForLegacyPlans?.includes(
        this.forPlanGroup(spec.planGroup).getActivePlan()
      )
    },

    /**
     * Determines whether the postSubscriptionCreate endpoint or the upgrade endpoint should be used to upgrade to a plan.
     */
    chooseUpgradeMethodTo(planType: PlanType): 'upgrade' | 'create' | undefined {
      if (!describeMyPlans(userMe).canUpgradeTo(planType)) return undefined

      const planGroup = planSpecs[planType].planGroup

      const activePlanSpec = this.forPlanGroup(planGroup).getActivePlanSpec()
      return activePlanSpec.isFree ? 'create' : 'upgrade'
    },

    hasAnyPaidPlan(opts: { considerLifetimePaid: boolean }) {
      return userMe.active_subscriptions?.some((subscription) => {
        if (!subscription.type) return false
        const { hasPaidPlan, hasLifetimePlan } = this.forPlanTypeGroup(subscription.type)

        if (hasLifetimePlan()) return opts.considerLifetimePaid

        return hasPaidPlan()
      })
    },

    getNextPeriodEnd() {
      return userMe.active_subscriptions
        ?.filter(
          (sub) =>
            sub.type &&
            sub.current_period_end != null &&
            !this.forPlanTypeGroup(sub.type).isSubscriptionBeingCancelled()
        )
        .sort(numberComparator((sub) => new Date(sub.current_period_end ?? '').getTime()))[0]
        ?.current_period_end
    },
  })
}
