import { SpecBuilder } from 'utils/general'
import { ApiType } from 'utils/types'

export type CobrandedPartnerSlug = NonNullable<ApiType['SetUserTipCommand']['cobranded_partner']>

export const cobrandingPartnerSpec = new SpecBuilder<{
  slug: CobrandedPartnerSlug
  promotions: { code: string; redirect: string }[]
}>().build<CobrandedPartnerSlug>({
  AARP: {
    slug: 'AARP',
    promotions: [{ code: 'aarp25', redirect: '/aarp' }],
  },
  AOL: {
    slug: 'AOL',
    promotions: [{ code: 'aol5', redirect: '/aol' }],
  },
  CARING_COM: {
    slug: 'CARING_COM',
    promotions: [{ code: 'caring25', redirect: '/caring' }],
  },
  E_FUNERAL: {
    slug: 'E_FUNERAL',
    promotions: [{ code: 'efuneral25', redirect: '/efuneral' }],
  },
  EVER_LOVED: {
    slug: 'EVER_LOVED',
    promotions: [{ code: 'everloved25', redirect: '/everloved' }],
  },
  JOIN_CAKE: {
    slug: 'JOIN_CAKE',
    promotions: [{ code: 'joincake25', redirect: '/joincake' }],
  },
  NGL: {
    slug: 'NGL',
    promotions: [{ code: 'ngl25', redirect: '/ngl' }],
  },
  GOODTRUST_HOLIDAY: {
    slug: 'GOODTRUST_HOLIDAY',
    promotions: [{ code: 'Holiday1', redirect: '/holiday2022' }],
  },
})
