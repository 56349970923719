import { useUserMe } from 'api/goodtrust/user'
import { useEffect } from 'react'
import { ANALYTICS } from 'utils/analytics/consts'
import { events } from 'utils/analytics/events'
import { fireEvent } from 'utils/analytics/fire'
import { mutateAnalyticsData } from 'utils/analytics/mutate'
import { Flow } from 'utils/analytics/types'
import { useAuth } from 'utils/auth/hooks/useAuth'

export { ANALYTICS, mutateAnalyticsData, fireEvent, events }

// Set user_id field of analytics events when user logs in/out
export const useAnalyticsUserId = () => {
  const { isLogged } = useAuth()
  const { data: userData } = useUserMe({
    disabled: !isLogged,
    config: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  })
  const user_id = userData?.json?.uuid ?? undefined
  useEffect(() => {
    if (window.gtag) {
      window.gtag('set', { user_id })
    }
  }, [user_id])
}

/*
 * If the user hit a paywall with living or singing portraits and the flow has been set,
 * this function is used to avoid resetting it in animation pricing pages
 */
export const getAnimationSubscriptionFlow: () => Flow = () => {
  const animationFlows: Flow[] = ['singing_portraits', 'memories']
  if (ANALYTICS.flow && animationFlows.includes(ANALYTICS.flow)) {
    return ANALYTICS.flow
  } else return 'memories'
}
