import useSWR from 'swr'
import { FetchResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { apiAuthFetcher } from 'api/goodtrust/api'
import { getDirectiveKey } from './utils'

export function useUserFuneral(uuid: string) {
  return useSWR<FetchResponse<ApiType['UserDirectiveFuneralResponse']>>(
    uuid ? getDirectiveKey(uuid, 'FUNERAL') : null,
    apiAuthFetcher,
    { revalidateOnFocus: false }
  )
}

export function postUserFuneralFinish(uuid: string) {
  return apiAuthFetcher<ApiType['UserDirectiveResponse']>(
    `/api/v1/user/directive/funeral/${uuid}/finish`,
    {
      method: 'POST',
    }
  )
}

export function downloadUserFuneral(uuid: string) {
  return apiAuthFetcher(`/api/v1/user/directive/funeral/${uuid}/generate`, {
    toBlob: true,
    noThrowOnError: true,
  })
}
