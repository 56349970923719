import useSWR, { mutate } from 'swr'
import { toastSuccessString } from 'components/Toast'
import { getSessionValue, setSessionValue } from 'utils/sessionStorage'
import { GoodtrustApiMock, GoodtrustApiMockState } from 'utils/mocks/api.mock'
import { setupWorker } from 'msw'
import { safeWindow, tryParseJson } from 'utils/general'

export type MockType = 'group_life' | 'i18n' | 'api'

const mockState: Record<MockType, boolean> = {
  group_life: false,
  i18n: false,
  api: getSessionValue('@goodtrust/mockState/api') === 'true',
}
const initState = tryParseJson<Partial<GoodtrustApiMockState>>(
  getSessionValue('@goodtrust/mockState/api/state')
)
const mockedApi = new GoodtrustApiMock(
  String(process.env.NEXT_PUBLIC_API_URL),
  initState,
  (state) => {
    setSessionValue('@goodtrust/mockState/api/state', JSON.stringify(state))
  }
)
const worker = safeWindow != null ? setupWorker(...mockedApi.handlers()) : undefined

function startWorker() {
  worker?.start({ onUnhandledRequest: 'bypass' })
}
if (mockState.api) {
  startWorker()
}

export function useMockState() {
  return useSWR('@goodtrust/mockState', () => mockState)
}

export function useMockEnabled(type: MockType) {
  return useMockState().data?.[type] ?? false
}

export function toggleMock(type: MockType) {
  mockState[type] = !mockState[type]
  mutate('@goodtrust/mockState')

  if (type === 'api') {
    setSessionValue('@goodtrust/mockState/api', mockState.api ? 'true' : '')
    if (mockState.api) {
      startWorker()
    } else {
      worker?.stop()
    }
  }
  toastSuccessString(`${mockState[type] ? 'Enabled' : 'Disabled'} mock ${type}`)
}

export function resetMockApiState() {
  mockedApi.resetState()
}
