import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const SForm = styled.form`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const SButtonsContainer = styled.div`
  display: flex;
  width: min-content;
  margin-left: auto;
  & > button {
    width: ${pxToRem(180)}rem;
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  @media ${BREAKPOINTS.MD.max} {
    margin: 0;
    width: 100%;
    & > button {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
`
