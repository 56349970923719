import { postLogin, postMfaLogin } from 'api/goodtrust/auth'
import { handleAuthResponse } from 'api/goodtrust/auth.util'
import { patchUserMe, postUserRegister } from 'api/goodtrust/user'
import { toastSuccess } from 'components/Toast'
import { format } from 'date-fns'
import { createDirectiveAndRedirect } from 'logic/directive/create/create'
import Router from 'next/router'
import { mutate } from 'swr'
import { updateEventHistory } from 'utils/analytics/history'
import { isUserLoggedIn } from 'utils/auth/authFetcher'
import { logout } from 'utils/auth/logout'
import { addCart, setPlanCart } from 'utils/cart'
import { resetMockApiState, toggleMock } from 'utils/devtools/useMock'
import { MessageException } from 'utils/error'
import { keysOf } from 'utils/general'

export type Command = {
  name: string
  run: () => Promise<void>
}

const LOGOUT: Command = {
  name: 'logout',
  run: async () => {
    await logout()
  },
}

const LOGIN_HANNA: Command = {
  name: 'login_hanna',
  run: async () => {
    await logout()

    await handleAuthResponse(await postLogin(`blablabla@mailinator.com`, 'test123'), false, {
      onLoginSuccess: () => {
        toastSuccess('logged_in')
      },
      onMfa: async (token: string) => {
        await handleAuthResponse(await postMfaLogin('4242', token), false, {
          onVerificationRequired: () => {
            throw new MessageException('user_not_verified')
          },
        })
      },
      onVerificationRequired: () => {
        throw new MessageException('user_not_verified')
      },
    })
    await mutate('/api/v1/user/me')
  },
}
const LOGIN_OGUZ: Command = {
  name: 'login_oguz',
  run: async () => {
    await logout()

    await handleAuthResponse(
      await postLogin(`oguz.kazkayasi+gt35@cleevio.com`, 'password'),
      false,
      {
        onLoginSuccess: () => {
          toastSuccess('logged_in')
        },
        onMfa: async (token: string) => {
          await handleAuthResponse(await postMfaLogin('4242', token), false, {
            onVerificationRequired: () => {
              throw new MessageException('user_not_verified')
            },
          })
        },
        onVerificationRequired: () => {
          throw new MessageException('user_not_verified')
        },
      }
    )
    await mutate('/api/v1/user/me')
    await mutate('@goodtrust/auth')
  },
}

const NEW_ACCOUNT: Command = {
  name: 'new_account',
  run: async () => {
    await logout()
    const date = format(new Date(), 'yy_MM_dd__HH_mm_ss')
    const authResponse = await postUserRegister({
      email: `test-account+${date}@example.com`,
      password: '123',
      source: 'DEFAULT',
    })

    const authResult = await handleAuthResponse(authResponse, true, {
      onError: () => {
        throw new MessageException('failed_to_create_new_acc')
      },
      onVerificationRequired: () => {
        throw new MessageException('user_not_verified')
      },
    })

    if (!authResult) return

    await patchUserMe({
      first_name: 'Test Acc',
      last_name: date,
    })

    await mutate('/api/v1/user/me')
    await mutate('@goodtrust/auth')
  },
}

const ESTATE_PLUS: Command = {
  name: 'estate_plus_checkout',
  run: async () => {
    await NEW_ACCOUNT.run()

    setPlanCart({
      firstSelectedType: 'ESTATE_PLAN',
      type: 'ESTATE_PLAN',
      isSigningUp: true,
    })

    Router.push('/signup/checkout')
  },
}

const START_DIGITAL_EXECUTOR: Command = {
  name: 'start_digital_executor',
  run: async () => {
    Router.push('/wgs/select')

    addCart({
      type: 'cart/v2',
      cartItem: {
        id: 478,
        actionUuid: '609bfd7f-7de6-11ec-b781-22e1b4bc5595',
        actionType: 'FULL_ACCESS',
        amount: 5600,
        currency: 'USD',
        siteUuid: '60175116-7de6-11ec-b781-22e1b4bc5595',
        siteTitle: 'Kraken',
      },
    })
  },
}

const GOTO_ANIMATIONS_PRICING: Command = {
  name: 'goto_animations_pricing',
  run: async () => {
    Router.push('/subscription/animations')
  },
}

const GOTO_PLAN: Command = {
  name: 'goto_plan',
  run: async () => {
    Router.push('/me/profile?tab=plan')
  },
}

const START_WILL: Command = {
  name: 'start_will',
  run: async () => {
    const isLoggedIn = await isUserLoggedIn()
    if (!isLoggedIn) {
      await NEW_ACCOUNT.run()
    }

    await createDirectiveAndRedirect('WILL', { isLogged: true, routerMechanism: 'push' })
  },
}

const GOTO_GROUP_LIFE: Command = {
  name: 'goto_group_life',
  run: async () => {
    Router.push('/goodtrust-life')
  },
}

const MOCK_I18N: Command = {
  name: 'mock_i18n',
  run: async () => {
    toggleMock('i18n')
  },
}

const MOCK_API: Command = {
  name: 'mock_api',
  run: async () => {
    toggleMock('api')
  },
}

const MOCK_API_RESET_STATE: Command = {
  name: 'mock_api_reset_state',
  run: async () => {
    resetMockApiState()
  },
}

const FILL_GROUP_LIFE_CART: Command = {
  name: 'fill_group_life_cart',
  run: async () => {
    setPlanCart({
      firstSelectedType: 'GROUP_LIFE_10K',
      type: 'GROUP_LIFE_10K',
    })
  },
}

const RESET_EVENT_HISTORY: Command = {
  name: 'reset_event_history',
  run: async () => {
    updateEventHistory((history) => {
      keysOf(history).forEach((key) => {
        delete history[key]
      })
    })
  },
}

export const secretCommands: Command[] = [LOGIN_HANNA, LOGIN_OGUZ]
export const availableCommands: Command[] = [
  LOGOUT,
  NEW_ACCOUNT,
  ESTATE_PLUS,
  GOTO_ANIMATIONS_PRICING,
  GOTO_PLAN,
  START_DIGITAL_EXECUTOR,
  GOTO_GROUP_LIFE,
  FILL_GROUP_LIFE_CART,
  MOCK_I18N,
  START_WILL,
  RESET_EVENT_HISTORY,
  LOGIN_HANNA,
  LOGIN_OGUZ,
  MOCK_API,
  MOCK_API_RESET_STATE,
]
