import { getSubscriptionMap } from 'api/goodtrust/subscription'
import { getUserMe } from 'api/goodtrust/user'
import { GroupLifePlanType } from 'logic/subscription/plan/types'
import { getButtonBehavior } from 'logic/subscription/button/useSubscriptionCardButton'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { isGroupLifePlanType } from 'logic/subscription/plan/type/parse'
import { AnalyticsEvent } from 'utils/analytics/event'
import { coverageSpec } from 'utils/analytics/group-life/spec'
import { EventArgs } from 'utils/analytics/types'
import { ShouldNeverHappenError } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { matchMap } from 'utils/general'
import { ApiType } from 'utils/types'

/**
 * This event is fired when user selects his plan and proceeds to the checkout.
 */
export const gl_plan_selected = new AnalyticsEvent<{
  plan: GroupLifePlanType
}>({
  async onFire(ctx) {
    const userMe = await getUserMe().then(unwrapResponse.body)
    const subMap = await getSubscriptionMap().then(unwrapResponse.body)
    const activePlanType = describeMyPlans(userMe).forPlanGroup('GROUP_LIFE').getActivePlan()

    if (!isGroupLifePlanType(activePlanType)) throw new ShouldNeverHappenError()

    ctx.fireEvent('gl_plan_selected', {
      flow: ctx.history.gl_plans_open?.flow ?? 'outside',
      new_plan: matchMap(ctx.args.plan, coverageSpec),
      previous_plan: matchMap(activePlanType, coverageSpec),
      price: (subMap.subscriptions?.[ctx.args.plan]?.DEFAULT?.price ?? 0) / 100,
      type: determineType(ctx.args.plan, userMe),
    })
  },
})

export function determineType(
  planType: GroupLifePlanType,
  userMe: ApiType['UserResponse']
): EventArgs['gl_plan_selected']['type'] {
  const buttonBehavior = getButtonBehavior(planType, userMe)
  const hasAnyActiveSubscription = !!userMe.active_subscriptions?.length
  if (!hasAnyActiveSubscription && buttonBehavior === 'upgrade') {
    return 'new_user'
  }

  return buttonBehaviorSpec[buttonBehavior]
}

const buttonBehaviorSpec: Record<
  ReturnType<typeof getButtonBehavior>,
  EventArgs['gl_plan_selected']['type']
> = {
  downgrade: 'downgrade',
  upgrade: 'upgrade',
  register: 'new_user',
  renew: 'cancellation',

  'contact-support': 'else',
  'downgrade-before-upgrade': 'else',
  current: 'else',
}
