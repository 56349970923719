import { ApiType } from 'utils/types'
import { apiAuthFetcher } from './api'
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite'
import useSWR from 'swr'
import { FetchResponse } from 'utils/fetcher'
import { encodePageQuery } from 'utils/pagination'
import { useMemo } from 'react'
import { useDebounce } from 'utils/hooks'
import { emptyObject } from 'utils/constants'
import { fireEvent } from 'utils/analytics'
import { throwIfTemporarilyDisabledMfa } from 'api/goodtrust/user'
import { TTCDelete } from 'utils/analytics/types'
import { defineApiResource } from 'api/goodtrust/api/define'

export const { use: useMyContact } = defineApiResource(
  '/api/v1/trusted-contact/my/{trustedContactUuid}',
  {
    requiresAuth: true,
    parameters: ['trustedContactUuid'],
  }
)

export const { use: useMyContactPublic } = defineApiResource('/api/v1/trusted-contact/{uuid}', {
  requiresAuth: false,
  parameters: ['uuid'],
})

export const { patch: patchContact } = defineApiResource('/api/v1/trusted-contact/{uuid}', {
  requiresAuth: true,
  parameters: ['uuid'],
})

export const { use: useContactSuggestions } = defineApiResource(
  '/api/v1/trusted-contact/{uuid}/suggestions',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export function useAllContactsList() {
  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseTrustedContactResponse']>>(
    (_, prev) => encodePageQuery('/api/v1/trusted-contact/', prev),
    apiAuthFetcher
  )
}

export function useMyContactsList(
  size?: number,
  filters?: ApiType['TrustedContactSpecification'],
  config?: SWRInfiniteConfiguration<
    FetchResponse<ApiType['PaginatedResponseTrustedContactResponse']>,
    Error
  >
) {
  const debouncedBody = useDebounce(filters, 350)
  const memoizedBody = useMemo(() => {
    return {
      method: 'POST',
      body: debouncedBody || emptyObject,
    }
  }, [debouncedBody])

  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseTrustedContactResponse']>>(
    (_, prev) => [encodePageQuery('/api/v1/trusted-contact/my', prev, { size }), memoizedBody],
    apiAuthFetcher,
    { revalidateOnFocus: false, revalidateOnReconnect: false, ...config }
  )
}

export function useImContactOfList(
  size?: number,
  filters?: ApiType['TrustedContactSpecification']
) {
  const memoizedBody = useMemo(() => {
    return {
      method: 'POST',
      body: { ...filters },
    }
  }, [filters])

  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseTrustedContactResponse']>>(
    (_, prev) => [encodePageQuery('/api/v1/trusted-contact/of', prev, { size }), memoizedBody],
    apiAuthFetcher
  )
}

export function usePendingTrustedContactInvitationCount() {
  const memoizedBody: { body: ApiType['TrustedContactSpecification'] } = useMemo(() => {
    return { method: 'POST', body: { states: ['PENDING'] } }
  }, [])

  return useSWR<FetchResponse<ApiType['TrustedContactCountResponse']>>(
    ['/api/v1/trusted-contact/of/count', memoizedBody],
    apiAuthFetcher
  )
}
export const { use: useImContactOf } = defineApiResource(
  '/api/v1/trusted-contact/of/{trustedContactUuid}',
  {
    requiresAuth: true,
    parameters: ['trustedContactUuid'],
  }
)

export function deleteContact(uuid: string, gaData: TTCDelete) {
  fireEvent('tc_deleted', gaData)
  return apiAuthFetcher<void>(`/api/v1/trusted-contact/${uuid}`, { method: 'DELETE' })
}

export function declineContact(uuid: string) {
  fireEvent('tc_declined')
  return apiAuthFetcher<void>(`/api/v1/trusted-contact/${uuid}/decline`, {
    method: 'POST',
  })
}

export function postContact(body: ApiType['CreateTrustedContactCommand']) {
  fireEvent('tc_invite_sent', { type: body.type, message: body.note ? 'yes' : 'no' })
  return apiAuthFetcher<ApiType['TrustedContactResponse']>(`/api/v1/trusted-contact`, {
    method: 'POST',
    body: body,
  })
}

export function remindContactInvite(
  uuid: string,
  type: ApiType['TrustedContactUserResponse']['type'],
  body: ApiType['TrustedContactReminderRequest']
) {
  if (body.send_type !== 'EMAIL') throwIfTemporarilyDisabledMfa()
  fireEvent('tc_reminder_sent', { type: type, reminder_type: body.send_type })
  return apiAuthFetcher<void>(`/api/v1/trusted-contact/resend/${uuid}`, { method: 'POST', body })
}

export function useSentContactReminders(uuid?: string) {
  return useSWR<FetchResponse<ApiType['TrustedContactReminderResponse']>>(
    uuid != null ? `/api/v1/trusted-contact/${uuid}/reminders` : null,
    apiAuthFetcher
  )
}

export function postContactBind(body: ApiType['BindUserToTrustedContactCommand']) {
  fireEvent('tc_bind_called')
  return apiAuthFetcher<void>(`/api/v1/trusted-contact/bind`, {
    method: 'POST',
    body: body,
  })
}

// Share item with TC calls for TC pages

export const { post: postContactShareUserSite } = defineApiResource(
  '/api/v1/user/site/{uuid}/share',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export const { post: postContactShareOrderSite } = defineApiResource('/api/v1/order/{uuid}/share', {
  requiresAuth: true,
  parameters: ['uuid'],
})

export const { post: postContactShareDocument } = defineApiResource(
  '/api/v1/user/document/{uuid}/share',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export const { post: postContactShareDevice } = defineApiResource(
  '/api/v1/user/device/{uuid}/share',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export const { post: postContactShareUserDirective } = defineApiResource(
  '/api/v1/user/directive/{uuid}/share',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export const { delete: deleteContactDeathReport } = defineApiResource(
  '/api/v1/trusted-contact/{uuid}/death-report',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export function postContactDeathTrigger(contactUuid: string) {
  return apiAuthFetcher<void>(`/api/v1/trusted-contact/${contactUuid}/death-trigger`, {
    method: 'POST',
  })
}
