import { safeWindow } from 'utils/general'

/**
 * __Context__
 * The browser can just return false immediately on window.confirm immediately without presenting the user with the message to be confirmed.
 * We suspect that this can be due to checking a checkbox that the user does not want such pop up windows.
 *
 * However we're using the window.confirm message to ask the user if they want to leave the form in case they did some changes to it.
 *
 * __Problem__
 * The problem is that if confirmMessage returns false immediately, the user is unable to leave the page.
 *
 * __Solution__
 * Luckily, we can guess whether the dialog was shown or it returned immediately.
 * To make an educated guess, we measure the time and if it was under a threshold, we assume it returned immediately and we return true, otherwise we respect the return value from the confirm call.
 */
export function confirmMessage(
  message: string,
  behavior: {
    assumeConfirmedIfReturnsImmediately: boolean
  }
) {
  if (!safeWindow) return false

  const start = Date.now()

  const result = safeWindow.confirm(message)

  const end = Date.now()
  const hasReturnedImmediately = end - start < 200

  return result || (behavior.assumeConfirmedIfReturnsImmediately && hasReturnedImmediately)
}
