import immer, { Draft } from 'immer'
import useSWR, { mutate, SWRResponse } from 'swr'
import { ident, logMsg, logWarn, truncateDecimals } from '../general'
import { getSessionValue, setSessionValue } from '../sessionStorage'
import {
  AddCartType,
  CartObjects,
  CartTotal,
  CartType,
  ICart,
  ICartDiscount,
  IPlanCart,
} from './types'
import {
  getKeyFromSessionKey,
  getPlaceholderCart,
  PACKAGES_CART_KEY,
  PACKAGES_CART_SESSION_KEY,
  PLACEHOLDER_CART,
  PLACEHOLDER_PACKAGES_CART,
  PLAN_CART_SESSION_KEY,
  STORE_CART_KEY,
  STORE_CART_SESSION_KEY,
} from './contants'

export function getCart(cartType: 'plan_cart'): IPlanCart | null
export function getCart(cartType: CartType): CartObjects | null {
  try {
    const tmp = getSessionValue(cartType)
    return tmp ? JSON.parse(tmp) : null
  } catch (e) {
    logWarn('Parse cart ended with error: ', e)
    return null
  }
}
export const getPlanCart = () => getCart(PLAN_CART_SESSION_KEY)

export function setCart(cart: CartObjects, cartType: CartType) {
  logMsg(`Set cart for ${cartType}: `, cart)
  setSessionValue(cartType, JSON.stringify(cart))
  mutate(getKeyFromSessionKey(cartType))
}
export const setPlanCart = (cart: IPlanCart) => setCart(cart, PLAN_CART_SESSION_KEY)
export const updatePlanCart = (update: (draft: Draft<IPlanCart>) => void) =>
  setPlanCart(
    immer(
      getPlanCart() ?? ident<IPlanCart>({ type: 'ESTATE_PLAN', firstSelectedType: 'ESTATE_PLAN' }),
      update
    )
  )

export const clearCart = (cartType: CartType): Promise<ICart | undefined | null> => {
  logMsg(`Clear cart ${cartType}`)
  return mutate(getKeyFromSessionKey(cartType), () =>
    cartCommit(getPlaceholderCart(cartType), cartType)
  )
}
export const clearPlanCart = () => clearCart(PLAN_CART_SESSION_KEY)
export const clearStoreCart = () => clearCart(STORE_CART_SESSION_KEY)
export const clearPackagesCart = () => clearCart(PACKAGES_CART_SESSION_KEY)

export async function removeCart(id: string): Promise<ICart | undefined> {
  return mutate(STORE_CART_KEY, async (cart: ICart = PLACEHOLDER_CART) => {
    const newCart: ICart = {
      items: { ...cart.items },
      discount: null,
      timestamp: Date.now(),
    }
    delete newCart?.items?.[id]
    await cartCommit(newCart, STORE_CART_SESSION_KEY)
    return newCart
  })
}

export function planCartChangeCountry(country: string) {
  const currentCart = getPlanCart()
  if (!currentCart) return
  return mutate(PLAN_CART_SESSION_KEY, () => {
    const newCart: IPlanCart = {
      ...currentCart,
      country,
    }
    return setPlanCart(newCart)
  })
}

export function cartCommit(cart: ICart | null, cartType: CartType) {
  logMsg(`Set cart: ${cartType}`, cart)
  setSessionValue(cartType, JSON.stringify(cart))
  return cart
}
export function cartFetcher(cartType: 'cart/v2' | 'packages_cart'): ICart
export function cartFetcher(cartType: 'plan_cart'): IPlanCart | null
export function cartFetcher(cartType: CartType) {
  const json = getSessionValue(cartType)
  const placeholder = getPlaceholderCart(cartType)
  if (!json) return placeholder
  return JSON.parse(json) ?? placeholder
}

export function useCartSwr(cartType: CartType) {
  const fetcher = () => (cartType === 'plan_cart' ? cartFetcher(cartType) : cartFetcher(cartType))
  const swr = useSWR(getKeyFromSessionKey(cartType), fetcher)

  return swr
}

export function usePlanCartSwr() {
  return useCartSwr('plan_cart') as SWRResponse<IPlanCart, unknown>
}

export function useCart(cartType: 'cart/v2' | 'packages_cart'): ICart
export function useCart(cartType: 'plan_cart'): IPlanCart | null
export function useCart(cartType: CartType): CartObjects | null {
  const data = useCartSwr(cartType).data
  const placeholder = getPlaceholderCart(cartType)
  return data ?? placeholder
}
export const useStoreCart = () => useCart(STORE_CART_SESSION_KEY)
export const usePackagesCart = () => useCart(PACKAGES_CART_SESSION_KEY)
export const usePlanCart = () => useCart(PLAN_CART_SESSION_KEY)

export const addCart = (props: AddCartType): Promise<ICart | null | undefined> => {
  const { type, count, interval, price, cartItem, userFrom } = props
  return mutate(getKeyFromSessionKey(type), (cart: ICart | null = getPlaceholderCart(type)) => {
    if (cart === null) return null
    return cartCommit(
      {
        count,
        price,
        interval,
        userFrom,
        discount: null,
        timestamp: Date.now(),
        ...(cartItem && { items: { ...cart.items, [cartItem.siteUuid]: cartItem } }),
      },
      type
    )
  })
}

// sets discount for cart/v2 and packages_cart
export async function setDiscount(
  discount: ICartDiscount | null,
  cartType: CartType
): Promise<ICart | null | undefined> {
  return mutate(getKeyFromSessionKey(cartType), (cart = getPlaceholderCart(cartType)) => {
    const isPackages = cartType === PACKAGES_CART_SESSION_KEY
    return cartCommit(
      {
        ...cart,
        discount: discount,
        timestamp: Date.now(),
        ...(isPackages && { count: cart?.count, price: cart?.price }),
      },
      cartType
    )
  })
}

export const getCartTotal = (cart: ICart, cartType: 'cart/v2' | 'packages_cart') => {
  const isPackages = cartType === 'packages_cart'
  let sum = 0
  let discount
  if (isPackages) {
    sum = cart.price ?? 0
    const offer = cart.discount?.offer
    const amount = offer?.amount ?? 0
    discount =
      offer?.option === 'PERCENTAGE'
        ? Math.max(0, truncateDecimals((cart?.price ?? 0) * amount))
        : Math.min(amount * 100, cart?.price ?? Infinity)
  } else {
    if (cart?.items) {
      sum = Object.values(cart?.items).reduce((sum, item) => sum + (item?.amount ?? 0), 0)
    }
    discount =
      cart.discount?.offer?.option === 'PERCENTAGE'
        ? Math.max(0, sum * (cart.discount?.offer.amount ?? 0))
        : Math.min((cart.discount?.offer?.amount ?? 0) * 100, sum)
  }
  return {
    total: sum - discount,
    sum,
    discount,
  }
}
export const getStoreCartTotal = (cart: ICart) => getCartTotal(cart, 'cart/v2')
export const getPackagesCartTotal = (cart: ICart) => getCartTotal(cart, 'packages_cart')

export type { AddCartType, CartObjects, CartTotal, CartType, ICart, ICartDiscount, IPlanCart }
export {
  STORE_CART_KEY,
  STORE_CART_SESSION_KEY,
  PACKAGES_CART_KEY,
  PACKAGES_CART_SESSION_KEY,
  PLACEHOLDER_CART,
  PLACEHOLDER_PACKAGES_CART,
  PLAN_CART_SESSION_KEY,
}
