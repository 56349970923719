import styled from '@emotion/styled'
import { Text } from 'components/Typography'

export const SText = styled(Text)`
  ul,
  ol {
    padding-left: 1.5rem;
    margin: 1.5rem 0;
  }
`
