import { DirectiveLPSection } from 'components/directive/directiveLPSection/DirectiveLPSection'
import { IconName } from 'components/icon'
import { TranslatableLink } from 'components/TranslatableLink'
import { Caption } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import { colors } from 'utils/colors'
import { numberRange } from 'utils/general'
import { NumberRange } from 'utils/types'

const extraParagraphIcons: Record<NumberRange<3>, IconName> = {
  '1': 'ShieldCheck',
  '2': 'Will',
  '3': 'FileUpload',
}

export const WillLPSections = () => {
  const { t } = useTranslation('will_lp')

  return (
    <>
      <DirectiveLPSection
        type="WILL"
        title={t('will_lp.section_why_will.title')}
        description={
          <Trans
            t={t}
            i18nKey="will_lp.section_why_will.description"
            components={[<Caption key="0" style={{ color: colors.blue[500], marginBottom: 0 }} />]}
          />
        }
        imageSlug="will_lp_section_why_will"
      />

      <DirectiveLPSection
        type="WILL"
        imagePlacement="right"
        title={t('will_lp.section_digital_legacy.title')}
        description={
          <Trans
            t={t}
            i18nKey="will_lp.section_digital_legacy.description"
            components={[<TranslatableLink key="0" href="/digital-vault" />]}
          />
        }
        imageSlug="will_lp_section_digital_legacy"
        extraParagraphs={numberRange(3).map((i) => ({
          title: t(`will_lp.section_digital_legacy.paragraph_${i}.title`),
          description: t(`will_lp.section_digital_legacy.paragraph_${i}.description`),
          icon: extraParagraphIcons[i],
        }))}
        hideButton
      />
    </>
  )
}
