import { defineApiResource } from 'api/goodtrust/api/define'
import immer from 'immer'
import useSWR from 'swr'
import { hashPassword } from 'utils/auth/password'
import { ApiType } from 'utils/types'
import { apiAuthFetcher, apiFetcher } from './api'

export const {
  get: getUserMe,
  put: putUserMe,
  patch: patchUserMe,
  delete: deleteUserMe,
  use: useUserMe,
  mutate: mutateUserMe,
} = defineApiResource('/api/v1/user/me', {
  requiresAuth: true,
  mapResponse: {
    GET: (data) =>
      immer(data, (data) => {
        if (data && isMfaDisabledTemporarily()) {
          data.mfa = false
        }
      }),
  },
})

export async function postUserRegister(payload: ApiType['UserCreateRequest']) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/user', {
    method: 'POST',
    body: {
      ...payload,
      password: await hashPassword(payload.password),
    },
  })
}

export async function postPasswordReset(token: string, password: string) {
  return apiFetcher<ApiType['UserLoginResponse']>(`/api/v1/user/password/reset`, token, {
    method: 'POST',
    body: {
      password,
    },
  })
}

export async function postResendVerifyEmail(payload: ApiType['ResendConfirmationCommand']) {
  return apiFetcher(`/api/v1/user/confirm/resend`, {
    method: 'POST',
    body: payload,
  })
}

export async function putUserPassword(token: string, password: string) {
  return apiFetcher<ApiType['UserLoginResponse']>(`/api/v1/user/password`, token, {
    method: 'PUT',
    body: {
      password,
    },
  })
}

export const postPasswordRecovery = defineApiResource('/api/v1/user/password/recovery', {
  requiresAuth: false,
}).post

export async function putUserConfirm(token: string) {
  return apiFetcher<ApiType['UserConfirmResponse']>('/api/v1/user/confirm', token, {
    method: 'PUT',
  })
}

export const PIN_LENGTH = 4
export function isMfaDisabledTemporarily(): boolean {
  return false
}

export function isReminderSMSDisabled(): boolean {
  return true
}

// Disabled since we use Sinch now. Keeping this in case we have the verification again
export function isPhoneNumberValidationDisabled(): boolean {
  return true
}

export function throwIfTemporarilyDisabledMfa() {
  if (isMfaDisabledTemporarily()) {
    throw new Error('Temporarily disabled 2FA.')
  }
}

export async function postPhoneConfirm(phone_number: string, captchaToken: string) {
  throwIfTemporarilyDisabledMfa()
  return apiAuthFetcher<ApiType['UserConfirmResponse']>('/api/v1/user/confirm/phone', {
    method: 'POST',
    body: {
      phone_number,
    },
    headers: {
      'captcha-response': captchaToken,
    },
  })
}

export async function postPhoneNumberValidate(phone_number: string) {
  return apiAuthFetcher<ApiType['PhoneNumberValidityResponse']>('/api/v1/user/phone/validate', {
    method: 'POST',
    body: {
      phone_number,
    },
  })
}

export async function postEmailConfirmMail() {
  return apiAuthFetcher<ApiType['UserConfirmResponse']>('/api/v1/user/confirm/email', {
    method: 'POST',
  })
}

export async function putUserPhoneNumber(body: ApiType['UserChangePhoneRequest']) {
  throwIfTemporarilyDisabledMfa()
  return apiAuthFetcher<ApiType['UserResponse']>('/api/v1/user/change/phone', {
    method: 'PUT',
    body,
  })
}

export async function resendUserMfaCode() {
  throwIfTemporarilyDisabledMfa()
  return apiAuthFetcher<void>('/api/v1/user/change/phone', { method: 'GET' })
}

export async function putPhoneConfirm(code: string) {
  throwIfTemporarilyDisabledMfa()
  return apiAuthFetcher<ApiType['UserConfirmRequest']>('/api/v1/user/confirm/phone', {
    method: 'PUT',
    body: {
      code,
    },
  })
}

export const { get: requestMfaCode } = defineApiResource('/api/v1/user/mfa/send/verify', {
  requiresAuth: true,
})

export const { delete: deleteUserMfa } = defineApiResource('/api/v1/user/mfa', {
  requiresAuth: true,
})

export const { post: postUserCancelDeath } = defineApiResource(
  '/api/v1/user/deadManSwitch/cancelDeath',
  {
    requiresAuth: true,
  }
)

export const { post: postUserCheck } = defineApiResource('/api/v1/user/check', {
  requiresAuth: false,
})

export async function putUserEmail(token: string, email: string) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/user/email', {
    method: 'PUT',
    body: { email },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export type SocialSite = 'facebook' | 'google'

export async function postUserSocialBinding(token: string, site: SocialSite) {
  return apiAuthFetcher<ApiType['UserLoginResponse']>(`/api/v1/${site}/bind`, {
    method: 'POST',
    body: { token },
  })
}

export async function deleteUserSocialBinding(site: SocialSite) {
  return apiAuthFetcher<ApiType['UserLoginResponse']>(`/api/v1/${site}/unbind`, {
    method: 'DELETE',
  })
}

export const {
  get: getUserTips,
  use: useUserTips,
  patch: patchUserTips,
} = defineApiResource('/api/v1/user/tips', {
  requiresAuth: true,
})

export function useUserGeoData() {
  return useSWR<{
    country_code: string
    country_name: string
    city: string
    postal: string
    latitude: string
    longitude: string
    IP: string
    state: string
  }>('https://geolocation-db.com/json/ee33e930-db78-11eb-9218-377a93e0222e', {
    revalidateOnFocus: false,
    fetcher: async (url) => {
      return await (await fetch(url)).json()
    },
  })
}
