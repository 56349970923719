import { SubscriptionMapItem, TypedSubscriptionMapResponse } from 'api/goodtrust/subscription'
import { PlanType } from 'logic/subscription/plan/types'

export function describeSubscriptions(subMap: TypedSubscriptionMapResponse) {
  return {
    getEstatePlanningPrices: () => {
      const estatePlanning = subMap.subscriptions?.ESTATE_PLANNING?.DEFAULT
      return {
        initial_price: estatePlanning?.initial_price,
        recurrent_price: estatePlanning?.price,
      }
    },

    pickPlan: (planType: PlanType) => {
      const item = subMap.subscriptions?.[planType]
      if (!item) return undefined
      return describeSubscriptionItem(item)
    },
  }
}

export function describeSubscriptionItem(item: SubscriptionMapItem) {
  return {
    pickVariant: (ctx: { isEligibleForCyberscout: boolean }) => {
      // if the item does not have dashlane only variant, let's return the default
      if (item.DASHLANE_ONLY == null) return item.DEFAULT

      // if the user is eligible for cyberscout, we pick the default variant that includes cyberscout
      // otherwise, we pick the variant without cyberscout
      return ctx.isEligibleForCyberscout ? item.DEFAULT : item.DASHLANE_ONLY
    },
  }
}
