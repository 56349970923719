import { EventTriggerContext } from 'utils/analytics/event'
import { sortEventHistory } from 'utils/analytics/history'
import { EventArgs } from 'utils/analytics/types'

export function determineGroupLifeFlow(
  ctx: Pick<EventTriggerContext<unknown>, 'history'>
): EventArgs['gl_plans_open']['flow'] {
  const [latest] = sortEventHistory(ctx.history, [
    'silent_gl_my_account_change_plan_click',
    'gl_signup_open',
    'silent_gl_modal_change_plan_click',
    'silent_gl_upsell_modal_learn_more_click',
    'gl_plans_open',
  ])

  if (latest === 'silent_gl_my_account_change_plan_click') return 'inside'
  if (latest === 'silent_gl_modal_change_plan_click') return 'inside'
  if (latest === 'silent_gl_upsell_modal_learn_more_click') return 'inside'
  if (latest === 'gl_signup_open') return ctx.history.gl_signup_open?.flow ?? 'outside'
  if (latest === 'gl_plans_open') return ctx.history.gl_plans_open?.flow ?? 'outside'

  return 'outside'
}
