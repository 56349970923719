import { InterpolationPrimitive } from '@emotion/serialize'
import { Stack } from 'components/Stack'
import {
  Children,
  FieldsetHTMLAttributes,
  forwardRef,
  isValidElement,
  ReactNode,
  useState,
} from 'react'
import { colors } from 'utils/colors'
import {
  IconInfo,
  InfoTooltip,
  SErrorText,
  SPrefix,
  SRightSideContentContainer,
  StyledFieldset,
} from './Fieldset.styled'

function findDisabledInChildren(children?: ReactNode, depth = 0): boolean | null {
  let isDisabled: boolean | null = null

  Children.forEach(children, (child) => {
    if (!child || !isValidElement(child)) return

    if (child.props['disabled']) {
      isDisabled = child.props['disabled']
    }

    if (child.props.children && isDisabled == null && depth > 0) {
      isDisabled = findDisabledInChildren(child.props.children as ReactNode, depth - 1)
    }
  })

  return isDisabled
}

export interface FieldsetProps {
  error?: ReactNode
  customError?: { [key: string]: ReactNode }
  allErrors?: Record<string, string>
  className?: string
  tooltipCss?: InterpolationPrimitive
  children?: ReactNode
  disabled?: boolean
  info?: string
  rightSideContent?: ReactNode
  prefix?: string
}

export const Fieldset = forwardRef<
  HTMLFieldSetElement,
  React.DetailedHTMLProps<FieldsetHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> &
    FieldsetProps
>((props, ref) => {
  // detect whether a child is disabled
  const isDisabled = props.disabled || findDisabledInChildren(props.children, 0)

  // Detect if custom message should be used
  let element: ReactNode = props.error
  if (typeof props.error === 'string' && props.customError?.[props.error]) {
    element = props.customError?.[props.error]
  }

  return (
    <Stack space={2} className={props.className}>
      <StyledFieldset
        invalid={Boolean(props.error)}
        disabled={!!isDisabled}
        prefix={props.prefix}
        ref={ref}
        onClick={props.onClick}
      >
        {props.children}

        {(props.info || props.rightSideContent) && (
          <SRightSideContentContainer>
            {props.rightSideContent}
            {props.info && (
              <>
                <IconInfo name="Question" color={colors.gray[500]} size="1rem" />
                <InfoTooltip orientation="right" css={props.tooltipCss}>
                  {props.info}
                </InfoTooltip>{' '}
              </>
            )}
          </SRightSideContentContainer>
        )}

        {props.prefix && <SPrefix>{props.prefix}</SPrefix>}
      </StyledFieldset>

      {props.error && <SErrorText variant="inputLabel">{element}</SErrorText>}
    </Stack>
  )
})

interface IBlurCallback {
  (onBlurArg: () => void): () => void
  (): void
}

export const CallbackFieldset = (props: {
  error?: string | null | undefined
  className?: string
  wrapperClassName?: string
  children?: (props: { onFocus: () => void; onBlur: IBlurCallback }) => ReactNode
}) => {
  const [focused, setFocused] = useState(false)

  const onBlur = ((onBlurArg?: () => void) => {
    if (typeof onBlurArg === 'function') {
      return () => {
        onBlurArg()
        void setFocused(false)
      }
    }
    return void setFocused(false)
  }) as IBlurCallback

  const onFocus = () => void setFocused(true)

  return (
    <Stack space={2} className={props.wrapperClassName}>
      <StyledFieldset invalid={Boolean(props.error)} className={props.className} focused={focused}>
        {typeof props.children === 'function' ? props.children({ onFocus, onBlur }) : null}
      </StyledFieldset>
      {props.error && <SErrorText>{props.error}</SErrorText>}
    </Stack>
  )
}
Fieldset.displayName = 'Fieldset'
