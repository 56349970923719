import { GoodtrustApiMock } from 'utils/mocks/api.mock'
import { rest } from 'msw'
import { ident } from 'utils/general'
import { ApiType } from 'utils/types'

export class GoodtrustApiMockUserSlice {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: GoodtrustApiMock) {}
  handlers() {
    return [
      rest.get(this.api.prefixed('/api/v1/user/me'), (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json<ApiType['UserResponse']>({
            uuid: 'mock-user',
            first_name: 'David',
            last_name: 'Kubac',
          })
        )
      }),
      rest.get(this.api.prefixed('/api/v1/user/tips'), (req, res, ctx) => {
        const extendedTips = ident<ApiType['UserTipResponse']>({
          ...this.api.state.userTips,
          partner: this.api.state.userTips.cobranded_partner
            ? this.api.mockCobrandedPartner(this.api.state.userTips.cobranded_partner)
            : undefined,
        })

        return res(ctx.status(200), ctx.json(extendedTips))
      }),
      rest.patch(this.api.prefixed('/api/v1/user/tips'), (req, res, ctx) => {
        const body = req.body as typeof this.api.state.userTips
        this.api.updateState((st) => {
          Object.assign(st.userTips, body)
        })
        return res(ctx.status(200), ctx.json(this.api.state.userTips))
      }),
      rest.get(this.api.prefixed('/api/v1/promocode/claimed'), (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(this.api.state.claimedPromos))
      }),
      rest.post(this.api.prefixed('/api/v1/user/directive/search'), (req, res, ctx) => {
        return res(ctx.status(200), ctx.json([]))
      }),
      rest.get(this.api.prefixed('/api/v2/subscription/list'), (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json(
            ident<ApiType['SubscriptionMapResponse']>({
              subscriptions: {
                ESTATE_PLAN: {
                  DEFAULT: {
                    price: 9600,
                  },
                },
              },
            })
          )
        )
      }),
    ]
  }
}
