import { matchMap } from 'utils/general'

export const US_ZIP_REGEXP = /^[0-9]{5}$/
export const CA_ZIP_REGEXP = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i

export function mapCountryToZipRegexp(country?: string) {
  if (!country || ['CA', 'US'].indexOf(country) === -1) return undefined
  return matchMap(country, {
    CA: CA_ZIP_REGEXP,
    US: US_ZIP_REGEXP,
  })
}
