import { forwardRef } from 'react'
import { CheckboxBox, CheckboxContainer, CheckboxInput, SErrorText } from './Checkbox.styled'
import { InterpolationPrimitive } from '@emotion/serialize'
import { Stack } from 'components/Stack'
import { Icon } from 'components/icon'
import { CheckboxInfo } from 'components/checkbox/CheckboxInfo'

export interface CheckboxProps {
  size?: number
  checkBoxCss?: InterpolationPrimitive
  children?: React.ReactNode
  error?: string
  hideErrorTip?: boolean
  info?: { title: string; desc: string }
}

export const Checkbox = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<Pick<HTMLInputElement, 'type'>> & CheckboxProps
>(({ checkBoxCss, children, className, error, hideErrorTip, info, ...inputProps }, ref) => {
  return (
    <Stack space={0} className={className}>
      <CheckboxContainer css={{ paddingRight: info != null ? '1.5rem' : undefined }}>
        <CheckboxInput {...inputProps} type="checkbox" ref={ref} />
        <CheckboxBox invalid={!!error} css={checkBoxCss} size={inputProps.size}>
          <Icon name="CheckSimple" />
        </CheckboxBox>
        {children}
        {info && <CheckboxInfo info={info} />}
      </CheckboxContainer>
      {error && !hideErrorTip && <SErrorText>{error}</SErrorText>}
    </Stack>
  )
})
Checkbox.displayName = 'Checkbox'
