import styled from '@emotion/styled'
import { Text } from 'components/Typography'
import { BREAKPOINTS } from 'utils/styled'
import { Button } from 'components/button/Button'

export const SInfoModal = styled.div`
  display: flex;
  flex-direction: column;
`

export const STitle = styled(Text)`
  color: black;
  font-weight: bold;
  margin: 0 1.5rem 1.5rem 0;
  @media ${BREAKPOINTS.MD.max} {
    margin: 1.1rem 0 1.5rem;
  }
`

export const SButton = styled(Button)`
  margin-top: 1rem;
  margin-left: auto;
  padding: 1rem 2rem;
  @media ${BREAKPOINTS.MD.max} {
    display: none;
  }
`
