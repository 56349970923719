import { getUserMe } from 'api/goodtrust/user'
import { getDirectiveEditLink } from 'logic/directive/links'
import Router from 'next/router'
import { unwrapResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'

export async function continueDirective(directive: Partial<ApiType['UserDirectiveResponse']>) {
  const userMe = await getUserMe().then(unwrapResponse.body)
  await Router.push(getDirectiveEditLink(directive, userMe))
}
