import useSWRInfinite from 'swr/infinite'
import { FetchResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { apiAuthFetcher } from 'api/goodtrust/api'
import { encodePageQuery } from 'utils/pagination'
import { useMemo } from 'react'
import { DirectiveType, getDirectiveKey } from './utils'
import { DirectiveTypeSpec } from 'logic/directive/types'
import { defineApiResource } from 'api/goodtrust/api/define'

type DirectiveFilter = ApiType['UserDirectiveSpecification']

export function useUserDirectiveList(size?: number) {
  const memoizedBody = useMemo(
    () => ({
      method: 'POST',
      body: {},
    }),
    []
  )

  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseUserDirectiveResponse']>>(
    (_, prev) => [encodePageQuery('/api/v1/user/directive/search', prev, { size }), memoizedBody],
    apiAuthFetcher
  )
}

export const completedDirectivesFilter: DirectiveFilter = {
  states: ['COMPLETED'],
}

export const draftDirectivesFilter: DirectiveFilter = {
  states: ['IN_DRAFT', 'IN_VALIDATION', 'NEW'],
}

export function useUserFilteredDirectiveList(filter: DirectiveFilter) {
  const memoizedBody = useMemo(
    () => ({
      method: 'POST',
      body: filter,
    }),
    [filter]
  )

  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseUserDirectiveResponse']>>(
    (_, prev) => [
      encodePageQuery('/api/v1/user/directive/search', prev, { sort: 'modified,desc' }),
      memoizedBody,
    ],
    apiAuthFetcher
  )
}

export function useUserDirectiveSharedWithList(
  directiveType?: ApiType['UserDirectiveRequest']['directive_type']
) {
  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseUserDirectiveResponse']>>(
    (_, prev) =>
      encodePageQuery('/api/v1/user/directive/sharedWithMe', prev, {
        directiveType,
      }),
    apiAuthFetcher
  )
}

export const { use: useUserDirective, delete: deleteUserDirective } = defineApiResource(
  '/api/v1/user/directive/{uuid}',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export const { post: postUserDirective } = defineApiResource('/api/v1/user/directive', {
  requiresAuth: true,
})

export const { put: putUserDirectiveContact } = defineApiResource(
  '/api/v1/user/directive/{uuid}/trusted-contact',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export const { use: useUserWill } = defineApiResource('/api/v1/user/directive/will/{uuid}', {
  requiresAuth: true,
  parameters: ['uuid'],
})

export const { use: useUserTrust } = defineApiResource('/api/user/directive/trust/{uuid}', {
  requiresAuth: true,
  parameters: ['uuid'],
})

export async function getUserDirective<T extends DirectiveType>(
  type: T,
  uuid: string
): Promise<FetchResponse<DirectiveTypeSpec[T]['response']>> {
  return apiAuthFetcher(getDirectiveKey(uuid, type))
}

export const { delete: removePersonFromDirective } = defineApiResource(
  '/api/v1/user/directive/{uuid}/person/{personUuid}',
  {
    requiresAuth: true,
    parameters: ['uuid', 'personUuid'],
  }
)

export const { post: postUserWillFinish } = defineApiResource(
  '/api/v1/user/directive/will/{uuid}/finish',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export const { post: postUserTrustFinish } = defineApiResource(
  '/api/user/directive/trust/{uuid}/finish',
  {
    requiresAuth: true,
    parameters: ['uuid'],
  }
)

export function downloadUserWill(uuid: string) {
  return apiAuthFetcher(`/api/v1/user/directive/will/${uuid}/generate`, {
    toBlob: true,
    noThrowOnError: true,
  })
}

export function downloadUserTrust(uuid: string) {
  return apiAuthFetcher(getDirectiveKey(uuid, 'TRUST') + '/generate', {
    toBlob: true,
    noThrowOnError: true,
  })
}

export function postShareDirectiveViaEmail(
  directiveUuid: string,
  body: ApiType['UserDirectiveShareEmailRequest']
) {
  return apiAuthFetcher<void>(`/api/v1/user/directive/${directiveUuid}/share/email`, {
    method: 'POST',
    body,
    nullifyBody: true,
  })
}
