import { getUserTips, patchUserTips, useUserTips } from 'api/goodtrust/user'
import { useMemo } from 'react'
import { isUserLoggedIn } from 'utils/auth/authFetcher'
import { cobrandingPartnerSpec } from 'utils/cobranding/partners'
import { unwrapResponse } from 'utils/fetcher'
import { ident, tryParseJson, valuesOf } from 'utils/general'
import {
  clearSessionValue,
  getSessionValue,
  setSessionValue,
  useSessionValue,
} from 'utils/sessionStorage'
import { ApiType } from 'utils/types'

export const COBRANDED_USER_SESSION_KEY = 'cobranded_user'

type CobrandingSessionValue = {
  partnerSlug?: ApiType['SetUserTipCommand']['cobranded_partner']
  promoCode: string
}

export class CobrandingLogic {
  async onEstatePlanningLPMounted(ctx: { promoQuery: string | string[] | undefined }) {
    if (typeof ctx.promoQuery !== 'string') return

    const partnerSlug = getCobrandedPartnerSlug(ctx.promoQuery)

    const isLoggedIn = await isUserLoggedIn()
    const tips = isLoggedIn ? await getUserTips().then(unwrapResponse.body) : undefined

    const reasonToSkipSaving = tips?.partner != null ? 'already-bound-to-a-partner' : null

    if (reasonToSkipSaving == null) {
      setSessionValue(
        COBRANDED_USER_SESSION_KEY,
        JSON.stringify(ident<CobrandingSessionValue>({ promoCode: ctx.promoQuery, partnerSlug }))
      )
    }
  }

  useSessionValue() {
    const { data } = useSessionValue(COBRANDED_USER_SESSION_KEY)
    const parsed = useMemo(
      () => (data ? tryParseJson<CobrandingSessionValue>(data) : undefined),
      [data]
    )

    return parsed
  }

  onLoggedOut() {
    clearSessionValue(COBRANDED_USER_SESSION_KEY)
  }

  async onUserSignedUp() {
    const sessionValue = tryParseJson<CobrandingSessionValue>(
      getSessionValue(COBRANDED_USER_SESSION_KEY)
    )
    if (sessionValue?.partnerSlug && isCobrandedPartnerSlug(sessionValue.partnerSlug)) {
      await patchUserTips({ cobranded_partner: sessionValue.partnerSlug }).then(unwrapResponse)
    }
  }

  useCobrandedUser() {
    const { data: tips } = useUserTips()
    const partner = tips?.json?.partner

    if (partner == null) return undefined

    return {
      ctx: { partnerResponse: partner },
      cobrandedPartner: partner.cobranded_partner,
      partnerDisplayName: partner.title,
      partnerImageUrl: partner.partner_photo?.url,
    }
  }
}

export const cobrandingLogic = new CobrandingLogic()

export function getCobrandedPartnerSlug(code: string) {
  for (const partner of valuesOf(cobrandingPartnerSpec)) {
    const promo = partner.promotions.find((promo) => promo.code === code)

    if (promo) return partner.slug
  }
  return undefined
}

export function isCobrandedPartnerSlug(
  type: string
): type is NonNullable<ApiType['SetUserTipCommand']['cobranded_partner']> {
  return type in cobrandingPartnerSpec
}
