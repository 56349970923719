import { PIN_LENGTH } from 'api/goodtrust/user'
import { parseEnteredName } from 'components/personInput/logic/parseEnteredName'
import { PersonInputValue } from 'components/personInput/types'
import { IPinInputValue } from 'components/pinInput/PinInput'
import { US_ZIP_REGEXP } from 'logic/validation/zip'
import { useTranslation } from 'next-i18next'
import { SEQUENTIAL_CHARS } from 'utils/i18n/passwordConstants'
import { EMAIL_REGEX, PHONE_REGEX } from 'utils/regex'

export const useFieldValidation = () => {
  const { t } = useTranslation()

  return {
    required: (isRequired = true) => ({
      required: isRequired ? t('common.form.required_field') : false,
    }),
    requiredPerson: (isRequired = true) => ({
      validate: (value?: PersonInputValue) => {
        if (!isRequired) return

        if (parseEnteredName(value?.entered_name) != null) return

        const uuid = value?.trusted_contact_uuid ?? value?.person_uuid

        if (!uuid) return t('common.form.required_field')

        return
      },
    }),
    requiredTrustedContact: () => ({ required: t('common.form.required_trusted_contact') }),
    atLeast: (minThreshold: number) => ({
      min: {
        message: t('common.form.at_least', { threshold: minThreshold }),
        value: minThreshold,
      },
    }),
    requiredAgreeOption: () => ({
      required: t('common.form.required_agree_option'),
    }),
    requiredPetSpecies: () => ({
      required: t('common.form.required_pet_species'),
    }),
    requiredChooseCard: () => ({
      required: t('common.form.required_choose_card'),
    }),
    passwordAtLeastNChars: (n: number) => ({
      minLength: {
        value: n,
        message: t('common.form.password_at_least_n_chars', { n }),
      },
    }),
    atLeastNChars: (n: number) => ({
      minLength: {
        value: n,
        message: t('common.form.at_least_n_chars', { n }),
      },
    }),
    passwordAtMostNChars: (n: number) => ({
      maxLength: {
        value: n,
        message: t('common.form.password_at_least_n_chars', { n }),
      },
    }),
    atLeastOneUppercase: () => ({
      pattern: {
        value: new RegExp('[A-Z]'),
        message: t('common.form.at_least_one_uppercase'),
      },
    }),
    atLeastOneLowercase: () => ({
      pattern: {
        value: new RegExp('[a-z]'),
        message: t('common.form.at_least_one_lowercase'),
      },
    }),
    atLeastOneNumber: () => ({
      pattern: {
        value: new RegExp('[0-9]'),
        message: t('common.form.at_least_one_number'),
      },
    }),
    atLeastOneSpecialChar: () => ({
      pattern: {
        value: new RegExp('[!@#$%^&*]'),
        message: t('common.form.at_least_one_special_character'),
      },
    }),
    email: () => ({
      pattern: {
        value: new RegExp(EMAIL_REGEX),
        message: t('common.form.invalid_email'),
      },
    }),
    phone: () => ({
      pattern: {
        value: new RegExp(PHONE_REGEX),
        message: t('common.form.invalid_phone'),
      },
    }),
    invalidZipIfLessThanLength: (len: number) => ({
      minLength: {
        value: len,
        message: t('common.form.invalid_zip'),
      },
    }),
    patternZip: (thePattern?: RegExp) => ({
      pattern: thePattern && {
        value: thePattern,
        message: t('common.form.invalid_zip'),
      },
    }),
    maxCharExceeded: (len: number) => ({
      maxLength: {
        value: len,
        message: t('common.form.max_char_exceeded'),
      },
    }),
    usZipRegex: () => ({
      pattern: {
        value: US_ZIP_REGEXP,
        message: t('common.form.us_zip_code'),
      },
    }),
    incompleteCode: () => ({
      validate(value?: IPinInputValue) {
        const code = value?.filter(Boolean).join('') ?? ''
        return code.length === PIN_LENGTH || t('common.form.incomplete_code')
      },
    }),
    atLeastOneRequired: () => ({
      validate: (value?: string[] | null) => {
        if (value && value.length && value[0]) {
          return true
        } else {
          return t('common.form.at_least_one_required')
        }
      },
    }),
    passwordValidation: () => ({
      validate: {
        required_field: (value: string) => !!value,
        at_least_8_chars: (value: string) => value.length >= 8,
        at_most_64_chars: (value: string) => value.length <= 64,
        at_least_one_lowercase: (value: string) => new RegExp('[a-z]').test(value),
        at_least_one_uppercase: (value: string) => new RegExp('[A-Z]').test(value),
        at_least_one_number: (value: string) => new RegExp('[0-9]').test(value),
        password_repeated_chars: (value: string) => !new RegExp(/(.)\1{3,}/g).test(value),
        sequential_chars: (value: string) => {
          for (const seq of SEQUENTIAL_CHARS) {
            if (value.toLowerCase().includes(seq)) {
              return false
            }
          }
          return true
        },
      },
    }),
  }
}
