import styled from '@emotion/styled'
import { Text } from 'components/Typography'

export const SOption = styled.li`
  display: flex;
  text-align: left;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.2s;

  :hover,
  :focus {
    background-color: var(--c-blue100);
  }
`

export const SSelectCountryPicker = styled.button`
  display: flex;
  z-index: 1;
  cursor: pointer !important;
  height: 100%;
  width: 5rem !important;
  padding: 0.3rem 0.5rem !important;
  margin: 0;
  outline: none;
  background-color: transparent;
  justify-content: space-evenly;
  align-items: center;
`

export const SIconWrapper = styled.div`
  width: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
`

export const SFlagImg = styled.img`
  width: 2rem;
  object-fit: contain;
  margin-right: 1rem;
`

export const SLabel = styled(Text)`
  margin-right: 0.5rem;
  color: var(--c-gray100);
`
