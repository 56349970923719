import { PoaSectionKey } from 'components/directive/poa/form/types'
import { isValidationErrorRelatedToAboutYou } from 'components/directive/utils/isValidationErrorRelatedToAboutYou'
import { DirectiveSectionSpecification } from 'components/directive/utils/types'

export const POA_DIRECTIVE_PROGRESS: DirectiveSectionSpecification<PoaSectionKey>[] = [
  {
    name: 'directive.poa.about_you.nav_title',
    mobile: 'directive.poa.about_you.nav_title_short',
    value: 'ABOUT_YOU',
    url: 'about-you',
    subsections: [
      {
        sectionKey: 'ABOUT_YOU',
        isValidationErrorRelated: isValidationErrorRelatedToAboutYou,
      },
      {
        sectionKey: 'ABOUT_MORE',
      },
    ],
  },
  {
    name: 'directive.poa.form.designated_agent.nav_title',
    mobile: 'directive.poa.form.designated_agent.nav_title_short',
    value: 'DESIGNATED_AGENT',
    url: 'designated-agent',
  },
  {
    name: 'directive.poa.form.general_powers.nav_title',
    mobile: 'directive.poa.form.general_powers.nav_title_short',
    value: 'GENERAL_POWERS',
    url: 'general-powers',
  },
  {
    name: 'directive.poa.form.specific_authority_instructions.nav_title',
    mobile: 'directive.poa.form.specific_authority_instructions.nav_title_short',
    value: 'SPECIFIC_AUTHORITY_INSTRUCTIONS',
    url: 'authority-instructions',
  },
  {
    name: 'directive.poa.review_nav_title',
    value: 'REVIEW',
    url: 'review',
  },
]
