import { Fieldset } from 'components/fieldset/Fieldset'
import { FormFieldset } from 'components/FormButton'
import { UseFormReturn } from 'react-hook-form'
import { matchMap } from 'utils/general'
import { useTranslation } from 'next-i18next'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type IFormType = Record<string, any>

export const ZipElement = (props: {
  addressZipPattern?: RegExp
  zipElementPosition: 'contact' | 'payment'
  form: UseFormReturn<IFormType>
}) => {
  const { t } = useTranslation('pricing_plan')
  const validation = useFieldValidation()

  return (
    <FormFieldset form={props.form} name="address_zip">
      <Fieldset
        css={{
          gridColumn: matchMap(props.zipElementPosition, {
            contact: undefined,
            payment: '1 / 3',
          }),
        }}
      >
        <input
          {...props.form.register('address_zip', {
            ...validation.required(),
            ...validation.invalidZipIfLessThanLength(3),
            ...(props.addressZipPattern
              ? validation.patternZip(props.addressZipPattern)
              : { required: false }),
          })}
          defaultValue=""
          type="text"
          placeholder="&nbsp;"
        />
        <label>{t('pricing_plan.checkout_page.form.zip_code')}</label>
      </Fieldset>
    </FormFieldset>
  )
}
