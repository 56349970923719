import { bindEveryMethod } from 'utils/class'

export class EventTopic<T> {
  handlers: { handler: (message: T) => void; ttl: number }[]
  unsubscribedHandlers = new Set()
  constructor() {
    this.handlers = []
    bindEveryMethod(this)
  }
  emit(message: T) {
    this.handlers = this.handlers.filter((handler) => {
      handler.handler(message)
      handler.ttl -= 1
      return handler.ttl > 0
    })
  }
  on(handler: (message: T) => void) {
    const handlerObject = { handler, ttl: Infinity }
    this.handlers.push(handlerObject)
    return () => {
      this.handlers = this.handlers.filter((handler) => {
        return handler !== handlerObject
      })
    }
  }
  async once() {
    return new Promise<T>((resolve) => {
      this.handlers.push({
        handler: resolve,
        ttl: 1,
      })
    })
  }

  async onceMatch(select: (t: T) => boolean): Promise<T> {
    return new Promise<T>((resolve) => {
      let hasSelected = false
      const unsubscribe = this.on((value) => {
        if (hasSelected) return
        if (select(value)) {
          hasSelected = true
          unsubscribe()
          resolve(value)
        }
      })
    })
  }
}
