import { css } from '@emotion/react'
import { postContact } from 'api/goodtrust/contact'
import { removePersonFromDirective } from 'api/goodtrust/user/directive'
import { patchUserPerson, postUserPerson } from 'api/goodtrust/user/person'
import { getRelationship } from 'components/directive/utils/directiveUtils'
import { Icon } from 'components/icon'
import { ModalConfig } from 'components/modal/Modal'
import { PersonForm, PersonFormFields, PersonFormProps } from 'components/personForm/PersonForm'
import { PersonInputValue } from 'components/personInput/types'
import { BlockSpinner } from 'components/spinner/Spinner'
import { handleAndToastError, toastSuccess } from 'components/Toast'
import { Text, Title } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { MessageException } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { isIos } from 'utils/general'
import { useTopScrollWithRestoration } from 'utils/hooks'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { pxToRem } from 'utils/styled'
import { GenericFunction } from 'utils/types'
import { SModalTitleRow, SPersonModal } from './PersonAddEditModal.styled'

interface PersonModalProps {
  onChange: (person: PersonInputValue) => void
  defaultValues?: Partial<PersonFormFields> | null
  name?: string
  close: GenericFunction
  autoFocusField?: PersonFormProps['autoFocusField']
  onSubmitSuccessful?: () => void
}

export const personAddEditModalConfig: ModalConfig = {
  modalCss: css`
    max-width: ${pxToRem(700)}rem;
  `,
  disableFocusLock: true,
  mobilePlacement: 'top',
}

export const PersonAddEditModal = (props: PersonModalProps) => {
  const { t, isLoading } = useDynamicTranslations('directive')
  const { t: common_t } = useTranslation()
  const willUuid = useRouter().query.id as string
  useTopScrollWithRestoration(isIos())

  const onSubmit = async (data: PersonFormFields) => {
    const sanitizedData = {
      ...data,
      email: data.email || undefined,
      middle_name: data.middle_name || undefined,
      relationship: data.relationship ?? props.defaultValues?.relationship,
    }
    let res
    try {
      if (data.isContact) {
        if (!data.email) {
          throw new MessageException('missing_email')
        }
        await postContact({
          email: data.email,
          relationship: sanitizedData.relationship,
          type: 'STANDARD',
        }).then(unwrapResponse)
      }
      const uuid = props.defaultValues?.uuid
      if (uuid) {
        res = await patchUserPerson(uuid, sanitizedData).then(unwrapResponse)
      } else {
        res = await postUserPerson(sanitizedData).then(unwrapResponse)
      }
      props.onSubmitSuccessful?.()
      await props.close()
      await props.onChange({ person_uuid: res?.json?.uuid })
    } catch (err) {
      handleAndToastError(err)
    }
  }

  const onDelete = async () => {
    try {
      if (!props.defaultValues?.uuid) {
        throw new MessageException('unexpected_error')
      }

      const res = await removePersonFromDirective(undefined, {
        uuid: willUuid,
        personUuid: props.defaultValues.uuid,
      })
      if (!res.ok) throw Error(res?.error?.toString?.())
      toastSuccess('removed_person')
      await props.onChange({})
      await props.close()
    } catch (err) {
      handleAndToastError(err)
    }
  }
  if (isLoading) return <BlockSpinner />
  return (
    <SPersonModal>
      <SModalTitleRow>
        {props.defaultValues?.uuid ? <Icon name="DocumentEdit" /> : <Icon name="PersonAdd" />}
        <Title variant="h7" as="h5" css={{ paddingRight: '1.5rem' }}>
          {props.defaultValues?.uuid ? (
            <Trans t={t} i18nKey="directive.form_input.person_modal.title.edit" />
          ) : (
            <Trans
              t={t}
              i18nKey="directive.form_input.person_modal.title.add"
              values={{
                who: props.defaultValues?.relationship
                  ? t('directive.form_input.person_modal.title.add_relationship', {
                      relationship: getRelationship(common_t, props.defaultValues.relationship),
                    })
                  : t('directive.form_input.person_modal.title.add_general'),
              }}
            />
          )}
        </Title>
      </SModalTitleRow>
      {!props.defaultValues?.uuid && (
        <Text>
          <Trans t={t} i18nKey="directive.form_input.person_modal.note" />
        </Text>
      )}
      <PersonForm {...props} onCancel={props.close} onSubmit={onSubmit} onDelete={onDelete} />
    </SPersonModal>
  )
}
