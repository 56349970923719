import { directive_paywall_hit } from 'utils/analytics/events/directive_paywall_hit'
import { downgrade_open } from 'utils/analytics/events/downgrade_open'
import { gl_confirmation_modal } from 'utils/analytics/events/gl_confirmation_modal'
import { gl_modal_open } from 'utils/analytics/events/gl_modal_open'
import { gl_plans_open } from 'utils/analytics/events/gl_plans_open'
import { gl_plan_selected } from 'utils/analytics/events/gl_plan_selected'
import { gl_signup_open } from 'utils/analytics/events/gl_signup_open'
import { plan_selected } from 'utils/analytics/events/plan_selected'
import {
  premium_activated_paid,
  premium_activated_promo,
} from 'utils/analytics/events/premium_activated'
import { silent_gl_my_account_change_plan_click } from 'utils/analytics/events/silent_gl_my_account_change_plan_click'
import { user_registered } from 'utils/analytics/events/user_registered'

export const events = {
  plan_selected,
  premium_activated_paid,
  premium_activated_promo,
  downgrade_open,
  directive_paywall_hit,
  gl_modal_open,
  gl_signup_open,
  gl_plans_open,
  gl_plan_selected,
  gl_confirmation_modal,
  silent_gl_my_account_change_plan_click,
  user_registered,
}
