import { createContext, FC, useContext, useEffect, useMemo } from 'react'
import { ContentfulVideoProps, ContentfulVideos } from 'utils/contentful/types'

const ContentfulVideosContext = createContext<ContentfulVideoProps>({} as ContentfulVideoProps)
export const ContentfulVideosContextProvider: FC<
  React.PropsWithChildren<{ pageProps: ContentfulVideoProps }>
> = ({ children, pageProps }) => {
  const _contentfulVideos = useMemo(
    () => pageProps._contentfulVideos,
    [pageProps._contentfulVideos]
  )
  return (
    <ContentfulVideosContext.Provider value={{ _contentfulVideos }}>
      {children}
    </ContentfulVideosContext.Provider>
  )
}

export function useContentfulVideos(): ContentfulVideos {
  const context = useContext(ContentfulVideosContext)

  useEffect(() => {
    if (typeof context._contentfulVideos === 'undefined') {
      throw new Error(
        `useContentfulVideos is used on a page which not passes getPageVideos on it's server-side props`
      )
    }
  }, [context._contentfulVideos])

  return context._contentfulVideos || {}
}
