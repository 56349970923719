import { AnalyticsEvent } from 'utils/analytics/event'
import { determineGroupLifeFlow } from 'utils/analytics/history/determineGroupLifeFlow'

/**
 * This event is fired when the user gets to the signup page during the group life insurance flow
 * including both outside and inside flow
 * (as far as I know, even logged in users have to confirm their personal info and they do so on a prefilled sign up page)
 */
export const gl_signup_open = new AnalyticsEvent<void>({
  async onFire(ctx) {
    ctx.fireEvent('gl_signup_open', { flow: determineGroupLifeFlow(ctx) })
  },
})
