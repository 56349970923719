import { z } from 'zod'
import { apiAuthFetcher, apiFetcher } from 'api/goodtrust/api'
import { intervalToDuration } from 'date-fns'
import immer, { Draft } from 'immer'
import debounce from 'lodash.debounce'
import Router, { useRouter } from 'next/router'
import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { confirmMessage } from 'utils/confirm'
import { smsCodeValidityPeriodMs } from 'utils/constants'
import { RouteAbortException, ShouldNeverHappenError } from 'utils/error'
import { isIos, noop, resolvablePromise, safeWindow } from 'utils/general'
import {
  largeThreshold,
  mobileThreshold,
  smallThreshold,
  xLargeThreshold,
  xxxLargeThreshold,
} from 'utils/styled'
import { EventTopic } from 'utils/topic'
import { NonNullableTuple } from 'utils/types'

export function useLatestValueRef<T>(value: T) {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

type UseRunOnChangeCallback<T> = (prev: T, next: T) => void

/**
 * Similar to useEffect, but the callback is triggered only if a single value changes.
 * This can be used to access variables from the callback's scope without the need to have them trigger the callback should they change.
 *
 * Another feature is that the callback remembers the last value and passes it along with the current value in the callback arguments.
 */
export function useRunOnChange<T>(
  value: T,
  callback: UseRunOnChangeCallback<T>,
  opts?: { noRunOnMount?: boolean }
) {
  const ref = useRef<{ hasMounted: false } | { hasMounted: true; lastValue: T }>({
    hasMounted: false,
  })

  useEffect(() => {
    const isMount = !ref.current.hasMounted
    if (isMount) {
      ref.current = {
        hasMounted: true,
        lastValue: value,
      }
    }
    if (!ref.current.hasMounted) {
      // Has mounted should have been set in all code paths.
      throw new ShouldNeverHappenError()
    }
    const hasChanged = ref.current.lastValue !== value
    const runningCallbackOnMount = isMount && !opts?.noRunOnMount

    const shouldRun = hasChanged || runningCallbackOnMount

    if (!shouldRun) return

    callback(ref.current.lastValue, value)
    ref.current.lastValue = value
  }, [value, callback, opts])
}

export function useRunOnlyOnChange<T>(value: T, callback: UseRunOnChangeCallback<T>) {
  return useRunOnChange(value, callback, { noRunOnMount: true })
}

/**
 * Works pretty much like useEffect, but there are a few benefits over useEffect:
 * - you get access to the previous and next dependencies tuple
 * - you can access functions or values without having them trigger the effect when they change
 * - you can choose to not run the effect on mount
 */
export function useRunOnTupleChange<T extends readonly [...unknown[]]>(
  value: T,
  callback: UseRunOnChangeCallback<T>,
  opts?: { noRunOnMount?: boolean }
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memo = useMemo(() => value, [...value])
  useRunOnChange(memo, callback, opts)
}

export function useRunOnlyOnTupleChange<T extends readonly [...unknown[]]>(
  value: T,
  callback: UseRunOnChangeCallback<T>
) {
  useRunOnTupleChange(value, callback, { noRunOnMount: true })
}

export function useRunOnceResolved<T>(
  getPromise: () => Promise<T>,
  callback: (value: T) => void | Promise<void>
) {
  const latestCallback = useLatestCallback(callback)
  useRunOnceReady(true, async () => {
    await latestCallback(await getPromise())
  })
}

export function useRunOnceReady(
  ready: boolean,
  callback: () => void | (() => void) | Promise<void>
) {
  const done = useRef(false)
  const latestCallback = useLatestCallback(callback)

  ready = done.current || ready

  useEffect(() => {
    if (!done.current && ready) {
      done.current = true
      const destructorOrPromise = latestCallback?.()
      const destructor =
        destructorOrPromise && 'then' in destructorOrPromise ? undefined : destructorOrPromise
      return destructor
    }
  }, [ready, latestCallback])
}

export function useRunOnceAllValuesAreDefined<TTuple extends readonly [...unknown[]]>(
  tuple: TTuple,
  callback: (tuple: NonNullableTuple<TTuple>) => void
) {
  useRunOnceReady(
    tuple.every((val) => val != null),
    () => callback(tuple as NonNullableTuple<TTuple>)
  )
}

export function useResolveOnceDefined<T>(value: T | undefined | null): Promise<T> {
  const [{ resolve, promise }] = useState(() => resolvablePromise<T>())

  useRunOnceAllValuesAreDefined([value] as const, ([defined]) => resolve(defined))

  return promise
}

export function useInterval(callback: () => void, delay: number | null | false) {
  const savedCallback = useRef(noop)

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    if (delay === null || delay === false) return undefined
    const tick = () => savedCallback.current()
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

export function useTimeout(callback: () => void, delay: number | null | false) {
  const ref = useRef({ callback, hasTriggered: false })

  useEffect(() => {
    ref.current.callback = callback
  })

  useEffect(() => {
    if (delay === null || delay === false || ref.current.hasTriggered) return undefined

    const id = setTimeout(() => {
      ref.current.callback?.()
      ref.current.hasTriggered = true
    }, delay)

    return () => clearTimeout(id)
  }, [delay])
}

export function useDelayedState<S>(delay: number, init: () => S): S | undefined {
  const [st, setState] = useState<S | undefined>(undefined)

  useTimeout(() => {
    setState(init)
  }, delay)

  return st
}

export const useIsMounted = () => {
  const mounted = useRef<boolean>()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return mounted
}

export const useMountedAt = () => {
  const [mountedAt, setMountedAt] = useState<number | undefined>(undefined)

  useEffect(() => {
    setMountedAt(Date.now())
  }, [])

  return mountedAt
}

/**
 * Returns true if the component has mounted. False otherwise.
 * Can be used to render one thing serverside/before hydration clientside and
 * another thing after hydration.
 */
export const useHasMounted = () => {
  return useMountedAt() != null
}
/**
 * Combines the useLatestValueRef and useCallback to provide a callback that doesn't change reference, yet uses the latest closure.
 */
export const useLatestCallback = <TCallback extends (...args: any[]) => Promise<unknown> | unknown>(
  callback: TCallback
): TCallback => {
  const latest = useLatestValueRef(callback)
  const latestCallback = useCallback(
    (...args: Parameters<TCallback>) => latest.current(...args),
    [latest]
  )
  return latestCallback as TCallback
}

export const useLoadingCallback = <
  TCallback extends (...args: any[]) => Promise<unknown> | unknown
>(
  callback: TCallback
): [TCallback, boolean, boolean] => {
  const [loading, setLoading] = useState<boolean>(false)
  const [wasLoading, setWasLoading] = useState<boolean>(false)
  const isMounted = useIsMounted()

  const loadingCallback = useCallback(
    async (...args: Parameters<TCallback>) => {
      setLoading(true)
      setWasLoading(true)
      try {
        return await callback(...args)
      } finally {
        if (isMounted.current) setLoading(false)
      }
    },
    [callback, isMounted]
  ) as TCallback

  return [loadingCallback, loading, wasLoading]
}

export const useSaveSubmit = () => {
  const formRef = useRef<HTMLFormElement | null>(null)
  const [isSavingCounter, setSavingCounter] = useState(0)

  const isSaving = isSavingCounter > 0

  useEffect(() => {
    if (isSavingCounter && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
    }
  }, [isSavingCounter, formRef])

  const sendSaveSubmit = () => void setSavingCounter((c) => Math.max(1, c + 1))
  const sendSubmit = () => void setSavingCounter((c) => Math.min(-1, c - 1))

  function wrapSubmit<T>(callback: (data: T, isSaving: boolean) => void) {
    return (data: T) => callback(data, isSaving)
  }

  return { sendSaveSubmit, sendSubmit, isSaving, formRef, wrapSubmit }
}

export function useWindowSize(initial?: {
  isSmall?: boolean
  isDesktop?: boolean
  isMobile?: boolean
}) {
  const [windowSize, setWindowSize] = useState<{
    width: undefined | number
    height: undefined | number
    isSmall: undefined | boolean
    isDesktop: undefined | boolean
    isMobile: undefined | boolean
    isLarge: undefined | boolean
    isXLarge: undefined | boolean
    isLandscape: undefined | boolean
    isTablet: undefined | boolean
    isXXXLarge: undefined | boolean
  }>({
    width: undefined,
    height: undefined,
    isSmall: initial?.isSmall ?? undefined,
    isDesktop: initial?.isDesktop ?? undefined,
    isMobile: initial?.isMobile ?? undefined,
    isLarge: undefined,
    isXLarge: undefined,
    isLandscape: undefined,
    isTablet: undefined,
    isXXXLarge: undefined,
  })

  useEffect(() => {
    function handleResize() {
      const isSmall = window.innerWidth > smallThreshold
      const isLarge = window.innerWidth > largeThreshold
      const isDesktop = window.innerWidth > mobileThreshold
      const isMobile = !isDesktop

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isSmall,
        isDesktop,
        isMobile,
        isLarge,
        isXLarge: window.innerWidth > xLargeThreshold,
        isLandscape: window.innerWidth > window.innerHeight,
        isTablet: !isLarge && isDesktop,
        isXXXLarge: window.innerWidth > xxxLargeThreshold,
      })
    }
    const debouncedResize = debounce(handleResize, 100)
    window.addEventListener('resize', debouncedResize)
    handleResize()
    return () => window.removeEventListener('resize', debouncedResize)
  }, [])

  return windowSize
}

export function useOuterClick(onOuterClick?: (e: MouseEvent) => void) {
  const callbackRef = useRef<(e: MouseEvent) => void>()
  const innerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    callbackRef.current = onOuterClick
  })
  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', handleClick)
    }, 0)
    return () => document.removeEventListener('click', handleClick)
    function handleClick(e: MouseEvent) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target as Node))
        callbackRef.current(e)
    }
  }, [])

  return innerRef
}

export const useIsUserIdle = (delay: number) => {
  const [isIdle, setIsIdle] = useState(false)
  const [lastTime, setLastTime] = useState<number>()
  const timer = useRef<NodeJS.Timeout>()

  const resetTimer = useCallback(() => {
    const time = Date.now()
    if (!lastTime || time - lastTime >= 500) {
      if (timer.current) clearTimeout(timer.current)
      setIsIdle(false)
      setLastTime(time)
      timer.current = setTimeout(() => setIsIdle(true), delay)
    }
  }, [delay, lastTime])

  const events = useMemo(
    () => ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'],
    []
  )

  useEffect(() => {
    events.forEach((event) => window.addEventListener(event, resetTimer))
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer))
    }
  }, [events, resetTimer])

  return isIdle
}

export const useWarningOnExit = (shouldWarn: boolean, warningText?: string) => {
  const message = warningText || 'Are you sure that you want to leave?'
  const warnRef = useRef(shouldWarn)
  warnRef.current = shouldWarn

  useEffect(() => {
    let isWarned = false

    const routeChangeStart = (url: string) => {
      if (Router.asPath !== url && warnRef.current && !isWarned) {
        isWarned = true
        if (confirmMessage(message, { assumeConfirmedIfReturnsImmediately: true })) {
          Router.push(url)
        } else {
          isWarned = false
          Router.events.emit('routeChangeError')
          Router.replace(Router, Router.asPath, { shallow: true })
          throw new RouteAbortException()
        }
      }
    }

    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (warnRef.current && !isWarned) {
        const event = e || window.event
        event.returnValue = message
        return message
      }
      return null
    }

    Router.events.on('routeChangeStart', routeChangeStart)
    window.addEventListener('beforeunload', beforeUnload)
    Router.beforePopState(({ url }) => {
      if (Router.asPath !== url && warnRef.current && !isWarned) {
        isWarned = true
        if (confirmMessage(message, { assumeConfirmedIfReturnsImmediately: true })) {
          return true
        } else {
          isWarned = false
          window.history.pushState(null, '', url)
          Router.replace(Router, Router.asPath, { shallow: true })
          return false
        }
      }
      return true
    })

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
      window.removeEventListener('beforeunload', beforeUnload)
      Router.beforePopState(() => {
        return true
      })
    }
  }, [message])
}

export const useAuthAwareFetcher = () => {
  const { isLogged } = useAuth()
  return useMemo(
    () => (isLogged === null ? null : isLogged ? apiAuthFetcher : apiFetcher),
    [isLogged]
  )
}

export const useFormAutosave = ({
  form,
  onSave,
  cooldown,
}: {
  form: UseFormReturn
  onSave: (data: never) => void
  cooldown: number
}) => {
  const timer = useRef<NodeJS.Timeout>()
  const data = form.watch() as never

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (form.formState.isDirty) {
        onSave(data)
      }
    }, cooldown)
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cooldown, data])
}

export function useIsHolidayOffer() {
  // we currently don't use holiday offer and the subscription structure has changed quite a lot
  // so let's remove the holiday offer logic for now and write it again once we decide to use a holiday offer again
  // git blame might help with the implementation in the future
  return false
}

export const useSmsCodeLogic = () => {
  const [expiresAt, setExpiresAt] = React.useState<string | undefined>()

  return {
    onSent: React.useCallback(() => {
      const expiresAt = new Date(Date.now() + smsCodeValidityPeriodMs).toISOString()

      setExpiresAt(expiresAt)
    }, []),
    expiresAt,
  }
}

export const useNow = () => {
  const [now, setNow] = React.useState(Date.now())

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now())
    }, 500)
    return () => {
      clearTimeout(interval)
    }
  }, [])

  return now
}

export const useExpirationCounter = (args: { expiresAt: string | undefined }) => {
  const now = useNow()

  if (args.expiresAt == null) {
    return {
      hasExpired: false,
    }
  }
  const expiresAt = new Date(args.expiresAt).getTime()
  const hasExpired = expiresAt < now

  const duration = intervalToDuration({
    start: expiresAt,
    end: now,
  })

  const formattedDuration = [
    duration.minutes,
    (duration.seconds ?? 0).toString().padStart(2, '0'),
  ].join(':')

  return {
    hasExpired,
    formattedDuration,
  }
}

export const useActualVh = () => {
  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }

    window.addEventListener('resize', setVh)

    setVh()

    return () => window.removeEventListener('resize', setVh)
  }, [])
}

/**
 * Uses useEffect to attach a handler for eventName to eventEmitter.
 * @param eventEmitter An event emitter like window or document
 * @param eventName String name of the observed event
 * @param handler Handler that receives the event, the latest value of the handler is used
 */
export function useEvent<
  TEventName extends string,
  THandler extends (event: Event) => void,
  TEventEmitter extends {
    addEventListener: (eventName: TEventName, handler: THandler) => void
    removeEventListener: (eventName: TEventName, handler: THandler) => void
  }
>(eventEmitter: TEventEmitter | null | undefined, eventName: TEventName, handler: THandler) {
  const handlerRef = useLatestValueRef(handler)
  useEffect(() => {
    const handler = ((...args) => {
      handlerRef.current(...args)
    }) as THandler
    if (!eventEmitter) return
    eventEmitter.addEventListener(eventName, handler)
    return () => {
      eventEmitter.removeEventListener(eventName, handler)
    }
  }, [eventName, eventEmitter, handlerRef])
}

/**
 * Observes scrolling and if the user scrolls past a section, updates the hash in URL to the section id.
 * @param sections array of objects with ids, representing the sections, the ids should be referring to html elements related to the sections
 * @param scrollElement optionally, you can provide an element that is scrolled instad of the window
 */
export const useSectionsInHash = <TSection extends { id: string }>(
  sections: TSection[],
  scrollElement?: HTMLElement | null
) => {
  const idsFromBottom = useMemo(() => {
    return sections
      .slice()
      .reverse()
      .map((step) => step.id)
  }, [sections])

  useEvent(scrollElement ?? safeWindow, 'scroll', () => {
    // find first section from the bottom that is almost above top of viewport
    const stepId = idsFromBottom.find((id) => {
      const el = document.getElementById(id)
      const rect = el?.getBoundingClientRect()
      if (!rect) return false
      return rect.top - 100 < 0
    })
    if (!safeWindow) return

    const { history, location } = safeWindow

    const targetHash = stepId ? `#${stepId}` : ''
    const isHashTheSame = safeWindow.location.hash === targetHash

    // we only want to change the hash if it's different
    if (isHashTheSame) return

    const targetPushUrl = `${location.pathname}${location.search}${targetHash}`

    // we cannot use next router, because it scrolls to the section automatically
    // we cannot use window.location.hash = value for the same reason
    // pushState does not automatically scroll to the section
    const state = history.state as { url?: string; as?: string }

    // updated state is needed
    const updatedState = {
      ...state,
      as: targetPushUrl,
    }

    history.pushState(updatedState, '', targetPushUrl)
  })
}

/**
 * Useful for modals with autofocus inputs.
 * Solves the following problem:
 * - device is iOS
 * - user has an input focused - for example PersonInput
 * - user triggers an action that opens a modal with autofocus field
 * - keyboard from the original input doesn't have enough time to close
 * - the user's viewport is scrolled way below the modal
 *   and the focused input is not visible to the user
 *
 * This can be solved by scrolling to top.
 * But there is an expectation on modal close that the user will be scrolled
 * where they were before opening the modal.
 * Thus, we restore the original scroll on unmount.
 */
export function useTopScrollWithRestoration(isEnabled = true) {
  useEffect(() => {
    if (!isEnabled) return
    const scrollY = window.scrollY
    window.scrollTo({
      top: 0,
    })
    return () => {
      window.scrollTo({
        top: scrollY,
      })
    }
  }, [isEnabled])
}

export function useDisableUserInteraction(disableOnMount = true) {
  const [isDisabled, setDisabled] = useState(disableOnMount)

  useEffect(() => {
    if (!isDisabled) return

    // disable all user interaction
    document.body.style.pointerEvents = 'none'
    return () => {
      document.body.style.pointerEvents = 'all'
    }
  }, [isDisabled])

  return {
    setDisabled,
  }
}

export function useRouterQueryState<T extends Record<string, string>>() {
  const router = useRouter()
  const state = router.query as Partial<T>

  const setState = (act: SetStateAction<Partial<T>>) => {
    const stateUpdate = typeof act === 'function' ? act(state) : act
    const updatedState = { ...state }
    for (const [key, value] of Object.entries(stateUpdate)) {
      if (value == null || value === '') {
        delete updatedState[key as keyof T]
      } else {
        updatedState[key as keyof T] = value
      }
    }
    const nextParams = new URLSearchParams(updatedState as Record<string, string>)
    // shallow push prevents page to reload for tab switches
    router.replace(`?${nextParams}`, `?${nextParams}`, { shallow: true })
  }

  return [state, setState, router.isReady] as const
}

export function useIsIos() {
  const [isIosUserAgent, setIsIosUserAgent] = useState(false)
  useEffect(() => {
    setIsIosUserAgent(isIos())
  }, [])

  return isIosUserAgent
}

/**
 * An abstraction similar to an EventEmitter that allows one side to pour events in
 * and the other side to listen/wait for those events.
 * The benefit over just a callback is that this allows us to use promises and await the event.
 */
export function useEventTopic<T>() {
  return useRef<EventTopic<T>>(new EventTopic()).current
}

/**
 * A function similar to useState, but makes it easier to manage
 * more complex state without having multiple useState.
 * But without being as complex as useReducer.
 */
export function useImmerState<T>(defaultState: T | (() => T)) {
  const [state, setState] = useState(defaultState)

  const update = useCallback((act: T | ((draft: Draft<T>) => void)) => {
    if (typeof act === 'function') {
      setState((st) =>
        immer(st, (st) => {
          const actFn = act as (draft: Draft<T>) => void
          actFn(st)
        })
      )
    } else {
      setState(act)
    }
  }, [])

  return [state, update] as const
}

export function useKeyboardCombination(
  isEnabled: boolean,
  code: string,
  modifierKey: 'altKey' | 'ctrlKey' | 'metaKey',
  onTrigger: () => void
) {
  useEvent(isEnabled ? safeWindow : undefined, 'keydown', (e) => {
    if (!isEnabled) return
    if (!(e instanceof KeyboardEvent)) return

    const isTrigger = e.code === code && e[modifierKey]
    if (!isTrigger) return

    e.preventDefault()
    onTrigger()
  })
}

/** Only works if mobile text uses ${key}_mobile such as hero_title -> hero_title_mobile. */
export const useResponsiveLocalization = () => {
  const { isDesktop } = useWindowSize()
  return {
    context: isDesktop ? 'undefined' : 'mobile',
  }
}

export function useUrlParams(key: string) {
  const router = useRouter()
  const data = router.query[key]
  const safeValue = z.string().safeParse(data)
  if (!safeValue.success) return null
  return safeValue.data
}
