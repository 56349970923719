export const colors = {
  black: '#000000',
  white: '#ffffff',
  blue: {
    /** #f7fbfd */
    50: '#f7fbfd',
    /** #f0faff */
    75: '#F0FAFF',
    /** #e5f2f8 */
    100: '#e5f2f8',
    /** #99cbe4 */
    200: '#99cbe4',
    /** #66b1d7 */
    300: '#66b1d7',
    /** #3397c9 */
    400: '#3397c9',
    /** #007dbc */
    500: '#007dbc',
    /** #006496 */
    600: '#006496',
    /** ##008FD8 */
    blueberryShine: '#008FD8',
  },
  gray: {
    /** #F5F6F7 */
    50: '#F5F6F7',
    /** #00131c */
    100: '#00131c',
    /** #f9fcfe */
    200: '#f9fcfe',
    /** #f8fcfe */
    300: '#f8fcfe',
    /** #6e8088 */
    400: '#6e8088',
    /** #657795 */
    500: '#657795',
    greenishPale: '#E7E8E9',
  },
  green: {
    /** #EDFAF9 */
    100: '#EDFAF9',
    /** #00bca5 */
    200: '#00bca5',
  },
  red: {
    /** #fddfe3 */
    100: '#fddfe3',
    /** #fbc0c7 */
    200: '#fbc0c7',
    /** #f7818f */
    400: '#f7818f',
    /** #f56173 */
    500: '#f56173',
    /** #c44e5c */
    600: '#c44e5c',
  },
  orange: {
    /** #FEF7F2 */
    100: '#FEF7F2',
    /** #EC8C45 */
    200: '#EC8C45',
  },
  trust: {
    /** #0F3949 */
    primary: '#0F3949',
  },
  // note(const): TS will show the hex values on color name hover
} as const
