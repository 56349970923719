import { Stack } from 'components/Stack'
import { css } from '@emotion/react'
import { Icon } from 'components/icon'

const CARD_TYPES = ['unknown', 'visa', 'mastercard', 'amex', 'jcb'] as const

export type ICardType = (typeof CARD_TYPES)[number]
export function isValidWizardCardType(type: string): type is ICardType {
  return (CARD_TYPES as readonly string[]).indexOf(type) !== -1
}

export function WizardCardType({
  cardType,
  className,
}: {
  cardType: ICardType
  className?: string
}) {
  return (
    <Stack
      orientation="horizontal"
      space="0.5rem"
      className={className}
      css={css`
        margin-right: 1rem;
        & svg {
          transition: opacity 0.2s;
          width: 2rem;
          height: 3.5rem;
          z-index: 1;
        }
      `}
    >
      <Icon
        name="ColorVisa"
        css={css`
          opacity: ${['unknown', 'visa'].includes(cardType) ? 1 : 0.3};
        `}
      />
      <Icon
        name="ColorMasterCard"
        css={css`
          opacity: ${['unknown', 'mastercard'].includes(cardType) ? 1 : 0.3};
        `}
      />
      <Icon
        name="ColorAmericanExpress"
        css={css`
          opacity: ${['unknown', 'amex'].includes(cardType) ? 1 : 0.3};
        `}
      />
    </Stack>
  )
}
