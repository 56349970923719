import { SButtonsDiv, SConfirmModal, SHeading, SIcon, SText, STitle } from './ConfirmModal.styled'
import { ReactElement, ReactNode } from 'react'
import { useLoadingCallback } from 'utils/hooks'
import { Button } from 'components/button/Button'
import { Icon } from 'components/icon'
import { useTranslation } from 'next-i18next'

export const ConfirmModal = (props: {
  title?: string
  description?: string
  onConfirm: () => void
  close: () => void
  className?: string
  confirmText?: string
  cancelText?: string
  danger?: boolean
  icon?: ReactElement
  children?: ReactNode
}) => {
  const { t } = useTranslation()
  const {
    title = t('common.modals.confirm_modal.title'),
    confirmText = t('common.modals.confirm_modal.confirm_button'),
    cancelText = t('common.modals.confirm_modal.cancel_button'),
    icon = <Icon name="Bin" />,
    description,
    onConfirm,
    close,
    danger,
    className,
  } = props

  const [handleConfirm, isLoading] = useLoadingCallback(async () => {
    await onConfirm()
    await close()
  })

  return (
    <SConfirmModal className={className}>
      <SHeading>
        {icon && <SIcon>{icon}</SIcon>}
        <STitle>{title}</STitle>
      </SHeading>
      {description && <SText>{description}</SText>}
      {props.children}
      <SButtonsDiv>
        <Button
          type="button"
          loading={isLoading}
          size="large"
          tone={danger ? 'danger' : 'standard'}
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
        <Button type="button" size="large" variant="secondary" onClick={close}>
          {cancelText}
        </Button>
      </SButtonsDiv>
    </SConfirmModal>
  )
}
