import useSWR from 'swr'
import { FetchResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { apiAuthFetcher } from 'api/goodtrust/api'
import { getDirectiveKey } from './utils'

export function useUserPoa(uuid: string) {
  return useSWR<FetchResponse<ApiType['UserDirectivePoaResponse']>>(
    uuid ? getDirectiveKey(uuid, 'POWER_OF_ATTORNEY') : null,
    apiAuthFetcher,
    { revalidateOnFocus: false }
  )
}

export function postUserPoaFinish(uuid: string) {
  return apiAuthFetcher<ApiType['UserDirectiveResponse']>(
    `${getDirectiveKey(uuid, 'POWER_OF_ATTORNEY')}/finish`,
    {
      method: 'POST',
    }
  )
}

export function downloadUserPoa(uuid: string) {
  return apiAuthFetcher(`${getDirectiveKey(uuid, 'POWER_OF_ATTORNEY')}/generate`, {
    toBlob: true,
    noThrowOnError: true,
  })
}
