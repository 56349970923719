export const SEQUENTIAL_CHARS = [
  '0123',
  '1234',
  '2345',
  '3456',
  '4567',
  '5678',
  '6789',
  '7890',
  '8901',
  '9012',
  '0987',
  '9876',
  '8765',
  '7654',
  '6543',
  '5432',
  '4321',
  '3210',
  '2109',
  '1098',
  'abcd',
  'bcde',
  'cdef',
  'defg',
  'efgh',
  'fghi',
  'ghij',
  'hijk',
  'ijkl',
  'jklm',
  'klmn',
  'lmno',
  'mnop',
  'nopq',
  'opqr',
  'pqrs',
  'qrst',
  'rstu',
  'stuv',
  'tuvw',
  'uvwx',
  'vwxy',
  'wxyz',
  'zyxw',
  'yxwv',
  'xwvu',
  'wvut',
  'vuts',
  'utsr',
  'tsrq',
  'srqp',
  'rqpo',
  'qpon',
  'ponm',
  'onml',
  'nmlk',
  'mlkj',
  'lkji',
  'kjih',
  'jihg',
  'ihgf',
  'hgfe',
  'gfed',
  'fedc',
  'edcb',
  'dcba',
]
