import { I18nContext, TFunction } from 'next-i18next'
import React, { FC, useContext } from 'react'
import { useMockEnabled } from 'utils/devtools/useMock'
import { useLatestValueRef, useRunOnlyOnChange } from 'utils/hooks'

//@ts-ignore
let original_t: TFunction = undefined

export const I18nMock: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const ctx = useContext(I18nContext)
  const enabled = useMockEnabled('i18n')
  const enabledRef = useLatestValueRef(enabled)

  original_t = original_t ?? ctx.i18n.t

  useRunOnlyOnChange(ctx.i18n, () => {
    original_t = ctx.i18n.t
    mock()
  })
  useRunOnlyOnChange(enabled, mock)

  function mock() {
    if (!enabledRef.current) {
      ctx.i18n.t = original_t
      return
    }
    ctx.i18n.t = (...args) => {
      const res = original_t.call(
        ctx.i18n,
        //@ts-ignore
        ...args
      )
      return enabledRef.current
        ? Array.isArray(res)
          ? res.map((x) => `x_${x}_x`)
          : `x_${res}_x`
        : res
    }
  }

  return <React.Fragment key={String(enabled)}>{children}</React.Fragment>
}
