import { ApiType } from 'utils/types'
import { apiAuthFetcher, apiFetcher } from './api'
import useSWR from 'swr'
import { encodeQuery, FetchCall, FetchResponse } from 'utils/fetcher'
import { useMemo } from 'react'
import { useAuth } from 'utils/auth/hooks/useAuth'

export function getPromotions(filter?: ApiType['OfferUserSpecification']) {
  filter = {
    redeemableOnly: false,
    ...filter,
  }
  return apiAuthFetcher<ApiType['ClaimedListResponse']>(
    encodeQuery('/api/v1/promocode/claimed', {
      ...(filter ?? {}),
    })
  )
}

export function usePromotions(filter?: ApiType['OfferUserSpecification']) {
  const { isLogged } = useAuth()
  filter = {
    redeemableOnly: false,
    ...filter,
  }
  return useSWR<FetchResponse<ApiType['ClaimedListResponse']>>(
    isLogged
      ? encodeQuery('/api/v1/promocode/claimed', {
          ...(filter ?? {}),
        })
      : null,
    apiAuthFetcher
  )
}

export function useValidatedPromo(code?: string | null) {
  const { isLogged } = useAuth()
  const memoizedBody = useMemo(() => {
    return {
      method: 'POST',
      body: { code: code },
    }
  }, [code])

  return useSWR<FetchResponse<ApiType['PromoCodeValidationResponse']>>(
    !isLogged ? null : [`/api/v1/promocode/validate`, memoizedBody],
    apiAuthFetcher
  )
}

export async function postPromocodeCheck(code: string | undefined) {
  return apiFetcher<ApiType['PromoCodeResponse']>(`/api/v1/promocode/check`, {
    method: 'POST',
    body: {
      code: code,
    },
  })
}

export type PromoDiscountType = Exclude<
  NonNullable<ApiType['ValidatePromoCodeCommand']['discount_type']>,
  'ALL'
>

export async function postPromoCodeValidate(
  code: string,
  discount_type?: PromoDiscountType,
  fetcher: FetchCall = apiAuthFetcher
) {
  const body: ApiType['ValidatePromoCodeCommand'] = {
    code,
    discount_type,
  }

  return fetcher<ApiType['PromoCodeValidationResponse']>('/api/v1/promocode/validate', {
    method: 'POST',
    body,
    noThrowOnError: true,
  })
}

export function postPromocodeClaim(body: ApiType['ClaimPromoCodeCommand']) {
  return apiFetcher<ApiType['PromoCodeResponse']>(`/api/v1/promocode/claim`, {
    method: 'POST',
    body,
  })
}
