import { FuneralSectionKey } from 'components/directive/funeral/types'
import { isValidationErrorRelatedToAboutYou } from 'components/directive/utils/isValidationErrorRelatedToAboutYou'
import { DirectiveSectionSpecification } from 'components/directive/utils/types'

export const FUNERAL_DIRECTIVE_PROGRESS: DirectiveSectionSpecification<FuneralSectionKey>[] = [
  {
    name: 'directive.funeral.about_you.nav_title',
    mobile: 'directive.funeral.about_you.nav_title_short',
    value: 'ABOUT_YOU',
    url: 'about-you',
    subsections: [
      {
        sectionKey: 'ABOUT_YOU',
        isValidationErrorRelated: isValidationErrorRelatedToAboutYou,
      },
      {
        sectionKey: 'ABOUT_MORE',
      },
    ],
  },
  {
    name: 'directive.funeral.form.appointee.nav_title',
    mobile: 'directive.funeral.form.appointee.nav_title_short',
    value: 'APPOINTEE',
    url: 'appointee',
  },
  {
    name: 'directive.funeral.form.death_announcement.nav_title',
    mobile: 'directive.funeral.form.death_announcement.nav_title_short',
    value: 'DEATH_ANNOUNCEMENT',
    url: 'death-announcement',
  },
  {
    name: 'directive.funeral.form.organ_donation.nav_title',
    mobile: 'directive.funeral.form.organ_donation.nav_title_short',
    value: 'ORGAN_DONATION',
    url: 'organ-donation',
  },
  {
    name: 'directive.funeral.form.body_disposal.nav_title',
    mobile: 'directive.funeral.form.body_disposal.nav_title_short',
    value: 'BODY_DISPOSAL',
    url: 'body-disposal',
  },
  {
    name: 'directive.funeral.form.my_funeral.nav_title',
    mobile: 'directive.funeral.form.my_funeral.nav_title_short',
    value: 'MY_FUNERAL',
    url: 'my-funeral',
  },
  {
    name: 'directive.funeral.form.funeral_financing.nav_title',
    mobile: 'directive.funeral.form.funeral_financing.nav_title_short',
    value: 'FUNERAL_FINANCING',
    url: 'funeral-financing',
  },
  {
    name: 'directive.funeral.form.additional_wishes.nav_title',
    mobile: 'directive.funeral.form.additional_wishes.nav_title_short',
    value: 'ADDITIONAL_WISHES',
    url: 'additional-wishes',
  },
  {
    name: 'directive.funeral.review_nav_title',
    value: 'REVIEW',
    url: 'review',
  },
]
