import { css, Global } from '@emotion/react'
import { useUserGeoData } from 'api/goodtrust/user'
import { Fieldset } from 'components/fieldset/Fieldset'
import { useTranslation } from 'next-i18next'
import React, { forwardRef, Fragment, useCallback, useState } from 'react'
import PhoneInput, { CountrySelectComponentProps, PhoneInputProps } from 'react-phone-number-input'
import { mergeRefs } from 'utils/general'
import { pxToRem } from 'utils/styled'
import { CountrySelectComponent } from './CountrySelectComponent'

export const CustomPhoneInput = forwardRef<
  PhoneInput,
  PhoneInputProps & {
    label?: string
    error?: string
  }
>(({ value, error, label, className, ...inputProps }, ref) => {
  const { t } = useTranslation()

  const userGeoData = useUserGeoData()

  const [fieldset, setFieldset] = useState<HTMLFieldSetElement | null>(null)

  const localRef = React.useRef<HTMLInputElement>()
  const [hasHadValue, setHasHadValue] = React.useState(false)
  const hasValue = !!value
  React.useEffect(() => {
    setHasHadValue((val) => val || hasValue)
  }, [hasValue])

  // if the input hasn't had value yet, use the default
  // if the input had value before, we don't want to use defaultCountry, because it would cause the default country to be autofilled on CTRL-A and Delete
  // which is undesired behaviour according to GT-2938
  const defaultCountry = !hasHadValue ? userGeoData.data?.country_code || 'US' : undefined

  const countrySelectComponent = useCallback(
    (props: CountrySelectComponentProps) => (
      <CountrySelectComponent {...props} fieldset={fieldset} />
    ),
    [fieldset]
  )

  return (
    <Fragment>
      <Global
        styles={css`
          .PhoneInput {
            display: flex;
            align-items: center;
            min-height: 3.4375rem;
            background: ${inputProps.disabled ? 'var(--c-blue50)' : 'unset'};
            width: 100%;
          }

          .PhoneInputInput {
            flex: 1;
            min-width: 0;
            box-sizing: border-box;
            width: 100%;
            border: none;
            font-family: var(--f-text);
            color: var(--c-gray100);
            font-size: 16px;
            line-height: 1.31;
            outline: none;
            cursor: text;
            padding-top: ${pxToRem(23)}rem;
          }

          .PhoneInputCountryIcon {
            border: none;
            outline: none;
          }

          .PhoneInputCountryIconImg {
            width: 100%;
            object-fit: contain;
            object-position: center;
            height: 100%;
          }
        `}
      />
      <Fieldset
        ref={setFieldset}
        className={className}
        error={error}
        css={css`
          grid-column-end: span 2;
          & label {
            left: 5rem !important;
          }
          & .PhoneInputInput {
            padding-left: 0;
          }
          & .PhoneInputCountrySelect {
            padding: 0;
          }
          &:focus-within {
            & label {
              transform: scale(0.75);
            }
          }
          ${(value || defaultCountry) &&
          `
            & label {
              transform: scale(0.75);
            }
          `}
        `}
      >
        <PhoneInput
          international
          placeholder="&nbsp;"
          ref={mergeRefs([
            ref,
            // I don't know a better way to prevent typescript from complaining
            // the actual value set by the ref is HTMLInputElement, but the ref is expecting PhoneInput type
            localRef as unknown as React.MutableRefObject<PhoneInput>,
          ])}
          value={value}
          countrySelectComponent={countrySelectComponent}
          countryOptionsOrder={['US', 'GB', 'CA', 'AU', 'IE', 'MX']}
          defaultCountry={defaultCountry}
          onClick={(e) => {
            if (!localRef.current) return
            const el = localRef.current

            // the y coordinate of the click relative to the top of the input element
            const relativeY = e.clientY - el.getBoundingClientRect().top

            // the return value includes 'px' suffix
            const paddingTopText = getComputedStyle(el).getPropertyValue('padding-top')

            // so we strip the px suffix by using parseFloat
            const paddingTop = parseFloat(paddingTopText)

            const hasClickedTopPadding = relativeY < paddingTop
            if (hasClickedTopPadding) {
              // for an unknown reason, when top padding of the input is clicked
              // the start of the phone number is focused
              // we move the text cursor to the end of the input in this case
              // but we only do that when top padding has been clicked, otherwise the user wouldn't
              // be able to choose where they want the cursor to be placed when clicking the zone under the padding
              el.selectionStart = el.value.length
              el.selectionEnd = el.value.length
            }
          }}
          {...inputProps}
          onChange={(e) => inputProps.onChange(e ?? null)}
        />
        <label>{label ?? t('common.labels.phone_number')}</label>
      </Fieldset>
    </Fragment>
  )
})
CustomPhoneInput.displayName = 'CustomPhoneInput'
