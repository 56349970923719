import { Children, cloneElement, Fragment, isValidElement, ReactNode, useEffect } from 'react'
import { get, useFormContext, UseFormReturn } from 'react-hook-form'
import { useLatestValueRef } from 'utils/hooks'
import { StateSpinner } from './StateSpinner'

// this type is pulled from react-hook-form, which includes any
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type IFormType = Record<string, any>

function findNameInChildren(children?: ReactNode, depth = 0): string | null {
  let name: string | null = null

  Children.forEach(children, (child) => {
    if (!child || !isValidElement(child)) return

    if (child.props['name']) {
      name = child.props['name']
    }

    if (child.props.children && !name && depth > 0) {
      name = findNameInChildren(child.props.children as ReactNode, depth - 1)
    }
  })

  return name
}

export function FormButton<T extends IFormType>(props: {
  form?: UseFormReturn<T>
  children: ReactNode
}) {
  const contextForm = useFormContext()
  const form = props.form ?? contextForm

  return <StateSpinner isLoading={form.formState.isSubmitting}>{props.children}</StateSpinner>
}

/**
 * This hook partially solves GT-3188 and the following problem:
 * - we have a required checkbox
 * - user does not touch the checkbox
 * - user submits the form
 * - user is presented with and error
 * - user clicks on the checkbox, but the error is still displayed, because onBlur needs to be called for it to disappear, but the checkbox is still focused
 *
 * The problem is solved by invalidating the field on change if it has an error.
 *
 * Inspired by https://github.com/react-hook-form/react-hook-form/issues/2217#issuecomment-678228108
 */
function useRevalidateErrorOnChange(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>,
  name?: string | null
) {
  const { watch, formState, trigger } = form
  const formStateRef = useLatestValueRef(formState)
  useEffect(() => {
    if (!name) return

    const sub = watch((values, change) => {
      if (change.name !== name) return
      const hasError = !!get(formStateRef.current.errors, name, null)
      if (hasError) {
        trigger(name)
      }
    })
    return () => {
      sub.unsubscribe()
    }
  }, [watch, name, formStateRef, trigger])
}

export function FormFieldset<T extends IFormType>(props: {
  form?: UseFormReturn<T>
  children: ReactNode
  name?: string
}) {
  const contextForm = useFormContext()
  const form = props.form ?? contextForm
  const name = props.name ?? findNameInChildren(props.children, 1)
  useRevalidateErrorOnChange(form, name)

  return (
    <Fragment>
      {Children.map(props.children, (child) => {
        if (!child || !isValidElement<{ error: string; allErrors: Record<string, string> }>(child))
          return child
        return cloneElement(child, {
          error: name ? get(form.formState.errors, name, null)?.message : null,
          allErrors: name ? get(form.formState.errors, name, null)?.types : null,
        })
      })}
    </Fragment>
  )
}
