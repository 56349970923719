import { DirectiveGuideStep, DirectiveType } from 'components/directive/utils/types'
import { NumberRange } from 'utils/types'

export type DirectiveGuideSpec = {
  guideSteps: Record<NumberRange<3>, DirectiveGuideStep>
}

export const directiveGuideSpecs: Record<DirectiveType, DirectiveGuideSpec> = {
  FUNERAL: {
    guideSteps: {
      '1': {
        illustration: 'illustrations/testament',
        icon: 'FuneralDirective',
      },
      '2': {
        illustration: 'illustrations/printer',
        icon: 'TrustedContact',
      },
      '3': {
        illustration: 'illustrations/safe',
        icon: 'Print',
      },
    },
  },
  WILL: {
    guideSteps: {
      '1': {
        illustration: 'illustrations/testament',
        icon: 'UserProfileTap',
      },
      '2': {
        illustration: 'illustrations/printer',
        icon: 'Print',
      },
      '3': {
        illustration: 'illustrations/safe',
        icon: 'FileUploadCloud',
      },
    },
  },
  TRUST: {
    guideSteps: {
      '1': {
        illustration: 'illustrations/testament',
        icon: 'Will',
      },
      '2': {
        illustration: 'illustrations/printer',
        icon: 'Print',
      },
      '3': {
        illustration: 'illustrations/safe',
        icon: 'LockClosed',
      },
    },
  },
  POWER_OF_ATTORNEY: {
    guideSteps: {
      '1': {
        illustration: 'illustrations/testament',
        icon: 'PowerOfAttorney',
      },
      '2': {
        illustration: 'illustrations/printer',
        icon: 'UserProfileTap',
      },
      '3': {
        illustration: 'illustrations/safe',
        icon: 'Print',
      },
    },
  },
  PET: {
    guideSteps: {
      '1': {
        illustration: 'illustrations/testament',
        icon: 'UserProfileTap',
      },
      '2': {
        illustration: 'illustrations/vet',
        icon: 'PetDirective',
      },
      '3': {
        illustration: 'illustrations/safe',
        icon: 'LockClosed',
      },
    },
  },
  MEDICAL: {
    guideSteps: {
      '1': {
        illustration: 'illustrations/testament',
        icon: 'UserProfileTap',
      },
      '2': {
        illustration: 'illustrations/printer',
        icon: 'HealthCareDirective',
      },
      '3': {
        illustration: 'illustrations/safe',
        icon: 'LockClosed',
      },
    },
  },
}
