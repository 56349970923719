const path = require('path')
const PhraseInContextEditorPostProcessor = process.browser
  ? require('i18next-phrase-in-context-editor-post-processor').default
  : require('i18next-phrase-in-context-editor-post-processor')

let isPreview = false

if (process.browser && process.env.NEXT_PUBLIC_ENV !== 'production') {
  function getSessionValue(key) {
    if (typeof Storage !== 'undefined') {
      // eslint-disable-next-line no-undef
      return sessionStorage.getItem(key)
    } else {
      return null
    }
  }

  isPreview = getSessionValue('preview_status') === 'on'
}

const phraseInContextEditor = new PhraseInContextEditorPostProcessor({
  phraseEnabled: isPreview,
  projectId: process.env.NEXT_PUBLIC_PHRASE_PROJECT_ID,
  autoLowercase: false,
})

module.exports = {
  defaultNS: 'common',
  localePath: path.resolve('./public/locales'),
  reloadOnPrerender: process.env.NEXT_PUBLIC_ENV === 'devel',
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  react: {
    transKeepBasicHtmlNodesFor: ['b', 'i'],
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    useSuspense: false,
  },
  use: [phraseInContextEditor],
  postProcess: ['phraseInContextEditor'],
  serializeConfig: false,
}
