import { DirectiveLPSection } from 'components/directive/directiveLPSection/DirectiveLPSection'
import { TranslatableLink } from 'components/TranslatableLink'
import { Trans, useTranslation } from 'next-i18next'
import { numberRange } from 'utils/general'

export const FuneralLPSections = () => {
  const { t } = useTranslation('funeral_lp')

  return (
    <>
      <DirectiveLPSection
        type="FUNERAL"
        title={t('funeral_lp.section_wishes.title')}
        description={t('funeral_lp.section_wishes.description')}
        imageSlug="funeral_lp_section_wishes"
      />

      <DirectiveLPSection
        type="FUNERAL"
        imagePlacement="right"
        title={t('funeral_lp.section_loved_ones.title')}
        description={
          <Trans
            t={t}
            i18nKey="funeral_lp.section_loved_ones.description"
            components={{
              grief: (
                <TranslatableLink href="/articles/someone-in-your-life-passes-away-5-steps-to-move-forward" />
              ),
              funeralPlans: (
                <TranslatableLink href="/articles/10-steps-to-planning-a-funeral-checklist" />
              ),
              association: <TranslatableLink href="https://nfda.org/news/statistics" />,
              preplan: (
                <TranslatableLink href="/articles/how-to-preplan-your-funeral-step-by-step" />
              ),
            }}
          />
        }
        imageSlug="funeral_lp_section_loved_ones"
      />

      <DirectiveLPSection
        type="FUNERAL"
        title={t('funeral_lp.section_directive_content.title')}
        description={
          <>
            {t('funeral_lp.section_directive_content.description')}
            <ul>
              {numberRange(5).map((i) => (
                <li key={i}>
                  {
                    <Trans
                      t={t}
                      i18nKey={`funeral_lp.section_directive_content.item_${i}`}
                      components={{
                        celebration: (
                          <TranslatableLink href="/articles/how-to-plan-a-celebration-of-life-guide-and-ideas" />
                        ),
                      }}
                    />
                  }
                </li>
              ))}
            </ul>
          </>
        }
        imageSlug="funeral_lp_section_directive_content"
      />
    </>
  )
}
