import { FetchResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { login } from 'utils/auth/login'
import { handleAndToastError, toastError } from 'components/Toast'
import { postContactBind } from './contact'
import useSWR from 'swr'
import { cobrandingLogic } from 'utils/cobranding/logic'
import { MessageException } from 'utils/error'

export function useMfaToken() {
  return useSWR<string | null>('@cleevio/mfa', {
    fetcher: undefined,
    fallbackData: null,
  })
}

type OnErrorHandler = (res: FetchResponse<ApiType['UserLoginResponse']>) => void

type OnLoginErrorHandler = (
  res: FetchResponse<ApiType['UserLoginResponse']>,
  onVerificationRequired: () => void
) => void

export interface IAuthResponseOptions {
  contact?: string
  errorMessage?: string
  onError?: OnErrorHandler
  onMfa?: (mfaToken: string) => void
  onLoginSuccess?: (loginStatus: THandleAuthResponse) => void
  onVerificationRequired: () => void
  verifyEmail?: boolean
}

export type THandleAuthResponse = false | 'signup' | 'login' | 'verify'

export async function handleAuthResponse(
  res: FetchResponse<ApiType['UserLoginResponse']>,
  isRegister: boolean | null,
  options: IAuthResponseOptions
): Promise<THandleAuthResponse> {
  const { ok, json, status } = res
  if (
    ok &&
    typeof json?.access_token === 'string' &&
    typeof json?.refresh_token === 'string' &&
    typeof json?.expires === 'number' &&
    !options?.verifyEmail
  ) {
    await login({
      access_token: json.access_token,
      expires: json.expires,
      refresh_token: json.refresh_token,
    })

    if (options?.contact) {
      try {
        await postContactBind({ uuid: options?.contact })
      } catch {
        toastError('failed_to_bind_contact')
      }
    }

    const loginStatus = isRegister || status === 201 ? 'signup' : 'login'

    if (loginStatus === 'signup') {
      await cobrandingLogic.onUserSignedUp()
    }

    if (options?.onLoginSuccess) {
      options.onLoginSuccess(loginStatus)
    }

    return loginStatus
  } else if (ok && json?.is_mfa && json?.mfa_token != null) {
    options?.onMfa?.(json?.mfa_token)
    return false
  } else if (ok && options.verifyEmail) {
    return 'verify'
  } else {
    isRegister ? onSignupError(res) : onLoginError(res, options.onVerificationRequired)
    options?.onError?.(res)
    return false
  }
}

const onLoginError: OnLoginErrorHandler = (
  { status, error, errorCode },
  onVerificationRequired
) => {
  console.log(errorCode, 'this is error code')
  if (status === 401) {
    return handleAndToastError(error, 'invalid_credentials')
  }

  if (errorCode === 'user_deleted') {
    return toastError('account_deleted_contact_support')
  }

  if (errorCode === 'user_not_verified') {
    onVerificationRequired()
    return
  }
  const exception = errorCode ? new MessageException(errorCode) : error
  return handleAndToastError(exception, 'failed_to_login')
}

const onSignupError: OnErrorHandler = ({ error, errorCode }) => {
  if (errorCode === 'user_deleted') {
    return toastError('account_deleted_contact_support')
  }

  return handleAndToastError(error, 'failed_to_create_account')
}
