import { SOfferModal, STextSmall } from './OfferModal.styled'
import { BlockSpinner } from 'components/spinner/Spinner'
import { useLoadingCallback } from 'utils/hooks'
import { logWarn, matchMap } from 'utils/general'
import { Button } from 'components/button/Button'
import { Spacer } from 'components/spacer/Spacer'
import { Icon } from 'components/icon'
import { css } from '@emotion/react'
import { useExtendedPromotion, usePerksOfPromotion } from 'utils/promo'
import { PerksList } from 'components/perksList/PerksList'
import { Stack } from 'components/Stack'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { OfferModalHeader } from './OfferModalHeader'
import { Perk } from 'components/perkItem/PerkItem'

export const OfferModal = (props: {
  code?: string | null
  onAccept?: () => void
  onDecline?: () => void
  loading?: boolean
  aol?: boolean
}) => {
  const { t, isLoading } = useDynamicTranslations('pricing_plan')
  const { data: promo } = useExtendedPromotion(props.code ?? undefined)
  const perks = usePerksOfPromotion(props.code ?? undefined)

  const aolPerks: Perk[] = [
    {
      title: t('pricing_plan.offer.aol_perk_title'),
      description: t('pricing_plan.offer.aol_perk_description'),
    },
  ]

  const everyPerkIsDisabled = perks.every((perk) => perk.variant === 'disabled')
  const continueType = everyPerkIsDisabled ? 'continue-anyway' : 'redeem'

  const [redeem, isRedeeming] = useLoadingCallback(async () => {
    if (promo) {
      await props.onAccept?.()
    } else {
      logWarn('Missing code data')
    }
  })

  if (!promo || isLoading) return <BlockSpinner />

  const RedeemGrabComponentFunc = () => (
    <>
      <Button
        type="button"
        loading={isRedeeming}
        size="large"
        onClick={redeem}
        block
        css={css`
          & > span {
            display: flex;
            align-items: center;
            gap: 0.75rem;
          }
        `}
      >
        <Icon name="CheckCircle" />
        {t('pricing_plan.offer.continue_button')}
      </Button>

      <Button
        type="button"
        size="large"
        onClick={props.onDecline}
        variant="secondary"
        tone="dark"
        block
      >
        {t('pricing_plan.offer.decline_button')}
      </Button>
    </>
  )
  const ContinueGrabComponentFunc = () => (
    <>
      <Button type="button" size="large" onClick={props.onDecline} block>
        {t('pricing_plan.offer.continue_anyway_button')}
      </Button>
    </>
  )

  return (
    <SOfferModal>
      <OfferModalHeader
        promo={promo}
        warningText={
          everyPerkIsDisabled ? t('pricing_plan.offer.no_eligible_offer_warning') : undefined
        }
        aol={props.aol}
      />
      <PerksList perks={props.aol ? aolPerks : perks} css={{ alignSelf: 'flex-start' }} />

      <Spacer size={1.5} />

      <Stack gap={'1rem'} css={{ width: '100%' }}>
        {matchMap(continueType, {
          redeem: RedeemGrabComponentFunc,
          'continue-anyway': ContinueGrabComponentFunc,
        })()}
      </Stack>

      {promo.promo_code_info?.use_before && !props.aol && (
        <STextSmall
          css={css`
            margin-top: 1.5rem;
            margin-bottom: 0;
          `}
        >
          {t(
            `pricing_plan.offer.expiration_info.${
              everyPerkIsDisabled ? 'no_eligible_offer' : 'some_eligible_offers'
            }`,
            {
              date: new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
                new Date(promo.promo_code_info.use_before)
              ),
            }
          )}
        </STextSmall>
      )}
    </SOfferModal>
  )
}
