import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { pxToRem } from 'utils/styled'
import { Tooltip } from 'components/Tooltip'
import { Text } from 'components/Typography'
import { Icon } from 'components/icon'

export const SPrefix = styled.span`
  box-sizing: border-box;
  display: none;
  position: absolute;
  left: 0.875rem;
  top: ${pxToRem(23)}rem;
  font-size: 16px;
  line-height: 1.3;
  font-family: var(--f-text);
`

export const SRightSideContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  & > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0.8rem;
    }
  }
`

export const StyledFieldset = styled.fieldset<{
  invalid: boolean
  focused?: boolean
  disabled?: boolean
  hasIcon?: boolean
  prefix?: string
}>`
  position: relative;
  padding: 0;
  margin: 0;
  overflow: visible;
  border: 1px solid;
  min-height: 3.4375rem;

  border-color: var(--c-blue100);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: border-color 0.3s;

  width: 100%;

  & input,
  & textarea,
  & select,
  & button,
  & .stripe {
    box-sizing: border-box;
    width: 100%;
    padding: ${pxToRem(23)}rem 0.875rem 0.5625rem 0.875rem;
    ${({ hasIcon: hasInfo }) => (hasInfo ? 'padding-right: 2.5rem;' : null)}
    border: none;

    font-family: var(--f-text);
    color: var(--c-gray100);
    -webkit-text-fill-color: var(--c-gray100); // Fix Safari 14 and older color issues
    font-size: 16px;
    line-height: 1.31;

    outline: none;
    cursor: text;

    text-overflow: ellipsis;
    border-radius: 4px;
    &[disabled] {
      background: var(--c-blue50) !important;
      cursor: auto;
    }

    &::-moz-placeholder {
      transition: color 300ms ease;
    }

    &::-webkit-input-placeholder {
      transition: color 300ms ease;
    }

    &:not(:focus)::-webkit-input-placeholder {
      color: transparent;
    }

    &:not(:focus)::-moz-placeholder {
      color: transparent;
    }

    ${({ prefix }) => {
      if (prefix) {
        return `padding-left: ${prefix.length}.7rem;`
      } else return null
    }}
  }

  &[disabled] select {
    background: var(--c-blue50) !important;
    cursor: auto;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    pointer-events: none;
    height: ${pxToRem(23)}rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    background: var(--c-white);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0));
  }

  & .stripe {
    transition: opacity 300ms ease;
    opacity: 1;

    &.empty:not(.focus) {
      opacity: 0;
    }
  }

  & label {
    position: absolute;
    top: 1rem;
    left: 0.875rem;
    font-size: 1rem;
    color: var(--c-gray500);
    transform-origin: 0 -200%;
    transition: transform 300ms ease;
    pointer-events: none;

    font-family: var(--f-text);
  }

  &:focus-within {
    border-color: var(--c-blue200);
    box-shadow: 0 0 0 3px var(--c-blue100);
    & label {
      transform: scale(0.75);
    }
    & ${SPrefix} {
      display: inline-block;
    }
  }

  & input:focus,
  & input:not(:placeholder-shown),
  & select:focus,
  & select:not(.empty),
  & button:not(.empty),
  & textarea:focus,
  & textarea:not(:placeholder-shown),
  & .react-datepicker-wrapper.focus,
  & .react-datepicker-wrapper:not(.empty),
  & .stripe.focus,
  & .stripe:not(.empty),
  & .select.focus,
  & .select:focus-within,
  & .select:not(.empty) {
    & ~ label {
      transform: scale(0.75);
      & ~ ${SPrefix} {
        display: inline-block;
      }
    }
  }

  & select {
    appearance: none;
    background: transparent;

    background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2715%27%20height%3D%279%27%20viewBox%3D%270%200%2015%209%27%20fill%3D%27none%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20clip-rule%3D%27evenodd%27%20d%3D%27M0.792893%201.70718L2.20711%200.292969L7.5%205.58586L12.7929%200.292969L14.2071%201.70718L7.5%208.41429L0.792893%201.70718Z%27%20fill%3D%27%23575757%27%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 0.875rem);
    background-position-y: center;

    padding-right: calc(0.875rem * 1.5 + 15px);
    text-overflow: ellipsis;
  }

  & input[type='date'],
  & input[type='datetime-local'],
  & input[type='time'] {
    -webkit-appearance: none;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  input[type='date']::-webkit-input-placeholder {
    visibility: hidden !important;
  }

  ${({ focused }) =>
    focused
      ? css`
          & {
            border-color: var(--c-blue200);
            box-shadow: 0 0 0 3px var(--c-blue100);
          }
          & > label {
            transform: scale(0.75);
          }
          & > ${SPrefix} {
            display: inline-block;
          }
        `
      : null}

  ${({ invalid }) =>
    invalid
      ? css`
          &,
          &:focus-within {
            border-color: var(--c-red500);
          }
        `
      : null}

  ${({ disabled }) => {
    if (disabled) {
      return css`
        background: var(--c-blue50) !important;

        & input {
          background: var(--c-blue50) !important;
        }

        &:before {
          background: var(--c-blue50) !important;
        }
      `
    }
    return css``
  }}
`

export const IconInfo = styled(Icon)`
  &:hover {
    & + span {
      display: unset;
    }
  }
`

export const InfoTooltip = styled(Tooltip)`
  display: none;
  right: 1.5rem;
  top: 1rem;
  max-width: calc(100% - 3rem);
  z-index: 1;
`

export const FieldsetError = styled.span`
  display: block;
  position: absolute;
  bottom: calc(100% + 15px);
  right: 0;
  white-space: nowrap;
  padding: 8px 12px;
  font-weight: bold;

  font-family: var(--f-text);
  color: var(--c-red500);
  box-shadow: var(--card-shadow);

  &:after {
    content: '';
    display: block;
    border: 5px solid transparent;
    border-top-color: var(--c-white);
    border-right-color: var(--c-white);

    position: absolute;
    right: 0;
    top: 100%;
  }
`

export const SErrorText = styled(Text)`
  color: var(--c-red500);
  font-size: 0.75rem;
`
