import { useRouter } from 'next/router'
import useSWR from 'swr'
import { useRunOnceReady } from 'utils/hooks'

export const impactClickIdQueryParamName = 'irclickid'
const sessionStorageImpactClickIdKey = '@goodtrust/irclickid'
const swrImpactClickIdKey = '@goodtrust/irclickid'

export function useSaveImpactClickId() {
  const router = useRouter()
  useRunOnceReady(router.isReady, () => {
    tryStoreImpactClickId(router.query[impactClickIdQueryParamName])
  })
}

export function tryStoreImpactClickId(codeInQuery?: string | string[]) {
  try {
    const code = Array.isArray(codeInQuery) ? codeInQuery[0] : codeInQuery
    if (!code) return

    sessionStorage.setItem(sessionStorageImpactClickIdKey, code)
  } catch (err) {}
}

export function tryLoadImpactClickId(): string | null | undefined {
  try {
    return sessionStorage.getItem(sessionStorageImpactClickIdKey)
  } catch {
    return undefined
  }
}

export function tryRemoveImpactClickId() {
  try {
    sessionStorage.removeItem(sessionStorageImpactClickIdKey)
  } catch (err) {}
}

export function useStoredImpactClickIdSwr() {
  return useSWR(swrImpactClickIdKey, tryLoadImpactClickId)
}
