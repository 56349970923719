import { DirectiveLPSection } from 'components/directive/directiveLPSection/DirectiveLPSection'
import { TranslatableLink } from 'components/TranslatableLink'
import { Trans, useTranslation } from 'next-i18next'
import { FC } from 'react'
import { colors } from 'utils/colors'

export const TrustLPSections: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation('trust_lp')

  return (
    <>
      <DirectiveLPSection
        type="TRUST"
        imagePlacement="left"
        title={t('trust_lp.section_why_do_i_need.title')}
        description={
          <Trans
            t={t}
            i18nKey="trust_lp.section_why_do_i_need.description"
            components={[<span key="0" css={{ color: colors.blue[500] }} />]}
          />
        }
        imageSlug="trust_lp_section_why_do_i_need"
      />
      <DirectiveLPSection
        type="TRUST"
        imagePlacement="right"
        title={t('trust_lp.section_create_trust.title')}
        description={
          <Trans
            t={t}
            i18nKey="trust_lp.section_create_trust.description"
            components={[<TranslatableLink href="/digital-vault" key={0} />]}
          />
        }
        imageSlug="trust_lp_section_create_trust"
        extraParagraphs={[
          {
            title: t('trust_lp.section_create_trust.digital_legacy.title'),
            description: t('trust_lp.section_create_trust.digital_legacy.description'),
            icon: 'ShieldCheck',
          },
          {
            title: t('trust_lp.section_create_trust.last_will.title'),
            description: t('trust_lp.section_create_trust.last_will.description'),
            icon: 'Will',
          },
          {
            title: t('trust_lp.section_create_trust.upload_information.title'),
            description: t('trust_lp.section_create_trust.upload_information.description'),
            icon: 'FileUpload',
          },
        ]}
        hideButton
      />
      <DirectiveLPSection
        type="TRUST"
        imagePlacement="left"
        title={t('trust_lp.section_protect_family.title')}
        description={t('trust_lp.section_protect_family.description')}
        imageSlug="trust_lp_section_protect_family"
      />
      <DirectiveLPSection
        type="TRUST"
        imagePlacement="right"
        title={t('trust_lp.section_avoid_probate.title')}
        description={t('trust_lp.section_avoid_probate.description')}
        imageSlug="trust_lp_section_avoid_probate"
      />
      <DirectiveLPSection
        type="TRUST"
        imagePlacement="left"
        title={t('trust_lp.section_difference.title')}
        description={t('trust_lp.section_difference.description')}
        imageSlug="trust_lp_section_difference"
      />
    </>
  )
}
