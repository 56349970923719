import {
  SIconWrapper,
  SOption,
  SSelectCountryPicker,
  SFlagImg,
  SLabel,
} from './SelectCountry.styled'
import React, { ButtonHTMLAttributes, forwardRef, ReactElement } from 'react'
import { Text } from 'components/Typography'
import { getCountryCallingCode } from 'react-phone-number-input'
import { Icon } from 'components/icon'

type Option = {
  value?: string | undefined
  label: string
  icon: React.Component
}

interface Props {
  onChange: (newValue: string | undefined) => void
  onClick?: () => void
  options?: Option[]
}

function Option({
  label,
  value,
  onChange,
  onClick,
}: Option & {
  onChange: (newValue: string | undefined) => void
  onClick?: () => void
}) {
  if (!value) {
    return null
  }

  return (
    <SOption
      data-country={value}
      onClick={() => {
        onClick?.()
        onChange(value)
      }}
    >
      <SFlagImg src={`/flags/${value}.svg`} />

      <SLabel>{label}</SLabel>
      <Text>{`+${getCountryCallingCode(value)}`}</Text>
    </SOption>
  )
}

export const SelectCountryPicker = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & {
    flag?: ReactElement
  }
>(({ flag, ...props }, ref) => {
  return (
    <SSelectCountryPicker type="button" ref={ref} {...props}>
      <SIconWrapper>{flag || <Icon name="Globe" />}</SIconWrapper>
      <Icon name="CaretDown" css={{ marginLeft: '.5rem' }} />
    </SSelectCountryPicker>
  )
})
SelectCountryPicker.displayName = 'SelectCountryPicker'

export function SelectCountry({ onChange, options, onClick }: Props) {
  if (!options) {
    return null
  }

  return (
    <div css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {options.map((option, i) => (
        <Option onClick={onClick} key={i} {...option} onChange={onChange} />
      ))}
    </div>
  )
}
