import { useEffect } from 'react'

let hiderCount = 0

export const useHideFreshDeskButton = () => {
  useEffect(() => {
    hiderCount++

    document.body.classList.add('hide-freshdesk')

    return () => {
      hiderCount--
      if (hiderCount === 0) {
        document.body.classList.remove('hide-freshdesk')
      }
    }
  }, [])
}

export const hideFreshDeskButtonCss = `
body.hide-freshdesk iframe#launcher-frame {
  display: none !important;
}

@media (max-width: 768px) {
  body iframe#launcher-frame {
    display: none !important;
  }
}
`
