import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Text } from 'components/Typography'

export const CheckboxContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
`

export const CheckboxInput = styled.input`
  position: absolute;
  pointer-events: none;
  opacity: 0;
`

export const CheckboxBox = styled.div<{ invalid?: boolean; size?: number }>`
  border: 2px solid var(--c-gray500);
  border-radius: 4px;
  width: ${({ size }) => (size ? size : 28)}px;
  height: ${({ size }) => (size ? size : 28)}px;
  flex-basis: ${({ size }) => (size ? size : 28)}px;
  flex-shrink: 0;
  background: white;
  color: #a7a7a7;
  cursor: pointer;

  margin-right: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    color: var(--c-blue500);
  }

  & > * {
    opacity: 0;
    transition: opacity 0.2s;
  }

  input:focus + & {
    box-shadow: 0 0 0 3px var(--c-blue100);
  }

  input:checked + & {
    padding: 0px;
    border-color: var(--c-blue500);

    & > * {
      opacity: 1;
    }
  }

  input[disabled] + & {
    border: 1px solid #adadad;
    background: #adadad;
    cursor: auto;
    color: #d7d7d7;
  }

  ${({ invalid }) => {
    if (invalid) {
      return css`
        input + & {
          border-color: var(--c-red500);
        }

        input:focus + & {
          box-shadow: 0 0 0 3px var(--c-red100);
        }
      `
    }

    return null
  }}
`

export const SErrorText = styled(Text)`
  color: var(--c-red500);
  font-size: 0.75rem;
  line-height: 1.5rem;
`
