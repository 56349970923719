import { SPerkItem } from './PerkItem.styled'
import { ReactElement, ReactNode, VFC } from 'react'
import { Text } from 'components/Typography'
import { css } from '@emotion/react'
import { Icon } from 'components/icon'
import { matchMap } from 'utils/general'
import { colors } from 'utils/colors'

export type PerkProps = {
  isHolidayOffer?: boolean
  perk: Perk
  titleOnly?: boolean
}

export interface Perk {
  title: ReactNode
  description?: ReactNode
  icon?: ReactElement
  customView?: ReactNode
  image?: ReactNode
  variant?: 'basic' | 'disabled'
}

export const PerkItem: VFC<PerkProps> = (props) => {
  const { perk, isHolidayOffer } = props

  const variant = perk.variant ?? 'basic'
  const defaultIcon = matchMap(variant, {
    basic: <Icon name="Check" color={isHolidayOffer ? 'var(--c-blue500)' : 'var(--c-green200)'} />,
    disabled: <Icon name="Cancel" color={colors.gray[500]} />,
  })

  const textColor = matchMap(variant, {
    basic: 'black',
    disabled: colors.gray[500],
  })

  return (
    <SPerkItem isHolidayOffer={isHolidayOffer} containsImage={!!perk.image}>
      {perk.icon || defaultIcon}
      {perk.customView || (
        <div>
          <Text
            color={textColor}
            variant={props.titleOnly ? 'body' : 'bodyBold'}
            style={{ display: 'block' }}
          >
            {perk.title}
          </Text>
          {perk.description && !props.titleOnly && (
            <Text
              css={[
                isHolidayOffer &&
                  css`
                    &,
                    p {
                      // Here we're &,p selector, because we want to override the color of ContentfulContent
                      // and simply relying on inheritance of the color doesn't work, since ContentfulContent overrides the color
                      color: rgb(116, 80, 80);
                    }
                  `,
                variant === 'disabled' && {
                  textDecoration: 'line-through',
                },
              ]}
            >
              {perk.description}
            </Text>
          )}
        </div>
      )}
      {perk.image}
    </SPerkItem>
  )
}
