import { GoodtrustApiMock } from 'utils/mocks/api.mock'
import { rest } from 'msw'
import { ApiType } from 'utils/types'
import immer from 'immer'

export class GoodtrustApiMockDirectiveSlice {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: GoodtrustApiMock) {}

  handlers() {
    return [
      rest.post(this.api.prefixed('/api/v1/user/directive'), (req, res, ctx) => {
        const body = req.body as ApiType['UserDirectiveRequest']
        return res(
          ctx.status(200),
          ctx.json<ApiType['UserDirectiveResponse']>({
            uuid: 'mock-directive',
            directive_type: body.directive_type,
            trusted_contacts: [],
            locked: undefined,
            state: 'NEW',
            last_section: undefined,
            user: {
              email: 'test-account+22_12_07__11_46_51@example.com',
              first_name: 'Test Acc',
              middle_name: undefined,
              last_name: '22_12_07__11_46_51',
            },
            numbering: undefined,
            eligible_download: false,
            upsell_modal: true,
            created: '2022-12-07T18:15:22.855998691Z',
            modified: '2022-12-07T18:15:22.856001395Z',
            visited_sections: undefined,
          })
        )
      }),
      rest.post(
        this.api.prefixed('/api/user/directive/trust/mock-directive/finish'),
        (req, res, ctx) => {
          this.api.updateState((st) => {
            st.mockTrust.state = 'COMPLETED'
          })
          return res(
            ctx.status(200),
            ctx.json<ApiType['UserDirectiveTrustResponse']>(this.enhancedTrust())
          )
        }
      ),
      rest.patch(this.api.prefixed('/api/user/directive/trust/mock-directive'), (req, res, ctx) => {
        const body = req.body as ApiType['PatchTrustRequest']

        this.api.updateState((st) => {
          st.mockTrust.user_detail = Object.assign(
            {},
            st.mockTrust.user_detail,
            body.user_detail ?? {}
          )

          st.mockTrust.section_state = st.mockTrust.section_state ?? {}

          if (body.last_section)
            st.mockTrust.section_state.visited_sections = Array.from(
              new Set((st.mockTrust.section_state.visited_sections ?? []).concat(body.last_section))
            )

          if (body.family) {
            // @ts-ignore
            st.mockTrust.family = { ...body.family }
          }
          if (body.distribution_of_property) {
            // @ts-ignore
            st.mockTrust.distribution_of_property = body.distribution_of_property
          }
          if (body.remaining_estate) {
            // @ts-ignore
            st.mockTrust.remaining_estate = body.remaining_estate
          }
          if (body.pour_over_will) {
            // @ts-ignore
            st.mockTrust.pour_over_will = body.pour_over_will
          }
          if (body.trust) {
            // @ts-ignore
            st.mockTrust.trust = { ...body.trust }
          }
        })

        return res(
          ctx.status(200),
          ctx.json<ApiType['UserDirectiveTrustResponse']>(this.enhancedTrust())
        )
      }),
      rest.get(this.api.prefixed('/api/user/directive/trust/mock-directive'), (req, res, ctx) => {
        return res(
          ctx.status(200),
          ctx.json<ApiType['UserDirectiveTrustResponse']>(this.enhancedTrust())
        )
      }),
    ]
  }

  enhancedTrust() {
    return immer(this.api.state.mockTrust, (trust) => {
      trust.people = []
      if (trust.family?.partner) {
        trust.family.partner.first_name = 'Mock'
        trust.family.partner.last_name = 'Partner'
        trust.people.push({
          ...trust.family.partner,
          relationship:
            trust.family.marital_status === 'DOMESTIC_PARTNERSHIP'
              ? 'DOMESTIC_PARTNER'
              : trust.family.marital_status === 'MARRIED'
              ? 'SPOUSE'
              : undefined,
        })
      }
      trust.family?.pets?.items?.forEach((item, i) => {
        item.beneficiary.first_name = 'Mock'
        item.beneficiary.last_name = 'Pet Beneficiary #' + (i + 1)
      })
      trust.family?.children?.items?.forEach((item, i) => {
        item.first_name = 'Mock'
        item.last_name = 'Child #' + (i + 1)
      })
      trust.distribution_of_property?.assets?.forEach((item, i) => {
        if (!item.beneficiary) item.beneficiary = {}
        item.beneficiary.first_name = 'Mock'
        item.beneficiary.last_name = 'Beneficiary #' + (i + 1)
        if (!item.alternate_beneficiary) item.alternate_beneficiary = {}
        item.alternate_beneficiary.first_name = 'Mock'
        item.alternate_beneficiary.last_name = 'Alternate Beneficiary #' + (i + 1)
      })
      trust.remaining_estate?.beneficiaries?.items?.forEach((bene, i) => {
        if (!bene.beneficiary) bene.beneficiary = {}
        bene.beneficiary.first_name = 'Mock'
        bene.beneficiary.last_name = 'Beneficiary #' + (i + 1)
      })
      trust.remaining_estate?.alternate_beneficiaries?.items?.forEach((bene, i) => {
        if (!bene.alternate_beneficiary) bene.alternate_beneficiary = {}
        bene.alternate_beneficiary.first_name = 'Mock'
        bene.alternate_beneficiary.last_name = 'Alternate Beneficiary #' + (i + 1)
      })
      if (trust.pour_over_will?.another_executor) {
        trust.pour_over_will.another_executor = {
          first_name: 'Mock',
          last_name: 'Another Executor',
          relationship: 'COUSIN',
        }
      }
      trust.eligible_download = trust.state === 'COMPLETED'
    })
  }
}
