import { LPActionCardKey } from 'components/actions/useLPActions'
import { FUNERAL_DIRECTIVE_PROGRESS } from 'components/directive/funeral/funeralDirectiveProgress'
import { FuneralLPSections } from 'components/directive/funeral/FuneralLPSections'
import { MEDICAL_DIRECTIVE_PROGRESS } from 'components/directive/medical/medicalDirectiveProgress'
import { MedicalLPSections } from 'components/directive/medical/MedicalLPSections'
import { PET_DIRECTIVE_PROGRESS } from 'components/directive/pet/petDirectiveProgress'
import { PetLPSections } from 'components/directive/pet/PetLPSections'
import { POA_DIRECTIVE_PROGRESS } from 'components/directive/poa/form/poaDirectiveProgress'
import { POALPSections } from 'components/directive/poa/POALPSections'
import { TrustLPSections } from 'components/directive/trust/landing/TrustLPSections'
import { TRUST_DIRECTIVE_PROGRESS } from 'components/directive/trust/progress'
import { DirectiveSectionSpecification, DirectiveType } from 'components/directive/utils/types'
import { WILL_DIRECTIVE_PROGRESS } from 'components/directive/will/willDirectiveProgress'
import { WillLPSections } from 'components/directive/will/WillLPSections'
import { IconName } from 'components/icon'
import { FC } from 'react'
import { Namespaces } from 'utils/i18n/types'
import { Subset } from 'utils/types'

export interface DirectiveGeneralSpec {
  landingPagePath: string
  insidePath: string
  icon: IconName
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sections: DirectiveSectionSpecification<any>[]
  openGraphImage: string
  landingPageNamespace: Subset<
    keyof Namespaces,
    'funeral_lp' | 'will_lp' | 'poa_lp' | 'ahcd_lp' | 'pet_lp' | 'trust_lp'
  >
  LandingPageSections: FC<React.PropsWithChildren<unknown>>
  actionCardKey: LPActionCardKey
}

export const directiveGeneralSpecs: Record<DirectiveType, DirectiveGeneralSpec> = {
  FUNERAL: {
    insidePath: '/me/directive/funeral',
    landingPagePath: '/funeral',
    icon: 'FuneralDirective',
    sections: FUNERAL_DIRECTIVE_PROGRESS,
    openGraphImage: '/images/preview/funeral-directive.png',
    landingPageNamespace: 'funeral_lp',
    LandingPageSections: FuneralLPSections,
    actionCardKey: 'funeral',
  },
  WILL: {
    insidePath: '/me/directive/will',
    landingPagePath: '/will',
    icon: 'Will',
    sections: WILL_DIRECTIVE_PROGRESS,
    openGraphImage: '/images/preview/will.png',
    landingPageNamespace: 'will_lp',
    LandingPageSections: WillLPSections,
    actionCardKey: 'will',
  },
  TRUST: {
    insidePath: '/me/directive/trust',
    landingPagePath: '/trust',
    icon: 'Handshake',
    sections: TRUST_DIRECTIVE_PROGRESS,
    openGraphImage: '/images/preview/trust.png',
    actionCardKey: 'trust',
    landingPageNamespace: 'trust_lp',
    LandingPageSections: TrustLPSections,
  },
  POWER_OF_ATTORNEY: {
    insidePath: '/me/directive/poa',
    landingPagePath: '/financial-power-of-attorney',
    icon: 'PowerOfAttorney',
    sections: POA_DIRECTIVE_PROGRESS,
    openGraphImage: '/images/preview/poa.png',
    landingPageNamespace: 'poa_lp',
    LandingPageSections: POALPSections,
    actionCardKey: 'poa',
  },
  PET: {
    insidePath: '/me/directive/pet',
    landingPagePath: '/pet-directive',
    icon: 'PetDirective',
    sections: PET_DIRECTIVE_PROGRESS,
    openGraphImage: '/images/preview/pet.png',
    landingPageNamespace: 'pet_lp',
    LandingPageSections: PetLPSections,
    actionCardKey: 'pet',
  },
  MEDICAL: {
    insidePath: '/me/directive/medical',
    landingPagePath: '/advance-health-care-directive',
    icon: 'HealthCareDirective',
    sections: MEDICAL_DIRECTIVE_PROGRESS,
    openGraphImage: '/images/preview/ahcd.png',
    landingPageNamespace: 'ahcd_lp',
    LandingPageSections: MedicalLPSections,
    actionCardKey: 'ahcd',
  },
}
