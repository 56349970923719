import { EventArgs } from 'utils/analytics/types'
import { numberComparator } from 'utils/general'
import { getSessionValue, setSessionValue } from 'utils/sessionStorage'

export type EventHistory =
  // eslint-disable-next-line @typescript-eslint/ban-types
  {
    [key in keyof EventArgs]?: EventArgs[key] extends null
      ? { __at: number }
      : EventArgs[key] & { __at: number }
  }

export const eventHistory: EventHistory = {}

export function updateEventHistory(update: (history: typeof eventHistory) => void) {
  update(eventHistory)
  setSessionValue('@goodtrust/analytics/eventHistory', JSON.stringify(eventHistory))
}

export function restoreEventHistory() {
  try {
    const str = getSessionValue('@goodtrust/analytics/eventHistory') ?? ''
    const parsed = JSON.parse(str)
    Object.assign(eventHistory, parsed)
  } catch (err) {}
}

export function sortEventHistory<TEventName extends keyof EventArgs>(
  history: EventHistory,
  tuple: TEventName[]
): TEventName[] {
  return tuple
    .flatMap((eventName) => {
      const historyRecord = history[eventName]
      if (!historyRecord) return []
      return [
        {
          historyRecord,
          eventName,
        },
      ]
    })
    .sort(
      numberComparator(({ historyRecord }) => {
        return historyRecord?.__at ? -historyRecord.__at : Infinity
      })
    )
    .map((x) => x.eventName)
}
