import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

export const loadAnimation = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 var(--spinner-size) 0 -0.5em;
  }
  40% {
    box-shadow: 0 var(--spinner-size) 0 0;
  }
`

export const StyledSpinner = styled.span`
  --spinner-size: 0.5em;

  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: var(--spinner-size);
    height: var(--spinner-size);
    animation-fill-mode: both;
    animation: ${loadAnimation} 1.8s infinite ease-in-out;
    display: block;
    padding: 0;
  }

  & {
    color: currentColor;
    position: relative;
    transform: translateY(calc(var(--spinner-size) * -1));
    margin: calc((1.2em - var(--spinner-size)) / 2) 1rem;

    animation-delay: -0.16s;
    display: flex;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: -1em;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1em;
  }
`

export const SpinnerContainer = styled.span`
  color: var(--c-blue500);
  margin-left: 1em;
  font-size: 1.6em;
  display: block;
`
