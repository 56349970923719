import NextImage, { ImageProps } from 'next/legacy/image'
import {
  DetailedHTMLProps,
  forwardRef,
  Fragment,
  ImgHTMLAttributes,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { ImageFragmentContentful } from 'utils/contentful/types'
import { removeNullFields } from 'utils/general'
import { useIsMounted } from 'utils/hooks'
import { Nullable } from 'utils/types'
import { AspectRatio } from './AspectRatio'

export const Image = forwardRef<
  HTMLImageElement,
  Omit<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'placeholder' | 'srcSet'
  > & {
    placeholder?: ReactElement
  }
>(({ ref: _, placeholder, ...props }, ref) => {
  if (!props.src) return <>{placeholder}</>
  return <img ref={ref} {...props} />
})
Image.displayName = 'Image'

export const BlobImage = forwardRef<
  HTMLImageElement,
  Omit<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'src' | 'srcSet' | 'blob' | 'placeholder'
  > & {
    blob?: Blob
    placeholder?: ReactNode
  }
>(({ blob, placeholder, ...props }, ref) => {
  const [src, setSrc] = useState<string | undefined>(undefined)
  const mounted = useIsMounted()

  useEffect(() => {
    let objectUrl: string | null = null

    if (blob && blob.type.startsWith('image')) {
      objectUrl = URL.createObjectURL(blob)
      setSrc(objectUrl)
    } else {
      setSrc(undefined)
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [blob, mounted])

  if (!src) return placeholder ? <Fragment>{placeholder}</Fragment> : null
  return <img ref={ref} {...props} src={src} />
})

BlobImage.displayName = 'BlobImage'

export type ContentfulImageProps = Nullable<
  Omit<Omit<ImageProps, 'alt'> & ImageFragmentContentful, 'src' | 'sys'>
>

export const ContentfulImage = forwardRef<HTMLDivElement, ContentfulImageProps>(
  ({ width, height, url, ...props }, ref) => {
    if (!url) return null

    const sanitizedProps = removeNullFields<ImageProps>({ ...props })
    const ratio = !!width && !!height ? width / height : 1

    return (
      <AspectRatio ref={ref} ratio={ratio}>
        <NextImage
          alt={props.title || props.alt || ''}
          objectFit="contain"
          layout="fill"
          src={url}
          quality={75}
          loader={({ src, width, quality }) => `${src}?w=${width}&q=${quality}&fm=webp`}
          {...sanitizedProps}
        />
      </AspectRatio>
    )
  }
)
