import { CheckoutVariant } from 'components/subscription/checkout/types'
import { GroupLifePlanType, PlanType } from 'logic/subscription/plan/types'
import { isOneOf } from 'utils/general'

export function isGroupLifePlanType(
  planType: PlanType | CheckoutVariant
): planType is GroupLifePlanType {
  return isOneOf(planType, [
    'GROUP_LIFE_10K',
    'GROUP_LIFE_25K',
    'GROUP_LIFE_50K',
    'FREE_PLAN_GROUP_LIFE',
  ])
}
