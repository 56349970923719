import { DirectiveLPSection } from 'components/directive/directiveLPSection/DirectiveLPSection'
import { useTranslation } from 'next-i18next'

export const MedicalLPSections = () => {
  const { t } = useTranslation('ahcd_lp')

  return (
    <>
      <DirectiveLPSection
        type="MEDICAL"
        title={t('ahcd_lp.section_what_is.title')}
        description={t('ahcd_lp.section_what_is.description')}
        imageSlug="ahcd_lp_section_what_is"
      />

      <DirectiveLPSection
        type="MEDICAL"
        imagePlacement="right"
        title={t('ahcd_lp.section_loved_ones.title')}
        description={t('ahcd_lp.section_loved_ones.description')}
        imageSlug="ahcd_lp_section_loved_ones"
      />

      <DirectiveLPSection
        type="MEDICAL"
        title={t('ahcd_lp.section_short.title')}
        description={t('ahcd_lp.section_short.description')}
        imageSlug="ahcd_lp_section_short"
      />
    </>
  )
}
