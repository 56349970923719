import { events } from 'utils/analytics/events'
import { prepareUserDataForImpact } from 'utils/impact/prepareDataLayerForCustomerIdAndEmailForImpact'
import { tryLoadImpactClickId } from 'utils/impactCdn'

export async function prepareAndSendSignupDataForImpact() {
  const clickId = tryLoadImpactClickId()
  prepareUserDataForImpact().then(({ customerId, hashedEmail }) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      orderId: '',
      customerId,
      customerEmail: hashedEmail,
      clickId,
    })
    events.user_registered.fire()
  })
}
