import { i18n, I18nContext, useTranslation } from 'next-i18next'
import { useContext } from 'react'
import useSWR from 'swr'
import { NotCommonNamespace } from 'utils/i18n/types'

/**
 * A hook that idempotently makes sure that a given namespace is loaded.
 *
 * In case it's not loaded, it fetches it client-side.
 *
 * Make sure to only use this hook in contexts where it doesn't hurt SEO (mostly modals & inside flows).
 *
 * If you need SEO, use getIntlProps instead.
 *
 * @param namespace
 */
export function useDynamicTranslations<N extends NotCommonNamespace>(namespace: N) {
  const ctx = useContext(I18nContext)
  const language = ctx.i18n.language
  const trans = useTranslation(namespace)
  const hasBundleSwr = useSWR(
    ['@goodtrust/i18n/hasBundle', language, namespace],
    fetcherFactory(language, namespace),
    { suspense: true }
  )
  const hasBundle = hasBundleSwr.data

  return {
    hasBundle,
    isLoading: !hasBundle,
    ...trans,
  }
}

function fetcherFactory(language: string, namespace: string) {
  async function translationFetcher(_key: string) {
    if (!language || !namespace) return false

    const hasBundle = i18n?.hasResourceBundle(language, namespace)
    if (hasBundle) {
      return true
    }

    const data = await fetch(`/locales/${language}/${namespace}.json`).then((res) => res.json())

    i18n?.addResourceBundle(language, namespace, data, true, true)
    return true
  }
  return translationFetcher
}
