import { init } from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

export const initSentry = () => {
  init({
    dsn: SENTRY_DSN || 'https://2b3fee15e5184ea0a0862f38dcd5ba5b@sentry.cleevio.io/21',
    tracesSampleRate: 1.0,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    environment: process.env.NEXT_PUBLIC_ENV,
    enabled: process.env.NODE_ENV !== 'development',
  })
}
