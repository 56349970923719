import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { fireEvent } from 'utils/analytics/fire'
import { eventHistory } from 'utils/analytics/history'
import { EventArgs } from 'utils/analytics/types'
import { logMsg, toLowerCase } from 'utils/general'

export const sendSimpleFlowStartedEvent = (
  product: SimpleFlowDirectiveType | 'not_selected',
  place: EventArgs['est_flow_started']['place']
) => {
  const lastEventTriggerAt = eventHistory.est_flow_started?.__at
  const timeSinceLastStartedEvent =
    lastEventTriggerAt != null ? lastEventTriggerAt - Date.now() : undefined

  if (timeSinceLastStartedEvent != null && timeSinceLastStartedEvent < 2 * 60e3) {
    // we don't want the event to be triggered too many times,
    // as there are multiple places that attempt to call it to make sure we cover every possible place,
    // so we limit it to trigger at most every 2 minutes
    logMsg(
      'Event: we have skipped the est_flow_started event, as it was already triggered in the last 2 minutes.'
    )
    return
  }

  const lowerCaseProduct = toLowerCase(product)
  fireEvent('est_flow_started', { product: lowerCaseProduct, place })
}
