// IMPROVE doesn't work on localhost on iOS 15 Safari
export async function hashPassword(password: string) {
  const buf = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(`${process.env.NEXT_PUBLIC_PASSWORD_SALT}:${password}`)
  )
  return Array.from(new Uint8Array(buf))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}
