import ReactModal from 'react-modal'
import { ClassNames, css } from '@emotion/react'
import React, {
  ElementType,
  forwardRef,
  HTMLProps,
  ReactNode,
  Suspense,
  useCallback,
  useRef,
  useState,
} from 'react'
import { BREAKPOINTS } from 'utils/styled'
import { Title } from 'components/Typography'
import { SFocusLock, SModal, SModalCloseButton, SModalContent } from './Modal.styled'
import { useLatestValueRef } from 'utils/hooks'
import { Icon } from 'components/icon'
import { InterpolationPrimitive } from '@emotion/serialize'
import { BlockSpinner } from 'components/spinner/Spinner'

export type ModalPlacement = 'top' | 'bottom' | 'center'

export interface IModalRender {
  (params: { isOpen: boolean; close: (skipOnCloseCallback?: true) => void }): ReactNode
}

// Fixes an issue while using react-modal with focus lock.
// react-modal wraps modals with a div has tabIndex=-1
// this causes scroll to reset on safari when user clicks on a button
// because on safari, buttons are not focused on click
// https://github.com/theKashey/react-focus-lock/issues/79#issuecomment-523227162
const whiteListTabbableParent = (element: HTMLElement) => {
  if (element.tabIndex === -1 && element.querySelector('[data-focus-guard]')) {
    return false
  }
  return true
}

export const ModalTitle = forwardRef<
  HTMLHeadingElement,
  HTMLProps<HTMLHeadingElement> & {
    children?: ReactNode
    as?: ElementType
  }
>(({ children, as, ...inputProps }, ref) => {
  return (
    <Title
      variant={'h7'}
      as={as || 'h6'}
      {...inputProps}
      ref={ref}
      css={css`
        margin-bottom: 1.5rem;

        @media ${BREAKPOINTS.MD.max} {
          margin-top: 2rem;
        }
      `}
    >
      {children}
    </Title>
  )
})

export const Modal = (props: {
  isOpen: boolean
  onUserClosesModal: (skipOnCloseCallback?: true) => void
  render: IModalRender
  disableFocusLock?: boolean
  modalContentCss?: InterpolationPrimitive
  modalCss?: InterpolationPrimitive
  modalCloseButtonCss?: InterpolationPrimitive
  closeButtonTestId?: string
  isNotClosable?: boolean
  mobilePlacement?: ModalPlacement
}) => {
  const onClose = () => {
    if (props.isNotClosable) return
    props.onUserClosesModal()
  }
  return (
    <ClassNames>
      {({ css }) => (
        <ReactModal
          isOpen={props.isOpen}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          overlayClassName={css`
            z-index: 6;
            transform: translateZ(6px);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.75);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            animation: fadein 0.5s;
          `}
          className={css`
            transform: none;
            border-radius: 0;
            outline: none;
            flex-grow: 1;
            height: 100%;
            width: 100%;
          `}
        >
          <div css={{ height: '100%' }} onClick={onClose}>
            <SFocusLock disabled={props.disableFocusLock} whiteList={whiteListTabbableParent}>
              <SModal
                modalCss={props.modalCss}
                mobilePlacement={props.mobilePlacement}
                onSubmit={(e) => {
                  // We want to prevent the modal's buttons from submitting a form outside the modal.
                  e.stopPropagation()
                }}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <SModalContent modalContentCss={props.modalContentCss}>
                    {!props?.isNotClosable && (
                      <SModalCloseButton
                        type="button"
                        variant="text"
                        tone="dark"
                        onClick={() => props.onUserClosesModal()}
                        data-testid={props.closeButtonTestId}
                        modalCloseButtonCss={props.modalCloseButtonCss}
                      >
                        <Icon name="Close" size="1rem" />
                      </SModalCloseButton>
                    )}
                    <Suspense fallback={<BlockSpinner />}>
                      {props.render({
                        isOpen: props.isOpen,
                        close: props.onUserClosesModal,
                      })}
                    </Suspense>
                  </SModalContent>
                </div>
              </SModal>
            </SFocusLock>
          </div>
        </ReactModal>
      )}
    </ClassNames>
  )
}

export interface ModalConfig {
  disableFocusLock?: boolean
  modalContentCss?: InterpolationPrimitive
  modalCss?: InterpolationPrimitive
  modalCloseButtonCss?: InterpolationPrimitive
  mobilePlacement?: ModalPlacement
  onClose?: () => void
  onOpen?: () => void
  closeButtonTestId?: string
  isNotClosable?: boolean
}

export const useModal = (render: IModalRender, config?: ModalConfig) => {
  const [isOpen, setIsOpen] = useState(false)
  const closeCallback = useRef<(() => void) | null>(null)
  const configRef = useLatestValueRef(config)
  const openModal: () => Promise<void> = useCallback(
    () =>
      new Promise((resolve) => {
        setIsOpen(true)
        if (configRef.current?.onOpen) configRef.current.onOpen()
        closeCallback.current = resolve
      }),
    [configRef]
  )

  const closeModal = useCallback(() => {
    if (closeCallback.current) {
      closeCallback.current()
    }
    setIsOpen(false)
  }, [])

  const onUserClosesModal = (skipOnCloseCallback?: boolean) => {
    if (skipOnCloseCallback !== true && config?.onClose) {
      config.onClose()
    }
    closeModal()
  }

  const modalLayout = (
    <Modal
      isOpen={isOpen}
      onUserClosesModal={onUserClosesModal}
      render={render}
      disableFocusLock={config?.disableFocusLock}
      modalContentCss={config?.modalContentCss}
      modalCss={config?.modalCss}
      modalCloseButtonCss={config?.modalCloseButtonCss}
      closeButtonTestId={config?.closeButtonTestId}
      isNotClosable={config?.isNotClosable}
      mobilePlacement={config?.mobilePlacement}
    />
  )

  return [modalLayout, openModal, closeModal, isOpen] as const
}
ModalTitle.displayName = 'ModalTitle'
