import { ANALYTICS } from 'utils/analytics/consts'
import { eventHistory } from 'utils/analytics/history'
import { mutateAnalyticsData } from 'utils/analytics/mutate'
import { Analytics, EventArgs } from 'utils/analytics/types'
import get from 'lodash.get'

export function preprocessEvent<EventName extends keyof EventArgs>(
  initialEventName: EventName,
  eventArgs?: EventArgs[EventName]
) {
  // IMPROVE replace this legacy way of preprocessing events by AnalyticsEvent instances
  const eventArgsType = get(eventArgs, 'type')
  const type = eventArgsType ?? ANALYTICS.type ?? 'unknown'
  let data = {}
  let eventName: keyof EventArgs = initialEventName
  switch (eventName) {
    case 'signup_open':
      data = {
        type,
        partner: ANALYTICS.partner,
        page: ANALYTICS.page,
        button: ANALYTICS.button,
      }
      break
    case 'signup_started':
      if (!ANALYTICS.is_signing_up) return
      data = {
        type,
        page: ANALYTICS.page,
        button: ANALYTICS.button,
      }
      break
    case 'signup_plan_bought':
      data = {
        type,
        page: ANALYTICS.page,
        button: ANALYTICS.button,
      }
      break
    case 'signup_details_entered':
      if (!ANALYTICS.is_signing_up) return
      data = {
        type,
        page: ANALYTICS.page,
        button: ANALYTICS.button,
      }
      break
    case 'wgs_open':
      data = { state: ANALYTICS.loginState, page: ANALYTICS.page }
      break
    case 'wgs_started':
      data = { state: ANALYTICS.loginState, page: ANALYTICS.page }
      break
    case 'wgs_sites_selected':
      data = {
        state: ANALYTICS.loginState,
        page: ANALYTICS.page,
        price: ANALYTICS.price,
        currency: ANALYTICS.currency,
      }
      break
    case 'wgs_checkout':
      data = {
        state: ANALYTICS.loginState,
        page: ANALYTICS.page,
        price: ANALYTICS.price,
        currency: ANALYTICS.currency,
        new_registration: ANALYTICS.is_signing_up ? 'yes' : 'no',
      }
      break
    case 'photo_uploaded':
      mutateAnalyticsData({ page: (eventArgs as EventArgs['photo_uploaded']).page })
      data = {
        state: ANALYTICS.loginState,
        invited: ANALYTICS.invited || false,
      }
      break
    case 'sh_photo_uploaded': {
      const args = eventArgs as EventArgs['sh_photo_uploaded']
      mutateAnalyticsData({ page: args.page })
      data = {
        state: ANALYTICS.loginState,
        page: args.page,
        invited: ANALYTICS.invited || false,
      }
      break
    }
    case 'photo_upload_signup':
      mutateAnalyticsData({
        new_user: (eventArgs as EventArgs['photo_upload_signup']).action === 'signed_up',
      })
      data = {
        invited: ANALYTICS.invited || false,
      }
      break
    case 'sh_signup':
      mutateAnalyticsData({
        new_user: (eventArgs as EventArgs['sh_signup']).action === 'signed_up',
      })
      data = {
        invited: ANALYTICS.invited || false,
      }
      break
    case 'sh_song_selected':
      mutateAnalyticsData({ song_name: (eventArgs as EventArgs['sh_song_selected']).song_name })
      data = {
        invited: ANALYTICS.invited || false,
        page: ANALYTICS.page,
      }
      break
    case 'animation_finished':
      data = {
        invited: ANALYTICS.invited || false,
        new_user: ANALYTICS.new_user,
        page: ANALYTICS.page,
      }
      break
    case 'sh_finished':
      data = {
        invited: ANALYTICS.invited || false,
        new_user: ANALYTICS.new_user || false,
        page: ANALYTICS.page,
      }
      break
    case 'paywall_hit':
      if (ANALYTICS.memory_paywall_type === 'sp') {
        eventName = 'sh_paywall_hit'
      }
      data = { current_plan: ANALYTICS.current_plan, page: ANALYTICS.page }
      break
    case 'buy_upgrade_selected':
      if (!ANALYTICS.upgrade_type) return
      if (ANALYTICS.memory_paywall_type === 'sp') {
        eventName = 'sh_buy_upgrade_selected'
      }
      data = {
        current_plan: ANALYTICS.current_plan,
        page: ANALYTICS.page,
        type: ANALYTICS.upgrade_type,
        subs_type: ANALYTICS.subscription_type,
        pack_type: ANALYTICS.pack_type,
        price: ANALYTICS.price,
      }
      break
    case 'buy_upgrade_bought':
      if (!ANALYTICS.upgrade_type) return
      if (ANALYTICS.memory_paywall_type === 'sp') {
        eventName = 'sh_buy_upgrade_bought'
      }
      data = {
        current_plan: ANALYTICS.current_plan,
        page: ANALYTICS.page,
        type: ANALYTICS.upgrade_type,
        subs_type: ANALYTICS.subscription_type,
        pack_type: ANALYTICS.pack_type,
        price: ANALYTICS.price,
      }
      break
    case 'animation_shared':
    case 'sh_animation_shared':
      data = { page: ANALYTICS.page }
      break
    case 'shared_animation_viewed':
    case 'sh_animation_viewed':
      data = { state: ANALYTICS.loginState }
      break
    case 'upgrade_options':
      data = { flow: ANALYTICS.flow || 'else' }
      break
    case 'tokens_purchase':
      data = {
        flow: ANALYTICS.flow || 'else',
        pack_type: ANALYTICS.pack_type,
        price: ANALYTICS.price,
      }
      break
    case 'tutorial_viewed':
    case 'fd_tutorial_viewed':
    case 'pet_tutorial_viewed':
    case 'hd_tutorial_viewed':
    case 'poa_tutorial_viewed':
    case 'trust_tutorial_viewed':
      data = {
        logged: ANALYTICS.loginState === 'logged_in',
        source_page: ANALYTICS.last_page?.includes('/me/') ? 'dashboard' : 'landing_page',
      }
      break
    case 'aboutyou_filled':
    case 'fd_aboutyou_filled':
    case 'pet_aboutyou_filled':
    case 'hd_aboutyou_filled':
    case 'poa_aboutyou_filled':
    case 'trust_aboutyou_filled':
      data = {
        logged: ANALYTICS.loginState === 'logged_in',
      }
      break
    case 'edit_document':
    case 'fd_edit_document':
    case 'pet_edit_document':
    case 'hd_edit_document':
    case 'poa_edit_document':
    case 'trust_edit_document':
      data = {
        source_page: ANALYTICS.last_page?.includes('/steps/review') ? 'after_finish' : 'later',
      }
      break
    case 'download_pdf':
    case 'fd_download_pdf':
    case 'pet_download_pdf':
    case 'hd_download_pdf':
    case 'poa_download_pdf':
    case 'trust_download_pdf':
      data = {
        source_page: ANALYTICS.last_page?.includes('/steps/review') ? 'after_finish' : 'later',
      }
      break
    case 'fm_started':
    case 'fm_continue':
    case 'fm_inspired_tip':
    case 'fm_inspired_opened':
    case 'fm_record_open':
    case 'fm_record_upload':
    case 'fm_media_open':
    case 'fm_media_uploaded':
    case 'fm_viewed':
    case 'fm_shared':
      data = { logged: ANALYTICS.loginState === 'logged_in' ? 'yes' : 'no' }
      break
    case 'directive_paywall_continue':
      data = {
        dir_type: eventHistory.directive_paywall_hit?.dir_type,
        place: eventHistory.directive_paywall_hit?.place,
      }
      break

    default:
      data = {}
      break
  }

  if (eventName.match(/^sh_/)) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(
      data as {
        page: ReturnType<typeof transformSingingSourcePage>
      }
    ).page = transformSingingSourcePage(
      (
        data as {
          page: Analytics['page']
        }
      ).page
    )
  }

  return {
    name: eventName,
    data: {
      // send all props from eventArgs if they are provided
      ...(eventArgs ?? {}),
      // but allow data variable to override them
      ...data,
    },
  }
}

function transformSingingSourcePage(page: Analytics['page']) {
  if (page === 'singing_landing') return 'outside'
  if (page === 'singing_ready') return 'after_finish'
  if (page === 'banner_flow' || page === 'memories_dashboard') return 'inside'
  return page
}
