import { Maybe } from '@graphql-tools/utils'
import { formatDistanceToNowStrict, formatDuration, parseISO } from 'date-fns'
import { enUS, es } from 'date-fns/locale'
import { i18n } from 'next-i18next'
import { useRouter } from 'next/router'

export function formatPriceInCents(amount: number, fractionDigits?: number) {
  return formatPrice(amount / 100, fractionDigits)
}

export const formatCentsWithDollarSign = (price: Maybe<number>, fractionDigits?: number) =>
  withDollarSign(formatPriceInCents(price ?? 0, fractionDigits))

export const withDollarSign = (price: unknown) => ['$', price].join('')
export function formatPrice(amount: number, fractionDigits?: number) {
  if (amount === 0) {
    return '0'
  } else if (Number.isInteger(amount)) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(amount)
  } else {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: fractionDigits ?? 2,
      minimumFractionDigits: fractionDigits ?? 2,
    }).format(amount)
  }
}

export function formatName(
  firstName?: string | null,
  lastName?: string | null,
  opts?: {
    oneLetterLastName?: boolean
  }
) {
  const lastNameAfterModificationStep = opts?.oneLetterLastName
    ? lastName?.substring(0, 1) + '.'
    : lastName

  return [firstName, lastNameAfterModificationStep].filter(Boolean).join(' ')
}

export function formatNameWithMiddleName(
  firstName?: string | null,
  middleName?: string | null,
  lastName?: string | null
) {
  return [firstName, middleName, lastName].filter(Boolean).join(' ')
}

export function parseName(fullName?: string) {
  let firstName = undefined
  let lastName = undefined

  if (fullName) {
    const nameTrimmed = fullName.trim()
    firstName = nameTrimmed.split(' ')?.slice?.(0, -1)?.join?.(' ')
    lastName = nameTrimmed.split(' ')?.slice?.(-1)?.join?.(' ')
  }

  return { firstName, lastName }
}

export function formatCapitalize(text: string) {
  return text.charAt(0).toLocaleUpperCase() + text.slice(1)
}

export function formatFileSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

export const getUrlPathFromEnumValue = (value?: string) => {
  if (!value) return null
  return value.replace?.(/_/g, '-')?.toLowerCase?.()
}

/**
 * Serializes birthday to yyyy-MM-dd
 */
export function serializeBirthday(values: {
  br_day?: string | number
  br_month?: string | number
  br_year?: string | number
}) {
  const hasAllValues = values.br_day && values.br_month && values.br_year
  if (!hasAllValues) return undefined
  return [
    String(values.br_year),
    String(values.br_month).padStart(2, '0'),
    String(values.br_day).padStart(2, '0'),
  ].join('-')
}

export const formatUpdatedAgo = (modifiedAt: string) => {
  const distanceStrict = formatDistanceToNowStrict(parseISO(modifiedAt), {
    locale: i18n?.language === 'es' ? es : enUS,
  })
  return i18n?.t('common.updated_ago', { time: distanceStrict })
}

export const joinHumanly = (parts: string[]) => {
  const commaJoinedParts = parts.slice(0, -1).join(', ')
  const lastPart = parts.slice(-1).join('')
  return [commaJoinedParts, lastPart].filter((x) => !!x).join(' and ')
}

export const useFormattedMinutes = (minutes?: number | string) => {
  const router = useRouter()

  if (!Number.isInteger(Number(minutes))) return undefined

  let localizedMinutes
  try {
    localizedMinutes = new Intl.NumberFormat(router.locale, {
      style: 'unit',
      unit: 'minute',
      unitDisplay: 'short',
    }).format(Number(minutes))
  } catch (e) {
    localizedMinutes = formatDuration(
      {
        minutes: Number(minutes),
      },
      { format: ['minutes'], locale: i18n?.language === 'es' ? es : enUS }
    )
  }

  return localizedMinutes
}

export const secondsToMinuteSecond = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const secondsLeft = Math.round(seconds % 60) + ''
  return `${minutes}:${secondsLeft.padStart(2, '0')}`
}
