import { Perk, PerkItem } from 'components/perkItem/PerkItem'
import immer from 'immer'
import { ReactElement } from 'react'
import { matchMap, numberComparator } from 'utils/general'

import { SPricingUl } from './PerkList.styled'

export function PerksList(props: {
  perks?: Perk[]
  className?: string
  icon?: ReactElement
  isHolidayOffer?: boolean
  titleOnly?: boolean
}) {
  const perks = (props.perks ?? [])
    .map((perk) =>
      immer(perk, (perk) => {
        perk.icon = perk.icon ?? props.icon
      })
    )
    .sort(
      numberComparator((perk) =>
        matchMap(perk.variant ?? 'basic', {
          basic: 0,
          disabled: 1,
        })
      )
    )
  return (
    <SPricingUl className={props.className}>
      {perks.map((perk, index) => (
        <PerkItem
          key={index}
          perk={perk}
          isHolidayOffer={props.isHolidayOffer}
          titleOnly={props.titleOnly}
        />
      ))}
    </SPricingUl>
  )
}
