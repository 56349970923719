import { GoodtrustApiMock } from 'utils/mocks/api.mock'
import { rest } from 'msw'

export class GoodtrustApiMockTokenSlice {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: GoodtrustApiMock) {}
  handlers() {
    return [
      rest.get(this.api.prefixed('/api/token'), (req, res, ctx) => {
        return this.api.state.isLoggedIn
          ? res(
              ctx.status(200),
              ctx.json({
                access_token: 'mock_access_token',
              })
            )
          : res(ctx.status(403))
      }),
    ]
  }
}
