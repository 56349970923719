import { updateEventHistory } from 'utils/analytics/history'
import { preprocessEvent } from 'utils/analytics/preprocess'
import {
  EventArgs,
  EventNames,
  EventNamesWithArgs,
  EventNamesWithoutArgs,
} from 'utils/analytics/types'
import { logError, logMsg } from 'utils/general'

export function fireEvent<EventName extends EventNamesWithoutArgs>(
  initialEventName: EventName
): void
export function fireEvent<EventName extends EventNamesWithArgs>(
  initialEventName: EventName,
  eventArgs: EventArgs[EventName]
): void
export function fireEvent<EventName extends EventNames>(
  initialEventName: EventName,
  eventArgs?: EventArgs[EventName]
) {
  const result = preprocessEvent(initialEventName, eventArgs)
  updateEventHistory((history) => {
    // @ts-expect-error
    history[initialEventName] = { ...eventArgs, __at: Date.now() }
  })

  if (!result) return
  const { name, data } = result

  const isSilent = initialEventName.startsWith('silent_')
  logMsg('Event: ' + (isSilent ? '(silent)' : ''), name, ' data: ', data)

  if (isSilent) return

  event(name, data)
}

const event = <EventName extends keyof EventArgs>(name: EventName, data?: Gtag.CustomParams) => {
  if (typeof window !== 'undefined') {
    window.gtag('event', name, data)
  } else {
    logError('Can not fire event: Undefined window')
  }
}
