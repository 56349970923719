import { Button } from 'components/button/Button'
import { ThemedTextImageSection } from 'components/content/themedTextImageSection/ThemedTextImageSection'
import { SText } from './DirectiveLPSection.styled'
import { getDirectiveSpec } from 'components/directive/utils/directiveSpecs'
import { useCreateDirectiveLogic } from 'components/directive/utils/directiveUtils'
import { DirectiveType } from 'components/directive/utils/types'
import { Icon } from 'components/icon'
import { ContentfulImage } from 'components/Image'
import {
  LandingPageSectionExtraParagraph,
  LandingPageSectionExtraParagraphProps,
} from 'components/landingPageSectionExtraParagraph/LandingPageSectionExtraParagraph'
import { Spacer } from 'components/spacer/Spacer'
import { ReactNode } from 'react'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { ContentfulImages } from 'utils/contentful/types'
import { HorizontalPlacement } from 'utils/types'

interface DirectiveLPSectionProps {
  title: string
  imageSlug: keyof ContentfulImages
  description: ReactNode
  extraParagraphs?: LandingPageSectionExtraParagraphProps[]
  hideButton?: boolean
  type: DirectiveType
  imagePlacement?: HorizontalPlacement
}

export const DirectiveLPSection = ({
  imageSlug,
  title,
  description,
  extraParagraphs,
  hideButton,
  type,
  imagePlacement,
}: DirectiveLPSectionProps) => {
  const images = useContentfulImages()
  const spec = getDirectiveSpec(type)
  const { cta, create, isCreating } = useCreateDirectiveLogic(type)

  return (
    <>
      <ThemedTextImageSection
        title={title}
        image={<ContentfulImage {...images[imageSlug]} />}
        imagePlacement={imagePlacement}
        disableContentGap
      >
        <SText>{description}</SText>

        {extraParagraphs?.map((p, i) => (
          <>
            <Spacer size={1.5} />
            <LandingPageSectionExtraParagraph
              key={i}
              description={p.description}
              icon={p.icon}
              title={p.title}
            />
          </>
        ))}

        {!hideButton && (
          <>
            <Spacer size={1.5} />

            <Button
              type="button"
              leftIcon={<Icon name={spec.icon} />}
              variant="text"
              size="large"
              onClick={create}
              loading={isCreating}
            >
              {cta}
            </Button>
          </>
        )}
      </ThemedTextImageSection>
      <Spacer size={[3, 7]} />
    </>
  )
}
