import { isValidationErrorRelatedToAboutYou } from 'components/directive/utils/isValidationErrorRelatedToAboutYou'
import { DirectiveSectionSpecification } from 'components/directive/utils/types'
import { WillSectionKey } from 'components/directive/will/types'

export const WILL_DIRECTIVE_PROGRESS: DirectiveSectionSpecification<WillSectionKey>[] = [
  {
    name: 'directive.will.about_you.nav_title',
    mobile: 'directive.will.about_you.nav_title_short',
    value: 'ABOUT_YOU',
    url: 'about-you',
    subsections: [
      {
        sectionKey: 'ABOUT_YOU',
        isValidationErrorRelated: isValidationErrorRelatedToAboutYou,
      },
      {
        sectionKey: 'ABOUT_MORE',
      },
    ],
  },
  {
    name: 'directive.will.form.your_family.nav_title',
    mobile: 'directive.will.form.your_family.nav_title_short',
    value: 'YOUR_FAMILY',
    url: 'your-family',
  },
  {
    name: 'directive.will.form.executors.nav_title',
    mobile: 'directive.will.form.executors.nav_title_short',
    value: 'EXECUTORS',
    url: 'executors',
  },
  {
    name: 'directive.will.form.personal_property.nav_title',
    mobile: 'directive.will.form.personal_property.nav_title_short',
    value: 'PERSONAL_PROPERTY',
    url: 'personal-property',
  },
  {
    name: 'directive.will.form.remaining_estate.nav_title',
    mobile: 'directive.will.form.remaining_estate.nav_title_short',
    value: 'REMAINING_ESTATE',
    url: 'remaining-estate',
  },
  {
    name: 'directive.will.form.witnesses.nav_title',
    mobile: 'directive.will.form.witnesses.nav_title_short',
    value: 'WITNESSES',
    url: 'witnesses',
  },
  {
    name: 'directive.will.review_nav_title',
    mobile: 'directive.will.review_nav_title',
    value: 'REVIEW',
    url: 'review',
  },
]
