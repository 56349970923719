import { toLowerCase } from 'utils/general'
import { ApiType } from './types'
import { Translation, useTranslation } from 'next-i18next'
import { COUNTRY_CODES, COUNTRY_CODES_ES } from 'utils/country_codes'

export const emptyObject = {}

export const GET_STORE_WIZARD_HEADER = () => {
  const headerValues = ['site', 'checkout', 'upload', 'progress'] as const
  return headerValues.map((value) => ({
    value,
    name: <Translation>{(t) => t(`common.store_wizard_headers.${value}`)}</Translation>,
  }))
}

export const GET_ORDER_WIZARD_HEADER = () => {
  return [
    {
      name: <Translation>{(t) => t('common.wizard_header.profile')}</Translation>,
      value: 'profile',
    },
    { name: <Translation>{(t) => t('common.wizard_header.info')}</Translation>, value: 'info' },
    {
      name: <Translation>{(t) => t('common.wizard_header.upload')}</Translation>,
      value: 'upload',
    },
    { name: <Translation>{(t) => t('common.wizard_header.sign')}</Translation>, value: 'sign' },
    {
      name: <Translation>{(t) => t('common.wizard_header.progress')}</Translation>,
      value: 'progress',
    },
  ]
}

export const COUNTRIES = COUNTRY_CODES.map((c) => c.name)

export const COUNTRY_OPTIONS = COUNTRIES.map((c) => ({ label: c, value: c }))
export const COUNTRY_OPTIONS_ISO3166_1_ALPHA2 = COUNTRY_CODES.map((c) => ({
  label: c.name,
  value: c.code,
}))
export const COUNTRY_OPTIONS_ISO3166_1_ALPHA2_ES = COUNTRY_CODES_ES.map((c) => ({
  label: c.value,
  value: c.code,
}))

export const RELATIONS: readonly NonNullable<
  ApiType['TrustedContactItemResponse']['relationship']
>[] = [
  'PARENT',
  'SPOUSE',
  'DOMESTIC_PARTNER',
  'SIBLING',
  'COUSIN',
  'GRANDCHILD',
  'GRANDPARENT',
  'RELATIVE',
  'FRIEND',
  'CHILD',
  'LAWYER',
  'OTHER',
] as const

export const USE_RELATIONSHIP_OPTIONS = () => {
  const { t } = useTranslation('common')
  return RELATIONS.map((r) => ({ label: t(`common.relationships.${toLowerCase(r)}`), value: r }))
}

export const smsCodeValidityPeriodMs = 5 * 60e3

export function USE_SOCIAL_STATUS_OPTIONS(): {
  value: NonNullable<ApiType['UserPatchRequest']['social_status']>
  label: string
}[] {
  const { t } = useTranslation()
  return [
    {
      value: 'Single',
      label: t('common.social_status.single'),
    },
    {
      value: 'Married',
      label: t('common.social_status.married'),
    },
    {
      value: 'Divorced',
      label: t('common.social_status.divorced'),
    },
    {
      value: 'Widowed',
      label: t('common.social_status.widowed'),
    },
    {
      value: 'Other',
      label: t('common.social_status.other'),
    },
  ]
}

export const CHILDREN_OPTIONS: {
  value: NonNullable<ApiType['UserPatchRequest']['children']>
  label: string
}[] = [
  {
    value: 'ZERO',
    label: '0',
  },
  {
    value: 'ONE',
    label: '1',
  },
  {
    value: 'TWO',
    label: '2',
  },
  {
    value: 'THREE',
    label: '3',
  },
  {
    value: 'THREE_AND_MORE',
    label: '3+',
  },
]

export const GENDERS: NonNullable<ApiType['UserPatchRequest']['sex']>[] = ['Male', 'Female']

export const USE_GENDER_OPTIONS = () => {
  const { t } = useTranslation()
  return GENDERS.map((g) => ({ label: t(`common.gender.${g}`), value: g }))
}

export const PROPERTY_OPTIONS: NonNullable<NonNullable<ApiType['PropertyInput']>['type']>[] = [
  'VEHICLE',
  'CASH',
  'DIGITAL',
  'OTHER',
]

export const REAL_ESTATE_TYPE: NonNullable<
  NonNullable<ApiType['RealEstateInput']>['real_estate_type']
>[] = ['APARTMENT', 'HOUSE', 'LAND', 'OTHER']

export const SUPPORT_REASONS = [
  'General',
  'Contact',
  'Partnership',
  'Support',
  'Billing',
  'Problem',
] as const

export const USE_SUPPORT_REASON_OPTIONS = () => {
  const { t } = useTranslation()
  return SUPPORT_REASONS.map((r) => ({ label: t(`common.support_reasons.${r}`), value: r }))
}

export const CONTACT_SHARE_TIMING: NonNullable<
  Exclude<ApiType['ShareWithTrustedContactCommand']['share_mode'], 'NEVER'>
>[] = ['NOW', 'AFTER_DEATH']

export const USE_CONTACT_SHARE_TIMING_OPTIONS = () => {
  const { t } = useTranslation()
  return CONTACT_SHARE_TIMING.map((time) => ({
    label: t(`common.share_timing.${time}`),
    value: time,
  }))
}

export const USE_CONTACT_ACCESS_OPTIONS: () => {
  label: string
  value: NonNullable<ApiType['ShareWithTrustedContactCommand']['access_type']>
}[] = () => {
  const { t } = useTranslation()
  return [
    {
      label: t('common.contact_access_type.can_view'),
      value: 'READ_ONLY',
    },
    {
      label: t('common.contact_access_type.can_edit'),
      value: 'FULL',
    },
  ]
}

export const USE_MONTH_OPTIONS = () => {
  const { t } = useTranslation()
  return [
    { label: t('common.months.January'), value: 1 },
    { label: t('common.months.February'), value: 2 },
    { label: t('common.months.March'), value: 3 },
    { label: t('common.months.April'), value: 4 },
    { label: t('common.months.May'), value: 5 },
    { label: t('common.months.June'), value: 6 },
    { label: t('common.months.July'), value: 7 },
    { label: t('common.months.August'), value: 8 },
    { label: t('common.months.September'), value: 9 },
    { label: t('common.months.October'), value: 10 },
    { label: t('common.months.November'), value: 11 },
    { label: t('common.months.December'), value: 12 },
  ]
}

export const REVALIDATE_SECS = 10

export const SUPPORT_URL = 'https://support.mygoodtrust.com/support/home'
export const TRUST_VS_WILL_URL = '/articles/will-vs-trust-understanding-the-differences'
export const BBB_URL =
  'https://www.bbb.org/us/ca/palo-alto/profile/computer-software/goodtrust-1216-1651618'
export const PROMISE_NEVER = new Promise<never>(() => undefined)
