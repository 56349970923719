import { css } from '@emotion/react'
import { BlockSpinner } from 'components/spinner/Spinner'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { encodeQuery } from 'utils/fetcher'
import { useRunOnceReady } from 'utils/hooks'
import { isRoutePrivate } from './isRoutePrivate'

export const PrivateRoute: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter()
  const { isLogged } = useAuth()

  const isPrivate = useMemo(() => isRoutePrivate(router.pathname), [router.pathname])

  const isPublic = !isPrivate
  const variant = isPublic
    ? 'pass'
    : isLogged == null
    ? 'loading'
    : isLogged
    ? 'pass'
    : 'redirecting'

  useRunOnceReady(variant === 'redirecting', () => {
    router.push(encodeQuery('/login', { next: router.asPath }))
  })

  if (variant === 'pass') return <>{children}</>

  return (
    <div
      css={css`
        position: fixed;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <BlockSpinner />
    </div>
  )
}
