import styled from '@emotion/styled'

export const SBox = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid var(--c-blue100);
  padding: 1rem 1.5rem;
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const STitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  width: 100%;
  overflow: hidden;
  & > span {
    width: 100%;
    overflow-x: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    &:last-of-type {
      color: black;
    }
  }
`
