import styled from '@emotion/styled'
import { BREAKPOINTS } from 'utils/styled'

const defaultWidth = '1290px'
const maxWidth = `${BREAKPOINTS.XL.size}px`
export const minSpace = `5vw`

function getSpaceSmall(width: string) {
  return `calc((100% - ${width}) / 2)`
}

export const spaceLarge = `calc((100% - ${maxWidth} ) / 2)`
export const spaceSmall = getSpaceSmall(defaultWidth)

export const Block = styled.div<{ blockWidth?: string }>`
  display: grid;
  ${(p) => ({
    gridTemplateColumns: `minmax(${minSpace}, ${getSpaceSmall(
      p.blockWidth ?? defaultWidth
    )}) minmax(0, 1fr) minmax(
      ${minSpace},
      ${getSpaceSmall(p.blockWidth ?? defaultWidth)}
    )`,
  })}
  grid-template-areas: 'left center right';
  align-items: flex-start;

  &:before,
  &:after {
    content: '';
  }
`

export const NotEmptyBlock = styled.section`
  &:empty {
    display: none;
  }
`
