import styled from '@emotion/styled'
import { StyledLinkProps, pxToRem } from 'utils/styled'
import { css, keyframes } from '@emotion/react'

const fadeIn = keyframes`
  0% {
    transform: translateY(calc(-100% - 7px));
    opacity: 0;
  }
  
  100% {
    transform: translateY(calc(-100% - 15px));
    opacity: 1;
  }
`

export const Tooltip = styled.span<
  { orientation?: 'left' | 'right'; danger?: boolean } & StyledLinkProps
>`
  display: block;
  position: absolute;
  top: 0;

  padding: 8px 12px;
  font-weight: bold;
  break-inside: avoid;

  transform: translateY(calc(-100% - 15px));
  backface-visibility: hidden;
  animation: ${fadeIn} 0.2s 1 ease-in-out;

  font-family: var(--f-text);
  box-shadow: var(--card-shadow);
  background: var(--c-white);
  ${({ danger }) => (danger ? 'color: var(--c-red500);' : 'color: var(--c-gray500);')}

  max-width: ${pxToRem(250)}rem;

  z-index: 2;

  &:after {
    content: '';
    display: block;
    border: 5px solid transparent;

    position: absolute;
    border-top-color: var(--c-white);
    top: 100%;
  }

  ${({ orientation }) => {
    if (orientation === 'left') {
      return css`
        left: 0;
        text-align: left;
        &:after {
          border-left-color: var(--c-white);
          left: 0;
        }
      `
    }

    return css`
      right: 0;
      text-align: right;
      &:after {
        border-right-color: var(--c-white);
        right: 0;
      }
    `
  }}
`
