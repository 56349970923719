import { Key, mutate, useSWRConfig } from 'swr'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type TSWRCache = Map<Key, any> | null

export let SWRCache: TSWRCache = null

export const useAssignSwrCache = () => {
  SWRCache = useSWRConfig().cache as TSWRCache
}

export const mutateKeys = (mutationSelector: (key: Key) => boolean) => {
  for (const key of SWRCache?.keys() ?? []) {
    // not sure why key can be function and how to handle the case
    // but this function is mostly useful right now for string keys, so if we find out
    // let's update the behvaior
    if (typeof key !== 'function' && mutationSelector(key)) mutate(key)
  }
}
