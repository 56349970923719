import { ApiType } from 'utils/types'
import { apiAuthFetcher } from '../api'
import { StandardProps } from 'components/directive/utils/handleSubmitDefault'

export type DirectiveType = ApiType['UserDirectiveRequest']['directive_type']

export type PatchUserDirective = {
  uuid: string
}

export function getDirectiveKey(uuid: string, directiveType: DirectiveType) {
  const type = directiveType?.replace(/_/g, '-').toLowerCase()
  return `/api${directiveType === 'TRUST' ? '' : '/v1'}/user/directive/${type}/${uuid}`
}

export function patchUserDirective<T extends DirectiveType>(
  directiveType: T,
  args: Pick<StandardProps<T>, 'uuid' | 'data' | 'section'>
) {
  const { uuid, data, section } = args
  if (section) data.last_section = section
  return apiAuthFetcher(getDirectiveKey(uuid, directiveType), {
    method: 'PATCH',
    body: data,
    nullifyBody: true,
  })
}
