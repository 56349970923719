import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const SDropdown = styled.div<{ isOpen?: boolean }>`
  opacity: 1;
  box-shadow: var(--card-shadow);
  z-index: 99;
  transition-delay: 0.1s;

  ${({ isOpen }) =>
    css`
      visibility: ${isOpen ? 'visible' : 'hidden'};
      opacity: ${isOpen ? '1' : '0'};
      transition: visibility 0.2s, opacity 0.2s linear;
    `}
`

export const SFillScreen = styled.div`
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  display: grid;
  padding: 16px;
  padding-bottom: 0;
  grid-template-rows: min-content 1fr;
  background-color: white;
`

export const SFillScreenTrigger = styled.div`
  padding-bottom: 0.5rem;
`

export const SContent = styled.div`
  overflow: hidden;
  background-color: white;

  transform-origin: top center;
`

export const SItems = styled.ul<{
  width?: number
}>`
  padding: 0;
  margin: 0;
  max-height: min(25rem, 40vh);
  overflow-x: hidden;
  overflow-y: auto;

  & > * {
    cursor: pointer;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};
`
