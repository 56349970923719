import { Caption, Title_h3_default } from 'components/Typography'
import { Children, cloneElement, isValidElement, ReactElement, ReactNode } from 'react'
import { CornerstoneTheme } from 'components/cornerstone/CornerstoneUtils'
import { HorizontalPlacement } from 'utils/types'
import {
  SBlock,
  SDetails,
  SImageContainer,
  SThemedTextImageSection,
  STitle,
  STitleContainer,
} from './ThemedTextImageSection.styled'

export interface ThemedTextImageSectionProps {
  title?: string | null
  image?: ReactNode
  theme?: CornerstoneTheme
  children?: ReactNode
  imagePlacement?: HorizontalPlacement
  overflowingImage?: boolean
  bordered?: boolean
  tag?: ReactElement
  disableContentGap?: boolean
  key?: string
  logoAboveTitle?: ReactNode
  caption?: string
}

export const ThemedTextImageSection = (props: ThemedTextImageSectionProps) => {
  return (
    <SBlock bordered={props.bordered}>
      <SThemedTextImageSection
        imagePlacement={props.imagePlacement}
        overflowingImage={props.overflowingImage}
        customTheme={props.theme}
      >
        <SImageContainer bordered={props.bordered} imagePlacement={props.imagePlacement}>
          {props.image}
        </SImageContainer>
        <STitleContainer overflowingImage={props.overflowingImage}>
          {props.caption && <Caption>{props.caption}</Caption>}
          {props.tag}
          {props?.logoAboveTitle}
          <STitle variant={Title_h3_default}>{props.title}</STitle>
        </STitleContainer>
        <SDetails
          disableContentGap={props.disableContentGap}
          overflowingImage={props.overflowingImage}
        >
          {Children.map(props.children, (item) => {
            if (isValidElement<{ tone: string }>(item) && props.theme?.tone) {
              return cloneElement(item, {
                tone: props.theme?.tone,
              })
            }
            return item
          })}
        </SDetails>
      </SThemedTextImageSection>
    </SBlock>
  )
}
