import {
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { Spinner } from './spinner/Spinner'

export function StateSpinner({
  isLoading,
  children,
  spinner,
  className,
  hideSpinner = false,
  skipWait = false,
}: {
  isLoading: boolean
  children: ReactNode
  spinner?: ReactNode
  className?: string
  hideSpinner?: boolean
  skipWait?: boolean
}) {
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false)
  useEffect(() => {
    if (isLoading) {
      if (skipWait) {
        setIsSpinnerVisible(true)
        return () => void 0
      } else {
        const timeout = setTimeout(() => {
          setIsSpinnerVisible(true)
        }, 100)

        return () => clearTimeout(timeout)
      }
    }

    setIsSpinnerVisible(false)
    return () => void 0
  }, [isLoading, skipWait])

  if (isSpinnerVisible) {
    return (
      <Fragment>
        {Children.map(children, (child) => {
          if (!child || !isValidElement<{ disabled: boolean }>(child)) return child

          if (hideSpinner) {
            return cloneElement(child, { disabled: true })
          }

          return cloneElement(child, { disabled: true }, [
            spinner ?? <Spinner key="progress" className={className} />,
          ])
        })}
      </Fragment>
    )
  }

  return <Fragment>{children}</Fragment>
}
