import { getDowngradePageUrl } from 'logic/subscription/downgrade/getDowngradePageUrl'
import { PlanGroup } from 'logic/subscription/plan/types'
import { ApiType } from 'utils/types'
import { PlanType } from 'logic/subscription/plan/types'
import { describePlan } from 'logic/subscription/plan/describe'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'

export function describeDowngrading() {
  return {
    toPlan: describeDowngradingToPlan,
    withMe: describeDowngradingWithMe,
    fromPlan: describeDowngradingFromPlan,
  }
}

export function describeDowngradingFromPlan(planType: PlanType) {
  return {
    toFreePlan: () => describeDowngradingFromPlanToFreePlan(planType),
  }
}

export function describeDowngradingWithMe(userMe: ApiType['UserResponse']) {
  return {
    withPlanGroup: (planGroup: PlanGroup) => ({
      fromActivePlan: () =>
        describeDowngradingFromPlan(
          describeMyPlans(userMe).forPlanGroup(planGroup).getActivePlan()
        ),
    }),
  }
}

export function describeDowngradingToPlan(planType: PlanType) {
  return {
    getDowngradePageUrl: () => getDowngradePageUrl(planType),
  }
}

export function describeDowngradingFromPlanToFreePlan(planType: PlanType) {
  const freePlanType = describePlan(planType).planGroup().getFreePlanType()
  return describeDowngradingToPlan(freePlanType)
}
