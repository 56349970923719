export const COUNTRY_CODES = [
  { code: 'US', name: 'United States' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'CA', name: 'Canada' },
  { code: 'AU', name: 'Australia' },
  { code: 'IE', name: 'Ireland' },
  { code: 'MX', name: 'Mexico' },
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AX', name: 'Aland Islands' },
  { code: 'AL', name: 'Albania' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AG', name: 'Antigua and Barbuda' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AT', name: 'Austria' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BA', name: 'Bosnia and Herzegovina' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'BQ', name: 'Bonaire, Saint Eustatius and Saba ' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BV', name: 'Bouvet Island' },
  { code: 'BR', name: 'Brazil' },
  { code: 'BN', name: 'Brunei' },
  { code: 'VG', name: 'British Virgin Islands' },
  { code: 'IO', name: 'British Indian Ocean Territory' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'CV', name: 'Cape Verde' },
  { code: 'KY', name: 'Cayman Islands' },
  { code: 'CF', name: 'Central African Republic' },
  { code: 'TD', name: 'Chad' },
  { code: 'CL', name: 'Chile' },
  { code: 'CN', name: 'China' },
  { code: 'CX', name: 'Christmas Island' },
  { code: 'CC', name: 'Cocos Islands' },
  { code: 'CO', name: 'Colombia' },
  { code: 'KM', name: 'Comoros' },
  { code: 'CK', name: 'Cook Islands' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'HR', name: 'Croatia' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CW', name: 'Curacao' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czech Republic' },
  { code: 'CD', name: 'Democratic Republic of the Congo' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'TP', name: 'East Timor' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EG', name: 'Egypt' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'EE', name: 'Estonia' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FK', name: 'Falkland Islands' },
  { code: 'FO', name: 'Faroe Islands' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'GF', name: 'French Guiana' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'TF', name: 'French Southern Territories' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GE', name: 'Georgia' },
  { code: 'DE', name: 'Germany' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GR', name: 'Greece' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HM', name: 'Heard Island and McDonald Islands' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IN', name: 'India' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IR', name: 'Iran' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IM', name: 'Isle of Man' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italy' },
  { code: 'CI', name: 'Ivory Coast' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JP', name: 'Japan' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JO', name: 'Jordan' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'XK', name: 'Kosovo' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'LA', name: 'Laos' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libya' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MO', name: 'Macao' },
  { code: 'MK', name: 'Macedonia' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MV', name: 'Maldives' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'FM', name: 'Micronesia' },
  { code: 'MD', name: 'Moldova' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NE', name: 'Niger' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NU', name: 'Niue' },
  { code: 'NF', name: 'Norfolk Island' },
  { code: 'KP', name: 'North Korea' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'NO', name: 'Norway' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PS', name: 'Palestinian Territory' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'QA', name: 'Qatar' },
  { code: 'CG', name: 'Republic of the Congo' },
  { code: 'RE', name: 'Reunion' },
  { code: 'RO', name: 'Romania' },
  { code: 'RU', name: 'Russia' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'BL', name: 'Saint Barthelemy' },
  { code: 'SH', name: 'Saint Helena' },
  { code: 'KN', name: 'Saint Kitts and Nevis' },
  { code: 'LC', name: 'Saint Lucia' },
  { code: 'MF', name: 'Saint Martin' },
  { code: 'PM', name: 'Saint Pierre and Miquelon' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'Sao Tome and Principe' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SN', name: 'Senegal' },
  { code: 'RS', name: 'Serbia' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SX', name: 'Sint Maarten' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SO', name: 'Somalia' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { code: 'KR', name: 'South Korea' },
  { code: 'SS', name: 'South Sudan' },
  { code: 'ES', name: 'Spain' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'SD', name: 'Sudan' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen' },
  { code: 'SZ', name: 'Swaziland' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'SY', name: 'Syria' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TZ', name: 'Tanzania' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TG', name: 'Togo' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad and Tobago' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TR', name: 'Turkey' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TC', name: 'Turks and Caicos Islands' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'UM', name: 'United States Minor Outlying Islands' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VI', name: 'U.S. Virgin Islands' },
  { code: 'VA', name: 'Vatican' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'WF', name: 'Wallis and Futuna' },
  { code: 'EH', name: 'Western Sahara' },
  { code: 'YE', name: 'Yemen' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' },
]

export const COUNTRY_CODES_ES = [
  { code: 'US', value: 'Estados Unidos' },
  { code: 'GB', value: 'Reino Unido' },
  { code: 'CA', value: 'Canadá' },
  { code: 'AF', value: 'Afganistán' },
  { code: 'AL', value: 'Albania' },
  { code: 'DZ', value: 'Argelia' },
  { code: 'AS', value: 'Samoa Americana' },
  { code: 'AD', value: 'Andorra' },
  { code: 'AO', value: 'Angola' },
  { code: 'AI', value: 'Anguila' },
  { code: 'AQ', value: 'Antártida' },
  { code: 'AG', value: 'Antigua y Barbuda' },
  { code: 'AR', value: 'Argentina' },
  { code: 'AM', value: 'Armenia' },
  { code: 'AW', value: 'Aruba' },
  { code: 'AU', value: 'Australia' },
  { code: 'AT', value: 'Austria' },
  { code: 'AZ', value: 'Azerbaiyán' },
  { code: 'BS', value: 'Bahamas' },
  { code: 'BH', value: 'Bahrein' },
  { code: 'BD', value: 'Bangladesh' },
  { code: 'BB', value: 'Barbados' },
  { code: 'BY', value: 'Bielorrusia' },
  { code: 'BE', value: 'Bélgica' },
  { code: 'BZ', value: 'Belice' },
  { code: 'BJ', value: 'Benin' },
  { code: 'BM', value: 'Bermudas' },
  { code: 'BT', value: 'Bután' },
  { code: 'BO', value: 'Bolivia' },
  { code: 'BA', value: 'Bosnia y Herzegovina' },
  { code: 'BW', value: 'Botswana' },
  { code: 'BV', value: 'Isla Bouvet' },
  { code: 'BR', value: 'Brasil' },
  { code: 'IO', value: 'Territorio Británico del Océano Índico' },
  { code: 'BN', value: 'Brunei Darussalam' },
  { code: 'BG', value: 'Bulgaria' },
  { code: 'BF', value: 'Burkina Faso' },
  { code: 'BI', value: 'Burundi' },
  { code: 'KH', value: 'Camboya' },
  { code: 'CM', value: 'Camerún' },
  { code: 'CV', value: 'Cabo Verde' },
  { code: 'KY', value: 'Islas Caimán' },
  { code: 'CF', value: 'República Centroafricana' },
  { code: 'TD', value: 'Chad' },
  { code: 'CL', value: 'Chile' },
  { code: 'CN', value: 'China' },
  { code: 'CX', value: 'Isla de Navidad' },
  { code: 'CC', value: 'Islas Cocos (Keeling)' },
  { code: 'CO', value: 'Colombia' },
  { code: 'KM', value: 'Comoras' },
  { code: 'CG', value: 'Congo' },
  { code: 'CD', value: 'Congo (República Democrática del)' },
  { code: 'CK', value: 'Islas Cook' },
  { code: 'CR', value: 'Costa Rica' },
  { code: 'CI', value: 'Costa de Marfil' },
  { code: 'HR', value: 'Croacia' },
  { code: 'CU', value: 'Cuba' },
  { code: 'CY', value: 'Chipre' },
  { code: 'CZ', value: 'República Checa' },
  { code: 'DK', value: 'Dinamarca' },
  { code: 'DJ', value: 'Yibuti' },
  { code: 'DM', value: 'Dominica' },
  { code: 'DO', value: 'República Dominicana' },
  { code: 'EC', value: 'Ecuador' },
  { code: 'EG', value: 'Egipto' },
  { code: 'SV', value: 'El Salvador' },
  { code: 'GQ', value: 'Guinea Ecuatorial' },
  { code: 'ER', value: 'Eritrea' },
  { code: 'EE', value: 'Estonia' },
  { code: 'ET', value: 'Etiopía' },
  { code: 'FK', value: 'Islas Malvinas' },
  { code: 'FO', value: 'Islas Feroe' },
  { code: 'FJ', value: 'Fiji' },
  { code: 'FI', value: 'Finlandia' },
  { code: 'FR', value: 'Francia' },
  { code: 'GF', value: 'Guayana Francesa' },
  { code: 'PF', value: 'Polinesia Francesa' },
  { code: 'TF', value: 'Tierras Australes Francesas' },
  { code: 'GA', value: 'Gabón' },
  { code: 'GM', value: 'Gambia' },
  { code: 'GE', value: 'Georgia' },
  { code: 'DE', value: 'Alemania' },
  { code: 'GH', value: 'Ghana' },
  { code: 'GI', value: 'Gibraltar' },
  { code: 'GR', value: 'Grecia' },
  { code: 'GL', value: 'Groenlandia' },
  { code: 'GD', value: 'Granada' },
  { code: 'GP', value: 'Guadalupe' },
  { code: 'GU', value: 'Guam' },
  { code: 'GT', value: 'Guatemala' },
  { code: 'GN', value: 'Guinea' },
  { code: 'GW', value: 'Guinea Bissau' },
  { code: 'GY', value: 'Guyana' },
  { code: 'HT', value: 'Haití' },
  { code: 'HM', value: 'Heard e Islas McDonald' },
  { code: 'VA', value: 'Santa Sede' },
  { code: 'HN', value: 'Honduras' },
  { code: 'HK', value: 'Hong Kong' },
  { code: 'HU', value: 'Hungría' },
  { code: 'IS', value: 'Islandia' },
  { code: 'IN', value: 'India' },
  { code: 'ID', value: 'Indonesia' },
  { code: 'IR', value: 'República Islámica de Irán' },
  { code: 'IQ', value: 'Iraq' },
  { code: 'IE', value: 'Irlanda' },
  { code: 'IL', value: 'Israel' },
  { code: 'IT', value: 'Italia' },
  { code: 'JM', value: 'Jamaica' },
  { code: 'JP', value: 'Japón' },
  { code: 'JO', value: 'Jordania' },
  { code: 'KZ', value: 'Kazajistán' },
  { code: 'KE', value: 'Kenia' },
  { code: 'KI', value: 'Kiribati' },
  { code: 'KP', value: 'República Popular Democrática de Corea' },
  { code: 'KR', value: 'República de Corea' },
  { code: 'KW', value: 'Kuwait' },
  { code: 'KG', value: 'Kirguistán' },
  { code: 'LA', value: 'República Democrática Popular de Lao' },
  { code: 'LV', value: 'Letonia' },
  { code: 'LB', value: 'Líbano' },
  { code: 'LS', value: 'Lesoto' },
  { code: 'LR', value: 'Liberia' },
  { code: 'LY', value: 'Libia' },
  { code: 'LI', value: 'Liechtenstein' },
  { code: 'LT', value: 'Lituania' },
  { code: 'LU', value: 'Luxemburgo' },
  { code: 'MO', value: 'Macao' },
  { code: 'MK', value: 'Macedonia del Norte' },
  { code: 'MG', value: 'Madagascar' },
  { code: 'MW', value: 'Malaui' },
  { code: 'MY', value: 'Malasia' },
  { code: 'MV', value: 'Maldivas' },
  { code: 'ML', value: 'Malí' },
  { code: 'MT', value: 'Malta' },
  { code: 'MH', value: 'Islas Marshall' },
  { code: 'MQ', value: 'Martinica' },
  { code: 'MR', value: 'Mauritania' },
  { code: 'MU', value: 'Mauricio' },
  { code: 'YT', value: 'Mayotte' },
  { code: 'MX', value: 'México' },
  { code: 'FM', value: 'Micronesia' },
  { code: 'MD', value: 'Moldavia' },
  { code: 'MC', value: 'Mónaco' },
  { code: 'MN', value: 'Mongolia' },
  { code: 'MS', value: 'Montserrat' },
  { code: 'MA', value: 'Marruecos' },
  { code: 'MZ', value: 'Mozambique' },
  { code: 'MM', value: 'Myanmar' },
  { code: 'NA', value: 'Namibia' },
  { code: 'NR', value: 'Nauru' },
  { code: 'NP', value: 'Nepal' },
  { code: 'NL', value: 'Países Bajos' },
  { code: 'NC', value: 'Nueva Caledonia' },
  { code: 'NZ', value: 'Nueva Zelanda' },
  { code: 'NI', value: 'Nicaragua' },
  { code: 'NE', value: 'Níger' },
  { code: 'NG', value: 'Nigeria' },
  { code: 'NU', value: 'Niue' },
  { code: 'NF', value: 'Isla Norfolk' },
  { code: 'MP', value: 'Islas Marianas del Norte' },
  { code: 'NO', value: 'Noruega' },
  { code: 'OM', value: 'Omán' },
  { code: 'PK', value: 'Pakistán' },
  { code: 'PW', value: 'Palau' },
  { code: 'PS', value: 'Palestina' },
  { code: 'PA', value: 'Panamá' },
  { code: 'PG', value: 'Papua Nueva Guinea' },
  { code: 'PY', value: 'Paraguay' },
  { code: 'PE', value: 'Perú' },
  { code: 'PH', value: 'Filipinas' },
  { code: 'PN', value: 'Pitcairn' },
  { code: 'PL', value: 'Polonia' },
  { code: 'PT', value: 'Portugal' },
  { code: 'PR', value: 'Puerto Rico' },
  { code: 'QA', value: 'Catar' },
  { code: 'RE', value: 'Reunión' },
  { code: 'RO', value: 'Rumanía' },
  { code: 'RU', value: 'Rusia' },
  { code: 'RW', value: 'Ruanda' },
  { code: 'SH', value: 'Santa Helena, Ascensión y Tristán de Acuña' },
  { code: 'KN', value: 'Saint Kitts y Nevis' },
  { code: 'LC', value: 'Santa Lucía' },
  { code: 'PM', value: 'San Pedro y Miquelón' },
  { code: 'VC', value: 'San Vicente y las Granadinas' },
  { code: 'WS', value: 'Samoa' },
  { code: 'SM', value: 'San Marino' },
  { code: 'ST', value: 'Santo Tomé y Príncipe' },
  { code: 'SA', value: 'Arabia Saudita' },
  { code: 'SN', value: 'Senegal' },
  { code: 'SC', value: 'Seychelles' },
  { code: 'SL', value: 'Sierra Leona' },
  { code: 'SG', value: 'Singapur' },
  { code: 'SK', value: 'Eslovaquia' },
  { code: 'SI', value: 'Eslovenia' },
  { code: 'SB', value: 'Islas Salomón' },
  { code: 'SO', value: 'Somalia' },
  { code: 'ZA', value: 'Sudáfrica' },
  { code: 'GS', value: 'Georgia del Sur y las Islas Sandwich del Sur' },
  { code: 'ES', value: 'España' },
  { code: 'LK', value: 'Sri Lanka' },
  { code: 'SD', value: 'Sudán' },
  { code: 'SR', value: 'Suriname' },
  { code: 'SJ', value: 'Svalbard y Jan Mayen' },
  { code: 'SZ', value: 'Suazilandia' },
  { code: 'SE', value: 'Suecia' },
  { code: 'CH', value: 'Suiza' },
  { code: 'SY', value: 'República Árabe Siria' },
  { code: 'TW', value: 'Taiwán' },
  { code: 'TJ', value: 'Tayikistán' },
  { code: 'TZ', value: 'Tanzania' },
  { code: 'TH', value: 'Tailandia' },
  { code: 'TL', value: 'Timor-Leste' },
  { code: 'TG', value: 'Togo' },
  { code: 'TK', value: 'Tokelau' },
  { code: 'TO', value: 'Tonga' },
  { code: 'TT', value: 'Trinidad y Tobago' },
  { code: 'TN', value: 'Túnez' },
  { code: 'TR', value: 'Turquía' },
  { code: 'TM', value: 'Turkmenistán' },
  { code: 'TC', value: 'Islas Turcas y Caicos' },
  { code: 'TV', value: 'Tuvalu' },
  { code: 'UG', value: 'Uganda' },
  { code: 'UA', value: 'Ucrania' },
  { code: 'AE', value: 'Emiratos Árabes Unidos' },
  {
    code: 'UM',
    value: 'Islas Ultramarinas Menores de los Estados Unidos',
  },
  { code: 'UY', value: 'Uruguay' },
  { code: 'UZ', value: 'Uzbekistán' },
  { code: 'VU', value: 'Vanuatu' },
  { code: 'VE', value: 'Venezuela' },
  { code: 'VN', value: 'Vietnam' },
  { code: 'VG', value: 'Islas Vírgenes británicas' },
  { code: 'VI', value: 'Islas Vírgenes de los Estados Unidos' },
  { code: 'WF', value: 'Wallis y Futuna' },
  { code: 'EH', value: 'Sahara Occidental' },
  { code: 'YE', value: 'Yemen' },
  { code: 'ZM', value: 'Zambia' },
  { code: 'ZW', value: 'Zimbabue' },
  { code: 'AX', value: 'Islas Åland' },
  { code: 'BQ', value: 'Bonaire, San Eustaquio y Saba' },
  { code: 'CW', value: 'Curaçao' },
  { code: 'GG', value: 'Guernsey' },
  { code: 'IM', value: 'Isla de Man' },
  { code: 'JE', value: 'Jersey' },
  { code: 'ME', value: 'Montenegro' },
  { code: 'BL', value: 'Saint Barthélemy' },
  { code: 'MF', value: 'Saint Martin (francesa)' },
  { code: 'RS', value: 'Serbia' },
  { code: 'SX', value: 'Sint Maarten (neerlandesa)' },
  { code: 'SS', value: 'Sudán del Sur' },
  { code: 'XK', value: 'Kosovo' },
]
