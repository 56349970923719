import {
  downloadUserTrust,
  downloadUserWill,
  useUserTrust,
  useUserWill,
} from 'api/goodtrust/user/directive'
import { downloadUserFuneral, useUserFuneral } from 'api/goodtrust/user/funeral'
import { downloadUserMedical, useUserMedical } from 'api/goodtrust/user/medical'
import { downloadUserPet, useUserPet } from 'api/goodtrust/user/petDirective'
import { downloadUserPoa, useUserPoa } from 'api/goodtrust/user/poa'
import { DirectiveType } from 'components/directive/utils/types'
import { EitherUserDirectiveSwr } from 'logic/directive/types'
import { FetchBlobResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'

export type DirectiveApiSpec = {
  downloadPdf: (uuid: string) => Promise<FetchBlobResponse>
  userCreateSource: ApiType['UserCreateRequest']['source']
  useUserDirective: (uuid: string) => EitherUserDirectiveSwr
}

export const directiveApiSpecs: Record<DirectiveType, DirectiveApiSpec> = {
  FUNERAL: {
    userCreateSource: 'FUNERAL',
    useUserDirective: useUserFuneral,
    downloadPdf: downloadUserFuneral,
  },
  WILL: {
    userCreateSource: 'WILL',
    useUserDirective: (uuid: string) => useUserWill({ params: { uuid } }),
    downloadPdf: downloadUserWill,
  },
  TRUST: {
    userCreateSource: 'TRUST',
    downloadPdf: downloadUserTrust,
    useUserDirective: (uuid: string) => useUserTrust({ params: { uuid } }),
  },
  POWER_OF_ATTORNEY: {
    userCreateSource: 'POA',
    useUserDirective: useUserPoa,
    downloadPdf: downloadUserPoa,
  },
  PET: {
    userCreateSource: 'PET',
    useUserDirective: useUserPet,
    downloadPdf: downloadUserPet,
  },
  MEDICAL: {
    userCreateSource: 'MEDICAL',
    useUserDirective: useUserMedical,
    downloadPdf: downloadUserMedical,
  },
}
