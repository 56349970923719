import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const SPerkItem = styled.li<{ isHolidayOffer?: boolean; containsImage?: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr ${(p) => (p.containsImage ? 'auto' : '')};
  grid-template-rows: 1fr;
  grid-gap: 12px;

  & > svg,
  > img {
    flex-shrink: 0;
    margin-top: 0.3rem;
    width: 1rem;
    height: 1rem;
  }

  ${({ isHolidayOffer }) =>
    isHolidayOffer &&
    css`
      color: #745050;

      & svg > * {
        fill: #fa4e4e;
      }
    `}
`
