import { fetcher } from 'api/goodtrust/api'
import { AUTH_KEY, cache } from 'utils/auth/cache'
import { ShouldNeverHappenError } from 'utils/error'
import { SWRCache } from 'utils/swr'

export const login = async ({
  access_token,
  refresh_token,
  expires,
  persist = false,
}: {
  access_token: string
  refresh_token: string
  expires: number
  persist?: boolean
}) => {
  const response = await fetcher('/api/token', {
    method: 'POST',
    headers: { 'X-Request-Origin': 'v1' },
    body: { refresh_token, persist },
    credentials: 'include',
  })

  if (!response.ok) throw Error('Failed to login properly')
  if (!SWRCache) {
    // Missing cache object in login
    throw new ShouldNeverHappenError()
  }

  await cache.set(AUTH_KEY, { isLogged: true, access_token, expires })
}
