import { PlanType } from 'logic/subscription/plan/types'
import useSWR from 'swr'
import { encodeQuery, FetchResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { apiAuthFetcher, apiFetcher } from './api'

export type SubscriptionMapItem = {
  DEFAULT?: ApiType['Item']
  DASHLANE_ONLY?: ApiType['Item']
}
export type SubscriptionMap = Partial<Record<PlanType, SubscriptionMapItem>>
export type TypedSubscriptionMapResponse = Omit<
  ApiType['SubscriptionMapResponse'],
  'subscriptions'
> & {
  subscriptions?: SubscriptionMap
}

export async function getSubscriptionMap() {
  return apiFetcher<TypedSubscriptionMapResponse>('/api/v2/subscription/list')
}

export function useSubscriptionMap() {
  return useSWR('/api/v2/subscription/list', getSubscriptionMap)
}

export function postCreateSubscription(body: ApiType['SubscriptionCreateRequest']) {
  return apiFetcher<ApiType['SubscriptionResponse']>('/api/v1/subscription', {
    method: 'POST',
    body,
  })
}

export function postUpgradeSubscription(body: ApiType['SubscriptionUpgradeRequest']) {
  return apiAuthFetcher<ApiType['SubscriptionUpgradeResponse']>('/api/v2/subscription/upgrade', {
    method: 'POST',
    body,
  })
}

export function deleteSubscription(subscriptionUuid: string) {
  return apiAuthFetcher<ApiType['SubscriptionResponse']>(
    `/api/v1/subscription/${subscriptionUuid}`,
    {
      method: 'DELETE',
    }
  )
}

export function postDowngradeSubscription(body: ApiType['SubscriptionDowngradeRequest']) {
  return apiAuthFetcher('/api/v2/subscription/downgrade', {
    method: 'POST',
    body,
  })
}

export function useDowngradeInfo() {
  return useSWR<FetchResponse<ApiType['DowngradeLockedResponse']>>(
    '/api/v1/subscription/downgradeInfo',
    apiAuthFetcher
  )
}

export function useMemoryBundles() {
  return useSWR<FetchResponse<ApiType['AnimationBundlePriceResponse'][]>>(
    '/api/v1/memory/bundles',
    apiFetcher
  )
}

export function postLifetimePrepare(body: ApiType['SubscriptionLifetimeRequest']) {
  return apiFetcher('/api/v1/subscription/prepare/lifetime', {
    method: 'POST',
    body,
  })
}

export function postCancelDowngrade(subscriptionUuid: string) {
  return apiAuthFetcher<ApiType['SubscriptionResponse']>(
    `/api/v1/subscription/cancelDowngrade/${subscriptionUuid}`,
    {
      method: 'POST',
    }
  )
}

export function usePricePreview(
  command: ApiType['SubscriptionUpdateProrationCommand'] | undefined
) {
  return useSWR<FetchResponse<ApiType['SubscriptionUpdatePricePreviewResponse']>>(
    command ? encodeQuery('/api/v2/subscription/calculate-proration', command) : null,
    apiAuthFetcher
  )
}

export function getSubscriptionPdf(subscriptionUuid: string) {
  return apiAuthFetcher(`/api/v2/subscription/${subscriptionUuid}/generatePdf`, {
    toBlob: true,
  })
}
