import { fetcher } from 'api/goodtrust/api'
import { AUTH_KEY, cache, isExpired } from 'utils/auth/cache'
import { promiseManager } from 'utils/auth/promise-manager'
import { AuthResponse, AuthState } from 'utils/auth/types'

export async function authFetcher(): Promise<NonNullable<AuthState>> {
  // we cache the auth to avoid hitting the server every time

  const auth = cache.get(AUTH_KEY)

  // in case we have fetched the auth before and know that the user is logged out, we return the auth
  if (auth?.isLogged === false) {
    return auth
  }

  // in case we have fetched the auth before and know that the user is logged in
  // we check the token for expiration
  // if it's not expired, we return it
  if (auth?.isLogged === true && !isExpired(auth.expires)) {
    return auth
  }

  // if the code got here, that means we should ask the server
  // to provide us with a token
  // either we haven't got it or it expired and the server will provide us with a new one
  return promiseManager.resolveDeduped(
    async () => {
      const { ok, json } = await fetcher<AuthResponse>('/api/token', {
        method: 'GET',
        headers: { 'X-Request-Origin': 'v1' },
      })

      const authState: AuthState =
        !ok || !json
          ? { isLogged: false }
          : { isLogged: true, access_token: json.access_token, expires: json.expires }

      cache.set(AUTH_KEY, authState)

      return authState
    },
    { key: AUTH_KEY }
  )
}

export async function isUserLoggedIn() {
  return authFetcher().then((auth) => auth.isLogged)
}
