import { getUserMe } from 'api/goodtrust/user'
import { ExtendedPlanCart } from 'logic/subscription/cart/extended'
import { PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { ANALYTICS } from 'utils/analytics/consts'
import { AnalyticsEvent, EventTriggerContext } from 'utils/analytics/event'
import { gl_plan_activated } from 'utils/analytics/events/gl_plan_activated'
import { EventArgs } from 'utils/analytics/types'
import { ShouldNeverHappenError } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { matchMap } from 'utils/general'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'

type ExtendedPlanCartWithProduct = ExtendedPlanCart & { directiveProduct?: 'will' | 'trust' }
/**
 * This event is fired when a user buys a subscription.
 */
export const premium_activated_paid = new AnalyticsEvent<ExtendedPlanCartWithProduct>({
  async onFire(ctx) {
    const {
      basePrice,
      total,
      promo,
      planSpec,
      upgradeMethod,
      isSigningUp,
      firstSelectedType,
      type,
      directiveProduct,
    } = ctx.args

    const planGroup = planSpec?.planGroup ?? 'MAIN'
    if (planGroup === 'GROUP_LIFE') {
      gl_plan_activated.fire({
        paid_price: total,
      })
      return
    } else if (planGroup === 'ANIMATION') {
      ctx.fireEvent('animation_subscription_purchase', {
        base_price: basePrice ? basePrice / 100 : 0,
        paid_price: total ? total / 100 : 0,
        pack_type: planSpecs.STORY_PLUS.animationsPerWeek ?? 0,
        type: 'paid',
        product: ANALYTICS.flow ?? 'unknown',
      })
      return
    } else if (planGroup === 'ESTATE') {
      ctx.fireEvent('est_plan_paid', {
        price: total ? total / 100 : 0,
        type: planSpec?.displayName ?? 'unknown',
        product: directiveProduct ?? 'will',
      })
      return
    }

    if (planGroup !== 'MAIN') {
      // Plan group should be main here.
      throw new ShouldNeverHappenError()
    }

    ctx.fireEvent('buy_upgrade_bought', { result: 'order_paid' })

    const userMe = await getUserMe().then(unwrapResponse.body)

    const activePlanSpec = describeMyPlans(userMe).forPlanGroup(planGroup).getActivePlanSpec()

    const has_selected_upsell = firstSelectedType === type ? 'no' : 'yes'

    ctx.fireEvent('premium_activated', {
      type: 'paid',
      new_plan: planSpec?.analyticsPlanType ?? 'unknown',
      previous_plan: activePlanSpec.analyticsPlanType ?? 'unknown',
      upgrade_type: isSigningUp
        ? 'signup'
        : matchMap(upgradeMethod ?? 'create', {
            create: 'new',
            upgrade: 'upgrade',
          } as const),
      paid_price: total / 100,
      discount: promo?.offer?.option
        ? promo.offer.option === 'ABSOLUTE'
          ? 'absolute'
          : 'percentage'
        : 'none',
      discount_value: promo?.offer?.amount ?? null,
      base_price: basePrice / 100,
      has_selected_upsell,
      ...getCommon(ctx, has_selected_upsell),
    })

    if (isSigningUp)
      ctx.fireEvent('signup_plan_bought', {
        plan_type: planSpec?.analyticsPlanType ?? 'unknown',
        upsell: has_selected_upsell,
      })
  },
})

/**
 * This event is fired when a user gets a subscription via a promotion.
 */
export const premium_activated_promo = new AnalyticsEvent<{
  previousPlan: PlanType
  activatedPlan: PlanType
}>({
  async onFire({ ...ctx }) {
    const has_selected_upsell = 'no'

    const planSpec = planSpecs[ctx.args.activatedPlan]
    const planGroup = planSpec.planGroup
    if (planGroup === 'GROUP_LIFE') {
      gl_plan_activated.fire({
        paid_price: 0,
      })
      return
    }

    ctx.fireEvent('premium_activated', {
      type: 'promo',
      new_plan: planSpec.analyticsPlanType ?? 'unknown',
      previous_plan: planSpecs[ctx.args.previousPlan].analyticsPlanType ?? 'unknown',
      upgrade_type: 'new',
      discount: 'none',
      base_price: 0,
      paid_price: 0,
      has_selected_upsell,
      ...getCommon(ctx, has_selected_upsell),
    })
  },
})

function getCommon(
  ctx: EventTriggerContext<unknown>,
  has_selected_upsell: EventArgs['premium_activated']['has_selected_upsell']
): Pick<EventArgs['premium_activated'], 'flow' | 'place'> {
  return {
    flow: ctx.state.flow ?? 'else',
    place:
      ctx.history.directive_paywall_hit && ctx.history.directive_paywall_continue
        ? `dir_${ctx.history.directive_paywall_hit.place}`
        : has_selected_upsell === 'yes'
        ? 'checkout_upsell'
        : 'checkout',
  }
}
