import { apiAuthFetcher, apiFetcher } from 'api/goodtrust/api'
import {
  ApiResourceOperation,
  ApiResourcePath,
  DeleteBody,
  DeleteSuccessResponse,
  GetSuccessResponse,
  PatchBody,
  PatchSuccessResponse,
  PostBody,
  PostSuccessResponse,
  PutBody,
  PutSuccessResponse,
} from 'api/goodtrust/api/types'
import useSWR, { mutate, SWRConfiguration } from 'swr'
import { FetchResponse } from 'utils/fetcher'

export function defineApiResource<
  TPath extends ApiResourcePath,
  TParameter extends string | void = void
>(
  url: TPath,
  opts: {
    requiresAuth: boolean
    mapResponse?: { GET?: (res: GetSuccessResponse<TPath>) => GetSuccessResponse<TPath> }
    parameters?: TParameter[]
  }
) {
  const fetcher = opts.requiresAuth ? apiAuthFetcher : apiFetcher
  type ParamArg = TParameter extends void
    ? void
    : // @ts-expect-error
      Record<TParameter, string | number>

  function createMutation<TBody, TResponse>(method: 'PATCH' | 'PUT' | 'POST' | 'DELETE') {
    return async (body: TBody, params: ParamArg): Promise<FetchResponse<TResponse>> =>
      // @ts-expect-error
      fetcher<TResponse>(replaceUrlParameters(url, params), {
        method,
        body,
      }).then(async (response) => {
        await mutate(url)
        return response
      })
  }

  function replaceUrlParameters(
    url: string,
    params: Record<string, string | number | null | undefined> | void | undefined
  ) {
    if (!opts.parameters?.length) return url
    // @ts-expect-error
    return url.replace(/\{(.*?)\}/g, (_, param) => params[param])
  }

  const getFetcher = (url: string, params: ParamArg) => {
    return fetcher<GetSuccessResponse<TPath>>(replaceUrlParameters(url, params)).then((res) => {
      return {
        ...res,
        json: res.json != null && opts.mapResponse?.GET ? opts.mapResponse.GET(res.json) : res.json,
      }
    })
  }
  const patch = createMutation<PatchBody<TPath>, PatchSuccessResponse<TPath>>('PATCH')
  const put = createMutation<PutBody<TPath>, PutSuccessResponse<TPath>>('PUT')
  const post = createMutation<PostBody<TPath>, PostSuccessResponse<TPath>>('POST')
  const deleteMutation = createMutation<DeleteBody<TPath>, DeleteSuccessResponse<TPath>>('DELETE')

  return {
    use: (args?: {
      disabled?: boolean
      config?: SWRConfiguration
      params?: ParamArg extends void
        ? void
        : { [key in keyof ParamArg]: ParamArg[key] | undefined | null }
    }) =>
      useSWR<FetchResponse<GetSuccessResponse<TPath>>>(
        args?.disabled ||
          opts.parameters?.some(
            (param) =>
              // @ts-expect-error
              args?.params?.[param] == null
          )
          ? null
          : // @ts-expect-error
            replaceUrlParameters(url, args?.params ?? {}),
        getFetcher,
        args?.config
      ),
    get: (params: ParamArg) => getFetcher(url, params),
    ...({ patch } as ApiResourceOperation<TPath, 'patch'> extends never
      ? Record<never, never>
      : { patch: typeof patch }),
    ...({ put } as ApiResourceOperation<TPath, 'put'> extends never
      ? Record<never, never>
      : { put: typeof put }),
    ...({ post } as ApiResourceOperation<TPath, 'post'> extends never
      ? Record<never, never>
      : { post: typeof post }),
    ...({ delete: deleteMutation } as ApiResourceOperation<TPath, 'delete'> extends never
      ? Record<never, never>
      : { delete: typeof deleteMutation }),
    mutate: async () => mutate(url),
  }
}
