import { AnalyticsEvent } from 'utils/analytics/event'
import { EventArgs } from 'utils/analytics/types'

/**
 * This event is fired when the user opens the modal with group life insurance offer on the dashboard.
 */
export const gl_modal_open = new AnalyticsEvent<void>({
  async onFire(ctx) {
    const { router_pathname } = ctx.state
    const section: EventArgs['gl_modal_open']['section'] = router_pathname?.startsWith(
      '/me/dashboard'
    )
      ? 'dashboard'
      : router_pathname?.startsWith('/me/digital-vault')
      ? 'digital_vault'
      : 'else'

    ctx.fireEvent('gl_modal_open', { section })
  },
})
