import styled from '@emotion/styled'
import { Text } from 'components/Typography'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'

export const SOfferModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  @media ${BREAKPOINTS.MD.max} {
    padding: 1rem 0;
  }
`

export const STextSmall = styled(Text)`
  font-size: 0.8125rem;
  text-align: center;
  margin-bottom: 1rem;
`

export const SLogoWrapper = styled.div`
  max-width: 80%;
  margin-bottom: 1rem;

  & > img {
    height: 100%;
    width: auto;
  }
`

export const SOfferDivider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--c-blue100);
`

export const SNoCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;

  & > svg {
    margin-right: 0.5rem;
    height: 0.9375rem;
    width: auto;
    margin-top: 0.125rem;
  }
`

export const STitle = styled(Text)`
  color: black;
  font-weight: bold;
  font-size: ${pxToRemUnit(20)};
  text-align: center;
`
