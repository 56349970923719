import { directiveAnalyticSpecs } from 'components/directive/utils/directiveSpecs/analyticSpecs'
import { directiveApiSpecs } from 'components/directive/utils/directiveSpecs/apiSpecs'
import { directiveGeneralSpecs } from 'components/directive/utils/directiveSpecs/generalSpecs'
import { directiveGuideSpecs } from 'components/directive/utils/directiveSpecs/guideSpecs'
import { directiveStatisticsSpecs } from 'components/directive/utils/directiveSpecs/statisticsSpecs'
import { DirectiveType } from 'components/directive/utils/types'
import { useTranslation } from 'next-i18next'
import { isNonNullable, toLowerCase, withoutLeadingSlash } from 'utils/general'

export function getDirectiveSpec(directiveType: DirectiveType) {
  const generalSpecs = directiveGeneralSpecs[directiveType]

  return {
    ...generalSpecs,
    ...directiveApiSpecs[directiveType],
    ...directiveAnalyticSpecs[directiveType],
    ...directiveGuideSpecs[directiveType],
    ...directiveStatisticsSpecs[directiveType],
    /**
     * Returns url for the entity, optionally joining it with an additional path.
     * The url format looks like something like this: /me/directive/{directiveType}/{uuid}/[additionalPath]
     */
    entityUrl: (uuid: string, path?: string) => {
      return [generalSpecs.insidePath, uuid, path ? withoutLeadingSlash(path) : undefined]
        .filter(isNonNullable)
        .join('/')
    },
    insideTutorialPath: `${generalSpecs.insidePath}/tutorial`,
    typeLowercase: toLowerCase(directiveType),
    useTranslatedNames: () => {
      const { t } = useTranslation()
      return {
        short: t(`common.directives.${toLowerCase(directiveType)}.name`),
        long: t(`common.directives.${toLowerCase(directiveType)}.name_long`),
      }
    },
  }
}
export type DirectiveFullSpec = ReturnType<typeof getDirectiveSpec>

export const willSpec = getDirectiveSpec('WILL')
export const trustSpec = getDirectiveSpec('TRUST')
export const funeralSpec = getDirectiveSpec('FUNERAL')
export const poaSpec = getDirectiveSpec('POWER_OF_ATTORNEY')
export const medicalSpec = getDirectiveSpec('MEDICAL')
export const petSpec = getDirectiveSpec('PET')
