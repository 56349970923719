import { useUserMe } from 'api/goodtrust/user'
import { PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { isValid } from 'date-fns'
import { describeDowngrading } from 'logic/subscription/downgrade/describe'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { useTranslation } from 'next-i18next'
import { TFunction } from 'react-i18next'
import { formatDate } from 'utils/date'
import { encodeQuery } from 'utils/fetcher'
import { ident, matchMap } from 'utils/general'
import { ApiType } from 'utils/types'

type UserMe = ApiType['UserResponse']
export type UseSubscriptionCardButtonReturnValue = {
  href: string | undefined
  disabled: boolean
  content: [string, string] | string
  behavior: ButtonBehavior
}

function getTrialEndMessage(t: TFunction<'pricing_plan'>, userMe: UserMe): string | undefined {
  const { isTrialing, isFreePremium } = describeMyPlans(userMe)
  const { getSubscription } = describeMyPlans(userMe).forPlanGroup('MAIN')

  if (!isTrialing()) return undefined
  // for an unknown reason, we don't want to show trial end message for free premium users
  if (isFreePremium()) return undefined

  const current_period_end = new Date(getSubscription()?.current_period_end ?? '')

  if (!isValid(current_period_end)) {
    return undefined
  }

  const formattedDate = formatDate(current_period_end)
  return t('pricing_plan.general.trial_ends_on', { date: formattedDate })
}

type ButtonBehavior =
  | 'contact-support'
  | 'current'
  | 'renew'
  | 'upgrade'
  | 'downgrade'
  | 'downgrade-before-upgrade'
  | 'register'

export function getButtonBehavior(planType: PlanType, userMe: UserMe | undefined): ButtonBehavior {
  if (!userMe) return 'register'

  const { canUpgradeTo, canDowngradeTo, hasActivePlan, isPlanSuccessorToMyPlan, isTrialing } =
    describeMyPlans(userMe)
  const { isSubscriptionBeingDowngraded, hasLifetimePlan } =
    describeMyPlans(userMe).forPlanTypeGroup(planType)

  if (canUpgradeTo(planType))
    //
    return 'upgrade'

  if (hasActivePlan(planType) || isPlanSuccessorToMyPlan(planType))
    return isSubscriptionBeingDowngraded() ? 'renew' : 'current'

  if (hasLifetimePlan())
    //
    return 'contact-support'

  const mainDesc = describeMyPlans(userMe).forPlanGroup('MAIN')
  if (
    isTrialing() &&
    mainDesc.getActivePlanSpec().upgradeOptions?.includes(planType) &&
    mainDesc.isPeriodLongerThan({ years: 1 })
  )
    return 'contact-support'

  if (canDowngradeTo(planType))
    //
    return 'downgrade'

  return 'downgrade-before-upgrade'
}

function getContent(
  t: TFunction<'pricing_plan', undefined>,
  planType: PlanType,
  userMe: UserMe | undefined
): string | [string, string] {
  const behavior = getButtonBehavior(planType, userMe)
  const spec = planSpecs[planType]
  const planGroup = spec.planGroup

  const getPlan = ident<[string, string]>([
    t('pricing_plan.pricing_page.get_plan', { plan_name: spec.displayName }),
    t('pricing_plan.pricing_page.cancel_anytime'),
  ])
  return matchMap(behavior, {
    downgrade: getPlan,
    upgrade:
      planGroup === 'ANIMATION' ? t('pricing_plan.story_portraits.paywall_plan_button') : getPlan,
    register: getPlan,
    renew: t('pricing_plan.general.go_back_to', { plan: spec.displayName }),
    'contact-support': ident<[string, string]>([
      t('pricing_plan.general.not_available'),
      t('pricing_plan.general.contact_us_for_info'),
    ]),
    current: ident<[string, string]>([
      t('pricing_plan.general.current_plan'),
      !userMe
        ? ''
        : describeMyPlans(userMe).forPlanGroup(spec.planGroup).hasLifetimePlan()
        ? t('pricing_plan.general.lifetime_duration')
        : getTrialEndMessage(t, userMe) ?? '',
    ]),
    'downgrade-before-upgrade': ident<[string, string]>([
      t('pricing_plan.general.not_available'),
      t('pricing_plan.general.downgrade_to_free_before'),
    ]),
  })
}

function useHref(
  planType: PlanType,
  userMe: UserMe | undefined,
  next?: string
): string | undefined {
  const behavior = getButtonBehavior(planType, userMe)
  const planGroup = planSpecs[planType].planGroup

  if (
    behavior !== 'downgrade' &&
    behavior !== 'upgrade' &&
    behavior !== 'renew' &&
    behavior !== 'register'
  )
    return undefined

  const url = matchMap(behavior, {
    downgrade: describeDowngrading().toPlan(planType).getDowngradePageUrl(),
    upgrade: planGroup === 'GROUP_LIFE' ? '/goodtrust-life/checkout' : '/signup/checkout',
    register: planGroup === 'GROUP_LIFE' ? '/goodtrust-life/signup' : '/signup',
    renew: '',
  })

  return encodeQuery(url, { next })
}

export function useSubscriptionCardButton(
  planType: PlanType,
  next?: string
): UseSubscriptionCardButtonReturnValue {
  const { t } = useTranslation('pricing_plan')
  const userMe = useUserMe().data?.json
  const behavior = getButtonBehavior(planType, userMe)
  const href = useHref(planType, userMe, next)

  return {
    href,
    behavior,
    disabled: href == null,
    content: getContent(t, planType, userMe),
  }
}
