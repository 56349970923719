import { DirectiveType } from 'components/directive/utils/types'
import { IconName } from 'components/icon'
import { NumberRange } from 'utils/types'

export type DirectiveStatisticsSpec = {
  statisticIcons: Record<NumberRange<3>, IconName>
}

export const directiveStatisticsSpecs: Record<DirectiveType, DirectiveStatisticsSpec> = {
  FUNERAL: { statisticIcons: { '1': 'FuneralDirective', '2': 'Search', '3': 'Coffin' } },
  WILL: { statisticIcons: { '1': 'MoneyBag', '2': 'Will', '3': 'DocumentReady' } },
  TRUST: { statisticIcons: { '1': 'EstatePlan', '2': 'MoneyBag', '3': 'Calendar' } },
  POWER_OF_ATTORNEY: {
    statisticIcons: { '1': 'PowerOfAttorney', '2': 'ShieldCheck', '3': 'RibbonShield' },
  },
  PET: { statisticIcons: { '1': 'PetDirective', '2': 'Fish', '3': 'HealthCareDirective' } },
  MEDICAL: { statisticIcons: { '1': 'HealthCareDirective', '2': 'Will', '3': 'DocumentReady' } },
}
