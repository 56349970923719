import { BlockSpinner } from 'components/spinner/Spinner'
import { Stack } from 'components/Stack'
import { handleAndToastError, toastSuccessString } from 'components/Toast'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { availableCommands, Command, secretCommands } from 'utils/devtools/commands'
import {
  SCommandItem,
  SDimmedBackground,
  SSearchInput,
  SSpinner,
} from './DevtoolCommandPicker.styled'
import { colors } from 'utils/colors'
import { sleep } from 'utils/general'
import { useKeyboardSelectionLogic } from 'utils/hooks/useKeyboardSelectionLogic'
import { useLoadingCallback, useRunOnceReady, useRunOnlyOnChange } from 'utils/hooks'
import { MessageException } from 'utils/error'

export type DevtoolCommandPickerFormFields = {
  search: string
}
export const DevtoolCommandPicker: FC<
  React.PropsWithChildren<{
    onCommandFinished?: () => void
  }>
> = (props) => {
  const form = useForm<DevtoolCommandPickerFormFields>({
    defaultValues: {
      search: '',
    },
  })

  const search = form.watch('search')
  const commands = availableCommands.flatMap((comm) => {
    const index = comm.name.toLowerCase().indexOf(search.toLowerCase())
    if (index >= 0 && !secretCommands.map((s) => s.name).includes(comm.name)) {
      return [
        {
          command: comm,
          from: index,
          to: index + search.length,
        },
      ]
    }
    return []
  })

  const [execute, isExecuting] = useLoadingCallback(async (command: Command) => {
    try {
      const commandPromise = command.run()

      await Promise.race([
        commandPromise.then(() => 'command'),
        sleep(500).then(() => 'sleep'),
      ]).then((result) => {
        if (result === 'sleep') toastSuccessString(`Executing ${command.name}.`)
      })

      await commandPromise

      toastSuccessString(`Executed ${command.name}.`)

      props.onCommandFinished?.()
    } catch (err) {
      handleAndToastError(err)
    }
  })

  useRunOnceReady(true, () => {
    dropdownProps.onOpen()
  })
  const secretCommand = secretCommands.map((s) => s.name).includes(search)
    ? availableCommands.find((c) => c.name === search)
    : undefined

  const handleSubmit = () => {
    if (!selectedCommand && !secretCommand) throw new MessageException('no_command_selected')
    if (secretCommand) execute(secretCommand)
    else if (selectedCommand) execute(selectedCommand.command)
  }

  const { keyboardSelectedId, deselect, dropdownProps } = useKeyboardSelectionLogic(
    commands.map((comm) => ({
      uuid: comm.command.name,
      select: () => {
        execute(comm.command)
      },
    })),
    handleSubmit
  )

  const selectedCommand = keyboardSelectedId
    ? commands.find((comm) => comm.command.name === keyboardSelectedId)
    : commands[0]

  useRunOnlyOnChange(!!selectedCommand, () => {
    if (!selectedCommand) {
      deselect()
    }
  })

  const hiddenCommandCount = availableCommands.length - commands.length

  return (
    <SDimmedBackground>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        {isExecuting ? (
          <SSpinner>
            <BlockSpinner />
          </SSpinner>
        ) : (
          <>
            <SSearchInput autoFocus {...form.register('search')} autoComplete="off" />
            <Stack gap={'1rem'}>
              {commands.slice(0, 6).map((command) => {
                const isSelected = command.command.name === selectedCommand?.command.name
                const name = command.command.name
                const parts = [
                  name.slice(0, command.from),
                  name.slice(command.from, command.to),
                  name.slice(command.to),
                ]
                return (
                  <SCommandItem key={name} highlight={isSelected}>
                    {isSelected && (
                      <div
                        css={{
                          color: colors.blue[500],
                          position: 'absolute',
                          right: 8,
                          top: 8,
                        }}
                      >
                        selected
                      </div>
                    )}

                    {parts.map((part, i) => {
                      return (
                        <span
                          key={i}
                          css={{
                            color: i === 1 ? colors.blue[500] : undefined,
                          }}
                        >
                          {part}
                        </span>
                      )
                    })}
                  </SCommandItem>
                )
              })}
              {hiddenCommandCount > 0 && (
                <SCommandItem css={{ color: colors.gray[500] }}>
                  + {hiddenCommandCount} hidden
                </SCommandItem>
              )}
            </Stack>
          </>
        )}
      </form>
    </SDimmedBackground>
  )
}
