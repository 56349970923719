import { colors } from 'utils/colors'
import { ButtonTone } from './types'

export const toneColors: Record<ButtonTone, string> = {
  standard: colors.blue[500],
  accent: colors.orange[200],
  danger: colors.red[500],
  dark: colors.gray[100],
  success: colors.green[200],
  gray: colors.gray[500],
  trust: colors.trust.primary,
}
