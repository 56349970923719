import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Block } from 'components/Block'
import { CornerstoneTheme } from 'components/cornerstone/CornerstoneUtils'
import { Title } from 'components/Typography'
import { BREAKPOINTS, pxToRem } from 'utils/styled'
import { HorizontalPlacement } from 'utils/types'

export const SBlock = styled(Block)<{ bordered?: boolean }>`
  ${(p) =>
    p.bordered
      ? css`
          // prevents the detached border to overflow the page horizontally
          // while showing it outside the container vertically
          // 3rem should be enough, but if it's not, feel free to increase the amount
          --block-vertical-overflow-space: 3rem;
          margin-top: calc(var(--block-vertical-overflow-space) * -1);
          padding-top: var(--block-vertical-overflow-space);
          overflow: hidden;
        `
      : ''}
`

export const SThemedTextImageSection = styled.section<{
  overflowingImage?: boolean
  imagePlacement?: HorizontalPlacement
  customTheme?: CornerstoneTheme
}>`
  display: grid;
  grid-column-gap: ${pxToRem(120)}rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'image .'
    'image title'
    'image content'
    'image .';

  ${({ imagePlacement }) =>
    imagePlacement === 'right' &&
    css`
      grid-template-areas:
        '.       image'
        'title   image'
        'content image'
        '.       image';
    `}

  align-items: center;

  ${({ overflowingImage, customTheme }) => {
    if (overflowingImage) {
      return css`
        padding: 0;
      `
    } else if (customTheme) {
      return css`
        padding: 3rem;
        background-color: ${customTheme.light};
      `
    } else {
      return null
    }
  }}

  @media ${BREAKPOINTS.MD.max} {
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'image'
      'content';

    ${({ overflowingImage }) => {
      if (overflowingImage) {
        return css`
          grid-column-start: center;
          grid-column-end: right;
        `
      } else {
        return null
      }
    }}

    ${({ customTheme }) => {
      if (customTheme) {
        return css`
          padding-right: 1rem;
          padding-left: 1rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          grid-column-start: left;
          grid-column-end: right;
        `
      } else {
        return null
      }
    }}
  }
`

export const SImageContainer = styled.div<{
  imagePlacement?: HorizontalPlacement
  bordered?: boolean
}>`
  position: relative;
  width: 100%;
  grid-area: image;

  & img,
  picture {
    width: 100%;
  }

  ${({ bordered }) =>
    bordered &&
    css`
      &:before {
        content: '';
        position: absolute;
        border: 2px solid var(--c-blue100);
        top: -5%;
        bottom: 20%;
        left: 10%;
        right: -30%;
      }
    `}
`

export const STitle = styled(Title)`
  white-space: pre-line;
`

export const STitleContainer = styled.div<{ overflowingImage?: boolean }>`
  grid-area: title;

  ${({ overflowingImage }) =>
    overflowingImage &&
    css`
      padding-right: 1rem;
    `}

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${BREAKPOINTS.MD.min} {
    margin-bottom: 1.5rem;
  }
`

export const SDetails = styled.div<{
  disableContentGap?: boolean
  overflowingImage?: boolean
  imagePlacement?: 'left' | 'right'
}>`
  display: flex;
  flex-direction: column;
  align-self: start;
  grid-area: content;

  & button {
    width: min-content;
  }

  & > * {
    margin: 0;
  }

  ${({ disableContentGap }) =>
    !disableContentGap &&
    css`
      & > * {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    `}

  @media ${BREAKPOINTS.MD.max} {
    ${({ overflowingImage }) =>
      overflowingImage
        ? css`
            & button {
              width: unset;
            }
            padding-right: 1rem;
          `
        : null}
  }
`
