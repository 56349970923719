import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { useSessionValue } from './sessionStorage'

const PREVIEW_KEY = 'preview_status'

export const usePreviewLogic = () => {
  const router = useRouter()

  //keep preview mode variable on sessionStorage, and update it by "preview" query param
  useEffect(() => {
    const storedVal = sessionStorage.getItem(PREVIEW_KEY)
    const queryVal = router.query.preview

    const shouldOpenPreviewMode = queryVal === 'on' && storedVal !== 'on'
    const shouldClosePreviewMode = queryVal === 'off' && storedVal === 'on'

    if (shouldOpenPreviewMode) {
      sessionStorage.setItem(PREVIEW_KEY, 'on')
      router.reload()
    }

    if (shouldClosePreviewMode) {
      sessionStorage.removeItem(PREVIEW_KEY)
      router.reload()
    }
  }, [router])

  // show preview related things (like close preview button) on screen if "isPreviewing"
  const { data } = useSessionValue('preview_status')
  const isPreviewing = useMemo(
    () => data === 'on' && process.env.NEXT_PUBLIC_ENV !== 'production',
    [data]
  )
  return isPreviewing
}
