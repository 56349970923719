import { getUserMe } from 'api/goodtrust/user'
import { PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { AnalyticsEvent } from 'utils/analytics/event'
import { isUserLoggedIn } from 'utils/auth/authFetcher'
import { unwrapResponse } from 'utils/fetcher'

/**
 * This event is fired when a user selects a plan.
 */
export const plan_selected = new AnalyticsEvent<{ isSigningUp: boolean; planType: PlanType }>({
  async onFire({ args: { planType, isSigningUp }, ...ctx }) {
    const userMe = await isUserLoggedIn().then((loggedIn) =>
      loggedIn ? getUserMe().then(unwrapResponse.body) : undefined
    )

    const spec = planSpecs[planType]

    const plan = spec.isFree ? 'free_downgrade' : spec.analyticsPlanType ?? 'unknown'

    ctx.fireEvent('plan_selected', {
      plan,
      upgrade_type:
        isSigningUp || !userMe
          ? 'signup'
          : describeMyPlans(userMe).chooseUpgradeMethodTo(planType) === 'upgrade'
          ? 'upgrade'
          : 'new',
      flow: ctx.state.flow ?? 'else',
    })

    if (isSigningUp) {
      ctx.fireEvent('signup_plan_selected', {
        plan_type: plan,
        page: ctx.state.page ?? 'unknown',
        button: ctx.state.button ?? 'unknown',
      })
    }
  },
})
