import styled from '@emotion/styled'

export const SPricingUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`
