import { DirectiveLPSection } from 'components/directive/directiveLPSection/DirectiveLPSection'
import { IconName } from 'components/icon'
import { TranslatableLink } from 'components/TranslatableLink'
import { Trans, useTranslation } from 'next-i18next'
import { numberRange } from 'utils/general'
import { NumberRange } from 'utils/types'

const extraParagraphIcons: Record<NumberRange<2>, IconName> = {
  '1': 'PowerOfAttorney',
  '2': 'ShieldCheck',
}

export const POALPSections = () => {
  const { t } = useTranslation('poa_lp')

  return (
    <>
      <DirectiveLPSection
        type="POWER_OF_ATTORNEY"
        title={t('poa_lp.section_legal.title')}
        description={t('poa_lp.section_legal.description')}
        imageSlug="poa_lp_section_legal"
      />

      <DirectiveLPSection
        type="POWER_OF_ATTORNEY"
        imagePlacement="right"
        title={t('poa_lp.section_easy.title')}
        description={t('poa_lp.section_easy.description')}
        imageSlug="poa_lp_section_easy"
      />

      <DirectiveLPSection
        type="POWER_OF_ATTORNEY"
        title={t('poa_lp.section_digital_vault.title')}
        description={
          <Trans
            t={t}
            i18nKey="poa_lp.section_digital_vault.description"
            components={{
              uploadWill: <TranslatableLink href="/will" />,
              poa: <TranslatableLink href="/financial-power-of-attorney" />,
              ahcd: (
                <TranslatableLink href="/articles/advance-directive-what-it-is-and-how-to-create-one" />
              ),
              digitalLegacy: <TranslatableLink href="/articles/what-is-digital-legacy" />,
            }}
          />
        }
        imageSlug="poa_lp_section_digital_vault"
      />

      <DirectiveLPSection
        type="POWER_OF_ATTORNEY"
        imagePlacement="right"
        title={t('poa_lp.section_reason.title')}
        description={t('poa_lp.section_reason.description')}
        extraParagraphs={numberRange(2).map((i) => ({
          title: t(`poa_lp.section_reason.paragraph_${i}.title`),
          description: t(`poa_lp.section_reason.paragraph_${i}.description`),
          icon: extraParagraphIcons[i],
        }))}
        imageSlug="poa_lp_section_reason"
        hideButton
      />

      <DirectiveLPSection
        type="POWER_OF_ATTORNEY"
        title={t('poa_lp.section_select_right.title')}
        description={
          <>
            {t('poa_lp.section_select_right.description')}
            <ul>
              {numberRange(5).map((i) => (
                <li key={i}>{t(`poa_lp.section_select_right.item_${i}`)}</li>
              ))}
            </ul>
            {t('poa_lp.section_select_right.last_paragraph')}
          </>
        }
        imageSlug="poa_lp_section_select_right"
        hideButton
      />
    </>
  )
}
