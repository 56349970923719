import { logError, logWarn } from 'utils/general'
import useSWR, { mutate } from 'swr'

export const SESSION_CONST_TRUE = 'TRUE'
export const SESSION_NEW_USER_CREATED = 'new_user_created'

export const SESSION_REFERRAL_MEMORY_UUID = 'referral_memory_uuid'

export function setSessionValue(key: string, value: string) {
  if (typeof Storage !== 'undefined') {
    sessionStorage.setItem(key, value)
    mutateSessionValue(key)
  } else {
    logError('SessionStorage is not defined for writing!')
  }
}

export function getSessionValue(key: string) {
  if (typeof Storage !== 'undefined') {
    return sessionStorage.getItem(key)
  } else {
    logWarn('SessionStorage is not defined for reading!')
    return null
  }
}

export function clearSessionValue(key: string) {
  if (typeof Storage !== 'undefined') {
    sessionStorage.removeItem(key)
    mutateSessionValue(key)
    return true
  } else {
    logError('SessionStorage is not defined for clearing!')
    return false
  }
}

function createSessionFetcher(key: string) {
  return () => {
    const value = sessionStorage.getItem(key)
    return value ?? null
  }
}

export async function mutateSessionValue(key: string) {
  return mutate(`@session-storage-${key}`)
}

export function useSessionValue(key: string) {
  return useSWR(`@session-storage-${key}`, createSessionFetcher(key), {
    revalidateOnMount: true,
    fallbackData: undefined,
  })
}
