import { SLogoWrapper, SOfferDivider, STitle } from './OfferModal.styled'
import { Image } from 'components/Image'
import { Text } from 'components/Typography'
import { isNonNullable } from 'utils/general'
import { Spacer } from 'components/spacer/Spacer'
import { Icon } from 'components/icon'
import { ExtendedPromotion } from 'utils/promo'
import { HStack, Stack } from 'components/Stack'
import { colors } from 'utils/colors'
import { useTranslation } from 'next-i18next'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { FC } from 'react'

export const OfferModalHeader: FC<
  React.PropsWithChildren<{ promo: ExtendedPromotion; warningText?: string; aol?: boolean }>
> = ({ promo, warningText, aol }) => {
  useDynamicTranslations('pricing_plan')
  const { t } = useTranslation('pricing_plan')

  const partner = promo.promo_code_info?.partner
  return (
    <Stack css={{ alignItems: 'center', width: '100%', paddingTop: '2rem' }} gap={'0'}>
      {partner ? (
        <SLogoWrapper>
          <HStack css={{ alignItems: 'center' }}>
            <Icon name="ColorGoodTrust" size="3.75rem" />

            <Icon name="Plus" color={colors.gray[500]} />

            <Image src={partner.partner_photo?.url} css={{ height: 60 }} />
          </HStack>
        </SLogoWrapper>
      ) : (
        <SLogoWrapper>
          <Icon name="ColorGoodTrust" size="3.75rem" />
        </SLogoWrapper>
      )}

      <STitle>
        {aol
          ? t('pricing_plan.offer.title_aol')
          : t('pricing_plan.offer.title', {
              who: ['GoodTrust', partner?.title].filter(isNonNullable).join(' & '),
            }).replace('&amp;', '&')}
      </STitle>

      {warningText && (
        <>
          <Spacer size={0.5} />

          <HStack gap={'0.5rem'}>
            <Icon name="Warning" color={colors.red[500]} size={16} />
            <Text variant="body">{warningText}</Text>
          </HStack>
        </>
      )}

      <SOfferDivider css={{ margin: '1.5rem 0' }} />
    </Stack>
  )
}
