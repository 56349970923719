import { getDirectiveSpec } from 'components/directive/utils/directiveSpecs'
import { DirectiveType } from 'components/directive/utils/types'

interface PatternConfig {
  pattern: string
  exceptionPatterns?: string[]
}

const getDirectivePublicPatterns = (type: DirectiveType): string[] => {
  const { insidePath } = getDirectiveSpec(type)
  return [`${insidePath}/[id]/shared`, `${insidePath}/tutorial`, `${insidePath}/outside`]
}

const privatePatternConfigs: PatternConfig[] = [
  {
    pattern: '/goodtrust-life/',
    exceptionPatterns: ['/goodtrust-life/about-us', '/goodtrust-life/signup'],
  },
  {
    pattern: '/me/',
    exceptionPatterns: [
      '/me/cancel-death',
      '/me/future-message/',
      ...getDirectivePublicPatterns('FUNERAL'),
      ...getDirectivePublicPatterns('MEDICAL'),
      ...getDirectivePublicPatterns('PET'),
      ...getDirectivePublicPatterns('POWER_OF_ATTORNEY'),
      ...getDirectivePublicPatterns('WILL'),
      ...getDirectivePublicPatterns('TRUST'),
    ],
  },
  { pattern: '/memories/', exceptionPatterns: ['/memories/shared/[uuid]'] },
  { pattern: '/order/[orderId]/profile' },
  { pattern: '/signup/', exceptionPatterns: ['/signup/auto'] },
  { pattern: '/singing-portraits/', exceptionPatterns: ['/singing-portraits/shared/[uuid]'] },
  { pattern: '/story-portraits/[memoryId]' },
  { pattern: '/store/' },
  { pattern: '/subscription/' },
]

export const isRoutePrivate = (pathname: string) => {
  const config = privatePatternConfigs.find((p) => pathname.startsWith(p.pattern))

  if (!config) return false

  const { exceptionPatterns } = config

  const isException = exceptionPatterns?.some((pattern) => pathname.startsWith(pattern))

  return !isException
}
