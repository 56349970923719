import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { Spinner } from 'components/spinner/Spinner'
import Link from 'next/link'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { colors } from 'utils/colors'
import { SButton, SLeftNode, SLoadingContainer } from './Button.styled'
import { toneColors } from './toneColors'
import { ButtonProps } from './types'

const _Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (props, ref) => {
  const {
    as,
    size = 'medium',
    variant = 'primary',
    tone = 'standard',
    leftIcon,
    loading = false,
    children,
    disabled,
    customColor,
    block,
    renderAs,
    renderAsButton,
    active,
    stickyIcon,
    type,
    onClick,
    blurAfterClick,
    fitContentVertically,
    target,
    ...rest
  } = props

  const href = !props.disabled ? props.href : undefined

  const hasLeftIcon = !!leftIcon

  const mainColor = customColor || toneColors[tone]

  const shouldRenderAsAnchor = href && !renderAsButton
  const renderButtonAs = renderAs || (shouldRenderAsAnchor ? 'a' : 'button')

  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={(c) => (
        <Link href={href || ''} as={as} passHref legacyBehavior target={target}>
          {c}
        </Link>
      )}
    >
      <SButton
        disabled={disabled || loading}
        shouldLookDisabled={disabled}
        data-active={active} //instead of prop, used html data attribute, to use css selectors in .styled
        loading={loading}
        borderColor={
          variant === 'secondary' && tone === 'dark' ? colors.gray.greenishPale : undefined
        }
        hasLeftIcon={hasLeftIcon}
        ref={ref}
        size={size}
        variant={variant}
        mainColor={mainColor}
        block={block}
        as={renderButtonAs}
        type={shouldRenderAsAnchor ? undefined : type}
        fitContentVertically={fitContentVertically}
        onClick={(e) => {
          onClick?.(e)
          if (blurAfterClick && e.target instanceof HTMLElement) {
            // @ts-ignore
            e.target.closest(renderButtonAs)?.blur()
          }
        }}
        {...rest}
      >
        {hasLeftIcon && (
          <SLeftNode stickyIcon={stickyIcon} hasText={!!children}>
            {leftIcon}
          </SLeftNode>
        )}

        <span>{children}</span>

        {loading && (
          <SLoadingContainer hasLeftIcon={hasLeftIcon}>
            <Spinner />
          </SLoadingContainer>
        )}
      </SButton>
    </ConditionalWrapper>
  )
}

export const Button = forwardRef(_Button)
