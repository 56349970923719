import { Dropdown } from 'components/dropdown/Dropdown'
import { SelectCountry, SelectCountryPicker } from 'components/selectCountry/SelectCountry'
import { ReactElement, useCallback, useEffect, useRef } from 'react'
import { CountrySelectComponentProps } from 'react-phone-number-input'

const COUNTRY_INTL = 'ZZ'

export const CountrySelectComponent = (
  props: CountrySelectComponentProps & {
    iconComponent?: ({ country }: { country: string }) => ReactElement
    fieldset: HTMLFieldSetElement | null
  }
) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  const onChange = props.onChange
  const handleChange = useCallback(
    (value: string | undefined) => {
      onChange?.(value === COUNTRY_INTL ? undefined : value)
    },
    [onChange]
  )
  const flag = props.value ? props.iconComponent?.({ country: props.value }) : undefined

  useEffect(() => {
    // Without this timeout, we get null instead of elements on first render.
    // We need to be able to trigger the scroll on after first render
    setTimeout(() => {
      const wrapperEl = dropdownRef.current?.querySelector('[data-dropdown-wrapper]')
      const selectedEl = dropdownRef.current?.querySelector(
        `[data-country=${props.value}]`
      ) as HTMLElement

      const selectedElOffsetTop = selectedEl?.offsetTop

      wrapperEl?.scrollTo({ top: selectedElOffsetTop })
    }, 0)
  }, [props.value])

  return (
    <Dropdown
      ref={dropdownRef}
      referenceElement={props.fieldset}
      trigger={<SelectCountryPicker flag={flag} />}
      disabled={props.disabled}
    >
      <SelectCountry options={props.options} onChange={handleChange} />
    </Dropdown>
  )
}
