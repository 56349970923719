import { DevtoolCommandPicker } from 'utils/devtools/DevtoolCommandPicker'
import { useImmerState, useKeyboardCombination } from 'utils/hooks'

export function useDevtools() {
  const isEnabled = process.env.NEXT_PUBLIC_ENV === 'devel'
  const [{ active }, update] = useImmerState({ active: false })

  useKeyboardCombination(isEnabled, 'KeyX', 'altKey', () => {
    update((st) => {
      st.active = !st.active
    })
  })

  return {
    jsx: active ? (
      <DevtoolCommandPicker
        onCommandFinished={() => {
          update((st) => {
            st.active = false
          })
        }}
      />
    ) : null,
  }
}
