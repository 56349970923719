import { Icon, IconName } from 'components/icon'
import { Text } from 'components/Typography'
import { ReactNode } from 'react'
import { STitleRow } from './LandingPageSectionExtraParagraph.styled'

export interface LandingPageSectionExtraParagraphProps {
  icon?: IconName
  title: string
  description: ReactNode
}

export const LandingPageSectionExtraParagraph = (props: LandingPageSectionExtraParagraphProps) => (
  <div>
    <STitleRow>
      {props.icon && <Icon name={props.icon} />}
      <Text variant="bodyMediumBold" color="black">
        {props.title}
      </Text>
    </STitleRow>

    <Text>{props.description}</Text>
  </div>
)
