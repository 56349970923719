import { useInfoModal } from 'components/modal/infoModal/InfoModal'
import { colors } from 'utils/colors'
import { css } from '@emotion/react'
import { Icon } from 'components/icon'

export const CheckboxInfo = (props: { info: { desc: string; title: string } }) => {
  const [modal, openModal] = useInfoModal({
    infoTitle: props.info.title,
    infoDesc: props.info.desc,
  })
  return (
    <>
      {modal}
      <Icon
        onClick={(e) => {
          // prevent default is needed to prevent toggling of the checkbox on click
          // which is the default behaviour, as CheckboxInfo is used as a child of a label
          e.preventDefault()
          openModal()
        }}
        name="Question"
        size="1rem"
        color={colors.gray[500]}
        css={css`
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        `}
      />
    </>
  )
}
