import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ButtonSize,
  ButtonVariant,
  LeftNodeProps,
  LoadingContainerProps,
  StyledButtonProps,
} from './types'
import { darken, lighten, tint } from 'polished'
import { getResponsiveStyles, mapResponsiveValue } from 'utils/styled'
import isPropValid from '@emotion/is-prop-valid'
import { colors } from 'utils/colors'

export const SButton = styled('button', {
  shouldForwardProp: (prop) => isPropValid(prop as string) && prop !== 'loading',
})<StyledButtonProps>(
  ({
    size,
    variant,
    mainColor,
    borderColor,
    loading,
    hasLeftIcon,
    block,
    shouldLookDisabled,
    fitContentVertically,
  }) => {
    const shouldHideIcon = loading && hasLeftIcon
    const shouldHideText = loading && (!hasLeftIcon || (variant === 'text' && size !== 'large'))

    return css`
      --color-text: #ffffff;
      --color-disabled-text: #a3aec1;
      --color-disabled-bg: #e9ebef;

      --color-normal: none;
      --color-50: none;
      --color-100: none;
      --color-border: none;
      --color-dark: none;
      --color-shine: none;

      --height: none;
      --horizontal-padding: none;
      --left-node-gap: none;

      padding: 0 var(--horizontal-padding);

      cursor: pointer;
      height: var(--height);
      min-width: var(--height);
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      position: relative;

      font-family: var(--f-text);
      font-style: normal;
      font-weight: bold;
      text-decoration: unset;
      white-space: nowrap;

      outline: none;

      border-width: 0;
      border-style: solid;
      border-color: var(--color-border);
      border-radius: 0.125rem;

      transition: background-color 0.3s ease;

      ${getColorStyles(mainColor, borderColor)}

      ${getResponsiveStyles(size, sizeStyles)}

    ${variantStyles[variant]}

    ${getResponsiveStyles(size, variantSizeStyles[variant])}

    ${shouldHideIcon &&
      css`
        & svg {
          visibility: hidden;
        }
      `}
    
    ${shouldHideText &&
      css`
        & > span {
          visibility: hidden;
        }
      `}
    
    &:disabled {
        cursor: not-allowed;
      }

      ${fitContentVertically && { height: 'auto' }}

      ${shouldLookDisabled &&
      css`
        ${variant !== 'text' && 'background: var(--color-disabled-bg) !important;'}

        border-color: var(--color-disabled-bg) !important;
        color: var(--color-disabled-text) !important;

        ${SLeftNode} {
          color: var(--color-disabled-text) !important;
          ${size === 'large' && 'background: var(--color-disabled-bg) !important;'}
        }
      `}

    ${getResponsiveStyles(
        mapResponsiveValue(block, (block) => (block ? 'true' : 'false')),
        {
          true: css`
            width: 100%;
          `,
          false: css``,
        }
      )}
    `
  }
)

export const SLeftNode = styled.div<LeftNodeProps>(
  ({ hasText, stickyIcon }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    height: var(--height);
    width: 1.5rem;

    margin-right: ${hasText ? 'var(--left-node-gap)' : 0};

    & svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    ${getResponsiveStyles(
      mapResponsiveValue(stickyIcon, (stickyIcon) => (stickyIcon ? 'true' : 'false')),
      {
        true: css`
          position: absolute;
          left: var(--horizontal-padding);
        `,
        false: css``,
      }
    )}
  `
)

export const SLoadingContainer = styled.div<LoadingContainerProps>(
  ({ hasLeftIcon }) => css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    ${hasLeftIcon &&
    css`
      left: calc(var(--horizontal-padding) / 3);
      right: unset;
    `}

    & > * {
      &:before {
        left: -0.6rem !important;
      }

      &:after {
        left: 0.6rem !important;
      }
    }
  `
)

const getColorStyles = (mainColor: string, borderColor?: string): SerializedStyles => {
  const color50 = tint(0.975, mainColor)
  const color100 = tint(0.9, mainColor)
  const colorBorder = borderColor ?? tint(0.8, mainColor)
  const colorShine = lighten(0.05, mainColor)
  const colorDark = darken(0.04, mainColor)

  return css`
    --color-50: ${color50};
    --color-100: ${color100};
    --color-border: ${colorBorder};
    --color-shine: ${colorShine};
    --color-normal: ${mainColor};
    --color-dark: ${colorDark};
  `
}

const sizeStyles: Record<ButtonSize, SerializedStyles> = {
  large: css`
    --height: 3.375rem;
    --left-node-gap: 0.75rem;
    --horizontal-padding: 1.75rem;
    font-size: 1rem;
  `,
  medium: css`
    --height: 2.75rem;
    --left-node-gap: 0.625rem;
    --horizontal-padding: 1.25rem;
    font-size: 1rem;
  `,
  small: css`
    --height: 2rem;
    --left-node-gap: 0.5rem;
    --horizontal-padding: 0.75rem;
    font-size: 0.8125rem;
  `,
}

const variantSizeStyles: Record<ButtonVariant, Record<ButtonSize, SerializedStyles>> = {
  primary: { large: css``, medium: css``, small: css`` },
  secondary: { large: css``, medium: css``, small: css`` },
  underline: { large: css``, medium: css``, small: css`` },
  round: {
    large: css`
      --height: 2.75rem;
    `,
    medium: css`
      --height: 2.375rem;
    `,
    small: css``,
  },
  text: {
    large: css`
      --left-node-gap: 1rem;
      --horizontal-padding: 1.25rem;

      ${SLoadingContainer} {
        color: var(--color-text);
      }

      ${SLeftNode} {
        background: var(--color-normal);
        width: var(--height);
        color: var(--color-text);

        margin-left: calc(-1 * var(--horizontal-padding));
      }
    `,
    medium: css`
      --height: 2.375rem;
      --horizontal-padding: 0;
    `,
    small: css`
      --horizontal-padding: 0;
    `,
  },
}

const secondaryVariantStyle = css`
  background: var(--color-text);
  color: var(--color-normal);
  border-width: 0.0625rem;

  &:hover {
    background: var(--color-50);
  }

  &:active,
  :focus,
  :disabled {
    background: var(--color-100);
  }

  &[data-active='true'] {
    background: var(--color-100);
  }
`

const variantStyles: Record<ButtonVariant, SerializedStyles> = {
  primary: css`
    background: var(--color-normal);
    color: var(--color-text);

    &:hover {
      background: var(--color-shine);
    }

    &:active,
    :focus,
    :disabled {
      background: var(--color-dark);
    }

    &[data-active='true'] {
      background: var(--color-dark);
    }
  `,
  secondary: secondaryVariantStyle,
  round: css`
    ${secondaryVariantStyle}
    border-radius: calc(var(--height) / 2);
  `,
  text: css`
    background: transparent;
    color: var(--color-normal);
    text-align: start;

    &:not(:disabled) {
      &:hover,
      :active,
      :focus {
        text-decoration: underline;
      }
    }

    &[data-active='true'] {
      text-decoration: underline;
    }
  `,
  underline: css`
    background: transparent;
    color: ${colors.gray[500]};
    font-weight: 400;
    text-align: start;
    text-decoration: underline;
  `,
}
