import { authFetcher } from 'utils/auth/authFetcher'
import { cache } from 'utils/auth/cache'
import { FetchArgs, FetchBlobResponse, FetchCall, FetchResponse } from 'utils/fetcher'
import { logWarn } from 'utils/general'

export function wrapAuthFetch(apiFetch: FetchCall) {
  async function fetchCall(
    path: string,
    args: FetchArgs & {
      noThrowOnError?: boolean
      toBlob: true
    }
  ): Promise<FetchBlobResponse>

  async function fetchCall<T = { [key: string]: string }>(
    path: string,
    args?: FetchArgs & {
      noThrowOnError?: boolean
      toBlob?: false
    }
  ): Promise<FetchResponse<T>>

  async function fetchCall<T = { [key: string]: string } | Blob>(
    path: string,
    fetchArgs?: FetchArgs & { noThrowOnError?: boolean }
  ): Promise<FetchResponse<T> | FetchBlobResponse> {
    const auth = await authFetcher()

    if (!auth.isLogged || !auth.access_token) {
      // IMPROVE: this is mostly a stopgap solution to proper error handling
      // ideally, if ok !== true, throw an Error
      // we can't currently do that, as that would require checking every
      // single file
      if (fetchArgs?.noThrowOnError) {
        logWarn('Deprecated behavior of muting auth error, please allow try catch')
        return { ok: false, status: 401 }
      }
      throw Error('Not authenticated')
    }

    const accessToken = auth.access_token
    if (fetchArgs?.toBlob) {
      return apiFetch(path, accessToken, {
        ...fetchArgs,
        toBlob: true,
      })
    }

    return apiFetch(path, accessToken, {
      ...fetchArgs,
      toBlob: false,
    }).then(async (res) => {
      if (res.status === 401) {
        // 401 means Unauthorized, which could be caused by
        // the user's token being invalidated by some reason
        // for example when the user changes their email address in another browser
        // while being logged in in this browser window as well
        // see GT-2599 for more detail on what this piece of code solves
        cache.clear()
      }
      return res
    })
  }

  return fetchCall as FetchCall
}
