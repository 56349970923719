import Link, { LinkProps } from 'next/link'
import { AnchorHTMLAttributes, FC } from 'react'
import { BasicLink, PrimaryLink, SecondaryLink } from './Link'

export type LinkVariant = 'primary' | 'secondary' | 'basic'

const LinkComponents: Record<LinkVariant, FC<React.PropsWithChildren<unknown>>> = {
  basic: BasicLink,
  primary: PrimaryLink,
  secondary: SecondaryLink,
}

interface TranslatableLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  href: LinkProps['href']
  as?: LinkProps['as']
  variant?: LinkVariant
}

export const TranslatableLink = ({
  children,
  href,
  as,
  variant = 'primary',
  ...rest
}: TranslatableLinkProps) => {
  const LinkComponent = LinkComponents[variant]

  return (
    <Link href={href} as={as} passHref legacyBehavior>
      <LinkComponent {...rest}>{children}</LinkComponent>
    </Link>
  )
}
