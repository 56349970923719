import { ClassNames, css } from '@emotion/react'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { CallbackFieldset, Fieldset } from 'components/fieldset/Fieldset'
import { FormFieldset } from 'components/FormButton'
import { Select } from 'components/select/Select'
import { Spacer } from 'components/spacer/Spacer'
import { CheckoutFields } from 'components/subscription/checkout/utils/useCheckoutFields'
import { Caption } from 'components/Typography'
import { ICardType, WizardCardType } from 'components/wizard/wizardCardType/WizardCardType'
import { ZipElement } from 'components/wizard/wizardFormCardElement/ZipElement'
import { mapCountryToZipRegexp } from 'logic/validation/zip'
import { i18n, useTranslation } from 'next-i18next'
import { Fragment, ReactNode } from 'react'
import { Controller, get, UseFormReturn } from 'react-hook-form'
import {
  COUNTRY_OPTIONS_ISO3166_1_ALPHA2,
  COUNTRY_OPTIONS_ISO3166_1_ALPHA2_ES,
} from 'utils/constants'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'
import { TFunction } from 'react-i18next'

// this type is pulled from react-hook-form, which includes any
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type IFormType = {
  card_number?: {
    brand?: ICardType
  }
  country?: string
  first_name?: string
  last_name?: string
  address_zip?: string
  card_expiry?: string
  card_cvc?: string
  default?: boolean
}

interface IStripeChangeCallback {
  empty: boolean
  error: undefined | { message: string }
}

const getCssVariable = (variable: string) => {
  if (process.browser) {
    return getComputedStyle(document.body).getPropertyValue(variable)
  }
  return undefined
}

export const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Lato&amp;display=swap',
    },
  ],
}

const STRIPE_OPTIONS = {
  classes: {
    empty: 'empty',
    base: 'stripe',
    focus: 'focus',
  },
  style: {
    base: {
      fontFamily: getCssVariable('--f-text'),
      color: getCssVariable('--c-gray100'),
      fontSize: '16px',
    },
    invalid: {
      color: getCssVariable('--c-gray100'),
    },
  },
}

const stripeValidate =
  (t: TFunction<'common'>) =>
  (value?: IStripeChangeCallback | IFormType['card_number'] | string) => {
    if (!value || (typeof value === 'object' && 'empty' in value && value.empty)) {
      return t('common.form.required_field')
    }

    if (typeof value === 'object' && 'error' in value && value.error?.message) {
      return value.error.message
    }

    return true
  }

export function CheckoutContactInformationFields({
  fields,
  form,
}: {
  form: UseFormReturn<{
    country?: string
    first_name?: string
    last_name?: string
    address_zip?: string
  }>
  fields?: CheckoutFields
}) {
  const { t } = useTranslation('pricing_plan')
  const validation = useFieldValidation()
  const country = form.watch('country')
  const addressZipPattern = mapCountryToZipRegexp(country)
  return (
    <>
      {fields?.name && (
        <Fragment>
          <FormFieldset form={form}>
            <Fieldset>
              <input
                {...form.register('first_name', {
                  ...validation.required(),
                  ...validation.atLeastNChars(2),
                })}
                defaultValue=""
                type="text"
                placeholder="&nbsp;"
              />
              <label>{t('pricing_plan.checkout_page.form.first_name')}</label>
            </Fieldset>
          </FormFieldset>
          <FormFieldset form={form}>
            <Fieldset>
              <input
                {...form.register('last_name', {
                  ...validation.required(),
                  ...validation.atLeastNChars(2),
                })}
                defaultValue=""
                type="text"
                placeholder="&nbsp;"
              />
              <label>{t('pricing_plan.checkout_page.form.last_name')}</label>
            </Fieldset>
          </FormFieldset>
        </Fragment>
      )}
      {fields?.location && (
        <>
          <ZipElement
            addressZipPattern={addressZipPattern}
            form={form}
            zipElementPosition="contact"
          />
          <Controller
            name="country"
            rules={validation.required()}
            control={form.control}
            render={({ field }) => (
              <FormFieldset name={field.name} form={form}>
                <Select
                  {...field}
                  label={t('pricing_plan.checkout_page.form.country')}
                  items={
                    i18n?.language === 'es'
                      ? COUNTRY_OPTIONS_ISO3166_1_ALPHA2_ES
                      : COUNTRY_OPTIONS_ISO3166_1_ALPHA2
                  }
                  enableFiltering
                  onChange={(e) => {
                    form.setValue('country', e ?? undefined)
                  }}
                />
              </FormFieldset>
            )}
          />
        </>
      )}
    </>
  )
}

export function WizardFormCardElement({
  form,
  className,
  children,
  fields,
  hidePaymentInformation = false,
  addressZipPattern,
  walletFragment,
}: {
  form: UseFormReturn<IFormType>
  className?: string
  children?: ReactNode
  fields?: CheckoutFields
  hidePaymentInformation?: boolean
  addressZipPattern?: RegExp
  walletFragment?: ReactNode
}) {
  const { t: tCommon } = useTranslation()
  const { t } = useTranslation('pricing_plan')
  const showContactSection = !!(fields?.name || fields?.location)
  const showPaymentInformation = !hidePaymentInformation
  const zipElementPosition = fields?.location ? 'contact' : 'payment'

  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 9px;
        grid-row-gap: 10px;
      `}
    >
      {showContactSection && (
        <>
          <Caption css={{ gridColumn: '1 / 3' }}>
            {t('pricing_plan.checkout_page.contact_information_title')}
          </Caption>

          <CheckoutContactInformationFields {...{ form, fields }} />
        </>
      )}

      {walletFragment && (
        <div css={{ gridColumn: '1 / 3', paddingTop: 8 }}>
          {walletFragment}
          <Spacer size={1} />
        </div>
      )}

      {showPaymentInformation && (
        <>
          <Caption css={{ gridColumn: '1 / 3' }}>Payment Information</Caption>
          <ClassNames>
            {({ css }) => (
              <CallbackFieldset
                error={get(form.formState.errors, 'card_number', null)?.message}
                wrapperClassName={css`
                  grid-column: 1/3;
                `}
              >
                {(fieldset) => (
                  <Fragment>
                    <Controller
                      control={form.control}
                      name="card_number"
                      rules={{ validate: stripeValidate(tCommon) }}
                      render={({ field }) => (
                        <CardNumberElement
                          onBlur={fieldset.onBlur(field.onBlur)}
                          onFocus={fieldset.onFocus}
                          onChange={field.onChange}
                          options={STRIPE_OPTIONS}
                        />
                      )}
                    />
                    <label>Card number</label>
                    <WizardCardType cardType={form.watch('card_number')?.brand ?? 'unknown'} />
                  </Fragment>
                )}
              </CallbackFieldset>
            )}
          </ClassNames>

          <CallbackFieldset error={get(form.formState.errors, 'card_expiry', null)?.message}>
            {(fieldset) => (
              <Fragment>
                <Controller
                  control={form.control}
                  name="card_expiry"
                  defaultValue=""
                  rules={{ validate: stripeValidate(tCommon) }}
                  render={({ field }) => (
                    <CardExpiryElement
                      onBlur={fieldset.onBlur(field.onBlur)}
                      onFocus={fieldset.onFocus}
                      onChange={field.onChange}
                      options={STRIPE_OPTIONS}
                    />
                  )}
                />
                <label>Expiration date</label>
              </Fragment>
            )}
          </CallbackFieldset>

          <CallbackFieldset error={get(form.formState.errors, 'card_cvc', null)?.message}>
            {(fieldset) => (
              <Fragment>
                <Controller
                  control={form.control}
                  name="card_cvc"
                  defaultValue=""
                  rules={{ validate: stripeValidate(tCommon) }}
                  render={({ field }) => (
                    <CardCvcElement
                      onBlur={fieldset.onBlur(field.onBlur)}
                      onFocus={fieldset.onFocus}
                      onChange={field.onChange}
                      options={STRIPE_OPTIONS}
                    />
                  )}
                />
                <label>CVV / CVC</label>
              </Fragment>
            )}
          </CallbackFieldset>

          {zipElementPosition === 'payment' && (
            <ZipElement
              addressZipPattern={addressZipPattern}
              form={form}
              zipElementPosition="payment"
            />
          )}
        </>
      )}

      {children}
    </div>
  )
}
