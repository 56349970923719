import styled from '@emotion/styled'
import { colors } from 'utils/colors'

export const SDimmedBackground = styled.div({
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.7)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '6rem',
  alignItems: 'start',
})

export const SSpinner = styled.div({
  borderRadius: 4,
  padding: '1rem 1.5rem',
  paddingRight: '3rem',
  backgroundColor: 'white',
})

export const SSearchInput = styled.input({
  padding: '2rem 1.5rem',
  background: 'white',
  border: 0,
  fontFamily: 'monospace',
  fontSize: 20,
  outline: 'none',
  borderRadius: 8,
  marginBottom: '2rem',
})

export const SCommandItem = styled.div<{ highlight?: boolean }>([
  {
    fontFamily: 'monospace',
    fontSize: '16',
    borderRadius: 4,
    padding: '1rem 1.5rem',
    backgroundColor: 'white',
    position: 'relative',
  },
  (p) => ({ borderBottom: p.highlight ? `4px solid ${colors.blue[500]}` : undefined }),
])
