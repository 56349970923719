import { format, intervalToDuration } from 'date-fns'
import { matchMap } from './general'
import i18n from 'i18next'
import { enUS, es } from 'date-fns/locale'

export function formatTimeDuration(secs: number) {
  const hr = Math.floor(secs / 3600)
  let min: number | string = Math.floor((secs - hr * 3600) / 60)
  let sec: number | string = Math.floor(secs - hr * 3600 - min * 60)

  if (hr > 0 && min < 10) {
    min = '0' + min
  }

  if (sec < 10) {
    sec = '0' + sec
  }

  if (hr <= 0) {
    return min + ':' + sec
  }

  return hr + ':' + min + ':' + sec
}

export function getDaysInMonth(month?: string | number, year?: string | number) {
  if (!month) return 31
  return new Date(year as number, month as number, 0).getDate()
}

export function determineAge(birthdate: Date) {
  return intervalToDuration({
    start: birthdate,
    end: new Date(),
  })
}

export function formatDate(
  date: Date | string,
  variant: Intl.DateTimeFormatOptions['dateStyle'] | 'api' = 'short'
) {
  const dateAsDate = typeof date === 'string' ? new Date(date) : date

  if (isNaN(dateAsDate.getTime())) {
    return undefined
  }

  let formattedDate
  try {
    if (variant === 'api') {
      throw Error('Use date-fns')
    }
    formattedDate = new Intl.DateTimeFormat(i18n.language === 'es' ? 'es' : 'en-US', {
      day: variant === 'short' ? '2-digit' : 'numeric',
      month: variant === 'short' ? '2-digit' : variant === 'medium' ? 'short' : 'long',
      year: 'numeric',
      weekday: variant === 'full' ? 'long' : undefined,
      timeZone: 'UTC',
    }).format(dateAsDate)
  } catch (e) {
    const dateFormat = matchMap(variant, {
      full: 'EEEE, MMMM d, yyyy',
      long: 'MMMM d, yyyy',
      medium: 'MMM d, yyyy',
      short: 'MM/dd/yyyy',
      api: 'yyyy-MM-dd',
    })
    formattedDate = format(dateAsDate, dateFormat, { locale: i18n.language === 'es' ? es : enUS })
  }

  return formattedDate
}

export function assumeUTCTime(dateString: string) {
  if (dateString.match(/\d$/)) {
    return dateString + 'Z'
  }
  return dateString
}
