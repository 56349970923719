import { FetchResponse } from 'utils/fetcher'
import { ErrorMessage, TranslationParameter } from 'components/Toast'

export class ShouldNeverHappenError extends Error {
  constructor() {
    super('Something went wrong.')
    this.name = 'ShouldNeverHappenError'
  }
}

export class RouteAbortException extends Error {
  constructor() {
    super('Route change aborted.')
    this.name = 'RouteAbortException'
  }
}

export class ExpectedAlwaysNonNullableError extends ShouldNeverHappenError {
  constructor() {
    super()
    this.name = 'ExpectedAlwaysNonNullableError '
  }
}

export class MessageException extends Error {
  key: ErrorMessage
  params: TranslationParameter
  constructor(key: ErrorMessage, params: TranslationParameter = {}) {
    super(key)
    this.name = 'MessageException'
    this.key = key
    this.params = params
  }
}

export class DirectiveValidationMessageException extends MessageException {}

export class ApiError<T> extends Error {
  readonly errorCode?: ErrorMessage
  constructor(public readonly res: FetchResponse<T>) {
    super(String(res.error ?? 'Something went wrong.'))
    this.name = 'ApiError'
    this.errorCode = res.errorCode
  }

  addDefaultMessage(message: string) {
    if (!this.res.error) {
      this.message = message
    }
  }
}

/**
 * Wraps a function call in a trycatch with a specified handler.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tryCatched<TParams extends any[], TReturn extends any>(
  cb: (...args: TParams) => TReturn,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handle: (err: any) => void
): (...args: TParams) => TReturn | undefined {
  return (...args) => {
    try {
      return cb(...args)
    } catch (err) {
      handle(err)
      return undefined
    }
  }
}

export function expectAlwaysNonNullable<T>(t: T | null | undefined): T {
  if (t == null) throw new ShouldNeverHappenError()
  return t
}
