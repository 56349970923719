import { useMyContact } from 'api/goodtrust/contact'
import { useUserDirectiveList } from 'api/goodtrust/user/directive'
import { useUserPerson } from 'api/goodtrust/user/person'
import { DIRECTIVE_ACTIONS, useLPActions } from 'components/actions/useLPActions'
import { Action } from 'components/actions/utils'
import { SBox, SRow, STitleRow } from 'components/directive/DirectiveReviewContentComponents'
import {
  TDirectiveStep,
  TDirectiveStepSubsection,
} from 'components/directive/directiveStepsNav/DirectiveStepsNav'
import { getDirectiveSpec } from 'components/directive/utils/directiveSpecs'
import { DirectiveSectionSpecification, DirectiveType } from 'components/directive/utils/types'
import { PrimaryLink } from 'components/Link'
import { useModal } from 'components/modal/Modal'
import {
  PersonAddEditModal,
  personAddEditModalConfig as personAddEditModalStyleConfig,
} from 'components/personAddEditModal/PersonAddEditModal'
import { handleAndToastError } from 'components/Toast'
import { Text } from 'components/Typography'
import { format, parseISO } from 'date-fns'
import { sendSimpleFlowStartedEvent } from 'logic/directive/analytics/sendSimpleFlowStartedEvent'
import { continueDirective } from 'logic/directive/create/continue'
import { createDirectiveAndRedirect } from 'logic/directive/create/create'
import { Translation, useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldError, FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import useSWR from 'swr'
import { EventArgs } from 'utils/analytics/types'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { formatNameWithMiddleName, getUrlPathFromEnumValue } from 'utils/format'
import { getReadableString, pickRandomArrayItem, scrollToRect, toLowerCase } from 'utils/general'
import { useLoadingCallback, useRunOnceReady, useRunOnlyOnChange } from 'utils/hooks'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { ApiType, GenericAsyncFunction, Nullable } from 'utils/types'

export function useCreateDirectiveLogic(
  type: DirectiveType,
  opts?: { ignoreExistingDirective?: boolean; place?: EventArgs['est_flow_started']['place'] }
) {
  const { isLogged } = useAuth()
  const directiveToContinue = useDirectiveToContinue(type)
  const existingDirective = opts?.ignoreExistingDirective ? null : directiveToContinue
  const { useTranslatedNames } = getDirectiveSpec(type)
  const { t } = useTranslation()
  const names = useTranslatedNames()

  const _createDirective = useCallback(async () => {
    try {
      if (existingDirective) {
        await continueDirective(existingDirective)
        return
      }
      if (isLogged == null) return
      if (opts?.place && (type === 'WILL' || type === 'TRUST')) {
        sendSimpleFlowStartedEvent(type, opts?.place)
      }
      await createDirectiveAndRedirect(type, { isLogged, routerMechanism: 'push' })
    } catch (err) {
      handleAndToastError(err)
    }
  }, [existingDirective, type, isLogged])

  const [create, isCreating] = useLoadingCallback(_createDirective)

  const cta = t(`common.create_action_${existingDirective ? 'existing' : 'new'}`, {
    name: names.short,
  })
  const ctaShort = t(`common.create_action_${existingDirective ? 'existing' : 'new'}_short`, {
    name: names.short,
  })

  return {
    cta,
    ctaShort,
    create,
    isCreating,
    existingDirective,
  }
}

export function useDirectiveToContinue(directiveType: DirectiveType) {
  const directives = useUserDirectiveList(99)
  return directives?.data?.[0]?.json?.items?.find(
    (directive) => directive.directive_type === directiveType && directive.state !== 'COMPLETED'
  )
}

export const getReadableDirectiveError = (error?: ApiType['UserDirectiveConstraintResponse']) => {
  if (!error?.field) return getReadableString(error?.description) + '.'
  const fields = error.field?.split('.')
  const number = fields?.map((c) => Number(c)).filter((x) => Number.isInteger(x))[0]
  const readableNumber = number != null ? number + 1 : undefined
  const fieldset = getReadableString(fields?.[0])?.replace(/\[\]/g, '')
  const input = fields
    .slice(1)
    .flatMap((field) => {
      if (field.match(/iterable_element/)) return []
      return getReadableString(field, false)
    })
    .join(', ')

  return `${[fieldset, readableNumber ? `${readableNumber}. item` : input]
    .filter((x) => !!x)
    .join(', ')}: ${error.description}.`
}

export const useActualPerson = (value?: ApiType['PersonInput']) => {
  const { data: person } = useUserPerson(value?.person_uuid)
  const { data: contact } = useMyContact({
    params: { trustedContactUuid: value?.trusted_contact_uuid },
  })
  return person?.json || contact?.json?.contact_user
}

export function formatDirectivePersonName(
  person?: Nullable<ApiType['UserDirectivePersonResponse']>
) {
  return formatNameWithMiddleName(person?.first_name, person?.middle_name, person?.last_name)
}

export const getDirectivePersonNameRelationship = (
  t: TFunction<'common'>,
  person?: Nullable<ApiType['UserDirectivePersonResponse']>,
  showRelationship = true
) =>
  `${formatDirectivePersonName(person) || person?.email || ''}${
    showRelationship && person?.relationship ? ` - ${getRelationship(t, person.relationship)}` : ''
  }`

export function getRelationship(
  common_t: TFunction<'common', undefined>,
  relationship:
    | 'PARENT'
    | 'SPOUSE'
    | 'DOMESTIC_PARTNER'
    | 'SIBLING'
    | 'COUSIN'
    | 'GRANDCHILD'
    | 'GRANDPARENT'
    | 'RELATIVE'
    | 'FRIEND'
    | 'CHILD'
    | 'FAMILY'
    | 'ATTORNEY'
    | 'LAWYER'
    | 'CHILDREN'
    | 'GUARDIAN'
    | 'EXECUTOR'
    | 'DIGITAL_EXECUTOR'
    | 'BENEFICIARY'
    | 'WITNESS'
    | 'PARTNER'
    | 'BENEFICIARY_ALTERNATE'
    | 'BENEFICIARY_ORGANIZATION'
    | 'SURRENDER_PET_PERSON'
    | 'APPOINTEE'
    | 'EULOGY'
    | 'FUNERAL_GUEST'
    | 'OFFICIANT'
    | 'ORDER'
    | 'PET_PROPERTY_PERSON'
    | 'STOCK_PROPERTY_PERSON'
    | 'PROPERTY_PERSON'
    | 'DESIGNATED_AGENT'
    | 'DESIGNATED_CO-AGENT'
    | 'SUCCESSOR_AGENT'
    | 'CONSERVATOR'
    | 'GIFT_RECEIVER'
    | 'PET_CAREGIVER'
    | 'PET_VETERINARIAN'
    | 'PHYSICIAN'
    | 'HEALTH_AGENT'
    | 'PET_AGENT_PERSON'
    | 'FUNERAL_PALLBEARER'
    | 'SUCCESSOR_TRUSTEE_ALTERNATE'
    | 'OTHER'
    | 'TRUSTEE'
    | 'COTRUSTEE'
    | 'SUCCESSOR_TRUSTEE'
    | 'SUCCESSOR_COTRUSTEE'
) {
  return common_t(`common.relationships.${toLowerCase(relationship)}`)
}

export const getDirectivePersonAddress = (
  address?: ApiType['AddressResponse'] | null,
  singleRow?: boolean
) => {
  return (
    <>
      {[address?.street, `${address?.city || ''} ${address?.postal_code || ''}`, address?.country]
        .filter((s) => !!s)
        .map((s, index) => (index > 0 ? (singleRow ? `, ${s}` : `\n${s}`) : s))}
    </>
  )
}

export function formatDirectiveOrganization(
  org: ApiType['UserDirectiveOrganizationResponse'] | undefined,
  singleRow?: boolean
) {
  return (
    <>
      {[
        org?.name,
        org?.street,
        `${org?.city || ''} ${org?.postal_code || ''}`,
        org?.country,
        org?.phone_number,
      ]
        .filter((s) => !!s)
        .map((s, index) => (index > 0 ? (singleRow ? `, ${s}` : `\n${s}`) : s))}
    </>
  )
}

export const getFullNameLabel = (personRole: string) => {
  return <Translation>{(t) => t(`common.full_name_label`, { who: personRole })}</Translation>
}

export const getYesNoStringFromBoolean = (t: TFunction<'directive'>, val?: boolean) =>
  t(`directive.form_input.boolean_options.${val ? 'yes' : 'no'}`)

export function RenderDirectivePeople(props: {
  testIdPrefix: 'poa' | 'funeral' | 'will' | 'pet' | 'medical' | 'trust'
  link?: string
  user_detail?: ApiType['UserDetailResponse']
  people?: ApiType['UserDirectivePersonTypeResponse'][]
  isReadOnly?: boolean
  onPersonEdit?: GenericAsyncFunction
  ownerTitle?: string
  isUserVeteran?: boolean
}) {
  const { t } = useTranslation('directive')
  const common_t = useTranslation('common')
  const { testIdPrefix } = props
  const birthdate =
    props.user_detail?.birthday &&
    format(parseISO(props.user_detail?.birthday), "do 'of' MMMM, yyyy")

  const [personToEdit, setPersonToEdit] = useState<
    ApiType['UserDirectivePersonTypeResponse'] | undefined
  >(undefined)

  const personAddEditModalConfig = useMemo(
    () => ({
      ...personAddEditModalStyleConfig,
      onClose: () => setPersonToEdit(undefined),
    }),
    []
  )

  const filteredPeople =
    props.people?.filter((person) => {
      // at least one id should be defined
      // sometimes we get organizations that act as a gift receiver in UserDirectivePoaResponse.people from backend
      // but those organizations only have UserDirectivePersonTypeResponse.types defined and no other parameters
      // which results in them being displayed to the user without any name, which is not good
      return person.trusted_contact_uuid || person.person_uuid
    }) ?? []

  const [personEditModal, showPersonEditModal] = useModal(
    ({ isOpen, close }) =>
      isOpen ? (
        <PersonAddEditModal
          defaultValues={{
            ...personToEdit,
            uuid: personToEdit?.person_uuid ?? undefined,
            first_name: personToEdit?.first_name ?? undefined,
            middle_name: personToEdit?.middle_name ?? undefined,
            last_name: personToEdit?.last_name ?? undefined,
            email: personToEdit?.email ?? undefined,
            phone_number: personToEdit?.phone_number ?? undefined,
            relationship: personToEdit?.relationship ?? undefined,
          }}
          onChange={async () => {
            await props.onPersonEdit?.()
            setPersonToEdit(undefined)
          }}
          close={close}
        />
      ) : null,
    personAddEditModalConfig
  )

  useEffect(() => {
    if (personToEdit) void showPersonEditModal()
  }, [personToEdit, showPersonEditModal])

  const firstDesignatedAgentIndex =
    filteredPeople.findIndex((p) => p.types?.some((type) => type === 'DESIGNATED_AGENT')) ?? -1

  return (
    <div>
      {personEditModal}
      <SBox>
        <STitleRow>
          <Text color="black" variant="bodyBold">
            {props.ownerTitle || t('directive.review.owner')}
          </Text>
          {!props.isReadOnly && (
            <PrimaryLink href={props.link}>{t('directive.review.edit_section_button')}</PrimaryLink>
          )}
        </STitleRow>
        <SRow data-testid={`${testIdPrefix}-review-name`}>
          <Text>{common_t.t('common.labels.name')}</Text>
          <Text>
            {formatNameWithMiddleName(
              props.user_detail?.first_name,
              props.user_detail?.middle_name,
              props.user_detail?.last_name
            )}
          </Text>
        </SRow>
        <SRow data-testid={`${testIdPrefix}-review-email`}>
          <Text>{common_t.t('common.labels.email_address')}</Text>
          <Text>{props.user_detail?.email}</Text>
        </SRow>
        <SRow data-testid={`${testIdPrefix}-review-address`}>
          <Text>{common_t.t('common.labels.address')}</Text>
          <Text>{getDirectivePersonAddress(props.user_detail)}</Text>
        </SRow>
        <SRow data-testid={`${testIdPrefix}-review-birth-date`}>
          <Text>{common_t.t('common.labels.birth_date')}</Text>
          <Text>{birthdate}</Text>
        </SRow>
        <SRow data-testid={`${testIdPrefix}-review-phone`}>
          <Text>{common_t.t('common.labels.phone_number')}</Text>
          <Text>{props.user_detail?.phone_number}</Text>
        </SRow>
        {props.isUserVeteran != null && (
          <SRow>
            <Text>{t('directive.funeral.about_you.is_veteran.text')}</Text>
            <Text>
              {t(
                `directive.funeral.about_you.is_veteran.options.${
                  props.isUserVeteran ? 'yes' : 'no'
                }`
              )}
            </Text>
          </SRow>
        )}
      </SBox>

      {filteredPeople.map((p, index) => {
        const actsAsText = p.types?.length
          ? p.types
              .map((t) => {
                const isDesignatedCoagent =
                  t === 'DESIGNATED_AGENT' && firstDesignatedAgentIndex < index
                return getRelationship(common_t.t, isDesignatedCoagent ? 'DESIGNATED_CO-AGENT' : t)
              })
              .join(', ')
          : ''

        return (
          <SBox key={`Person_${p?.person_uuid || p?.trusted_contact_uuid}_${index}`}>
            <STitleRow>
              <Text color="black" variant="bodyBold">
                {getDirectivePersonNameRelationship(common_t.t, p, false)}
              </Text>
              {!props.isReadOnly && !p.trusted_contact_uuid && (
                <PrimaryLink onClick={() => setPersonToEdit(p)}>
                  {t('directive.review.edit_person_button')}
                </PrimaryLink>
              )}
            </STitleRow>
            {p.relationship && (
              <SRow>
                <Text>{common_t.t('common.labels.relationship')}</Text>
                <Text>{getRelationship(common_t.t, p.relationship)}</Text>
              </SRow>
            )}
            {!!(p.email && p.first_name && p.last_name) && (
              <SRow>
                <Text>{common_t.t('common.labels.email')}</Text>
                <Text>{p.email}</Text>
              </SRow>
            )}
            {p.phone_number && (
              <SRow>
                <Text>{common_t.t('common.labels.phone_number')}</Text>
                <Text>{p.phone_number}</Text>
              </SRow>
            )}
            {!!p.types?.length && (
              <SRow>
                <Text>{common_t.t('common.labels.acts_as')}</Text>
                <Text>{actsAsText}</Text>
              </SRow>
            )}
          </SBox>
        )
      })}
    </div>
  )
}

export function calculateDirectiveProgress(steps: TDirectiveStep[], considerTutorialDone = false) {
  const progressableSteps = steps.flatMap((step): { isDone: boolean }[] => {
    if (step.value === 'REVIEW') return []
    if (step.subsections) return step.subsections.map((x) => ({ isDone: x.isDone }))
    return [{ isDone: step.isDone }]
  })
  const doneSteps = progressableSteps.filter((step) => step.isDone)

  const tutorialStepCount = 1
  const progressableStepCount = progressableSteps.length + tutorialStepCount
  const perStepProgress = Math.floor(100 / progressableStepCount) / 100
  const doneStepCount = (considerTutorialDone ? tutorialStepCount : 0) + doneSteps.length

  if (doneStepCount === progressableStepCount) return 1

  return doneStepCount * perStepProgress
}

/**
 * Represents a section specification as subsections.
 * If there are no subsections explicitly specified, the section is considered the only subsection.
 * It also distributes errors to the subsections and determines whether they are done.
 */
export function representAsSubsections<TSectionKey extends string>(
  section: DirectiveSectionSpecification<TSectionKey>,
  visitedSections: string[],
  sectionErrors: ApiType['UserDirectiveConstraintResponse'][]
) {
  const subsections = (section.subsections ?? [{ sectionKey: section.value }]).map(
    (subsection): TDirectiveStepSubsection => {
      return {
        ...subsection,
        errors: [],
        isTouched: visitedSections.includes(subsection.sectionKey),
        isDone: false,
      }
    }
  )

  // distribute errors to subsections
  for (const error of sectionErrors) {
    // the error is distributed to the first subsection that the error is related to (determined by isValidationErrorRelated)
    // but if no subsection says it's related to the error, we consider it to be related to the last subsection
    // this also means that for sections that have exactly one subsection, this error is distributed to the one subsection
    const subsection =
      subsections.find((x) => x.isValidationErrorRelated?.(error)) ??
      subsections[subsections.length - 1]
    subsection?.errors.push(error)
  }

  // determine if the sections are done once we distributed the errors
  for (const subsection of subsections) {
    // we consider the subsection to be done if it's touched Save & Continued (which is what isTouched tells us)
    // Because, it's too heavy to calculate the validation errors in the list on BE, and we wanna keep the progress consistent between the directive list and the directive form
    subsection.isDone = subsection.isTouched
  }

  return subsections
}

export function useDirectiveSteps<TSectionKey extends string>(
  steps: DirectiveSectionSpecification<TSectionKey>[],
  currentStep: string,
  data?:
    | ApiType['UserDirectiveWillResponse']
    | ApiType['UserDirectiveFuneralResponse']
    | ApiType['UserDirectivePoaResponse']
    | ApiType['UserDirectivePetResponse']
    | ApiType['UserDirectiveMedicalResponse']
    | ApiType['UserDirectiveTrustResponse']
) {
  useDynamicTranslations('directive')
  const { t } = useTranslation('directive')
  return steps
    .slice()
    .reverse()
    .reduce<TDirectiveStep[]>((memo, item) => {
      // `data` can have `visited_sections` if it's type is ApiType['UserDirectiveResponse'], which we used in `DirectiveCard.tsx`
      // FUTURE [Improve] couldn't come up with a proper TS solution
      const visitedSections: string[] =
        data?.section_state?.visited_sections ??
        (data as ApiType['UserDirectiveResponse'])?.visited_sections ??
        []
      const isItemTouched = visitedSections.includes(item.value)

      const sectionErrors: ApiType['UserDirectiveConstraintResponse'][] = []

      if (data?.state && data?.section_state) {
        if (isItemTouched || (data.state !== 'NEW' && data.state !== 'IN_DRAFT')) {
          if (data?.validation_errors && Array.isArray(data.validation_errors)) {
            for (const error of data?.validation_errors) {
              if (error.section === item.value) {
                sectionErrors.push(error)
              }
            }
          }
        }
      }

      const subsections = representAsSubsections(item, visitedSections, sectionErrors)
      const isDone = subsections.every((x) => x.isDone)
      const isCurrent = subsections.some((x) => x.sectionKey === currentStep)

      const errors = subsections.flatMap((subsection) => {
        if (subsection.isTouched) return subsection.errors
        return []
      })
      const hasError = errors.length === 0 ? null : errors.map(getReadableDirectiveError).join('\n')

      memo.push({
        name: String(t(item.name)),
        mobileName: String(t(item.mobile ?? item.name)),
        value: item.value,
        url: item.url ?? (getUrlPathFromEnumValue(item.value) as string),
        isDone,
        isCurrent,
        hasError,
        disabled: item.value === 'REVIEW' && !!data?.validation_errors?.length,
        subsections,
      })

      return memo
    }, [])
    .reverse()
}

export function getDirectivePageName(router: { pathname: string }) {
  return router.pathname.substring(router.pathname.lastIndexOf('/') + 1)
}

const encouragementTextKey = `@cleevio/encouragementText`

export function useEncouragementText(changeKey: string) {
  const { t } = useDynamicTranslations('directive')
  const encouragementTexts = t('directive.progress.encouragements', {
    returnObjects: true,
  })
  const swr = useSWR<{ text?: string; changeKey: string }, unknown>(encouragementTextKey, null)

  const generateNext = () => {
    const hasKeyChanged = swr.data?.changeKey !== changeKey
    if (!swr.data || hasKeyChanged) {
      swr.mutate({
        changeKey,
        text: pickRandomArrayItem(encouragementTexts, swr.data?.text),
      })
    }
  }

  useRunOnceReady(Array.isArray(encouragementTexts), generateNext)
  useRunOnlyOnChange(changeKey, generateNext)
  return swr.data?.text
}

function isErrorTreeLeaf(node: unknown): node is FieldError {
  return !!(
    typeof node === 'object' &&
    node &&
    'type' in node &&
    typeof (node as { type: unknown }).type === 'string'
  )
}
export function recursivelyFlattenFieldErrors<TFieldValues extends FieldValues>(
  errorTree: FieldErrors<TFieldValues>,
  maxDepth = Infinity
): { name: string; error: FieldError }[] {
  if (maxDepth === 0) return []

  if (isErrorTreeLeaf(errorTree)) return [{ name: '', error: errorTree }]

  return Object.entries(errorTree).flatMap(([key, node]) => {
    if (typeof node !== 'object' || !node) return []

    // if the node is a leaf of the tree, return it
    if (isErrorTreeLeaf(node)) return [{ name: key, error: node }]

    const nodeAsArray = Array.isArray(node) ? node : [node]

    return nodeAsArray.flatMap((x, i) =>
      recursivelyFlattenFieldErrors(x, maxDepth - 1).map((err) => ({
        ...err,
        name: [key, Array.isArray(node) ? i : undefined, err.name]
          .filter((x) => !!x || x === 0)
          .join('.'),
      }))
    )
  })
}

/**
 * Finds if there are any errors in the form
 * If it cannot find any errors, it does nothing
 * If it finds errors, it compares their inputs' top position and picks the highest error to be scrolled to.
 */
export function scrollToFirstFormError(form: UseFormReturn) {
  const errors = recursivelyFlattenFieldErrors(form.formState.errors, 10).flatMap(
    ({ error, name }) => {
      if (!error.ref) return []
      const refAsElement =
        'getBoundingClientRect' in error.ref ? (error.ref as HTMLElement) : undefined

      // it seems that due to an update to react-hook-form, the ref is not always an html element
      // in case we're using a Controller for the field, the ref does not give us a way to get the rect
      // so we have to try querying it
      const queriedElement = document.querySelector(`input[name="${name}"]`)
      const element = refAsElement ?? queriedElement
      const rect = element?.getBoundingClientRect()
      if (!rect) return []
      return [{ error, rect }]
    }
  )

  const visuallyFirstError =
    errors.length === 0
      ? null
      : errors.reduce((top, next) => {
          if (top.rect.top < next.rect.top) return top
          else return next
        })

  if (visuallyFirstError?.rect) {
    scrollToRect(visuallyFirstError.rect)
  }
}

export const mapPersonOrganizationListForUpdate = (
  personOrganizationList?: ApiType['UserDirectiveListResponsePersonOrganizationResponse']
) => {
  const items = personOrganizationList?.items?.map((item) => {
    const result: ApiType['PersonOrganizationInput'] = {}

    if (item.type === 'PERSON') {
      result.person = item.person ?? {}
    } else {
      result.organization = item.organization ?? {}
    }

    return result
  })

  return { items }
}

export const useSortedDirectivesWithTutorial = ({
  firstDirectiveType,
}: {
  firstDirectiveType: DirectiveType
}) => {
  const userDirectivesSwr = useUserDirectiveList(99)
  const finishedDirectives =
    userDirectivesSwr?.data?.[0]?.json?.items?.filter(
      (directive) => directive.state === 'COMPLETED'
    ) || []
  const actions = useInsideDirectiveActions()

  const sortedActions = actions
    .map((a) =>
      finishedDirectives?.some((finished) => finished.directive_type === a.type)
        ? { ...a, isCompleted: true }
        : a
    )
    .sort((a) => (a.type === firstDirectiveType ? -1 : 0))

  return sortedActions
}

export const useDirectiveLPActions = (landingPage: DirectiveType): Action[] => {
  const { t } = useTranslation()
  const { actionCardKey } = getDirectiveSpec(landingPage)
  const { create, existingDirective } = useCreateDirectiveLogic(landingPage)

  const keysExpectCurrent = DIRECTIVE_ACTIONS.filter((k) => k !== actionCardKey)
  const keysCurrentFirst = [actionCardKey, ...keysExpectCurrent]

  const buttonText = t(existingDirective ? 'common.continue' : 'common.start_now')

  const actions = useLPActions(keysCurrentFirst, {
    forSomeKeys: {
      [actionCardKey]: {
        buttonText,
        buttonTextMobile: buttonText,
        url: undefined,
        onClick: create,
      },
    },
  })

  return actions
}

export const useInsideDirectiveActions = (): Action[] => {
  const actions = useLPActions(DIRECTIVE_ACTIONS)

  return actions.flatMap((action) => {
    const { type } = action
    if (type == null) return []

    return [
      {
        ...action,
        url: undefined,
        onClick: () =>
          createDirectiveAndRedirect(type, {
            isLogged: true,
            fromInside: true,
            routerMechanism: 'push',
          }).catch(handleAndToastError),
      },
    ]
  })
}
