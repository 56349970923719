import { css } from '@emotion/react'
import { useUserMe } from 'api/goodtrust/user'
import { Button } from 'components/button/Button'
import { useModal } from 'components/modal/Modal'
import { OfferModalHeader } from 'components/modal/offerModal/OfferModalHeader'
import { BlockSpinner } from 'components/spinner/Spinner'
import { CheckoutContactInformationFields } from 'components/wizard/wizardFormCardElement/WizardFormCardElement'
import { AdditionalRedeemInfo } from 'logic/promo/type'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { COUNTRY_CODES } from 'utils/country_codes'
import { useEventTopic, useRunOnceReady } from 'utils/hooks'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { useExtendedPromotion } from 'utils/promo'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'

type Props = {
  code: string
  onFilled: (data: AdditionalRedeemInfo) => void
}

export const AdditionalOfferInformationModal: FC<Props> = (props) => {
  const { t, isLoading: isLoadingTranslations } = useDynamicTranslations('pricing_plan')
  const { data: promo } = useExtendedPromotion(props.code)
  const userMeSwr = useUserMe()
  const userMe = userMeSwr.data?.json
  const form = useForm<AdditionalRedeemInfo>()
  const [isReady, setReady] = useState(false)
  useRunOnceReady(!!form, () => {
    if (userMe) {
      form.setValue('first_name', userMe.first_name)
      form.setValue('last_name', userMe.last_name)
      form.setValue('address_zip', userMe.postal_code)
      form.setValue('country', userMe.country)
    }

    const values = form.getValues()
    if (
      values.first_name?.length &&
      values.last_name?.length &&
      values.address_zip?.length &&
      values.country &&
      COUNTRY_CODES.some((x) => x.code === values.country)
    ) {
      // if the user already has enough data filled, let's autosubmit
      form.handleSubmit(props.onFilled)()
      return
    }
    setReady(true)
  })
  const isLoading = !isReady || isLoadingTranslations

  return (
    <>
      {isLoading && <BlockSpinner />}
      <form
        onSubmit={form.handleSubmit(props.onFilled)}
        css={{ display: isLoading ? 'none' : undefined }}
      >
        {promo && (
          <OfferModalHeader
            promo={promo}
            warningText={String(t('pricing_plan.offer.additional_information_required_warning'))}
          />
        )}

        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @media ${BREAKPOINTS.MD.max} {
              grid-template-columns: 1fr;
            }
            grid-gap: 9px;
            grid-row-gap: 10px;
            margin-bottom: 1.5rem;
          `}
        >
          <CheckoutContactInformationFields form={form} fields={{ location: true, name: true }} />
        </div>
        <Button type="submit" size="large" block>
          {t('pricing_plan.offer.additional_information_continue_button')}
        </Button>
      </form>
    </>
  )
}

export const useAdditionalOfferInformationModal = () => {
  const resultTopic = useEventTopic<AdditionalRedeemInfo | 'close'>()
  const [code, setCode] = useState<string>()
  const [jsx, openModal, close] = useModal(
    () => code && <AdditionalOfferInformationModal code={code} onFilled={resultTopic.emit} />,
    {
      modalContentCss: {
        maxWidth: pxToRemUnit(560),
        padding: '2rem',
        alignSelf: 'center',
      },
      onClose() {
        resultTopic.emit('close')
      },
    }
  )

  return {
    jsx,
    open: async (code: string) => {
      setCode(code)
      openModal()
      const data = await resultTopic.once()
      close()
      return data
    },
  }
}
