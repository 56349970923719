import { createContext, FC, useContext, useEffect, useMemo } from 'react'
import { ContentfulImages, ContentfulPageProps } from './types'

const ContentfulImagesContext = createContext<ContentfulPageProps>({} as ContentfulPageProps)

export const ContentfulImagesContextProvider: FC<
  React.PropsWithChildren<{ pageProps: ContentfulPageProps }>
> = ({ children, pageProps }) => {
  const _contentfulImages = useMemo(
    () => pageProps._contentfulImages,
    [pageProps._contentfulImages]
  )
  return (
    <ContentfulImagesContext.Provider value={{ _contentfulImages }}>
      {children}
    </ContentfulImagesContext.Provider>
  )
}

export function useContentfulImages(): ContentfulImages {
  const context = useContext(ContentfulImagesContext)

  useEffect(() => {
    if (typeof context._contentfulImages === 'undefined') {
      throw new Error(
        `useContentfulImages is used on a page which not passes getPageImages on it's server-side props`
      )
    }
  }, [context._contentfulImages])

  return context._contentfulImages || {}
}
