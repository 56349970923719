import { css, CSSObject, SerializedStyles } from '@emotion/react'
import { InterpolationPrimitive } from '@emotion/serialize'
import { hideFreshDeskButtonCss } from 'components/directive/utils/useHideFreshDeskButton'
import { transparentize } from 'polished'
import React, { CSSProperties, ElementType } from 'react'
import { colors } from './colors'

export interface StyledLinkProps {
  as?: ElementType | keyof JSX.IntrinsicElements
}
interface IMediaQuery {
  size: number
  min: `(min-width: ${number}px)`
  max: `(max-width: ${number}px)`
  toString: () => string
  css: (css: InterpolationPrimitive) => InterpolationPrimitive
}

function getMediaQuery(size: number) {
  class MediaQuery implements IMediaQuery {
    size
    min
    max

    constructor(size: number) {
      this.size = size
      // min-width: size doesn't play well with max-width: size, as both rules are applied at the threshold
      this.min = `(min-width: ${this.size + 1}px)` as const
      this.max = `(max-width: ${this.size}px)` as const
    }
    toString() {
      return this.max
    }
    css(css: InterpolationPrimitive): InterpolationPrimitive {
      return {
        [`@media ${this.max}`]: css,
      }
    }
  }

  return new MediaQuery(size)
}

export const smallThreshold = 425
export const mobileThreshold = 768
export const largeThreshold = 1024
export const xLargeThreshold = 1440
export const xxLargeThreshold = 1600
export const xxxLargeThreshold = 1920
export const BREAKPOINTS = {
  /** 320px */
  XS: getMediaQuery(320),
  /** 425px */
  SM: getMediaQuery(smallThreshold),
  /** 768px */
  MD: getMediaQuery(mobileThreshold),
  /** 1024px */
  LG: getMediaQuery(largeThreshold),
  /** 1440px */
  XL: getMediaQuery(xLargeThreshold),
}

export function pxToRem(px: number): number {
  return px / 16
}

export function pxToRemUnit(px: number): string {
  return pxToRem(px) + 'rem'
}

export type ResponsiveValue<Atom> =
  | Readonly<[Atom, Atom, Atom, Atom]>
  | Readonly<[Atom, Atom, Atom]>
  | Readonly<[Atom, Atom]>
  | Atom

export function mapResponsiveValue<TInput, TOutput>(
  value: ResponsiveValue<TInput>,
  map: (input: TInput) => TOutput
): ResponsiveValue<TOutput> {
  if (Array.isArray(value)) {
    return (value as Exclude<ResponsiveValue<TInput>, TInput>).map(map) as Exclude<
      ResponsiveValue<TOutput>,
      TOutput
    >
  }
  return map(value as TInput)
}

type CSSResult = CSSObject | SerializedStyles

function applyStyle<Key extends string | number>(
  value: Key,
  resolved: Record<Key, CSSResult> | ((value: Key) => CSSResult | undefined)
): CSSResult | undefined {
  if (typeof resolved === 'function') {
    return resolved(value)
  }
  return resolved[value]
}

export function getResponsiveStyles<Key extends string | number>(
  value: ResponsiveValue<Key> | undefined,
  valueMap: Record<Key, CSSResult> | ((value: Key) => CSSResult | undefined)
): SerializedStyles | undefined {
  if (value == null) return undefined

  if (typeof value === 'string' || typeof value === 'number') {
    return css(applyStyle(value, valueMap))
  }

  if (Array.isArray(value)) {
    const { length } = value

    if (length === 2) {
      const [smallDevices, bigDevices] = value

      return css`
        @media ${BREAKPOINTS.MD.max} {
          ${applyStyle(smallDevices, valueMap)};
        }

        @media ${BREAKPOINTS.MD.min} {
          ${applyStyle(bigDevices, valueMap)};
        }
      `
    }

    if (length === 3) {
      const [mobile, tablet, desktop] = value as Readonly<[Key, Key, Key]>

      return css`
        @media ${BREAKPOINTS.SM.max} {
          ${applyStyle(mobile, valueMap)};
        }

        @media ${BREAKPOINTS.SM.min} and ${BREAKPOINTS.LG.max} {
          ${applyStyle(tablet, valueMap)};
        }

        @media ${BREAKPOINTS.LG.min} {
          ${applyStyle(desktop, valueMap)};
        }
      `
    }

    if (length === 4) {
      const [mobile, smallTablet, bigTablet, desktop] = value as Readonly<[Key, Key, Key, Key]>

      return css`
        @media ${BREAKPOINTS.SM.max} {
          ${applyStyle(mobile, valueMap)};
        }

        @media ${BREAKPOINTS.SM.min} and ${BREAKPOINTS.MD.max} {
          ${applyStyle(smallTablet, valueMap)};
        }

        @media ${BREAKPOINTS.MD.min} and ${BREAKPOINTS.LG.max} {
          ${applyStyle(bigTablet, valueMap)};
        }

        @media ${BREAKPOINTS.LG.min} {
          ${applyStyle(desktop, valueMap)};
        }
      `
    }

    throw new Error(`Invalid responsive value: ${JSON.stringify(value)}`)
  }

  throw new Error(`Invalid responsive value: ${JSON.stringify(value)}`)
}

export const prettierScrollbarCss = css`
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 1rem;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 1rem;
    border: 0.25rem solid #fff;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`

export const hiddenScrollbarCss = css`
  scrollbar-width: none;
  overscroll-behavior-x: contain;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const scrollShadowCss = css`
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    linear-gradient(to bottom, rgba(0, 0, 0, 0.2), transparent),
    linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);

  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-position: top 0 right 0, bottom 0 right 0;
  background-attachment: local, local, scroll, scroll;
`

export const addTranslateZToStyles = (styles: CSSProperties, px: number) => {
  const transform = styles.transform ?? ''
  return { ...styles, transform: `${transform} translateZ(${px}px)` }
}

export const globalStyles = `
body {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  min-height: 100%;
  height: 100%;

  min-width: 100%;
  width: 100%;
}

${hideFreshDeskButtonCss}

html {
  height: 100%;
  width: 100%;

  --c-blue50: ${colors.blue[50]};
  --c-blue100: ${colors.blue[100]};
  --c-blue200: ${colors.blue[200]};
  --c-blue300: ${colors.blue[300]};
  --c-blue400: ${colors.blue[400]};
  --c-blue500: ${colors.blue[500]};
  --c-blue500-transparent: ${transparentize(0.93, colors.blue[500])};
  --c-blue600: ${colors.blue[600]};

  --c-gray50: ${colors.gray[50]};
  --c-gray100: ${colors.gray[100]};
  --c-gray200: ${colors.gray[200]};
  --c-gray300: ${colors.gray[300]};
  --c-gray300-transparent: ${transparentize(0.7, colors.gray[300])};
  --c-gray400: ${colors.gray[400]};
  --c-gray500: ${colors.gray[500]};

  --c-white: ${colors.white};
  --c-white-transparent: ${transparentize(0.8, colors.white)};
  --c-black: ${colors.black};

  
  --c-green100: ${colors.green[100]};
  --c-green200: ${colors.green[200]};
  --c-green200-transparent: ${transparentize(0.93, colors.green[200])};
  
  --c-red100: ${colors.red[100]};
  --c-red200: ${colors.red[200]};
  --c-red400: ${colors.red[400]};
  --c-red500: ${colors.red[500]};
  --c-red600: ${colors.red[600]};
  
  --c-orange100: ${colors.orange[100]};
  --c-orange200: ${colors.orange[200]};
  --c-orange200-transparent: ${transparentize(0.93, colors.orange[200])};
  

  --f-heading: Lora, serif;
  --f-text: Lato, sans-serif;

  --p-content: 1.5625rem;
  --p-spacing: 2.625rem;
  --p-button: 1.6875rem;

  --h-header: 100px;
  --h-landing-page-header: 4.5rem;

  --card-shadow-light: 0px 2px 10px rgba(98, 131, 147, 0.21);
  --card-shadow-light-hover: 0px 5px 15px rgba(98, 131, 147, 0.21);
  --card-shadow-light-active: 0px 2px 15px rgba(98, 131, 147, 0.21);

  --card-shadow: 0px 5px 30px rgba(98, 131, 147, 0.21);
  --card-shadow-hover: 0px 10px 40px rgba(98, 131, 147, 0.28);
  --card-shadow-active: 0px 4px 10px rgba(98, 131, 147, 0.21);
}

@media ${BREAKPOINTS.XS.min} {
  html {
    font-size: calc(100vw / 20);
    --p-content: 0;
    --p-spacing: 5vw;
    --h-header: 64px;
  }
}

@media ${BREAKPOINTS.XS.min} {
  html {
    font-size: 16px;
  }
}

@media ${BREAKPOINTS.MD.max} {
  .desktop-only {
    display: none !important;
  }
}

@media ${BREAKPOINTS.MD.min} {
  html {
    font-size: calc(100vw / 64);
    --p-content: 1.5625rem;
    --p-spacing: 2.625rem;

    --h-header: 100px;
  }

  .mobile-only {
    display: none !important;
  }
}

@media ${BREAKPOINTS.LG.min} {
  html {
    font-size: calc(100vw / 90);
  }
}

@media ${BREAKPOINTS.XL.min} {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--c-blue500);

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--c-blue500), 0 0 5px var(--c-blue500);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

/* Hide ugly and not aligned dropdown arrow next to inputs with datalists */
[list]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pac-container {
  transform: translate3d(0, 0, 10px);
}
.hot-toast {
  padding: 0 !important;
  max-width: calc(100vw - 32px) !important;
  margin: 0;
}
.hot-toast > div {
  margin: 0;
}


.ReactModalPortal {
  height: 100%;
  width: 100%;
}

#freshworks-container {
  /* Show freshdesk below cookies. */
  z-index: 5 !important;
}
#freshworks-frame-wrapper{
  /* Display freshdesk menu over signup dropdowns and button */
  transform: translateZ(100px);
}

`
/**
 * Creates a new component from an existing component, but with default props.
 * If some of the default props were required in the component, they are optional in the returned component.
 */
export function withDefaultProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TComponent extends React.ComponentType<React.PropsWithChildren<any>>,
  TDefaultProps extends React.ComponentProps<TComponent>
>(Component: TComponent, defaultProps: TDefaultProps) {
  type Props = Omit<React.ComponentPropsWithRef<TComponent>, keyof TDefaultProps> &
    Partial<TDefaultProps>

  return React.forwardRef<TComponent, Props>((props, ref) => {
    const defaultedProps: React.ComponentProps<TComponent> = { ...defaultProps, ...props }
    // need to ts-ignore or do some weird 'as' typecasting, but we're not doing anything unsafe with the types, are we?
    // @ts-ignore
    return <Component {...defaultedProps} ref={ref} />
  }) as React.ExoticComponent<Props & React.RefAttributes<React.ElementRef<TComponent>>>
}
