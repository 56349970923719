import { AuthState } from 'utils/auth/types'

export const AUTH_KEY = '@goodtrust/auth'

class AuthCache {
  cache: Map<string, AuthState>
  constructor() {
    this.cache = new Map()
  }

  set(key: string, value: AuthState) {
    this.cache.set(key, value)
  }

  get(key: string) {
    return this.cache.get(key)
  }

  delete(key: string) {
    this.cache.delete(key)
  }

  clear() {
    this.cache.clear()
  }
}

export const isExpired = (expires: number) => !expires || Date.now() >= expires

export const cache = new AuthCache()
