import { InterpolationPrimitive } from '@emotion/serialize'
import { Placement } from '@popperjs/core/lib/enums'
import { DropdownContext } from 'components/dropdown/DropdownContext'
import { Portal } from 'components/portal/Portal'
import {
  Children,
  cloneElement,
  forwardRef,
  ForwardRefRenderFunction,
  isValidElement,
  JSXElementConstructor,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { usePopper } from 'react-popper'
import { isEventInElement, isIos, mergeRefs, safeVisualViewport, safeWindow } from 'utils/general'
import { useEvent, useLatestValueRef, useRunOnceReady, useWindowSize } from 'utils/hooks'
import { addTranslateZToStyles } from 'utils/styled'
import { GenericFunction } from 'utils/types'
import { SContent, SDropdown, SFillScreen, SFillScreenTrigger, SItems } from './Dropdown.styled'

export interface DropdownProps {
  referenceElement: HTMLElement | null
  trigger: ReactNode | ((ctx: { isFillScreen: boolean; isOpen: boolean }) => ReactNode)
  placement?: Placement
  className?: string
  disabled?: boolean
  onClose?: GenericFunction
  onOpen?: GenericFunction
  fillScreenOnMobile?: boolean
  closeOnSelect?: boolean
  closeOnOutsideclick?: boolean
  updateFrequently?: boolean
  openOnFocus?: boolean
  closeOnBlur?: boolean
  handleRef?: MutableRefObject<DropdownHandle | null>
  renderChildren?: (ctx: { isFillScreen: boolean; isOpen: boolean; close: () => void }) => ReactNode
  fillScreenCss?: InterpolationPrimitive
}

export type DropdownHandle = {
  close: () => void
}

interface TriggerListeners {
  onClick: () => void
  onFocus: () => void
  onBlur: () => void
  isOpen: boolean
}

const _Dropdown: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<DropdownProps>> = (
  {
    trigger: triggerOrRenderTrigger,
    referenceElement,
    children,
    className,
    onClose,
    onOpen,
    disabled,
    handleRef,
    renderChildren,
    fillScreenOnMobile = false,
    closeOnSelect = true,
    closeOnOutsideclick = true,
    openOnFocus = true,
    closeOnBlur = true,
    updateFrequently = true,
    fillScreenCss,
  },
  ref
) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isDesktop } = useWindowSize()

  const fillScreenRootRef = useRef<HTMLDivElement>(null)
  const onCloseRef = useLatestValueRef(onClose)

  const open = () => {
    if (!isOpen && !disabled) {
      setIsOpen(true)
      onOpen?.()
    }
  }

  const close = useCallback(() => {
    if (isOpen) {
      setIsOpen(false)
      onCloseRef.current?.()
    }
  }, [isOpen, onCloseRef])

  // run onClose on unmount
  useRunOnceReady(true, () => () => onClose?.())

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  useImperativeHandle(handleRef, () => ({
    close,
  }))

  useEffect(() => {
    if (!closeOnOutsideclick) return

    function handleClick(e: MouseEvent) {
      if (
        referenceElement &&
        !isEventInElement(e, referenceElement) &&
        popperElement &&
        !isEventInElement(e, popperElement)
      ) {
        close()
      }
    }

    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [close, closeOnOutsideclick, popperElement, referenceElement])

  const {
    styles,
    attributes,
    update: updatePopper,
  } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  })

  // Update popper position when reference element's size changes
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updatePopper?.()
    })

    if (referenceElement) {
      resizeObserver.observe(referenceElement, {
        box: 'border-box',
      })
    }

    return () => {
      if (referenceElement) {
        resizeObserver.unobserve(referenceElement)
      }
    }
  }, [referenceElement, updatePopper])

  /* Update popper position on each opening for reference elements whose
  positioning on the client viewport can change -there is no event
  listener/observer for offsetTop or offsetLeft changes yet */
  useEffect(() => {
    if (isOpen && updateFrequently && updatePopper) {
      updatePopper()
    }
  }, [updateFrequently, updatePopper, isOpen])

  const [lastViewport, setLastViewport] = useState<{
    visualHeight: number
    layoutHeight: number
  }>()

  function handleViewportUpdate(e: Event) {
    // there are two viewports
    // - layout viewport
    // - visual viewport
    // when user opens virtual keyboard on iOS, visual viewport is smaller while layout viewport stays the same
    // on android, virtual keyboard makes both viewports smaller
    // position fixed is relative to layout viewport, so we need to watch visual viewport changes on iOS and take into account the difference between the two viewports
    // because we want the content to be aligned to visual viewport
    const viewport =
      safeWindow && 'VisualViewport' in safeWindow && e.target instanceof VisualViewport
        ? e.target
        : undefined
    if (!viewport) return

    setLastViewport({
      visualHeight: viewport.height,
      layoutHeight: window.innerHeight,
    })
  }

  // we only need to get viewport updates on iOS and only when we fill screen on mobile
  useEvent(
    fillScreenOnMobile && isIos() ? safeVisualViewport : undefined,
    'resize',
    handleViewportUpdate
  )
  useEvent(
    fillScreenOnMobile && isIos() ? safeVisualViewport : undefined,
    'scroll',
    handleViewportUpdate
  )

  const displayType = isDesktop || !fillScreenOnMobile ? 'dropdown' : 'fill-screen'

  const bottom =
    fillScreenOnMobile && lastViewport && isIos()
      ? lastViewport.layoutHeight - lastViewport.visualHeight
      : 0

  const isFillScreen = displayType === 'fill-screen'
  const trigger =
    typeof triggerOrRenderTrigger === 'function'
      ? (triggerOrRenderTrigger({ isFillScreen, isOpen }) as ReactNode)
      : (triggerOrRenderTrigger as ReactElement<unknown, string | JSXElementConstructor<unknown>>)

  const triggerWithListeners =
    isValidElement<TriggerListeners>(trigger) &&
    cloneElement(trigger, {
      isOpen,
      onClick: () => {
        trigger.props.onClick?.()
        open()
      },
      onFocus: () => {
        trigger.props.onFocus?.()
        if (openOnFocus) {
          open()
        }
      },
      onBlur: () => {
        trigger.props.onBlur?.()
        if (closeOnBlur) {
          close()
        }
      },
    })

  function onItemSelected() {
    if (closeOnSelect) {
      close()
    }
  }

  const childrenItems = renderChildren
    ? renderChildren({ isFillScreen: displayType === 'fill-screen', isOpen, close: () => close() })
    : children
  const items = Children.map(childrenItems, (item) => {
    if (isValidElement<{ onClick: () => void }>(item)) {
      return cloneElement(item, {
        onClick: () => {
          item.props.onClick?.()
          onItemSelected()
        },
      })
    }
    return item
  })
  const showFillScreen = displayType === 'fill-screen' && isOpen

  useEffect(() => {
    // change focus from the first trigger to the fill screen trigger
    fillScreenRootRef.current?.querySelector('input')?.focus()
  }, [showFillScreen])

  return (
    <DropdownContext.Provider value={{ onItemSelected }}>
      {/* If we're not showing fill screen. */}
      {!showFillScreen ? triggerWithListeners : null}
      <Portal>
        {showFillScreen ? (
          <SFillScreen ref={fillScreenRootRef} css={[{ bottom }, fillScreenCss]}>
            {trigger && <SFillScreenTrigger>{trigger}</SFillScreenTrigger>}
            {items}
          </SFillScreen>
        ) : displayType === 'dropdown' ? (
          <SDropdown
            ref={mergeRefs([setPopperElement, ref])}
            style={styles.popper && addTranslateZToStyles(styles.popper, 99)}
            className={className}
            isOpen={isOpen}
            {...attributes.popper}
          >
            <SContent>
              <SItems data-dropdown-wrapper width={referenceElement?.getBoundingClientRect().width}>
                {items}
              </SItems>
            </SContent>
          </SDropdown>
        ) : null}
      </Portal>
    </DropdownContext.Provider>
  )
}

export const Dropdown = forwardRef(_Dropdown)
