import styled from '@emotion/styled'

export const SPersonModal = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const SModalTitleRow = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 1rem;
    & path {
      stroke-width: 2px;
      stroke: black;
    }
  }
`
