import {
  cloneElement,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Fieldset, FieldsetProps } from 'components/fieldset/Fieldset'
import { Dropdown, DropdownProps } from 'components/dropdown/Dropdown'
import { SIconArrow, SInput, SLabel, SSelectItem } from './Select.styled'
import { useDebounce, useLatestValueRef } from 'utils/hooks'
import { mergeRefs } from 'utils/general'
import { GenericFunction } from 'utils/types'

interface Item<T> {
  value: T | null | undefined
  label: string | null | undefined
  render?: ReactElement
  customOnClick?: GenericFunction
}

interface Props<T> extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  items: (Item<T> | undefined)[] | null | undefined
  value: T | null | undefined
  onChange: ((value: T | undefined | null) => void) | undefined
  error?: ReactNode
  readOnly?: boolean
  label?: string
  enableFiltering?: boolean
  customFilter?: (value?: string) => void
  customSelectedItemLabel?: string
  fieldsetProps?: FieldsetProps
  name?: string // For some reason this is not already present in HTMLAttributes<HTMLInputElement>
  dropdownProps?: Partial<DropdownProps>
  disableAutocomplete?: boolean
}

export type SelectItem = Item<unknown>

function _Select<T>(
  {
    className,
    items,
    value,
    label,
    onChange,
    error,
    readOnly,
    enableFiltering,
    customFilter,
    customSelectedItemLabel,
    fieldsetProps,
    dropdownProps,
    disableAutocomplete,
    ...props
  }: Props<T>,
  ref: Ref<HTMLInputElement>
) {
  const [fieldset, setFieldset] = useState<HTMLFieldSetElement | null>(null)
  const innerRef = useRef<HTMLInputElement | null>(null)

  const selectedItem = useMemo(
    () => (value ? items?.find((i) => i?.value === value) : null),
    [items, value]
  )

  const [inputValue, setInputValue] = useState(customSelectedItemLabel || selectedItem?.label || '')
  const debouncedInputValue = useDebounce(inputValue, 300)

  const customFilterRef = useLatestValueRef(customFilter)

  useEffect(() => {
    if (customFilterRef.current) {
      customFilterRef.current(debouncedInputValue)
    }
  }, [customFilterRef, debouncedInputValue])

  const filteredItems = useMemo(() => {
    if (!debouncedInputValue || !enableFiltering) {
      return items?.filter((item) => !!item?.value || !!item?.customOnClick)
    }

    return items?.filter(
      (item) =>
        (!!item?.value || !!item?.customOnClick) &&
        !!item.label &&
        item.label.toLowerCase().includes(debouncedInputValue.toLowerCase())
    )
  }, [enableFiltering, debouncedInputValue, items])

  return (
    <Fieldset
      rightSideContent={
        filteredItems?.length ? (
          <SIconArrow name="CaretDown" onClick={() => innerRef.current?.click()} />
        ) : null
      }
      {...fieldsetProps}
      ref={setFieldset}
      className={className}
      error={error}
      disabled={readOnly}
    >
      <Dropdown
        {...dropdownProps}
        referenceElement={fieldset}
        trigger={
          <SInput
            {...props}
            ref={mergeRefs([ref, innerRef])}
            placeholder="&nbsp;"
            value={customSelectedItemLabel || selectedItem?.label || inputValue}
            onChange={(e) => {
              if (selectedItem) onChange?.(null)
              setInputValue(e.target.value)
            }}
            readOnly={!enableFiltering && !customFilter}
            disabled={readOnly}
            autoComplete={disableAutocomplete ? 'off' : undefined}
          />
        }
        onClose={() => setInputValue('')}
      >
        {filteredItems?.map((item) => {
          const props = {
            onClick: item?.customOnClick || (() => onChange?.(item?.value)),
            selected: item?.value === value,
            key: String(item?.value),
          }
          if (item?.render) {
            return cloneElement(item?.render, props)
          }
          return (
            <SSelectItem {...props} key={props.key}>
              {item?.label}
            </SSelectItem>
          )
        })}
      </Dropdown>

      <SLabel>{label || 'Select'}</SLabel>
    </Fieldset>
  )
}

// Hack due to forwardRef and TS not working well with generics: https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref/58473012
export type TSelect = <T>(p: Props<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement
export const Select = forwardRef(_Select) as TSelect
