import styled from '@emotion/styled'
import { Text } from 'components/Typography'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const SConfirmModal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SHeading = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  margin-right: 2.5rem;
`

export const SIcon = styled.div`
  padding-top: 0.375rem;
`

export const STitle = styled(Text)`
  color: black;
  font-weight: bold;
  font-size: ${pxToRem(21)}rem;
  margin-left: ${pxToRem(12)}rem;
`

export const SText = styled(Text)`
  margin-bottom: ${pxToRem(24)}rem;
`

export const SButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 1.6875rem;
  @media ${BREAKPOINTS.MD.max} {
    & > button {
      flex: 1;
    }
  }
  & > button {
    min-width: 25%;
  }
  & > button:not(:first-of-type) {
    margin-right: ${pxToRem(24)}rem;
  }
`
