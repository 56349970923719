import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const ArticlesGrid = styled.div`
  display: grid;
  grid-gap: ${pxToRem(20)}rem;

  & > * {
    /* grid items seem to have min-width: auto by default, which causes them to overflow their parent in some cases, so we set min-width: 0 to stop this madness */
    min-width: 0;
  }

  position: relative;

  grid-template-columns: repeat(2, 1fr);

  @media ${BREAKPOINTS.MD.min} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${BREAKPOINTS.LG.min} {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const ItemGrid = styled.div<{ minItemWidth?: number }>`
  display: grid;
  grid-gap: ${pxToRem(14)}rem;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ minItemWidth }) => (minItemWidth ? pxToRem(minItemWidth) : pxToRem(200))}rem, 1fr)
  );
  grid-auto-rows: 1fr;

  @media ${BREAKPOINTS.XS.max} {
    grid-template-columns: 1fr;
  }
`

export const InputGrid = styled.div<{
  columns?: number
  disableMobileLayout?: boolean
}>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns || 2}, 1fr);
  grid-auto-rows: min-content;

  grid-gap: 0.625rem;
  grid-row-gap: 1rem;

  margin-bottom: 2rem;

  ${({ disableMobileLayout, columns }) =>
    !disableMobileLayout &&
    css`
      @media ${BREAKPOINTS.MD.max} {
        & > * {
          grid-column-end: span ${columns || 2};
        }
      }
    `}
`
