import { PlanGroup, PlanSpec, PlanType } from 'logic/subscription/plan/types'
import { TFunction } from 'react-i18next'
import { ident, keysOf, numberRange } from 'utils/general'
import { Subset } from 'utils/types'

export const PLAN_GROUPS = keysOf(
  ident<Record<PlanGroup, ''>>({
    ANIMATION: '',
    GROUP_LIFE: '',
    MAIN: '',
    ESTATE: '',
  })
)

const FREE_PLAN: Omit<PlanSpec, 'planGroup' | 'icon'> = {
  displayName: 'Free',
  isFree: true,
  analyticsPlanType: 'free',
}

export const getPerks = (
  t: TFunction<'pricing_plan', undefined>,
  planName: Subset<
    PlanType,
    | 'FREE_PLAN_MAIN'
    | 'ESTATE_PLAN'
    | 'DIGITAL_SAFETY'
    | 'ULTIMATE_PLAN'
    | 'FREE_PLAN_ANIMATION'
    | 'STORY_PLUS'
  >
) => {
  return numberRange(8)
    .map((i) => ({
      title: t(`pricing_plan.plans.${planName}.perks.perk_${i}.title`),
      description: t(`pricing_plan.plans.${planName}.perks.perk_${i}.description`),
    }))
    .filter((perk) => !!perk.title.trim() || !!perk.description.trim())
}

export const getPerksDirective = (
  t: TFunction<'directive', undefined>,
  planName: Subset<PlanType, 'ESTATE_PLANNING'>
) => {
  return numberRange(8)
    .map((i) => ({
      title: t(`directive.upsell_modal.${planName}.perks.perk_${i}.title`),
      description: t(`directive.upsell_modal.${planName}.perks.perk_${i}.description`),
    }))
    .filter((perk) => !!perk.title.trim() || !!perk.description.trim())
}

export const getStoryPortraitsCheckoutPerks = (t: TFunction<'pricing_plan', undefined>) => {
  return numberRange(10)
    .map((i) => ({
      title: t(`pricing_plan.plans.STORY_PLUS.story_portraits_checkout_perks.perk_${i}`),
    }))
    .filter((perk) => !!perk.title.trim())
}

export const getCheckoutPerks = (
  t: TFunction<'pricing_plan', undefined>,
  planName:
    | Subset<PlanType, 'ESTATE_PLAN' | 'DIGITAL_SAFETY' | 'ULTIMATE_PLAN' | 'STORY_PLUS'>
    | 'ANIMATIONS'
) => {
  return numberRange(10)
    .map((i) => ({
      title: t(`pricing_plan.plans.${planName}.checkout_perks.perk_${i}`),
    }))
    .filter((perk) => !!perk.title.trim())
}

const FREE_PLAN_MAIN: PlanSpec = {
  ...FREE_PLAN,
  planGroup: 'MAIN',
  upgradeOptions: ['ULTIMATE_PLAN', 'ESTATE_PLAN', 'DIGITAL_SAFETY', 'PREMIUM_YEARLY'],
  extractPricingSetupFields: (t) => ({
    perks: getPerks(t, 'FREE_PLAN_MAIN'),
  }),
  icon: 'Goodtrust',
  analyticsPlanType: 'free',
}

const FREE_PLAN_ANIMATION: PlanSpec = {
  ...FREE_PLAN_MAIN,
  planGroup: 'ANIMATION',
  upgradeOptions: ['STORY_PLUS'],
}

const FREE_PLAN_GROUP_LIFE: PlanSpec = {
  ...FREE_PLAN_MAIN,
  planGroup: 'GROUP_LIFE',
  upgradeOptions: ['GROUP_LIFE_10K', 'GROUP_LIFE_25K', 'GROUP_LIFE_50K'],
}

const FREE_PLAN_ESTATE: PlanSpec = {
  ...FREE_PLAN_MAIN,
  planGroup: 'ESTATE',
  upgradeOptions: ['ESTATE_PLANNING'],
}

const ESTATE_PLANNING: PlanSpec = {
  icon: 'EstatePlan',
  planGroup: 'ESTATE',
  displayName: 'Estate Plan',
  upgradeOptions: [],
  downgradeOptions: [],
  analyticsPlanType: 'estate',
  extractPricingSetupFields: (t) => {
    const perks = getPerks(t, 'ESTATE_PLAN')
    const checkoutPerks = getCheckoutPerks(t, 'ESTATE_PLAN')
    return {
      title: t('pricing_plan.plans.ESTATE_PLAN.display_name'),
      description: t('pricing_plan.plans.ESTATE_PLAN.description'),
      perks,
      checkoutPerks,
    }
  },
  givesFullDirectiveAccess: true,
  discountType: 'ESTATE_PLAN',
}

const ESTATE_PLAN: PlanSpec = {
  ...ESTATE_PLANNING,
  planGroup: 'MAIN',
  displayName: 'Estate+',
  upgradeOptions: ['ULTIMATE_PLAN'],
  downgradeOptions: ['FREE_PLAN_MAIN'],
  showAsCurrentForLegacyPlans: ['LIFETIME', 'PREMIUM_MONTHLY', 'PREMIUM_YEARLY'],
}
const ESTATE_FAMILY_PLAN: PlanSpec = {
  ...ESTATE_PLAN,
  displayName: 'Estate Family Plan',
  upgradeOptions: [],
  downgradeOptions: [],
}

const PREMIUM_YEARLY: PlanSpec = { ...ESTATE_PLAN, isLegacy: true }
const PREMIUM_MONTHLY: PlanSpec = { ...ESTATE_PLAN, isLegacy: true, isMonthly: true }
const LIFETIME: PlanSpec = {
  ...ESTATE_PLAN,
  displayName: 'Lifetime',
  isLegacy: true,
  isLegacyLifetime: true,
  upgradeOptions: undefined,
  downgradeOptions: undefined,
}
const DIGITAL_SAFETY: PlanSpec = {
  icon: 'ProtectPlan',
  planGroup: 'MAIN',
  displayName: 'Protect+',
  upgradeOptions: ['ULTIMATE_PLAN'],
  downgradeOptions: ['FREE_PLAN_MAIN'],
  extractPricingSetupFields: (t) => ({
    title: t('pricing_plan.plans.DIGITAL_SAFETY.display_name'),
    description: t('pricing_plan.plans.DIGITAL_SAFETY.description'),
    perks: getPerks(t, 'DIGITAL_SAFETY'),
    checkoutPerks: getCheckoutPerks(t, 'DIGITAL_SAFETY'),
  }),
  includesDashlaneAndCyberscout: true,
  analyticsPlanType: 'protect',
  discountType: 'DIGITAL_SAFETY',
}

const ULTIMATE_PLAN: PlanSpec = {
  icon: 'PremiumPlan',
  planGroup: 'MAIN',
  displayName: 'Premium',
  downgradeOptions: ['FREE_PLAN_MAIN', 'DIGITAL_SAFETY', 'ESTATE_PLAN'],
  extractPricingSetupFields: (t) => ({
    title: t('pricing_plan.plans.ULTIMATE_PLAN.display_name'),
    description: t('pricing_plan.plans.ULTIMATE_PLAN.description'),
    perks: getPerks(t, 'ULTIMATE_PLAN'),
    checkoutPerks: getCheckoutPerks(t, 'ULTIMATE_PLAN'),
  }),
  topHighlight: true,
  includesDashlaneAndCyberscout: true,
  analyticsPlanType: 'ultimate',
  givesFullDirectiveAccess: true,
  discountType: 'ULTIMATE_PLAN',
}

const GROUP_LIFE_10K: PlanSpec = {
  planGroup: 'GROUP_LIFE',
  displayName: 'Life Insurance $10,000',
  downgradeOptions: ['FREE_PLAN_GROUP_LIFE'],
  upgradeOptions: ['GROUP_LIFE_25K', 'GROUP_LIFE_50K'],
  icon: 'LifeInsurance',
  includes: [],
  isMonthly: true,
  groupLifeCoverageInCents: 10e5,
  analyticsPlanType: '10000',
}
const GROUP_LIFE_25K: PlanSpec = {
  planGroup: 'GROUP_LIFE',
  displayName: 'Life Insurance $25,000',
  downgradeOptions: ['FREE_PLAN_GROUP_LIFE', 'GROUP_LIFE_10K'],
  upgradeOptions: ['GROUP_LIFE_50K'],
  icon: 'LifeInsurance',
  includes: [],
  isMonthly: true,
  groupLifeCoverageInCents: 25e5,
  analyticsPlanType: '25000',
}
const GROUP_LIFE_50K: PlanSpec = {
  planGroup: 'GROUP_LIFE',
  displayName: 'Life Insurance $50,000',
  downgradeOptions: ['FREE_PLAN_GROUP_LIFE', 'GROUP_LIFE_25K', 'GROUP_LIFE_10K'],
  upgradeOptions: [],
  icon: 'LifeInsurance',
  includes: [],
  isMonthly: true,
  groupLifeCoverageInCents: 50e5,
  analyticsPlanType: '50000',
}

const STORY_PLUS: PlanSpec = {
  planGroup: 'ANIMATION',
  displayName: 'Story+',
  downgradeOptions: ['FREE_PLAN_ANIMATION'],
  upgradeOptions: [],
  icon: 'Layers',
  includes: [],
  isMonthly: false,
  isWeekly: true,
  discountType: 'ANIMATION_SUBSCRIPTION',
  analyticsPlanType: 'animation',
  animationsPerWeek: 10,
}

const ANIMATION_SUBSCRIPTION: PlanSpec = {
  ...STORY_PLUS,
  displayName: 'Animation Membership',
  isMonthly: true,
  isWeekly: false,
  animationsPerWeek: undefined,
  animationsPerMonth: 10,
}

export const planSpecs: Record<PlanType, PlanSpec> = {
  ESTATE_PLAN,
  ESTATE_PLANNING,
  ESTATE_FAMILY_PLAN,
  DIGITAL_SAFETY,
  PREMIUM_MONTHLY,
  PREMIUM_YEARLY,
  LIFETIME,
  FREE_PLAN_MAIN,
  FREE_PLAN_ANIMATION,
  FREE_PLAN_GROUP_LIFE,
  FREE_PLAN_ESTATE,
  ULTIMATE_PLAN,
  ANIMATION_SUBSCRIPTION,
  STORY_PLUS,
  GROUP_LIFE_10K,
  GROUP_LIFE_25K,
  GROUP_LIFE_50K,
}

export function isPlanType(str?: string): str is PlanType {
  return !!(str && str in planSpecs)
}

export const plansLikeEstatePlan: PlanType[] = [
  'ESTATE_PLAN',
  'ESTATE_PLANNING',
  'ESTATE_FAMILY_PLAN',
  'PREMIUM_MONTHLY',
  'PREMIUM_YEARLY',
  'LIFETIME',
]
