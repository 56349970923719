import useSWR from 'swr'
import { FetchResponse } from 'utils/fetcher'
import { ApiType } from 'utils/types'
import { apiAuthFetcher } from 'api/goodtrust/api'
import { getDirectiveKey } from './utils'

export function useUserMedical(uuid: string) {
  return useSWR<FetchResponse<ApiType['UserDirectiveMedicalResponse']>>(
    uuid ? getDirectiveKey(uuid, 'MEDICAL') : null,
    apiAuthFetcher,
    { revalidateOnFocus: false }
  )
}

export function postUserMedicalFinish(uuid: string) {
  return apiAuthFetcher<ApiType['UserDirectiveResponse']>(
    `${getDirectiveKey(uuid, 'MEDICAL')}/finish`,
    {
      method: 'POST',
    }
  )
}

export function downloadUserMedical(uuid: string) {
  return apiAuthFetcher(`${getDirectiveKey(uuid, 'MEDICAL')}/generate`, {
    toBlob: true,
    noThrowOnError: true,
  })
}
