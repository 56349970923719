import immer, { Draft } from 'immer'
import { CobrandedPartnerSlug } from 'utils/cobranding/partners'
import { GoodtrustApiMockDirectiveSlice } from 'utils/mocks/api/slices/directive'
import { GoodtrustApiMockPromocodeSlice } from 'utils/mocks/api/slices/promocode'
import { GoodtrustApiMockTokenSlice } from 'utils/mocks/api/slices/token'
import { GoodtrustApiMockUserSlice } from 'utils/mocks/api/slices/user'
import { ApiType } from 'utils/types'

export type GoodtrustApiMockState = {
  isLoggedIn: boolean
  userTips: ApiType['SetUserTipCommand']
  claimedPromos: ApiType['ClaimedListResponse']

  mockTrust: ApiType['UserDirectiveTrustResponse']
}

function initState(): GoodtrustApiMockState {
  return {
    isLoggedIn: false,
    userTips: {},
    claimedPromos: {
      promo_codes: [],
    },
    mockTrust: {
      owner_uuid: 'mock-user',
      uuid: 'mock-directive',
      state: 'IN_DRAFT',

      section_state: {
        last_section: 'ABOUT_YOU',
        visited_sections: [],
      },
      family: {
        marital_status: 'MARRIED',
      },
    },
  }
}

export class GoodtrustApiMock {
  state: GoodtrustApiMockState = initState()

  constructor(
    private urlPrefix: string,
    private stateUpdate?: Partial<GoodtrustApiMockState>,
    private onUpdated?: (state: GoodtrustApiMockState) => void
  ) {
    this.resetState()
  }

  resetState() {
    this.state = { ...initState(), ...this.stateUpdate }
    this.onUpdated?.(this.state)
  }

  updateState(recipe: (st: Draft<GoodtrustApiMockState>) => void) {
    this.state = immer(this.state, recipe)
    this.onUpdated?.(this.state)
  }

  mockCobrandedPartner(partnerSlug: CobrandedPartnerSlug): ApiType['PartnerResponse'] {
    return {
      uuid: 'mock-uuid-' + partnerSlug,
      cobranded_partner: partnerSlug,
      title: `Mock Partner ${partnerSlug}`,
      partner_photo: {
        url: 'http://example.com/partner.jpg',
      },
    }
  }

  prefixed(url: string) {
    return `${this.urlPrefix}${url}`
  }

  handlers() {
    return [
      ...new GoodtrustApiMockDirectiveSlice(this).handlers(),
      ...new GoodtrustApiMockPromocodeSlice(this).handlers(),
      ...new GoodtrustApiMockUserSlice(this).handlers(),
      ...new GoodtrustApiMockTokenSlice(this).handlers(),
    ]
  }
}
