import { ErrorMessage } from 'components/Toast'
import IsoFormData from 'isomorphic-form-data'
import { ApiError } from 'utils/error'
import { logError } from 'utils/general'

export type FetchArgs = Omit<RequestInit, 'body'> & {
  body?: Record<string, unknown> | string | IsoFormData
  toBlob?: boolean
  nullifyBody?: boolean
  noContentTypeHeader?: boolean
}

export interface FetchResponse<T> {
  ok: boolean
  status: number
  headers?: Headers
  errorCode?: ErrorMessage
  error?: string | Error | null
  json?: T
}

export interface FetchBlobResponse {
  ok: boolean
  status: number
  headers?: Headers
  errorCode?: ErrorMessage
  error?: string | Error | null
  blob?: Blob
}

export interface FetchCall {
  (
    path: string,
    authToken: string | null,
    args: FetchArgs & {
      toBlob: true
      noThrowOnError?: boolean
    }
  ): Promise<FetchBlobResponse>

  (
    path: string | string[],
    args: FetchArgs & {
      toBlob: true
      noThrowOnError?: boolean
    }
  ): Promise<FetchBlobResponse>

  <T = { [key: string]: string }>(
    path: string,
    authToken: string | null,
    args?: FetchArgs & {
      toBlob?: false
      noThrowOnError?: boolean
    }
  ): Promise<FetchResponse<T>>

  <T = { [key: string]: string }>(
    path: string | string[],
    args?: FetchArgs & {
      toBlob?: false
      noThrowOnError?: boolean
    }
  ): Promise<FetchResponse<T>>
}

export function fetcherFactory(baseUrl = '', attachAcceptHeader = false): FetchCall {
  async function fetchCall<T = { [key: string]: string } | Blob>(
    pathArg: string | Array<string & FetchArgs>,
    firstArg?: string | FetchArgs | null,
    secondArg?: FetchArgs
  ): Promise<FetchResponse<T> | FetchBlobResponse> {
    try {
      let authToken = null
      let path = null
      let fetchArgs: FetchArgs = {}

      if (typeof firstArg === 'string') {
        authToken = firstArg
        fetchArgs = secondArg ?? {}
      } else if (typeof firstArg === 'object' && firstArg !== null) {
        fetchArgs = firstArg
      }

      if (Array.isArray(pathArg)) {
        path = pathArg[0]
        fetchArgs = pathArg[1] || {}
      } else if (typeof pathArg === 'string') {
        path = pathArg
      }

      let body
      if (typeof fetchArgs?.body === 'object') {
        if (fetchArgs?.body instanceof IsoFormData) {
          body = fetchArgs?.body as unknown as FormData // IMPROVE: wonder, whether we can hint TS to cast this properly...
        } else {
          body = fetchArgs?.nullifyBody
            ? JSON.stringify(fetchArgs?.body)?.replace(/:""/g, ':null')
            : JSON.stringify(fetchArgs?.body)
        }
      } else {
        body = fetchArgs?.body
      }
      if (process.env.NODE_ENV === 'test' && baseUrl === '' && path?.startsWith('/')) {
        baseUrl = 'http://test.localhost'
      }

      const result = await fetch(`${baseUrl}${path}`, {
        ...fetchArgs,
        headers: {
          ...(attachAcceptHeader
            ? {
                Accept: `application/com.goodtrust-v1+${
                  fetchArgs.toBlob ? 'octet_stream' : 'json'
                }`,
              }
            : {}),
          ...(fetchArgs?.body instanceof IsoFormData || fetchArgs.noContentTypeHeader
            ? {}
            : { 'Content-Type': 'application/json' }),
          ...(fetchArgs?.headers || {}),
          ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        },
        body: body,
      })

      const payload = {
        ok: result.ok,
        headers: result.headers,
        status: result.status,
      }

      if (fetchArgs.toBlob) {
        return {
          ...payload,
          blob: await result.blob(),
        }
      }

      const contentTypeHeader = result.headers.get('Content-Type')
      const isJsonBody =
        contentTypeHeader?.includes('application/json') ||
        contentTypeHeader?.includes('application/com.goodtrust-v1+json')
      const json = isJsonBody ? await result.json() : null

      if (!payload.ok) {
        return {
          ...payload,
          errorCode: json?.code,
          error: Array.isArray(json?.message) ? json.message.join(', ') : json?.message,
          json,
        }
      }

      return {
        ...payload,
        json,
      }
    } catch (err) {
      logError(err)
      return { ok: false, status: 500, error: err }
    }
  }

  return fetchCall
}

function filterQuery(
  x: [string, string | string[] | number | number[] | boolean | undefined | null]
): x is [string, string | number | boolean] {
  return x[1] != null && x[1] !== ''
}

export type QueryParams = {
  [key: string]: string | string[] | number | number[] | boolean | undefined | null
}
export const encodeQuery = (url: string, args: QueryParams = {}): string => {
  const params = Object.entries(args)
    .filter(filterQuery)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value?.toString?.())}`)

  if (params.length > 0) {
    const questionMarkIndex = url.split('').findIndex((c) => c === '?')
    const urlHasQuestionMark = questionMarkIndex >= 0
    const joiningCharacter = urlHasQuestionMark ? '&' : '?'
    return [url, params.join('&')].join(joiningCharacter)
  }
  return url
}

export const unwrapResponseBlob: {
  (res: FetchBlobResponse): FetchBlobResponse
} = (res) => {
  if (!res.ok) {
    throw new ApiError(res)
  }
  return res
}

export const unwrapResponse: {
  <T>(res: FetchResponse<T>): FetchResponse<T>
  body: <T>(res: FetchResponse<T>) => T
} = (res) => {
  if (!res.ok) {
    throw new ApiError(res)
  }
  return res
}

unwrapResponse.body = (res) => {
  res = unwrapResponse(res)
  if (!res.json) {
    throw new ApiError(res)
  }
  return res.json
}

export function handleApiErrors<T = never>(config: {
  byCode?: Record<string, () => T>
  defaultMessage?: string
}) {
  return (err: unknown): T => {
    if (!(err instanceof ApiError)) {
      // rethrow error
      throw err
    }

    const { errorCode } = err.res

    const handler = errorCode && config.byCode?.[errorCode]
    if (!handler) {
      if (config.defaultMessage) err.addDefaultMessage(config.defaultMessage)
      throw err
    }

    return handler()
  }
}
