import { useCallback, useEffect, useMemo } from 'react'
import { SWRInfiniteResponse } from 'swr/infinite/dist/infinite'
import { encodeQuery, FetchResponse, QueryParams } from 'utils/fetcher'
import { noop } from 'utils/general'
import { useLatestCallback } from 'utils/hooks'
import { GenericFunction } from './types'

export type PaginatedResponse = SWRInfiniteResponse<
  FetchResponse<{
    current_page?: number
    current_page_size?: number
    pages_total?: number
    items_count?: number
    items_count_total?: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items?: any[]
  }>
>

export type PaginationMetaData = {
  isLoadingInitial?: boolean
  isLoadingMore?: boolean
  isEmpty?: boolean
  isReachingEnd?: boolean
  handleLoadMore?: GenericFunction
}

type PaginationMetaDataGetter = (swr: PaginatedResponse) => PaginationMetaData

export const getPaginationMeta: PaginationMetaDataGetter = (swr) => {
  const isLoadingInitial = !swr.data && !swr.error
  const isLoadingMore =
    isLoadingInitial || (swr.data && typeof swr.data[(swr.size ?? 0) - 1] === 'undefined')

  const isEmpty = swr.data?.[0]?.json?.items_count === 0
  const isReachingEnd =
    isEmpty ||
    (swr.data &&
      (swr.data[swr.data.length - 1]?.json?.current_page ?? 0) + 1 >=
        (swr.data[swr.data.length - 1]?.json?.pages_total ?? 0))

  const handleLoadMore = () => swr.setSize?.((swr.size ?? 0) + 1)

  return {
    isLoadingInitial,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    handleLoadMore,
  }
}

export function usePaginateToEnd(swr: PaginatedResponse) {
  const meta = useMemoizedPaginationMeta(swr)

  const { isLoadingInitial, isLoadingMore, isReachingEnd } = meta
  const handleLoadMore = useLatestCallback(meta.handleLoadMore ?? noop)

  useEffect(() => {
    if (isLoadingInitial) return

    if (!isLoadingMore && !isReachingEnd) {
      handleLoadMore?.()
    }
  }, [isLoadingInitial, isLoadingMore, isReachingEnd, handleLoadMore])

  return meta
}

export const useMemoizedPaginationMeta: PaginationMetaDataGetter = (swr) => {
  const { isLoadingInitial, isLoadingMore, isEmpty, isReachingEnd } = getPaginationMeta(swr)

  const { setSize, size } = swr
  const handleLoadMore = useCallback(() => setSize?.((size ?? 0) + 1), [size, setSize])

  return useMemo(
    () => ({
      isLoadingInitial,
      isLoadingMore,
      isEmpty,
      isReachingEnd,
      handleLoadMore,
    }),
    [handleLoadMore, isEmpty, isLoadingInitial, isLoadingMore, isReachingEnd]
  )
}

export function encodePageQuery(
  url: string,
  prevPageData:
    | {
        json?: {
          current_page?: number
          pages_total?: number
        }
      }
    | undefined
    | null,
  args: QueryParams = {}
) {
  if (
    prevPageData &&
    (prevPageData?.json?.current_page ?? 0) >= (prevPageData?.json?.pages_total ?? 0)
  ) {
    return null
  }

  return encodeQuery(url, {
    page: (prevPageData?.json?.current_page ?? -1) + 1,
    ...args,
  })
}
