import { CartType, ICart } from './types'

//cart
export const STORE_CART_SESSION_KEY = 'cart/v2'
export const STORE_CART_KEY = '@cart'
// packages cart
export const PACKAGES_CART_SESSION_KEY = 'packages_cart'
export const PACKAGES_CART_KEY = '@packagesCart'
// premium cart
export const PLAN_CART_SESSION_KEY = 'plan_cart'

export const PLACEHOLDER_CART: ICart = {
  items: {},
  discount: null,
  timestamp: 0,
}
export const PLACEHOLDER_PACKAGES_CART: ICart = {
  count: 0,
  price: 0,
  discount: null,
  timestamp: 0,
}

export const getKeyFromSessionKey = (type: CartType) => {
  if (type === STORE_CART_SESSION_KEY) return STORE_CART_KEY
  else if (type === PACKAGES_CART_SESSION_KEY) return PACKAGES_CART_KEY
  else return type
}

export const getPlaceholderCart = (type: CartType) => {
  if (type === STORE_CART_SESSION_KEY) return PLACEHOLDER_CART
  else if (type === PACKAGES_CART_SESSION_KEY) return PLACEHOLDER_PACKAGES_CART
  else return null
}

export const CYBERSCOUT_COUNTRIES = ['US', 'CA']
