import { getDirectiveSpec } from 'components/directive/utils/directiveSpecs'
import { DirectiveType } from 'components/directive/utils/types'
import { getDirectiveSimpleFlowSignupUrl } from 'logic/directive/getDirectiveSimpleFlowSignupUrl'
import { describeSimpleFlowDirectiveLandingPage } from 'logic/directive/landing/describe'
import {
  DirectiveTypeSpec,
  SimpleDirectiveStep,
  SimpleFlowDirectiveType,
} from 'logic/directive/types'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { getUrlPathFromEnumValue } from 'utils/format'
import { isOneOf, matchMap } from 'utils/general'
import { ApiType } from 'utils/types'

export function describeDirective<T extends DirectiveType>(directiveType: T) {
  function simpleFlow() {
    return directiveType === 'WILL' || directiveType === 'TRUST'
      ? describeSimpleDirectiveFlow(directiveType)
      : undefined
  }

  function advancedFlow() {
    return describeAdvancedDirectiveFlow(directiveType)
  }

  function isSectionPartOfSimpleFlow(section: string): section is SimpleDirectiveStep {
    return isOneOf(section, ['BASICS_ABOUT_YOU', 'BASICS_FAMILY'])
  }

  return {
    getLandingPageUrl: () => getDirectiveSpec(directiveType).landingPagePath,
    simpleFlow,
    advancedFlow,
    pickFlow: (
      ctx: { isLoggedIn: true; userMe: ApiType['UserResponse'] } | { isLoggedIn: false }
    ) =>
      ctx.isLoggedIn && describeMyPlans(ctx.userMe).hasFullDirectiveAccess()
        ? advancedFlow()
        : simpleFlow() ?? advancedFlow(),
    isSectionPartOfSimpleFlow,
    hasVisitedSimpleFlow: (x: DirectiveTypeSpec[T]['response']['section_state'] | undefined) =>
      x?.visited_sections?.some(isSectionPartOfSimpleFlow),
  }
}

export function describeSimpleDirectiveFlow(directiveType: SimpleFlowDirectiveType) {
  return {
    type: 'simple' as const,
    // links to other describes
    withDirective: (directive: Pick<ApiType['UserDirectiveResponse'], 'uuid' | 'last_section'>) =>
      describeSimpleFlowDirectiveWithDirective(directiveType, directive),
    landingPage: () => describeSimpleFlowDirectiveLandingPage(directiveType),
    //
    getDirectiveType: () => directiveType,
    getSignupUrl: () => getDirectiveSimpleFlowSignupUrl(directiveType),
    getInitialSection: () => 'BASICS_ABOUT_YOU' as const,
    getNextSection: (section: SimpleDirectiveStep) =>
      matchMap(section, {
        BASICS_ABOUT_YOU: 'BASICS_FAMILY',
        BASICS_FAMILY: 'CHECKOUT',
      } as const),
    shouldRedirectToCheckoutFromAdvancedFlowSteps: (
      userMe: ApiType['UserResponse'],
      isReadOnly: boolean
    ) => !describeMyPlans(userMe).hasFullDirectiveAccess() && !isReadOnly,
  }
}

export function describeAdvancedDirectiveFlow(directiveType: DirectiveType) {
  return {
    type: 'advanced' as const,
    getDirectiveType: () => directiveType,
    getInitialSection: () => 'ABOUT_YOU' as const,
  }
}

export function describeSimpleFlowDirectiveWithDirective(
  directiveType: SimpleFlowDirectiveType,
  directive: Pick<ApiType['UserDirectiveResponse'], 'uuid' | 'last_section'>
) {
  const { insidePath } = getDirectiveSpec(directiveType)
  const directivePath = [insidePath, directive.uuid].join('/')

  function getSectionUrl(section: SimpleDirectiveStep | 'CHECKOUT') {
    return [
      directivePath,
      matchMap(section, {
        BASICS_ABOUT_YOU: 'about',
        BASICS_FAMILY: 'family',
        CHECKOUT: 'checkout',
      }),
    ].join('/')
  }
  return {
    getInitialStepUrl: () => getSectionUrl('BASICS_ABOUT_YOU'),
    getSectionUrl,
    getCheckoutUrl: () => getSectionUrl('CHECKOUT'),
    getUrlAfterCheckout: () => {
      const nextStep =
        directive.last_section === 'BASICS_FAMILY' ||
        directive.last_section === 'BASICS_ABOUT_YOU' ||
        directive.last_section == null
          ? // if coming from simple flow, the next step is your family for both trust and will
            'YOUR_FAMILY'
          : // otherwise, the user visited an advanced step before and we should put them back where the user came from
            directive.last_section

      return [directivePath, 'steps', getUrlPathFromEnumValue(nextStep)].join('/')
    },
  }
}
