import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { SUPPORT_URL } from 'utils/constants'
import { ApiError, MessageException, RouteAbortException } from 'utils/error'
import { logError } from 'utils/general'
import { Button } from './button/Button'
import { Icon } from './icon'
import { Trans, useTranslation } from 'next-i18next'
import { Namespaces } from 'utils/i18n/types'
import reactToast from 'react-hot-toast'
import { BasicLink } from 'components/Link'

export type TranslationParameter = Record<string, string | number | undefined>

export const BaseToast = styled.div`
  border-radius: 2px;
  color: var(--c-white);
  padding: 16px 28px;
  font-family: var(--f-text);
  display: flex;
  align-items: center;
  box-shadow: var(--card-shadow);

  & > svg {
    flex-shrink: 0;
  }
`

export const SProgressToast = styled(BaseToast)`
  background-color: white;
  color: black;
  font-family: var(--f-text);
  justify-content: center;
  top: unset;
  bottom: 1rem;
`

const SuccessToast = (props: {
  onClose?: () => void
  onCloseClick?: () => void
  className?: string
  children?: ReactNode
  translationKey?: SuccessMessage
  parameters: TranslationParameter
}) => {
  const { t } = useTranslation()
  return (
    <BaseToast
      className={props.className}
      css={css`
        background: var(--c-blue500);
      `}
    >
      <div>
        {props.translationKey
          ? t?.(`common.success_codes.${props.translationKey}`, props.parameters)
          : props.children}
      </div>
      {props.onCloseClick && (
        <button
          onClick={() => {
            if (props.onCloseClick) props.onCloseClick()
            props.onClose?.()
          }}
        >
          Close
        </button>
      )}
    </BaseToast>
  )
}

const ErrorToast = (props: {
  onClose?: () => void
  onCloseClick?: () => void
  className?: string
  children?: ReactNode
  translationKey?: `common.error_codes.${ErrorMessage}`
  parameters: TranslationParameter
}) => {
  const { t } = useTranslation()

  return (
    <BaseToast
      className={props.className}
      css={css`
        background: var(--c-red500);
      `}
    >
      <div>{props.translationKey ? t(props.translationKey, props.parameters) : props.children}</div>
      {props.onCloseClick && (
        <button
          onClick={() => {
            if (props.onCloseClick) props.onCloseClick()
            props.onClose?.()
          }}
        >
          Close
        </button>
      )}
    </BaseToast>
  )
}

const AccountDeletedToast = () => {
  const { t } = useTranslation()
  return (
    <BaseToast
      css={css`
        background: var(--c-red500);
      `}
    >
      <div>
        <Trans
          t={t}
          i18nKey="common.error_codes.account_deleted_contact_support"
          components={[
            <BasicLink key="0" href={SUPPORT_URL} target="_blank" style={{ color: 'inherit' }} />,
          ]}
        />
      </div>
    </BaseToast>
  )
}

const ProgressToast = (props: {
  onClose?: () => void
  onCloseClick?: () => void
  className?: string
  percentage?: number
  children?: ReactNode
}) => {
  return (
    <SProgressToast className={props.className}>
      <div>{props.children}</div>
      {props.onCloseClick && (
        <Button
          type="button"
          variant="text"
          tone="dark"
          onClick={() => {
            if (props.onCloseClick) props.onCloseClick()
            props.onClose?.()
          }}
        >
          <Icon name="Close" size={12} />
        </Button>
      )}
    </SProgressToast>
  )
}

export function handleError(error?: Error | string | null): undefined {
  const shouldLogError = error && !(error instanceof MessageException)
  if (shouldLogError) {
    logError(error)
  }
  return undefined
}

export type ErrorMessage = keyof Namespaces['common']['common']['error_codes']

export type CaughtError = Error | string | null | undefined

export function handleAndToastError(
  error?: CaughtError,
  fallback?: ErrorMessage,
  options?: {
    duration?: number
    position?: 'bottom-center' | 'top-center'
  },
  parameters?: TranslationParameter
): undefined {
  if (error != null && error instanceof RouteAbortException) {
    return undefined
  }
  toastCaughtError(
    error ? error : undefined,
    fallback ?? 'something_went_wrong',
    options,
    parameters
  )
  return handleError(error)
}

export function toastCaughtError(
  err: CaughtError,
  fallback: ErrorMessage,
  options?: {
    duration?: number
    position?: 'bottom-center' | 'top-center'
  },
  parameters?: TranslationParameter
) {
  if (err instanceof Error) {
    toastError(err, options, parameters, fallback)
  } else if (typeof err === 'string') {
    toastErrorString(err, options)
  } else {
    toastError(fallback, options, parameters, fallback)
  }
}
export function toastError(
  content?: ErrorMessage | Error,
  options?: {
    duration?: number
    position?: 'bottom-center' | 'top-center'
  },
  parameters?: TranslationParameter,
  fallback?: ErrorMessage
) {
  if (content instanceof MessageException) {
    toast({
      type: 'error',
      translationKey: `common.error_codes.${content.key}`,
      options,
      parameters: parameters ?? content.params,
    })
    return
  } else if (content instanceof ApiError && content.errorCode) {
    toast({
      type: 'error',
      translationKey: `common.error_codes.${content.errorCode}`,
      options,
      parameters,
    })
    return
  } else if (content instanceof Error) {
    if (content.message) {
      toastErrorString(content.message, options)
    } else {
      toast({
        type: 'error',
        translationKey: `common.error_codes.${fallback || 'something_went_wrong'}`,
        options,
        parameters,
      })
    }
    return
  }
  toast({
    type: 'error',
    translationKey: content
      ? `common.error_codes.${content}`
      : `common.error_codes.${fallback || 'something_went_wrong'}`,
    options,
    parameters,
  })
}

export function toastErrorString(
  content?: ReactNode | Error,
  options?: {
    duration?: number
    position?: 'bottom-center' | 'top-center'
  }
) {
  toast({ type: 'error', content: content ?? 'Something went wrong', options })
}

export type SuccessMessage = keyof Namespaces['common']['common']['success_codes']

export function toastSuccess(
  content: SuccessMessage,
  options?: {
    duration?: number
    position?: 'bottom-center' | 'top-center'
  },
  parameters: TranslationParameter = {}
) {
  toast({ type: 'success', translationKey: content, options, parameters })
}
export function toastSuccessString(content: ReactNode) {
  toast({ type: 'success', content: content })
}

type GeneralToastParams = {
  content?: Error | ReactNode
  onCloseRequest?: () => void
  options?: {
    duration?: number
    position?: 'bottom-center' | 'top-center'
  }
  grabCloseCallback?: (f: () => void) => void
  parameters?: TranslationParameter
}

type SuccessToastParams = GeneralToastParams & {
  type: 'success'
  translationKey?: SuccessMessage
}
type ErrorToastParams = GeneralToastParams & {
  type: 'error'
  translationKey?: `common.error_codes.${ErrorMessage}`
}
type ProgressToastParams = GeneralToastParams & {
  type: 'progress'
  translationKey?: undefined
}

type AccountDeletedParams = {
  type: 'account_deleted'
  grabCloseCallback?: (f: () => void) => void
  options?: {
    duration?: number
    position?: 'bottom-center' | 'top-center'
  }
}

export function toast(
  params: SuccessToastParams | ErrorToastParams | ProgressToastParams | AccountDeletedParams
) {
  if (params.grabCloseCallback) params.grabCloseCallback(reactToast.dismiss)
  return reactToast(
    () => {
      switch (params.type) {
        case 'success': {
          return (
            <SuccessToast
              onCloseClick={params.onCloseRequest}
              translationKey={params.translationKey}
              onClose={reactToast.dismiss}
              parameters={params.parameters ?? {}}
            >
              {params.content instanceof Error ? params.content?.message : params.content}
            </SuccessToast>
          )
        }

        case 'error': {
          return (
            <ErrorToast
              onCloseClick={params.onCloseRequest}
              translationKey={params.translationKey}
              onClose={reactToast.dismiss}
              parameters={params.parameters ?? {}}
            >
              {params.content instanceof Error ? params.content?.message : params.content}
            </ErrorToast>
          )
        }
        case 'progress': {
          return (
            <ProgressToast onCloseClick={params.onCloseRequest} onClose={reactToast.dismiss}>
              {params.content instanceof Error ? params.content?.message : params.content}
            </ProgressToast>
          )
        }
        case 'account_deleted': {
          return <AccountDeletedToast />
        }
        default:
          return null
      }
    },
    {
      position: params.type === 'progress' ? 'bottom-center' : 'top-center',
      duration: params.type === 'progress' ? undefined : 5000,
      className: 'hot-toast',
      ...(params.options ?? {}),
    }
  )
}
