import { CYBERSCOUT_COUNTRIES } from 'utils/cart/contants'
import { bound } from 'utils/class'

export function describeCyberscoutCountry(country: string | undefined) {
  return bound({
    isEligibleForCyberscout() {
      return !!(country && CYBERSCOUT_COUNTRIES.includes(country))
    },
    shouldDisplayAsEligibleForCyberscout() {
      return !country || this.isEligibleForCyberscout()
    },
  })
}
