import { css } from '@emotion/react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { getResponsiveStyles, ResponsiveValue } from 'utils/styled'

export const Spacer = styled('span', {
  shouldForwardProp: (prop) => isPropValid(prop as string) && prop !== 'size',
})<{
  size: ResponsiveValue<number | string>
  axis?: ResponsiveValue<'horizontal' | 'vertical'>
  parentStackGap?: ResponsiveValue<number | string>
}>`
  display: block;

  ${({ size: cssSize, axis, parentStackGap }) => [
    parentStackGap
      ? getResponsiveStyles(parentStackGap, (gap) => {
          const locAxis = axis ?? 'vertical'
          const negatedGap = `-${gap}`
          return getResponsiveStyles(locAxis, {
            vertical: {
              marginTop: negatedGap,
              marginBottom: negatedGap,
            },
            horizontal: {
              marginLeft: negatedGap,
              marginRight: negatedGap,
            },
          })
        })
      : undefined,
    getResponsiveStyles(cssSize, (value) => {
      const serialized = typeof value === 'number' ? `${value}rem` : value
      const locAxis = axis ?? 'vertical'

      return (
        getResponsiveStyles(locAxis, {
          horizontal: css`
            width: ${serialized};
            min-width: ${serialized};
            height: 100%;
            min-height: 1px;
          `,
          vertical: css`
            width: 100%;
            min-width: 1px;
            height: ${serialized};
            min-height: ${serialized};
          `,
        }) ??
        css`
          width: ${serialized};
          min-width: ${serialized};
          height: ${serialized};
          min-height: ${serialized};
        `
      )
    }),
  ]}
`
