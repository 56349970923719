import { TrustSectionKey } from 'components/directive/trust/types'
import { isValidationErrorRelatedToAboutYou } from 'components/directive/utils/isValidationErrorRelatedToAboutYou'
import { DirectiveSectionSpecification } from 'components/directive/utils/types'

export const TRUST_DIRECTIVE_PROGRESS: DirectiveSectionSpecification<TrustSectionKey>[] = [
  {
    name: 'directive.trust.about_you.nav_title',
    mobile: 'directive.trust.about_you.nav_title_short',
    value: 'ABOUT_YOU',
    url: 'about-you',
    subsections: [
      {
        sectionKey: 'ABOUT_YOU',
        isValidationErrorRelated: isValidationErrorRelatedToAboutYou,
      },
      {
        sectionKey: 'MORE_ABOUT_YOU',
      },
    ],
  },
  {
    name: 'directive.trust.form.your_family.nav_title',
    mobile: 'directive.trust.form.your_family.nav_title_short',
    value: 'YOUR_FAMILY',
    url: 'your-family',
  },
  {
    name: 'directive.trust.form.trust.nav_title',
    mobile: 'directive.trust.form.trust.nav_title_short',
    value: 'TRUST',
    url: 'trust',
  },
  {
    name: 'directive.trust.form.distribution_of_property.nav_title',
    mobile: 'directive.trust.form.distribution_of_property.nav_title_short',
    value: 'DISTRIBUTION_OF_PROPERTY',
    url: 'distribution-of-property',
  },
  {
    name: 'directive.trust.form.remaining_estate.nav_title',
    mobile: 'directive.trust.form.remaining_estate.nav_title_short',
    value: 'REMAINING_ESTATE',
    url: 'remaining-estate',
  },
  {
    name: 'directive.trust.form.pour_over_will.nav_title',
    mobile: 'directive.trust.form.pour_over_will.nav_title_short',
    value: 'POUR_OVER_WILL',
    url: 'pour-over-will',
  },
  {
    name: 'directive.trust.review_nav_title',
    mobile: 'directive.trust.review_nav_title',
    value: 'REVIEW',
    url: 'review',
  },
]
