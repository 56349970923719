import { getUserMe } from 'api/goodtrust/user'
import { postUserDirective } from 'api/goodtrust/user/directive'
import { getDirectiveSpec } from 'components/directive/utils/directiveSpecs'
import { DirectiveType } from 'components/directive/utils/types'
import { sendSimpleFlowStartedEvent } from 'logic/directive/analytics/sendSimpleFlowStartedEvent'
import { describeDirective, describeSimpleDirectiveFlow } from 'logic/directive/describe'
import { getDirectiveEditLink } from 'logic/directive/links'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import Router from 'next/router'
import { EventArgs } from 'utils/analytics/types'
import { encodeQuery, unwrapResponse } from 'utils/fetcher'

export type CreateDirectiveContext = {
  /** We let the caller specify the method/mechanism we use from the router to do the redirection.
   * We want replace for pages like signup or login (we don't want the user coming back there, as they are already logged in by then)
   * and push for other pages, as we want to keep them in history.  */
  routerMechanism: 'push' | 'replace'
  isLogged: boolean
  fromInside?: boolean
  simpleFlowPlace?: EventArgs['est_flow_started']['place']
}

export async function createDirectiveAndRedirect(
  directiveType: DirectiveType,
  ctx: CreateDirectiveContext
) {
  const flow = describeDirective(directiveType).pickFlow(
    ctx.isLogged
      ? await getUserMe()
          .then(unwrapResponse.body)
          .then((userMe) => ({ isLoggedIn: true, userMe }))
      : { isLoggedIn: false }
  )

  if (flow.type === 'simple') {
    await createSimpleFlowDirective(flow.getDirectiveType(), ctx)
  } else {
    await createAdvancedFlowDirective(directiveType, ctx)
  }
}

export async function createSimpleFlowDirective(
  directive_type: SimpleFlowDirectiveType,
  ctx: CreateDirectiveContext
) {
  const flow = describeSimpleDirectiveFlow(directive_type)

  sendSimpleFlowStartedEvent(directive_type, ctx.simpleFlowPlace ?? 'unknown')

  if (!ctx.isLogged) {
    await Router[ctx.routerMechanism](flow.getSignupUrl())
    return
  }

  const directive = await postUserDirective({ directive_type }).then(unwrapResponse.body)

  Router[ctx.routerMechanism](
    // redirect to the initial step
    flow.withDirective(directive).getInitialStepUrl()
  )
}

export async function createAdvancedFlowDirective(
  directive_type: DirectiveType,
  ctx: CreateDirectiveContext
) {
  if (!ctx.isLogged) {
    const { insidePath } = getDirectiveSpec(directive_type)
    await Router[ctx.routerMechanism](`${insidePath}/outside/about`)
    return
  }

  if (ctx.fromInside) {
    const { insideTutorialPath } = getDirectiveSpec(directive_type)
    await Router[ctx.routerMechanism](insideTutorialPath)
    return
  }

  const directive = await postUserDirective({ directive_type }).then(unwrapResponse.body)
  const userMe = await getUserMe().then(unwrapResponse.body)

  await Router[ctx.routerMechanism](
    encodeQuery(getDirectiveEditLink(directive, userMe), { created: true })
  )
}
