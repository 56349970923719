import styled from '@emotion/styled'
import { ElementType } from 'react'
import { getResponsiveStyles, ResponsiveValue, StyledLinkProps } from 'utils/styled'

function headerVariantFromAs(
  as?: ElementType
): 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined {
  if (as === 'h1' || as === 'h2' || as === 'h3' || as === 'h4' || as === 'h5' || as === 'h6') {
    return as
  }

  return undefined
}

export type TTitleVariant = ResponsiveValue<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7'>
export const Title_h1_default: TTitleVariant = ['h4', 'h3', 'h1']
export const Title_h2_default: TTitleVariant = ['h5', 'h4', 'h2']
export const Title_h3_default: TTitleVariant = ['h5', 'h4', 'h3']
export const Title_h4_default: TTitleVariant = ['h5', 'h5', 'h4']

export const Title = styled.h1<{
  variant: TTitleVariant
  as?: ElementType
}>`
  font-family: var(--f-heading);
  font-style: normal;
  font-weight: normal;
  color: var(--c-gray100);
  margin: 0;
  white-space: pre-wrap;

  ${({ variant, as }) =>
    getResponsiveStyles(variant ?? headerVariantFromAs(as) ?? 'h1', {
      h1: { fontSize: '3.625rem', lineHeight: '128%' },
      h2: { fontSize: '3rem', lineHeight: '128%' },
      h3: { fontSize: '2.625rem', lineHeight: '128%' },
      h4: { fontSize: '2.25rem', lineHeight: '128%' },
      h5: { fontSize: '1.75rem', lineHeight: '128%' },
      h6: {
        fontSize: '1.5rem',
        lineHeight: '133%',
        fontWeight: 'bold',
        fontFamily: 'var(--f-text)',
      },
      h7: {
        fontSize: '1.25rem',
        lineHeight: '150%',
        fontWeight: 'bold',
        fontFamily: 'var(--f-text)',
      },
    })}
`

type TTextVariant = ResponsiveValue<
  | 'subtitleBold'
  | 'body'
  | 'bodyBold'
  | 'bodyLarge'
  | 'bodyLargeBold'
  | 'bodySmall'
  | 'bodyMedium'
  | 'bodyMediumBold'
  | 'label'
  | 'labelSmall'
  | 'inputLabel'
  | 'inputBody'
  | 'italic'
>
export const Text = styled.span<{ variant?: TTextVariant; color?: string; as?: ElementType }>`
  display: inline-block;
  font-family: var(--f-text);
  font-style: normal;
  ${({ color }) => `
    color: ${color || 'var(--c-gray500)'};
  `}
  margin: 0;
  white-space: pre-wrap;

  ${({ variant }) =>
    getResponsiveStyles(variant ?? 'body', {
      subtitleBold: { fontSize: '1.5rem', lineHeight: '150%', fontWeight: 'bold' },
      body: { fontSize: '1rem', lineHeight: '175%' },
      bodyBold: { fontSize: '1rem', lineHeight: '175%', fontWeight: 'bold' },
      bodyLarge: { fontSize: '1.0625rem', lineHeight: '150%' },
      bodyLargeBold: { fontSize: '1.0625rem', lineHeight: '150%', fontWeight: 'bold' },
      bodySmall: { fontSize: '0.8125rem', lineHeight: '154%' },
      bodyMedium: { fontSize: '0.875rem', lineHeight: '171%', fontWeight: 400 },
      bodyMediumBold: { fontSize: '0.875rem', lineHeight: '171%', fontWeight: 'bold' },
      label: { fontSize: '0.6875rem', lineHeight: '164%' },
      labelSmall: { fontSize: '0.625rem', lineHeight: '180%' },
      inputLabel: { fontSize: '0.75rem', lineHeight: '117%' },
      inputBody: { fontSize: '1rem', lineHeight: '125%' },
      italic: { fontSize: '1rem', lineHeight: '180%', fontStyle: 'italic' },
    })}
  strong {
    font-weight: bold;
  }

  & > ul {
    white-space: normal;
    margin: 0.25rem 0;
  }
`

export const Caption = styled.p<StyledLinkProps>`
  font-family: var(--f-text);
  font-style: normal;
  color: var(--c-gray500);
  margin: 0;
  white-space: pre-wrap;
  font-size: 0.75rem;
  line-height: 200%;
  font-weight: bold;
  letter-spacing: 0.22em;
  text-transform: uppercase;
`
