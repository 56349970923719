import styled from '@emotion/styled'
import { Text } from './Typography'
import { ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'

export const BasicLink = styled(Text.withComponent('a'))`
  text-decoration: underline;
  cursor: pointer;
  ${({ variant }) => (!variant ? `font-size: unset;` : null)}

  &:hover {
    text-decoration: underline;
  }
`

export const PrimaryLink = styled(BasicLink)`
  font-weight: bold;
  text-decoration: none;
  color: var(--c-blue500);
`

export const PrimaryFakeLink = PrimaryLink.withComponent('span')

export const PrimaryLinkAligned = styled(PrimaryLink)`
  display: flex;
  align-items: center;
`

export const SecondaryLink = styled(BasicLink)`
  text-decoration: none;
`

export function LinkWrapper(props: {
  href?: LinkProps['href']
  as?: LinkProps['as']
  children: ReactNode
}) {
  if (props.href) {
    return (
      <Link href={props.href} as={props.as} passHref legacyBehavior>
        {props.children}
      </Link>
    )
  } else {
    return <>{props.children}</>
  }
}
