import { Action } from 'components/actions/utils'
import { DirectiveType } from 'components/directive/utils/types'
import { Icon, IconName, IconProps } from 'components/icon'
import { useTranslation } from 'next-i18next'
import { colors } from 'utils/colors'
import { useFormattedMinutes } from 'utils/format'
import { Namespaces, TranslationKey } from 'utils/i18n/types'

export type LPActionCardKey = keyof Namespaces['common']['common']['action_cards']
type LPActionCardSpec = {
  type?: DirectiveType
  iconName: IconName
  iconProps?: IconProps
  minutes?: number
  url?: string
  urlAs?: string
  disabled?: boolean
}

const LPActionCardSpecs: Record<LPActionCardKey, LPActionCardSpec> = {
  will: {
    type: 'WILL',
    iconName: 'Will',
    minutes: 15,
    url: '/will',
  },
  funeral: {
    type: 'FUNERAL',
    iconName: 'FuneralDirective',
    minutes: 8,
    url: '/funeral',
  },
  trust: {
    type: 'TRUST',
    iconName: 'Handshake',
    minutes: 5,
    url: '/trust',
  },
  pet: {
    type: 'PET',
    iconName: 'PetDirective',
    minutes: 10,
    url: '/pet-directive',
  },
  ahcd: {
    type: 'MEDICAL',
    iconName: 'HealthCareDirective',
    minutes: 8,
    url: '/advance-health-care-directive',
  },
  poa: {
    type: 'POWER_OF_ATTORNEY',
    iconName: 'PowerOfAttorney',
    minutes: 8,
    url: '/financial-power-of-attorney',
  },
  digital_vault: {
    iconName: 'DigitalVault',
    url: '/digital-vault',
  },
  future_messages: {
    iconName: 'Send',
    url: '/future-message',
  },
  live_portraits: {
    iconName: 'LivePortrait',
    url: '/memories',
  },
  singing_portraits: {
    iconName: 'SingingPortrait',
    url: '/singing-portraits',
  },
  story_portraits: {
    iconName: 'Voice',
    url: '/story-portraits',
  },
  timelines: {
    disabled: true,
    iconName: 'Hourglass',
  },
  documents: {
    iconName: 'TextDocument',
    url: '/me/document',
  },
  online_accounts: {
    iconName: 'ShieldCheck',
    url: '/me/site',
  },
  passcodes_devices: {
    iconName: 'PassCode',
    url: '/me/device',
  },
  trusted_contacts: {
    iconName: 'TrustedContact',
    url: '/me/trusted-contact',
  },
  cyberscout: {
    url: '/cyberscout-coverage',
    iconName: 'ColorCyberscout',
    iconProps: {
      width: '3.75rem',
    },
  },
  dashlane: {
    url: '/dashlane-password-manager',
    iconName: 'ColorDashlane',
    iconProps: {
      width: '5.625rem',
    },
  },
  secure_accounts: { url: '/digital-vault', iconName: 'UserProfileTap' },
  secure_devices: { url: '/digital-vault', iconName: 'PhoneLock' },
  secure_documents: {
    url: '/digital-vault',
    iconName: 'DocumentReady',
  },
  eol_family_history: {
    iconName: 'Family',
    url: '/articles/[articleSlug]',
    urlAs: '/articles/how-to-create-your-own-family-tree',
  },
  eol_social_media: {
    iconName: 'ShieldCheck',
    url: '/articles/[articleSlug]',
    urlAs: '/articles/top-3-steps-on-your-digital-death-to-do-list',
  },
  eol_protect_accounts: {
    iconName: 'UserProfileTap',
    url: '/articles/[articleSlug]',
    urlAs: '/articles/dont-become-a-victim-in-the-online-afterlife',
  },
  eol_documents: {
    iconName: 'TextDocument',
    url: '/articles/[articleSlug]',
    urlAs: '/articles/without-sharing-critical-documents-could-be-lost',
  },
  eol_future_messages: {
    iconName: 'Send',
    url: '/articles/[articleSlug]',
    urlAs: '/articles/new-create-inspirational-future-message-now-to-travel-through-time',
  },
}

export function useLPActions<T extends LPActionCardKey>(
  actionKeys: T[],
  extraConfig?: {
    forAll?: Partial<Action>
    forSomeKeys?: {
      [key in T]?: Partial<Action>
    }
  }
): Action[] {
  const { t } = useTranslation()

  const actions: Action[] = actionKeys.map((key) => {
    const { iconName, iconProps, minutes, type, url, urlAs, disabled } = LPActionCardSpecs[key]
    const formattedMinutes = useFormattedMinutes(minutes)

    const buttonText = String(
      disabled
        ? t(`common.action_cards.${key}.disabled_button` as TranslationKey<'common'>)
        : t(`common.action_cards.${key}.button` as TranslationKey<'common'>)
    )

    return {
      type,
      url,
      urlAs,
      icon: <Icon name={iconName} size="1.75rem" color={colors.gray[500]} {...iconProps} />,
      title: t(`common.action_cards.${key}.title`),
      description: t(`common.action_cards.${key}.desc`),
      buttonText,
      duration: formattedMinutes,
      ...extraConfig?.forAll,
      ...extraConfig?.forSomeKeys?.[key],
    }
  })
  return actions
}

export const DIRECTIVE_ACTIONS: LPActionCardKey[] = [
  'trust',
  'will',
  'funeral',
  'poa',
  'ahcd',
  'pet',
]
