import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { BREAKPOINTS } from 'utils/styled'
import { Button } from 'components/button/Button'
import { Icon } from 'components/icon'

export const SCookieBanner = styled(motion.div)`
  color: var(--c-white);
  background: white;
  padding: 1rem;

  box-shadow: var(--card-shadow);
  position: fixed;
  z-index: 25;
  transform: translateZ(25px);

  display: flex;
  align-items: center;

  max-width: 712px;

  gap: 1rem;

  bottom: 1.5rem;
  left: 1rem;
  right: 1rem;
  margin: auto;

  @media ${BREAKPOINTS.MD.max} {
    bottom: 1rem;

    flex-direction: column;
  }
`

export const SCookieButton = styled(Button)`
  min-width: 5rem;
  white-space: nowrap;
`

export const SIcon = styled(Icon)`
  min-width: 1.5rem;
`
