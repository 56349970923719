import { css } from '@emotion/react'
import { Button } from 'components/button/Button'
import { Checkbox } from 'components/checkbox/Checkbox'
import { MandatoryContactInfoContext } from 'components/directive/medical/form/physician/MandatoryContactInfo'
import { Fieldset } from 'components/fieldset/Fieldset'
import { FormButton, FormFieldset } from 'components/FormButton'
import { InputGrid } from 'components/Grid'
import { Icon } from 'components/icon'
import { CustomPhoneInput } from 'components/inputs/phoneInput/PhoneInput'
import { ConfirmModal } from 'components/modal/confirmModal/ConfirmModal'
import { useModal } from 'components/modal/Modal'
import { Select } from 'components/select/Select'
import { Text } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { USE_RELATIONSHIP_OPTIONS } from 'utils/constants'
import { formatNameWithMiddleName } from 'utils/format'
import { useWindowSize } from 'utils/hooks'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'
import { ApiType, GenericAsyncFunction, GenericFunction } from 'utils/types'
import { SButtonsContainer, SForm } from './PersonForm.styled'

type PersonFields = ApiType['CreatePersonCommand']

export interface PersonFormFields extends PersonFields {
  isContact?: boolean
  uuid?: string
}

export type PersonFormProps = {
  onSubmit: (data: PersonFormFields) => void
  onDelete: GenericAsyncFunction
  defaultValues?: Partial<PersonFormFields> | null
  onCancel?: GenericFunction
  autoFocusField?: 'first_name' | 'last_name' | 'middle_name' | 'email'
}

export const PersonForm = (props: PersonFormProps) => {
  const { t } = useDynamicTranslations('directive')
  const common_t = useTranslation()
  const validation = useFieldValidation()
  const { isDesktop } = useWindowSize()
  const form = useForm<PersonFormFields>({
    defaultValues: {
      ...props.defaultValues,
      isContact: !props.defaultValues?.uuid && props.defaultValues?.isContact,
      relationship: props.defaultValues?.relationship,
    },
  })
  const autoFocusField = props.autoFocusField
  const mandatoryContactInfo = useContext(MandatoryContactInfoContext)

  const [deleteModal, showDeleteModal] = useModal(({ close, isOpen }) =>
    isOpen ? (
      <ConfirmModal
        title={t('directive.form_input.person_modal.delete_confirm.title', {
          who: formatNameWithMiddleName(
            props.defaultValues?.first_name,
            props.defaultValues?.middle_name,
            props.defaultValues?.last_name
          ),
        })}
        description={t('directive.form_input.person_modal.delete_confirm.description')}
        danger
        confirmText={t('directive.form_input.person_modal.delete_confirm.confirm_button')}
        onConfirm={props.onDelete}
        close={close}
      />
    ) : null
  )

  const isContact = form.watch('isContact')
  const { clearErrors } = form
  useEffect(() => {
    if (!isContact) {
      clearErrors('email')
    }
  }, [clearErrors, isContact])
  const relationshipOptions = USE_RELATIONSHIP_OPTIONS()

  return (
    <>
      {deleteModal}
      <SForm noValidate onSubmit={form.handleSubmit(async (data) => await props.onSubmit(data))}>
        <InputGrid columns={3} css={{ marginBottom: 0 }}>
          <FormFieldset form={form}>
            <Fieldset>
              <input
                {...form.register('first_name', validation.required())}
                type="text"
                placeholder="&nbsp;"
                autoFocus={autoFocusField === 'first_name'}
              />
              <label>
                <Trans t={t} i18nKey="directive.form_input.person_modal.first_name" />
              </label>
            </Fieldset>
          </FormFieldset>
          <FormFieldset form={form}>
            <Fieldset>
              <input
                {...form.register('middle_name')}
                type="text"
                placeholder="&nbsp;"
                autoFocus={autoFocusField === 'middle_name'}
              />
              <label>
                <Trans t={t} i18nKey="directive.form_input.person_modal.middle_name" />
              </label>
            </Fieldset>
          </FormFieldset>
          <FormFieldset form={form}>
            <Fieldset>
              <input
                {...form.register('last_name', validation.required())}
                type="text"
                placeholder="&nbsp;"
                autoFocus={autoFocusField === 'last_name'}
              />
              <label>
                <Trans t={t} i18nKey="directive.form_input.person_modal.last_name" />
              </label>
            </Fieldset>
          </FormFieldset>
          <FormFieldset form={form}>
            <Fieldset css={{ gridColumnEnd: 'span 3' }}>
              <input
                {...form.register('email', {
                  ...(!!isContact
                    ? validation.requiredTrustedContact()
                    : mandatoryContactInfo.email
                    ? validation.required()
                    : { required: false }),
                  ...validation.email(),
                })}
                type="email"
                placeholder="&nbsp;"
                autoFocus={autoFocusField === 'email'}
              />

              <label>
                <Trans
                  t={t}
                  i18nKey="directive.form_input.person_modal.email"
                  context={!isContact && !mandatoryContactInfo.email ? 'optional' : undefined}
                />
              </label>
            </Fieldset>
          </FormFieldset>
          <Controller
            name="phone_number"
            rules={{
              validate: (value) => {
                if (mandatoryContactInfo.phone_number && !value) {
                  return t('directive.form_input.person_modal.phone_number_required')
                }
                if (!value || isPossiblePhoneNumber(value)) {
                  return true
                } else {
                  return common_t.t('common.form.invalid_phone')
                }
              },
            }}
            control={form.control}
            render={({ field }) => (
              <FormFieldset name="phone_number" form={form}>
                <CustomPhoneInput
                  {...field}
                  value={field.value || ''}
                  css={{ gridColumnEnd: 'span 3' }}
                  label={common_t.t('common.labels.phone_number', {
                    context: mandatoryContactInfo.phone_number ? undefined : 'optional',
                  })}
                />
              </FormFieldset>
            )}
          />
          <Controller
            name="relationship"
            rules={validation.required()}
            control={form.control}
            render={({ field }) => (
              <FormFieldset name={field.name} form={form}>
                <Select
                  {...field}
                  label={t('directive.form_input.person_modal.relationship_select')}
                  readOnly={!!props.defaultValues?.relationship}
                  css={{ gridColumnEnd: 'span 3' }}
                  items={relationshipOptions}
                />
              </FormFieldset>
            )}
          />
        </InputGrid>
        {props.defaultValues?.uuid ? (
          <Button
            variant="text"
            tone="gray"
            type="button"
            leftIcon={<Icon name="Bin" />}
            onClick={showDeleteModal}
            css={css`
              justify-content: flex-start;
            `}
          >
            {isDesktop ? (
              <Trans t={t} i18nKey="directive.form_input.person_modal.remove_button" />
            ) : (
              <Trans t={t} i18nKey="directive.form_input.person_modal.remove_button_short" />
            )}
          </Button>
        ) : (
          <FormFieldset form={form}>
            <Checkbox css={{ gridColumnEnd: 'span 3' }} {...form.register('isContact')}>
              <Text>
                {isDesktop ? (
                  <Trans t={t} i18nKey="directive.form_input.person_modal.invite_checkbox" />
                ) : (
                  <Trans t={t} i18nKey="directive.form_input.person_modal.invite_checkbox_short" />
                )}
              </Text>
            </Checkbox>
          </FormFieldset>
        )}

        <SButtonsContainer>
          {props.onCancel && (
            <Button variant="secondary" size="large" type="button" onClick={props.onCancel}>
              <Trans t={t} i18nKey="directive.form_input.person_modal.cancel_button" />
            </Button>
          )}
          <FormButton form={form}>
            <Button
              size="large"
              type="button"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                form.handleSubmit(async (data) => await props.onSubmit(data))()
              }}
            >
              {props.defaultValues?.uuid ? (
                <Trans t={t} i18nKey="directive.form_input.person_modal.save_button_edit" />
              ) : (
                <Trans t={t} i18nKey="directive.form_input.person_modal.save_button_add" />
              )}
            </Button>
          </FormButton>
        </SButtonsContainer>
      </SForm>
    </>
  )
}
