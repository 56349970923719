import { getUserMe } from 'api/goodtrust/user'
import { PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { AnalyticsEvent } from 'utils/analytics/event'
import { unwrapResponse } from 'utils/fetcher'

/**
 * This event is fired when the user enters the DowngradePage.
 */
export const downgrade_open = new AnalyticsEvent<{ downgradePlan: PlanType }>({
  async onFire({ args: { downgradePlan }, ...ctx }) {
    const userMe = await getUserMe().then(unwrapResponse.body)

    const downgradePlanSpec = planSpecs[downgradePlan]

    const activePlanSpec = describeMyPlans(userMe)
      .forPlanGroup(downgradePlanSpec.planGroup)
      .getActivePlanSpec()

    ctx.fireEvent('downgrade_open', {
      previous_plan: activePlanSpec?.analyticsPlanType ?? 'unknown',
      new_plan: downgradePlanSpec?.analyticsPlanType ?? 'unknown',
    })
  },
})
