import { captureException } from '@sentry/nextjs'
import { Nullable, NumberRangeArray } from './types'

/**
 * Unified function for debug logs
 * @param args
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logMsg(...args: any) {
  if (process.env.NODE_ENV === 'test') return
  // eslint-disable-next-line no-console
  console.log(...args)
}

/**
 * Unified function for warning logs
 * @param args
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logWarn(...args: any) {
  console.warn(...args)
}

/**
 * Unified function for error logs
 * @param firstArg
 * @param args
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logError(firstArg: any, ...args: any) {
  console.error(firstArg, ...args)
  captureException('Manual exception: ' + firstArg)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logDebug(name: string, ...args: any[]): void
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logDebug(record: Record<string, any>, ...args: any[]): void
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logDebug(nameOrRecord: string | Record<string, any>, ...args: any[]) {
  if (typeof nameOrRecord === 'string') {
    // eslint-disable-next-line no-console
    console.debug(`[debug] ${nameOrRecord}`, ...args)
  } else {
    // eslint-disable-next-line no-console
    console.debug('[debug]', ...Object.entries(nameOrRecord).flat())
  }
}

export function identDebug<T>(value: T, name = 'ident'): T {
  logDebug({ [name]: value })
  return value
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function noop() {
  // do nothing
  return
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * Clamp(x, 0, 255)
 */
export function Clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max)
}

type NonNullableFields<T, RequiredKeys extends keyof T> = {
  [Key in RequiredKeys]-?: Key extends RequiredKeys ? NonNullable<T[Key]> : T[Key]
} & Omit<T, RequiredKeys> &
  T

export function assertFields<T, OmitKeys extends keyof T>(
  data: T,
  keys: OmitKeys[],
  message?: string
): asserts data is NonNullableFields<T, OmitKeys> {
  if (data == null) throw Error(message)
  for (const key of keys) {
    if (data[key] == null) throw Error(message)
  }
}

export function isObjectEmpty(obj: Record<string, unknown> | undefined) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) return false
  }

  return true
}

export const getWindowCenter = (width: number, height: number) => {
  if (typeof window === 'undefined') return { x: 0, y: 0 }
  const pos = {
    x: window.screen.width / 2 - width / 2,
    y: window.screen.height / 2 - height / 2,
  }
  return pos
}

export const getIsMobile = () =>
  typeof window !== 'undefined'
    ? /(android|iphone|ipad|mobile)/i.test(window.navigator.userAgent)
    : false

export const getRandomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export function pickRandomArrayItem<TItem>(items: TItem[], exceptFor?: TItem): TItem | undefined {
  if (items.length === 0) return undefined
  const index = getRandomInteger(0, items.length)
  const item = items[index]
  if (item === exceptFor) {
    if (items.length === 1) return undefined
    return items[(index + getRandomInteger(1, items.length)) % items.length]
  }
  return item
}

export const getUTCDateWithoutTime = () => {
  // Get date
  const tmpDate = new Date()
  // Convert to UTC
  const dateUtc = tmpDate.toISOString()
  // Remove hours
  return new Date(dateUtc.replace(/T.*/, ''))
}

export function truncateDecimals(number: number, n = 2) {
  return Math.trunc(number * Math.pow(10, n)) / Math.pow(10, n)
}

export function modifyCurrentHistory(url: string, title: string) {
  try {
    if (typeof window !== 'undefined') {
      const newState = Object.assign({}, window.history.state)
      newState.url = url
      newState.as = url
      window.history.replaceState(newState, title)
    }
  } catch (e) {
    logError(e)
  }
}

export function mergeRefs<T>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) (ref as React.MutableRefObject<T | null>).current = value
    })
  }
}

export const camelCaseFromKebabCase = (kebabCase: string) => {
  return kebabCase.replace(/-.?/g, (substring) => {
    return substring.substring(1).toUpperCase()
  })
}

export const getReadableStringFromCamelCase = (camelCase: string, capitalizeFirstLetter = true) => {
  const afterSpacesAdded = camelCase.replace(/[A-Z]/g, (substring) => {
    return ' ' + substring
  })
  const afterCapitalization = afterSpacesAdded.replace(/^./, (firstLetter) =>
    capitalizeFirstLetter ? firstLetter.toUpperCase() : firstLetter
  )
  return afterCapitalization
}

export const getReadableString = (text = '', capitalFirstLetter = true) =>
  text?.[0] &&
  `${capitalFirstLetter ? text[0].toUpperCase() : text[0].toLowerCase()}${text
    .substring(1)
    ?.toLowerCase?.()}`?.replace?.(/_/g, ' ')

export const isEventInElement = (e: MouseEvent, element: HTMLElement) => {
  const rectangle = element.getBoundingClientRect()
  const x = e.clientX
  const y = e.clientY
  if (x < rectangle.left || x > rectangle.right) return false
  if (y < rectangle.top || y > rectangle.bottom) return false
  return true
}

/**
 * Returns false if x is null or undefined, else returns true.
 * Can be used to filter out nullish values from an array and tell TypeScript that the array no longer contains such values.
 */
export function isNonNullable<T>(x: T): x is NonNullable<T> {
  return x != null
}

export function removeNullFields<T extends unknown>(x: Partial<Nullable<T>>): Partial<T> {
  return Object.fromEntries(
    Object.entries(x as Record<string, unknown>).filter(isNonNullable)
  ) as Partial<T>
}

export const isElementInViewport = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  if (typeof window !== 'undefined') {
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
  return false
}

export function scrollToRect(rect: DOMRect) {
  if (typeof window === 'undefined') return
  const y = rect.top + window.scrollY - 50
  window.scrollTo({ top: y, behavior: 'smooth' })
}

export const safeWindow = typeof window !== 'undefined' ? window : undefined
export const safeVisualViewport =
  safeWindow && 'visualViewport' in safeWindow ? safeWindow.visualViewport : undefined

export const withoutLeadingSlash = (val: string) => val.match(/^\/?(.*)$/)?.[1] ?? val

export const isIos = () => !!safeWindow?.navigator.userAgent.match(/(iphone|ipad)/i)

export const getNumberArray = (size: number, start = 0) =>
  [...Array(size).keys()].map((i) => i + start)

export function sortEntries<T extends Record<string, unknown>>(obj: T): T {
  const sortedEntries = Object.entries(obj).sort((p, n) => p[0].localeCompare(n[0]))
  return Object.fromEntries(sortedEntries) as T
}

type BooleanStr = 'true' | 'false'
type Concatenate<
  T extends string,
  N extends number,
  Acc extends string = '',
  Sum extends T[] = []
> = Sum['length'] extends N
  ? Acc
  : Sum['length'] extends 0
  ? Concatenate<T, N, T, [T]>
  : Concatenate<T, N, `${T}-${Acc}`, [...Sum, T]>

function matchMapBoolHelper<
  TKey extends Concatenate<BooleanStr, N>,
  N extends number,
  TMap extends Record<TKey, unknown>
>(key: TKey, n: N, map: TMap): TMap[keyof TMap] {
  return map[key]
}
export function matchMapBool<
  TKey extends boolean[],
  N extends number,
  TMap extends Record<Concatenate<BooleanStr, N>, unknown>
>(bools: TKey, n: N, map: TMap) {
  const boolsToString = bools.map((b) => (b ? 'true' : 'false')).join('-') as Concatenate<
    BooleanStr,
    N
  >
  return matchMapBoolHelper(boolsToString, n, map)
}

export function matchMap<TKey extends string, TMap extends Record<TKey, unknown>>(
  key: TKey,
  map: TMap
): TMap[keyof TMap] {
  return map[key]
}

export function matchPartialMap<TKey extends string, TMap extends Partial<Record<TKey, unknown>>>(
  key: TKey | undefined,
  map: TMap
): TMap[keyof TMap] | undefined {
  if (key == null) return key
  return map[key]
}

/**
 * Requires to pass a record with keys of each value of given string union, which can help to cover all cases.
 *
 * It's strict because it's stricter than matchMap.
 */
export function matchMapStrict<TKey extends string, TReturnType>(
  key: TKey,
  map: Record<TKey, TReturnType>
): (typeof map)[TKey] {
  return map[key]
}

/**
 * Can be used to ensure in TypeScript that a object literal is of a given type and enable intellisense.
 */
export function ident<T>(t: T): T {
  return t
}

/**
 * Can be used to ensure in TypeScript that a object literal is of a given record type and enable intellisense.
 */
export function record<TKey extends string, TVal>(record: Record<TKey, TVal>): Record<TKey, TVal> {
  return record
}

/**
 * Can be used to ensure in TypeScript that an object literal extends a given type and enable intellisense.
 */
export function ensureExtends<TExtended>() {
  return function <TExtends extends TExtended>(ext: TExtends): TExtends {
    return ext
  }
}

/**
 * Can be used to ensure in TypeScript that an object literal extends a record with the given type as its value and enable intellisense.
 */
export function ensureExtendsRecord<TKey extends string, TRecordValue>() {
  return ensureExtends<Record<TKey, TRecordValue>>()
}

export class SpecBuilder<TSpec> {
  build<TKey extends string>(specs: Record<TKey, TSpec>) {
    return specs
  }
}

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ret: any = {}
  keys.forEach((key) => {
    ret[key] = obj[key]
  })
  return ret
}

export function isOneOf<TSet extends string>(key: string | undefined, set: TSet[]): key is TSet {
  return set.some((item) => item === key)
}

export function lastItemOf<TItem extends unknown>(arr: TItem[]): TItem | undefined {
  return arr[arr.length - 1]
}

export function keysOf<TMap extends Record<string, unknown>>(map: TMap): (keyof TMap)[] {
  return Object.keys(map)
}

export function entriesOf<TMap extends Record<string, unknown>>(
  map: TMap
): [keyof TMap, TMap[keyof TMap]][] {
  return Object.entries(map) as unknown as [keyof TMap, TMap[keyof TMap]][]
}

export function valuesOf<TMap extends Record<string, unknown>>(map: TMap): TMap[keyof TMap][] {
  return Object.values(map) as unknown as TMap[keyof TMap][]
}

export function fromEntries<TKey extends string, TValue>(
  entries: [TKey, TValue][]
): Record<TKey, TValue> {
  return Object.fromEntries(entries) as Record<TKey, TValue>
}

export function toLowerCase<TString extends string>(str: TString): Lowercase<TString> {
  return String(str).toLowerCase() as Lowercase<TString>
}

export function toUpperCase<TString extends string>(str: TString): Uppercase<TString> {
  return String(str).toUpperCase() as Uppercase<TString>
}

export function numberComparator<T>(map: (x: T) => number): (p: T, n: T) => number {
  return (p: T, n: T): number => {
    const pNumber = map(p)
    const nNumber = map(n)

    return pNumber - nNumber
  }
}

export function numberRange<T extends number>(size: T): NumberRangeArray<T> {
  //@ts-ignore
  return [...Array(size).keys()].map((i) => i + 1)
}

export function roundTo(number: number, places = 0) {
  const placesModifier = 10 ** places
  return Math.round(number * placesModifier) / placesModifier
}

export function floorTo(number: number, places = 0) {
  const placesModifier = 10 ** places
  return Math.floor(number * placesModifier) / placesModifier
}

export function ceilTo(number: number, places = 0) {
  const placesModifier = 10 ** places
  return Math.ceil(number * placesModifier) / placesModifier
}

export function tryParseJson<T>(str: string | null | undefined) {
  try {
    if (str == null) return undefined
    return JSON.parse(str) as T
  } catch {
    return undefined
  }
}

export function resolvablePromise<T>() {
  let resolve!: (val: T) => void
  let reject!: (err: Error) => void
  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  return {
    resolve,
    reject,
    promise,
  }
}
