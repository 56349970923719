import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { colors } from 'utils/colors'
import { matchMap } from 'utils/general'

export function describeSimpleFlowDirectiveLandingPage(directiveType: SimpleFlowDirectiveType) {
  return {
    getButtonTone: () =>
      matchMap(directiveType, {
        TRUST: 'trust',
        WILL: 'standard',
      } as const),
    getHeroTone: () =>
      matchMap(directiveType, {
        TRUST: 'trust',
        WILL: 'light-blue',
      } as const),

    getIconColors: () =>
      matchMap(directiveType, {
        WILL: {
          iconColor: colors.blue[500],
          backgroundColor: colors.blue[100],
        },
        TRUST: {
          iconColor: colors.green[200],
          backgroundColor: colors.green[100],
        },
      }),
  }
}
