import { fetcherFactory, FetchResponse } from 'utils/fetcher'
import { wrapAuthFetch } from 'utils/auth/wrapAuthFetch'
import immer, { Draft } from 'immer'

export const API_URL = process.env.NEXT_PUBLIC_API_URL as string
export const fetcher = fetcherFactory()
export const apiFetcher = fetcherFactory(API_URL, true)
export const apiAuthFetcher = wrapAuthFetch(apiFetcher)

export function mapFetcherResult<TResult>(
  fetcher: typeof apiAuthFetcher,
  recipe: (data: Draft<FetchResponse<TResult>>) => void
) {
  // @ts-ignore
  const resultFetcher: typeof fetcher = (...args: Parameters<typeof fetcher>) =>
    // @ts-ignore
    fetcher(...args).then((res: FetchResponse<TResult>) => {
      return immer(res, recipe)
    })

  return resultFetcher
}
