import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icon } from 'components/icon'
import { colors } from 'utils/colors'

export const SLabel = styled.label`
  transition-delay: 0.1s !important;
`

export const SSelectItem = styled.li<{ selected?: boolean }>`
  font-family: var(--f-text);
  padding: 1rem calc(2px + 0.875rem);
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--c-blue100);
  }

  ${(props) => {
    if (props.selected) {
      return css`
        font-weight: bold;
      `
    }
    return null
  }};
`

export const SInput = styled.input<{ disableFiltering?: boolean }>`
  &:read-only {
    cursor: pointer;
  }
`

export const SIconArrow = styled(Icon)`
  cursor: pointer;
  color: ${colors.gray[500]};
`
