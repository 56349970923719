import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRem } from 'utils/styled'
import { ModalPlacement } from './Modal'
import { RemoveScroll } from 'react-remove-scroll'
import ReactFocusLock from 'react-focus-lock'
import { Button } from 'components/button/Button'
import { InterpolationPrimitive } from '@emotion/serialize'

export const SModal = styled.div<{
  modalCss?: InterpolationPrimitive
  mobilePlacement?: ModalPlacement
}>`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-width: 768px;
  max-height: 100%;
  padding: 0.5rem;
  justify-content: center;

  @media ${BREAKPOINTS.MD.max} {
    padding: 0;
    justify-content: unset;
    ${({ mobilePlacement = 'bottom' }) =>
      mobilePlacement === 'bottom'
        ? 'flex-direction: column-reverse;'
        : mobilePlacement === 'top'
        ? 'justify-content: flex-start;'
        : 'justify-content: center;'};
  }

  ${({ modalCss }) => modalCss}
`

export const SFocusLock = styled(ReactFocusLock)`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const SModalContent = styled(RemoveScroll, {
  shouldForwardProp: (p) => p !== 'modalContentCss',
})<{
  modalContentCss?: InterpolationPrimitive
}>`
  padding: 1.6875rem;
  background: var(--c-white);
  box-shadow: var(--card-shadow);
  border-radius: 2px;
  max-height: calc(100vh - 4rem);

  position: relative;
  overflow-y: auto;

  @media ${BREAKPOINTS.MD.max} {
    padding: 0.875rem;
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      padding-bottom: calc(env(safe-area-inset-bottom) + 0.875rem);
      transition: padding-bottom 1s;
    }
  }

  ${({ modalContentCss }) => modalContentCss}
`

export const SModalCloseButton = styled(Button)<{
  modalCloseButtonCss?: InterpolationPrimitive
}>`
  z-index: 1;
  margin-left: auto;
  position: absolute;
  top: calc(${pxToRem(27 - 12)}rem + (${pxToRem(21)}rem * 1.19 - 16px) / 2);
  right: calc(${pxToRem(27 - 12)}rem + (${pxToRem(21)}rem * 1.19 - 16px) / 2);
  height: auto;

  padding: ${pxToRem(12)}rem;

  @media ${BREAKPOINTS.MD.max} {
    top: calc(${pxToRem(14 - 12)}rem + (${pxToRem(21)}rem * 1.19 - 16px) / 2);
    right: calc(${pxToRem(14 - 12)}rem + (${pxToRem(21)}rem * 1.19 - 16px) / 2);
  }
  ${({ modalCloseButtonCss }) => modalCloseButtonCss}
`

export const SPopperModal = styled.div<{ noStyle?: boolean }>`
  ${({ noStyle }) =>
    !noStyle &&
    `
  background-color: white;
  padding: 1rem;
  box-shadow: var(--card-shadow);
  z-index: 2;
  `}
`
