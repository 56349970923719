export const MockApiText = () => {
  return (
    <div
      css={{
        position: 'fixed',
        top: 4,
        left: '50%',
        color: 'red',
        zIndex: 1000,
        transform: 'translateX(-50%)',
        fontFamily: 'monospace',
      }}
    >
      MOCK API MODE
    </div>
  )
}
