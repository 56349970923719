import { keysOf } from 'utils/general'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function bindEveryMethod(obj: any) {
  const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(obj))
    .map((strProp) => {
      const prop = strProp as keyof typeof obj
      return {
        prop,
        method: typeof obj[prop] === 'function' && prop !== 'constructor' ? obj[prop] : undefined,
      }
    })
    .filter((x) => x.method != null)

  for (const { prop, method } of methods) {
    // @ts-ignore
    obj[prop] = method.bind(obj)
  }
}

/**
 * Binds all properties that are functions of an object to the object and returns it.
 * This is useful when you want to define method properties and might destructure them later.
 * If you would destructure them without them being bound, their this would no longer refer to the object they were defined on.
 */
export function bound<T extends Record<string, unknown>>(x: T): T {
  for (const name of keysOf(x)) {
    const value = x[name]
    if (typeof value === 'function') {
      x[name] = value.bind(x)
    }
  }
  return x
}
